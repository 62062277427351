import {
  Currencies,
  DEFAULT_CURRENCY,
} from '@u9/bob3-shared/lib/types/api.types';

export const formatMoney = (
  value = 0,
  currency: Currencies = DEFAULT_CURRENCY,
  locale = process.env.DEFAULT_LOCALE,
  options?: {
    precision: number;
  }
): string => {
  return currency
    ? new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: options?.precision ?? 2,
      }).format(value)
    : String(value);
};

export const getMonthName = (
  date: Date,
  locale = process.env.DEFAULT_LOCALE
): string => {
  return new Intl.DateTimeFormat(locale, { month: 'long' }).format(date);
};

export const getDayName = (
  date: Date,
  locale = process.env.DEFAULT_LOCALE
): string => {
  return new Intl.DateTimeFormat(locale, { weekday: 'narrow' }).format(date);
};

export const formatPercent = (
  value: string | number = 0,
  options?: { precision: number }
) => {
  const subject = !isFinite(Number(value))
    ? 0
    : isNaN(Number(value))
    ? 0
    : Number(value);
  const percentage = new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: options?.precision ?? 0,
    maximumFractionDigits: options?.precision ?? 2,
    // https://github.com/microsoft/TypeScript/issues/52072
    // eslint-disable-next-line
    // @ts-ignore
    roundingPriority: 'lessPrecision',
  }).format(Number(subject) / 100);
  return percentage;
};

export const addLeadingZero = (number: number): string => {
  if (number > 9) return `${number}`;

  return `0${number}`;
};

export const formatDate = (value: string | Date): string => {
  const date = new Date(value);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${addLeadingZero(day)} / ${addLeadingZero(month)} / ${year}`;
};

export const formatDateInline = (value?: string | Date | null): string => {
  if (!value) return '';
  const date = new Date(value);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${addLeadingZero(day)}/${addLeadingZero(month)}/${year}`;
};

export const formatDateFilename = (value: string | Date): string => {
  const date = new Date(value);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${addLeadingZero(day)}-${addLeadingZero(month)}-${year}`;
};

export const getDateString = (date: Date): string => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${addLeadingZero(month)}-${addLeadingZero(day)}`;
};

export const formatDays = (days?: number, daysSuffix?: string) => {
  const displayDays = days ?? 0;
  return `${Math.round(displayDays * 100) / 100}${daysSuffix}`;
};
