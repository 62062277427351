import * as Sentry from '@sentry/react';
import { useRouter } from 'next/router';
import { signOut, useSession } from 'next-auth/react';
import React, { useCallback, useMemo } from 'react';

import Badge from 'components/Badge/Badge';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useCopyStore, useGlobalStore } from 'store';
import { ROUTES } from 'utils/routes';
import { generateRandomString } from 'utils/string';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './Menu.styles';

enum MenuIds {
  LOGOUT_ID = 'LOGOUT',
  FAQ_ID = 'FAQ',
  FEEDBACK_ID = 'FEEDBACK',
  NOTIFICATIONS = 'NOTIFICATIONS',
  WHATSNEW = 'WHATSNEW',
  COOKIES = 'COOKIES',
  PROFILE = 'PROFILE',
}

const Menu = () => {
  const router = useRouter();
  const copy = useCopyStore(s => s.copy);
  const { data: session } = useSession();
  const openModal = useGlobalStore(s => s.openModal);

  const items = useMemo(
    () => [
      {
        id: MenuIds.PROFILE,
        displayName: copy.app.menuItems.profile,
      },
      {
        id: MenuIds.WHATSNEW,
        displayName: copy.app.menuItems.whatsNew,
        divider: true,
      },
      {
        id: MenuIds.NOTIFICATIONS,
        displayName: copy.app.menuItems.notifications,
      },
      {
        id: MenuIds.COOKIES,
        displayName: copy.app.menuItems.cookies,
        // This is the way to trigger cookie banner preferences in cookieyes
        // https://www.cookieyes.com/documentation/change-cookie-consent-using-cookieyes/
        cls: 'cky-banner-element',
      },
      // { id: MenuIds.FAQ_ID, displayName: copy.app.menuItems.faq },
      {
        id: MenuIds.FEEDBACK_ID,
        displayName: copy.app.menuItems.feedback,
      },
      { id: MenuIds.LOGOUT_ID, displayName: copy.app.menuItems.logout },
    ],
    [copy]
  );

  const handleClick = useCallback(
    async (id: MenuIds) => {
      if (id === MenuIds.NOTIFICATIONS) {
        router.push(ROUTES.NOTIFICATIONS);
      }
      if (id === MenuIds.FAQ_ID) {
        window.open(copy.app.FAQ.link, '_blank');
      }

      if (id === MenuIds.WHATSNEW) {
        openModal('whatsNew');
      }

      if (id === MenuIds.FEEDBACK_ID) {
        const eventId = Sentry.captureMessage(
          `User feedback (${generateRandomString()})`
        );
        Sentry.showReportDialog({
          eventId: eventId,
          labelSubmit: copy.app.feedbackDialog.labelSubmit,
          title: copy.app.feedbackDialog.title,
          subtitle: copy.app.feedbackDialog.subtitle,
          subtitle2: '',
          labelComments: copy.app.feedbackDialog.labelComments,
          user: {
            email: session?.user?.email || '',
            name: session?.user?.name || '',
          },
          onLoad: () => {
            const input = document.getElementById('id_comments');
            input &&
              input.setAttribute(
                'placeholder',
                copy.app.feedbackDialog.placeholderComments
              );
          },
        });
      }

      if (id === MenuIds.LOGOUT_ID) {
        await signOut({
          redirect: false,
        });
        router.reload();
      }
      if (id === MenuIds.COOKIES) {
        return;
      }
      if (id === MenuIds.PROFILE) {
        router.push(ROUTES.PROFILE);
      }
    },
    [copy.app, openModal, router, session?.user?.email, session?.user?.name]
  );

  return (
    <Styled.DropdownWrapper>
      <DropdownMenu>
        <DropdownMenuTrigger>
          {session?.user?.name}
          <Badge
            color={ColorNames.mountainMeadow}
            styles={{ marginLeft: '20rem' }}
            size="small"
          >
            {copy.app.new}
          </Badge>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {items.map(({ displayName, id, cls }) => (
            <DropdownMenuItem
              key={id}
              onSelect={() => handleClick(id)}
              className={cls}
            >
              {displayName}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.DropdownWrapper>
  );
};

export default React.memo(Menu);
