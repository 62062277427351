import { useMemo, useState } from 'react';

import {
  AdminListItem,
  CategoryIdentifier,
} from 'components/modules/Admin/hooks/useAdminListColumns';
import { useCurrentAdminRoute } from 'containers/AdminPage/hooks/useCurrentAdminRoute';
import { useCopyStore } from 'store';
import { ADMIN_ROUTES } from 'utils/routes';

const defaultValuesMap: Record<string, CategoryIdentifier> = {
  [ADMIN_ROUTES.USERS]: CategoryIdentifier.User,
  [ADMIN_ROUTES.BOB_ACCESS]: CategoryIdentifier.User,
  [ADMIN_ROUTES.INTERNAL]: CategoryIdentifier.Resource,
  [ADMIN_ROUTES.EXTERNAL]: CategoryIdentifier.Resource,
  [ADMIN_ROUTES.RESOURCE_REQUESTS]: CategoryIdentifier.Resource,
  [ADMIN_ROUTES.RESOURCE_UNUSED]: CategoryIdentifier.Resource,
  [ADMIN_ROUTES.CLIENTS]: CategoryIdentifier.Client,
  [ADMIN_ROUTES.CLIENTS_REQUESTS]: CategoryIdentifier.Client,
  [ADMIN_ROUTES.CLIENTS_UNUSED]: CategoryIdentifier.Client,
  [ADMIN_ROUTES.BRANDS]: CategoryIdentifier.Brand,
  [ADMIN_ROUTES.BRANDS_UNUSED]: CategoryIdentifier.Brand,
  [ADMIN_ROUTES.BRANDS_REQUESTS]: CategoryIdentifier.Brand,
  [ADMIN_ROUTES.VENDOR]: CategoryIdentifier.Vendor,
  [ADMIN_ROUTES.VENDOR_UNUSED]: CategoryIdentifier.Vendor,
  [ADMIN_ROUTES.VENDOR_REQUESTS]: CategoryIdentifier.Vendor,
  [ADMIN_ROUTES.VENDOR_SERVICE]: CategoryIdentifier.VendorService,
  [ADMIN_ROUTES.VENDOR_SERVICE_UNUSED]: CategoryIdentifier.VendorService,
  [ADMIN_ROUTES.VENDOR_SERVICE_REQUESTS]: CategoryIdentifier.VendorService,
};

export const useCategoryDropdown = (item?: AdminListItem) => {
  const copy = useCopyStore(s => s.copy);
  const route = useCurrentAdminRoute();
  const [activeCategoryId, setActiveCategoryId] = useState(
    item?.categoryIdentifier ||
      defaultValuesMap[route as unknown as string] ||
      CategoryIdentifier.User
  );
  const categoryItems = useMemo(() => {
    const { users, resource, clients, brands, vendor } =
      copy.admin.editModal.categoryDropdown;
    return [
      {
        displayName: users,
        id: CategoryIdentifier.User,
      },
      {
        displayName: resource,
        id: CategoryIdentifier.Resource,
      },
      { displayName: vendor, id: CategoryIdentifier.Vendor },
      {
        displayName: clients,
        id: CategoryIdentifier.Client,
      },
      {
        displayName: brands,
        id: CategoryIdentifier.Brand,
      },
    ];
  }, [copy.admin.editModal.categoryDropdown]);

  const activeCategoryDisplayName = useMemo(() => {
    return (
      categoryItems.find(item => item.id === activeCategoryId)?.displayName ||
      ''
    );
  }, [activeCategoryId, categoryItems]);

  const previousItemCategory = useMemo(() => {
    return categoryItems.find(
      category => category.id === item?.categoryIdentifier
    )?.displayName;
  }, [categoryItems, item?.categoryIdentifier]);

  return {
    previousItemCategory,
    categoryItems,
    activeCategoryId,
    setActiveCategoryId,
    activeCategoryDisplayName,
  };
};
