import { Dispatch, memo, SetStateAction } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useJobNumberPrefix } from 'hooks/useJobNumberPrefix';
import { useCopyStore } from 'store';
import { Project, ProjectMode } from 'utils/api.types';

import { useProject } from '../../hooks/useProject';

export interface JobNumberProps {
  projectId?: Project['id'];
  activeMode: Project['mode'];
  jobNumber: string;
  setJobNumber: Dispatch<SetStateAction<string>>;
  isJobNumberRequired: boolean;
  duplicateError: string;
  jobNumberError?: string | null;
  canEditProject: boolean;
}

export const JobNumber = ({
  projectId,
  activeMode,
  jobNumber,
  setJobNumber,
  isJobNumberRequired,
  duplicateError,
  jobNumberError,
  canEditProject,
}: JobNumberProps) => {
  const copy = useCopyStore(s => s.copy);
  const { project } = useProject(projectId);
  const { getJobNumberPrefix } = useJobNumberPrefix();

  return (
    <FormInput
      placeholder={copy.app.projectModal.jobNumber}
      value={jobNumber}
      onChange={setJobNumber}
      required={isJobNumberRequired}
      error={duplicateError || jobNumberError}
      forceError={!!duplicateError}
      dataCy="project-modal__job-number-field"
      startHtml={getJobNumberPrefix(
        (activeMode as ProjectMode) ?? project?.mode ?? ProjectMode.pitch
      )}
      disabled={!canEditProject}
      dataIntro="job-number-field"
    />
  );
};

export default memo(JobNumber);
