import { FC, memo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import BaseInput from 'components/inputs/BaseInput/BaseInput';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useClosureStates, usePostClosureState } from 'queries/closureStatess';
import { useCopyStore, useGlobalStore } from 'store';
import { useSvgIcon } from 'u9/hooks';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './DropdownConfigurationModal.styles';

export interface DropdownConfigurationModalProps {}

const DropdownConfigurationModal: FC<DropdownConfigurationModalProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { data: closureStates } = useClosureStates({
    onSuccess: data => setLocalClosureStates(data),
  });
  const { mutateAsync: postClosureStates } = usePostClosureState();
  const { SvgIcon: DeleteIcon } = useSvgIcon('trash');
  const [localClosureStates, setLocalClosureStates] = useState<string[]>(
    closureStates ?? []
  );

  const close = () => {
    closeModal('dropdownConfiguration');
  };

  const handleDelete = (index: number) => () => {
    setLocalClosureStates(localClosureStates.filter((_, i) => i !== index));
  };

  const addItem = () => {
    setLocalClosureStates([...localClosureStates, '']);
  };

  const setItemValue = (value: string, index: number) => {
    setLocalClosureStates(
      localClosureStates.map((item, i) => (i === index ? value : item))
    );
  };

  const handleSave = async () => {
    if (closureStates) {
      await postClosureStates(localClosureStates);
      close();
    }
  };

  return (
    <ConfigModal
      title={copy.app.dropdownConfigurationModal.title}
      color={currentColor}
      onCloseClick={close}
      zeroPadding
    >
      <Styled.Wrapper>
        <Styled.List>
          {localClosureStates.map((value, index) => (
            <Styled.ListItem key={index}>
              <Styled.ListItemLabel>
                <BaseInput
                  value={value}
                  onChange={value => setItemValue(value, index)}
                  placeholder={
                    copy.app.dropdownConfigurationModal.inputPlaceholder
                  }
                  styles={{ width: '100%' }}
                />
              </Styled.ListItemLabel>
              <Styled.ListItemButton onClick={handleDelete(index)}>
                <Tooltip
                  content={
                    copy.app.dropdownConfigurationModal.deleteButtonTooltip
                  }
                  sideOffset={5}
                >
                  <Styled.IconWrapper>
                    <DeleteIcon />
                  </Styled.IconWrapper>
                </Tooltip>
              </Styled.ListItemButton>
            </Styled.ListItem>
          ))}
        </Styled.List>
        <Styled.AddItemButton onClick={addItem}>
          {copy.app.dropdownConfigurationModal.addItemButton}
        </Styled.AddItemButton>
        <Styled.ButtonsWrapper>
          <Button
            onClick={close}
            label={copy.app.dropdownConfigurationModal.closeButton}
            color={ColorNames.nandor}
            icon="close"
          />
          <Button
            onClick={handleSave}
            label={copy.app.dropdownConfigurationModal.saveButton}
            color={currentColor}
            icon="check"
          />
        </Styled.ButtonsWrapper>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(DropdownConfigurationModal);
