import introJs from 'intro.js';
import { IntroJs } from 'intro.js/src/intro';
import Cookies from 'js-cookie';
import { useCallback } from 'react';

import { useCopyStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import {
  INTRO_ADMIN_NAVIGATION_SEEN,
  INTRO_CASHFLOW_SEEN,
  INTRO_INVOICE_MODAL_SEEN,
  INTRO_PROJECT_MODAL_SEEN,
  INTRO_PROJECT_PAGE_SEEN,
  INTRO_PROJECTS_LIST_SEEN,
} from 'utils/cookies';
import { getAdminRoute } from 'utils/routes';
import { sentryCaptureException } from 'utils/sentryCaptureException';

type IntroType =
  | 'projects-list'
  | 'project-modal'
  | 'admin-navigation'
  | 'project-page'
  | 'invoice-modal'
  | 'cashflow';
type IntroMode = 'automatic' | 'on-demand';

export const useIntro = () => {
  const copy = useCopyStore(s => s.copy);
  const { checkAccessRight } = useCheckAccessRights();

  const isOwner = checkAccessRight('owner');
  const isSuperAdmin = checkAccessRight('super admin');

  const getSteps = useCallback(
    (type: IntroType): Parameters<IntroJs['setOptions']>[0]['steps'] => {
      if (type === 'projects-list') {
        return [
          {
            intro: copy.app.intro.projectsList.welcome.text,
            title: copy.app.intro.projectsList.welcome.title,
            tooltipClass: 'intro-center',
          },
          {
            intro: copy.app.intro.projectsList.tutorials.text,
            title: copy.app.intro.projectsList.tutorials.title,
            element: '[data-intro="tutorials-menu"]',
          },
          {
            intro: copy.app.intro.projectsList.mainNavigation.text,
            title: copy.app.intro.projectsList.mainNavigation.title,
            element: '[data-intro="sidebar-navigation"]',
          },
          {
            element: '[data-intro="project-list-item"]',
            intro: copy.app.intro.projectsList.openProject.text,
            title: copy.app.intro.projectsList.openProject.title,
          },
          {
            element: '[data-intro="projects-list-dropdown"]',
            intro: `
              <div style="display: flex; flex-direction: column;">
                <div style="padding-bottom: 40rem;">${copy.app.intro.projectsList.projectOptions.text}</div>
                <img src="/images/intro-project-dropdown.png" alt="intro-project-dropdown" style="margin: 0 auto;" />
              </div>`,
            title: copy.app.intro.projectsList.projectOptions.title,
            tooltipClass: 'intro-wide',
          },
          {
            element: '[data-intro="add-project-button"]',
            intro: copy.app.intro.projectsList.newProjects.text,
            title: copy.app.intro.projectsList.newProjects.title,
          },
          {
            element: '[data-intro="projects-list-toggles"]',
            intro: copy.app.intro.projectsList.mainViewToggles.text,
            title: copy.app.intro.projectsList.mainViewToggles.title,
          },
          {
            element: '[data-intro="projects-list-filtering"]',
            intro: copy.app.intro.projectsList.filters.text,
            title: copy.app.intro.projectsList.filters.title,
          },
        ];
      }
      if (type === 'project-modal') {
        return [
          {
            intro: copy.app.intro.projectModal.jobNumber.text,
            title: copy.app.intro.projectModal.jobNumber.title,
            element: '[data-intro="job-number-field"]',
          },
          {
            intro: copy.app.intro.projectModal.additionalInfo.text,
            title: copy.app.intro.projectModal.additionalInfo.title,
            element: '[data-intro="additional-info"]',
          },
          {
            intro: copy.app.intro.projectModal.testProjects.text,
            title: copy.app.intro.projectModal.testProjects.title,
            element: '[data-intro="test-project"]',
          },
          {
            intro: copy.app.intro.projectModal.hiddenProjects.text,
            title: copy.app.intro.projectModal.hiddenProjects.title,
            element: '[data-intro="hidden-project"]',
          },
        ];
      }
      if (type === 'admin-navigation') {
        return [
          {
            element: `[data-intro="subnav_${getAdminRoute('USERS')}"]`,
            intro: copy.app.intro.adminNavigation.users.text,
            title: copy.app.intro.adminNavigation.users.title,
          },
          {
            element: `[data-intro="subnav_${getAdminRoute('INTERNAL')}"]`,
            intro: copy.app.intro.adminNavigation.internal.text,
            title: copy.app.intro.adminNavigation.internal.title,
          },
          {
            element: `[data-intro="subnav_${getAdminRoute('VENDOR')}"]`,
            intro: copy.app.intro.adminNavigation.vendors.text,
            title: copy.app.intro.adminNavigation.vendors.title,
          },
          {
            element: `[data-intro="subnav_${getAdminRoute('CLIENTS')}"]`,
            intro: copy.app.intro.adminNavigation.clients.text,
            title: copy.app.intro.adminNavigation.clients.title,
          },
          {
            element: `[data-intro="subnav_${getAdminRoute('BRANDS')}"]`,
            intro: copy.app.intro.adminNavigation.brands.text,
            title: copy.app.intro.adminNavigation.brands.title,
          },
          {
            element: `[data-intro="subnav_${getAdminRoute('ARCHIVE')}"]`,
            intro: copy.app.intro.adminNavigation.archive.text,
            title: copy.app.intro.adminNavigation.archive.title,
          },
          {
            element: `[data-intro="subnav_${getAdminRoute('HISTORY')}"]`,
            intro: copy.app.intro.adminNavigation.history.text,
            title: copy.app.intro.adminNavigation.history.title,
          },
          {
            element: `[data-intro="subnav_${getAdminRoute('ROLES')}"]`,
            intro: copy.app.intro.adminNavigation.roles.text,
            title: copy.app.intro.adminNavigation.roles.title,
          },
          {
            element: `[data-intro="subnav_${getAdminRoute('VENDOR_SERVICE')}"]`,
            intro: copy.app.intro.adminNavigation.vendorServices.text,
            title: copy.app.intro.adminNavigation.vendorServices.title,
          },
          {
            element: `[data-intro="subnav_${getAdminRoute('TERRITORY')}"]`,
            intro: copy.app.intro.adminNavigation.territory.text,
            title: copy.app.intro.adminNavigation.territory.title,
          },
          {
            element: `[data-intro="subnav_${getAdminRoute('VERTICAL')}"]`,
            intro: copy.app.intro.adminNavigation.verticals.text,
            title: copy.app.intro.adminNavigation.verticals.title,
          },
          ...(isOwner || isSuperAdmin
            ? [
                {
                  element: `[data-intro="subnav_${getAdminRoute(
                    'USER_PERMISSIONS'
                  )}"]`,
                  intro: copy.app.intro.adminNavigation.userPermissions.text,
                  title: copy.app.intro.adminNavigation.userPermissions.title,
                },
                {
                  element: `[data-intro="subnav_${getAdminRoute(
                    'WORKSPACE_SETTINGS'
                  )}"]`,
                  intro: copy.app.intro.adminNavigation.settings.text,
                  title: copy.app.intro.adminNavigation.settings.title,
                },
              ]
            : []),
        ];
      }
      if (type === 'project-page') {
        return [
          {
            element: '[data-intro="project-header-dropdown"]',
            intro: `
              <div style="display: flex; flex-direction: column;">
                <div style="padding-bottom: 40rem;">${copy.app.intro.projectsList.projectOptions.text}</div>
                <img src="/images/intro-project-dropdown.png" alt="intro-project-dropdown" style="margin: 0 auto;" />
              </div>`,
            title: copy.app.intro.projectPage.headerDropdown.title,
          },
          {
            element: '[data-intro="project-budget-header"]',
            intro: copy.app.intro.projectPage.budget.text,
            title: copy.app.intro.projectPage.budget.title,
          },
          {
            element: '[data-intro="project-budget-button"]',
            intro: copy.app.intro.projectPage.budgetButton.text,
            title: copy.app.intro.projectPage.budgetButton.title,
          },
          {
            element: '[data-intro="project-versions-dropdown"]',
            intro: copy.app.intro.projectPage.versions.text,
            title: copy.app.intro.projectPage.versions.title,
          },
          {
            element: '[data-intro="project-comment-button"]',
            intro: copy.app.intro.projectPage.commentButton.text,
            title: copy.app.intro.projectPage.commentButton.title,
          },
          {
            element: '[data-intro="project-row-items"]',
            intro: copy.app.intro.projectPage.rowItems.text,
            title: copy.app.intro.projectPage.rowItems.title,
          },
          {
            element: '[data-intro="project-resource-dropdown"]',
            intro: copy.app.intro.projectPage.resourceDropdown.text,
            title: copy.app.intro.projectPage.resourceDropdown.title,
          },
          {
            element: '[data-intro="project-quantity-cell"]',
            intro: copy.app.intro.projectPage.quantityCell.text,
            title: copy.app.intro.projectPage.quantityCell.title,
          },
          {
            element: '[data-intro="project-rate-cell"]',
            intro: copy.app.intro.projectPage.rateCell.text,
            title: copy.app.intro.projectPage.rateCell.title,
          },
          {
            element: '[data-intro="project-total-cell"]',
            intro: copy.app.intro.projectPage.totalCell.text,
            title: copy.app.intro.projectPage.totalCell.title,
          },
        ];
      }
      if (type === 'invoice-modal') {
        return [
          {
            intro: copy.app.intro.invoiceModal.addInvoice.text,
            title: copy.app.intro.invoiceModal.addInvoice.title,
            element: '[data-intro="budget-modal__add-button"]',
          },
          {
            intro: copy.app.intro.invoiceModal.amount.text,
            title: copy.app.intro.invoiceModal.amount.title,
            element: '[data-intro="budget-modal__invoice-amount"]',
          },
          {
            intro: copy.app.intro.invoiceModal.conversion.text,
            title: copy.app.intro.invoiceModal.conversion.title,
            element: '[data-intro="budget-modal__conversion-dropdown"]',
          },
          {
            intro: copy.app.intro.invoiceModal.totalInvoice.text,
            title: copy.app.intro.invoiceModal.totalInvoice.title,
            element: '[data-intro="budget-modal__total-invoice"]',
          },
          {
            intro: copy.app.intro.invoiceModal.contracts.text,
            title: copy.app.intro.invoiceModal.contracts.title,
            element: '[data-intro="budget-modal__contracts"]',
          },
          {
            intro: copy.app.intro.invoiceModal.totalBudget.text,
            title: copy.app.intro.invoiceModal.totalBudget.title,
            element: '[data-intro="budget-modal__total-budget"]',
          },
        ];
      }
      if (type === 'cashflow') {
        return [
          {
            intro: copy.app.intro.cashflow.welcome.text,
            title: copy.app.intro.cashflow.welcome.title,
            tooltipClass: 'intro-center',
          },
          {
            intro: `
              <div style="display: flex; flex-direction: column;">
                <div style="padding-bottom: 40rem">${copy.app.intro.cashflow.create.text}</div>
                <img width="400" height="400" src="/images/cashflow-create.gif" alt="Cashflow Create" style="margin: 0 auto;" />
              </div>`,
            title: copy.app.intro.cashflow.create.title,
            tooltipClass: 'intro-center-width-500',
          },
          {
            intro: `
              <div style="display: flex; flex-direction: column;">
                <div style="padding-bottom: 40rem">${copy.app.intro.cashflow.drag.text}</div>
                <img width="400" height="400" src="/images/cashflow-drag.gif" alt="Cashflow Drag" style="margin: 0 auto;" />
              </div>`,
            title: copy.app.intro.cashflow.drag.title,
            tooltipClass: 'intro-center-width-500',
          },
          {
            intro: copy.app.intro.cashflow.edit.text,
            title: copy.app.intro.cashflow.edit.title,
            tooltipClass: 'intro-center',
          },
          {
            intro: `
              <div style="display: flex; flex-direction: column;">
                <div style="padding-bottom: 40rem">${copy.app.intro.cashflow.configure.text}</div>
                <img width="400" height="400" src="/images/cashflow-configure.png" alt="Cashflow Configure" style="margin: 0 auto;" />
              </div>`,
            title: copy.app.intro.cashflow.configure.title,
            element: '[data-intro="cashflow-configure"]',
            tooltipClass: 'intro-width-500',
          },
          {
            intro: copy.app.intro.cashflow.zoom.text,
            title: copy.app.intro.cashflow.zoom.title,
            element: '[data-intro="cashflow-zoom-out"]',
          },
          {
            intro: copy.app.intro.cashflow.paymentMode.text,
            title: copy.app.intro.cashflow.paymentMode.title,
            element: '[data-intro="cashflow-payments-mode"]',
          },
          {
            intro: `
            <div style="display: flex; flex-direction: column;">
              <div style="padding-bottom: 40rem">${copy.app.intro.cashflow.stats.text}</div>
              <img src="/images/cashflow-stats.png" alt="Cashflow Stats" style="margin: 0 auto;" />
            </div>`,
            title: copy.app.intro.cashflow.stats.title,
            element: '[data-intro="cashflow-summaries-dropdown"]',
          },
        ];
      }
    },
    [copy.app.intro, isOwner, isSuperAdmin]
  );

  const getIntroAlreadySeen = useCallback((type: IntroType) => {
    if (type === 'projects-list') {
      return Cookies.get(INTRO_PROJECTS_LIST_SEEN) === '1';
    }
    if (type === 'project-modal') {
      return Cookies.get(INTRO_PROJECT_MODAL_SEEN) === '1';
    }
    if (type === 'admin-navigation') {
      return Cookies.get(INTRO_ADMIN_NAVIGATION_SEEN) === '1';
    }
    if (type === 'project-page') {
      return Cookies.get(INTRO_PROJECT_PAGE_SEEN) === '1';
    }
    if (type === 'invoice-modal') {
      return Cookies.get(INTRO_INVOICE_MODAL_SEEN) === '1';
    }
    if (type === 'cashflow') {
      return Cookies.get(INTRO_CASHFLOW_SEEN) === '1';
    }
  }, []);

  const getIntroConfig = useCallback(
    (type: IntroType, mode: IntroMode) => {
      const steps = getSteps(type);
      if (!steps) {
        sentryCaptureException(
          new Error(`No steps found for intro type: ${type}`)
        );
      }
      const isActive =
        !!steps && (mode === 'on-demand' || !getIntroAlreadySeen(type));
      const config: Parameters<IntroJs['setOptions']>[0] = {
        steps,
        disableInteraction: true,
        isActive,
        scrollToElement: false,
        doneLabel: copy.app.intro.doneLabel,
        exitOnOverlayClick: false,
      };
      return config;
    },
    [copy.app.intro.doneLabel, getIntroAlreadySeen, getSteps]
  );

  const handleExit = useCallback((type: IntroType) => {
    if (type === 'projects-list') Cookies.set(INTRO_PROJECTS_LIST_SEEN, '1');
    if (type === 'project-modal') Cookies.set(INTRO_PROJECT_MODAL_SEEN, '1');
    if (type === 'admin-navigation')
      Cookies.set(INTRO_ADMIN_NAVIGATION_SEEN, '1');
    if (type === 'project-page') Cookies.set(INTRO_PROJECT_PAGE_SEEN, '1');
    if (type === 'invoice-modal') Cookies.set(INTRO_INVOICE_MODAL_SEEN, '1');
    if (type === 'cashflow') Cookies.set(INTRO_CASHFLOW_SEEN, '1');
  }, []);

  const handleOnChange = useCallback((intro: IntroJs) => {
    // Intro.js seems to get elements at the time of starting the intro
    // If element was not present at that time, or was rerendered in the meantime it will not work
    // intro.refresh(true) seems to change that behavior, it now gets elements on each step
    try {
      intro.refresh(true);
    } catch (e) {}
  }, []);

  const replaceCloseButton = useCallback(() => {
    const closeButton = document.querySelector('.introjs-skipbutton');
    if (closeButton) {
      closeButton.innerHTML = 'SKIP';
    }
  }, []);

  const runIntro = useCallback(
    async (
      type: IntroType,
      mode: IntroMode,
      { onExit }: { onExit?: () => void } = {}
    ) => {
      const introConfig = getIntroConfig(type, mode);
      const intro = introJs();

      if (mode === 'automatic' && process.env.ENV === 'development') {
        return;
      }

      await intro
        .setOptions(introConfig)
        .onexit(() => {
          onExit?.();
          handleExit(type);
        })
        .onbeforechange(() => {
          handleOnChange(intro);
          return true;
        })
        .start();

      replaceCloseButton();
    },
    [getIntroConfig, handleExit, handleOnChange, replaceCloseButton]
  );

  return {
    runIntro,
  };
};
