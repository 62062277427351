import React, { useMemo } from 'react';

import BaseInput from 'components/inputs/BaseInput/BaseInput';
import Row from 'components/Table/Row/Row';
import Table from 'components/Table/Table';
import TBody from 'components/Table/TBody/TBody';
import TBodyCell from 'components/Table/TBodyCell/TBodyCell';
import THead from 'components/Table/THead/THead';
import THeadCell from 'components/Table/THeadCell/THeadCell';
import { useProjectAccess } from 'hooks/useProjectAccess';
import { useCurrentProject } from 'queries/project';
import { useGlobalStore } from 'store';
import { useCopyStore } from 'store';
import { useSvgIconList } from 'u9/hooks/useSvgIcon';

import ContractsLinkToolbar from './ContractsLinkToolbar/ContractsLinkToolbar';
import { useContractsLink } from './hooks/useContractsLink';

import * as Styled from './ContractsLink.styles';

const ContractsLink = () => {
  const [CheckIcon, CloseIcon, ExternalLinkIcon] = useSvgIconList([
    'check',
    'close',
    'external_link',
  ]);
  const currentColor = useGlobalStore(s => s.currentColor);
  const copy = useCopyStore(s => s.copy);
  const currentProject = useCurrentProject();
  const { getCanEditProject } = useProjectAccess();

  const isProjectEditDisabled = !getCanEditProject(currentProject);

  const {
    toolbarVisible,
    isEditing,
    link,
    handleDeleteClick,
    handleEditClick,
    handleMouseLeave,
    handleMouseOver,
    handleLinkChange,
    handleSave,
    handleDiscard,
    handleOpenLink,
  } = useContractsLink();

  const hasLink = useMemo(() => !!link, [link]);

  return (
    <Styled.Wrapper data-intro="budget-modal__contracts">
      <Table tableTheme="dark" size="mediumNarrow">
        <THead>
          <Row hover={false}>
            <THeadCell divider={false} align="left" width="350rem">
              {copy.project.budgetModal.contracts}
            </THeadCell>
          </Row>
        </THead>
        <TBody bottomSafeArea={false}>
          <Row hover={false}>
            <Styled.RowWrapper
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              data-cy="folder-link-row"
            >
              <TBodyCell align="left" width="350rem">
                {isEditing ? (
                  <>
                    <Styled.InputWrapper>
                      <BaseInput
                        value={link || ''}
                        onChange={handleLinkChange}
                        styles={{ maxWidth: '150rem' }}
                        placeholder="https://..."
                        dataCy="folder-link-input"
                      />
                      <Styled.ActionButtonsWrapper>
                        <Styled.ActionButton
                          color={currentColor}
                          onClick={handleSave}
                          data-cy="folder-link-save"
                        >
                          <Styled.IconWrapper>
                            <CheckIcon />
                          </Styled.IconWrapper>
                        </Styled.ActionButton>
                        <Styled.ActionButton
                          color={currentColor}
                          onClick={handleDiscard}
                        >
                          <Styled.IconWrapper>
                            <CloseIcon />
                          </Styled.IconWrapper>
                        </Styled.ActionButton>
                      </Styled.ActionButtonsWrapper>
                    </Styled.InputWrapper>
                  </>
                ) : (
                  <>
                    <Styled.CellWrapper
                      hasLink={hasLink}
                      onClick={handleOpenLink}
                      title={link}
                    >
                      {copy.project.budgetModal.link}
                      {hasLink && (
                        <Styled.LinkIconWrapper data-cy="folder-link__has-link">
                          <ExternalLinkIcon />
                        </Styled.LinkIconWrapper>
                      )}
                    </Styled.CellWrapper>

                    {!isProjectEditDisabled && (
                      <Styled.ToolbarWrapper visible={toolbarVisible}>
                        <ContractsLinkToolbar
                          onEditClick={handleEditClick}
                          onDeleteClick={handleDeleteClick}
                        />
                      </Styled.ToolbarWrapper>
                    )}
                  </>
                )}
              </TBodyCell>
            </Styled.RowWrapper>
          </Row>
        </TBody>
      </Table>
    </Styled.Wrapper>
  );
};

export default React.memo(ContractsLink);
