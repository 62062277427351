import axios from 'axios';

export const UNPROCESSABLE_ENTITY = 422;
export const UNAUTHORIZED = 401;
export const MAINTENANCE = 503;
export const NOT_FOUND = 404;

export const knownErrorCodes = [
  'resource.email_not_unique',
  'vendor.email_not_unique',
  'server.error',
  'user.last_owner',
  'fx.error',
  'fx.runtime_error',
  'project.pitch_number_not_unique',
  'project.job_number_not_unique',
  'role.mfr_rate_exists',
  'mfr.resource_exists',
  'server.maintenance',
  'workspace.default_client_rate',
  'resource.exceeds_role_rate',
  'role.resource_exists_with_higher_rate',
  'reconcile.incorrect_job_number',
  'reconcile.format_error',
  'reconcile.incorrect_id',
  'project.last_pitch',
  'email.does_not_exist',
  'integration.auth_error',
  'integration.not_found',
  'integration.connection_error',
  'project.job_number_not_found',
  'access_request.already_exists',
  'client.already_exists',
  'client_report.currency',
  'service_account.invalid_key',
  'project.locked',
  'permission.in_use',
  'brand.already_exists',
  'vendor_service.already_exists',
] as const;

export const getErrorCode = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    if (
      error?.response?.status === UNPROCESSABLE_ENTITY ||
      error?.response?.status === MAINTENANCE
    ) {
      const errorsArray = error.response?.data || [];
      const firstErrorCode = knownErrorCodes.find(errorCode =>
        errorsArray.some((err: any) => err.type === errorCode)
      );
      return firstErrorCode;
    }
  } else {
    return null;
  }
};
