import { FC, memo } from 'react';

import Copy from 'components/Copy/Copy';
import { useNotificationHover } from 'components/Notifications/hooks/useNotificationHover';
import { useCopyStore } from 'store';
import { VendorServiceRejectedNotification as VendorServiceRejectedNotificationType } from 'utils/api.types';

import * as Styled from '../../NotificationItem.styles';

export interface VendorServiceRejectedNotificationProps {
  notification: VendorServiceRejectedNotificationType;
}

const VendorServiceRejectedNotification: FC<
  VendorServiceRejectedNotificationProps
> = ({ notification }) => {
  const copy = useCopyStore(s => s.copy);
  const { onMouseEnter, onMouseLeave } = useNotificationHover();

  return (
    <Styled.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Copy
        text={copy.app.notifications.vendorServiceRequestRejected}
        replacements={[
          [
            '{user}',
            `${notification.extra_info.fullName} (${notification.extra_info.username})`,
          ],
          ['{vendorService}', notification.extra_info.vendorServiceName],
        ]}
      />
    </Styled.Wrapper>
  );
};

export default memo(VendorServiceRejectedNotification);
