import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction, useState } from 'react';

import {
  Combobox,
  ComboboxCheckboxItem,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { useResources } from 'queries/resources';
import { useCopyStore } from 'store';
import { Resource as ResourceType } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectsListFiltersModal.styles';

export interface ResourceProps {
  resourceIds: ResourceType['id'][];
  setResourceIds: Dispatch<SetStateAction<ResourceType['id'][]>>;
}

const Resource: FC<ResourceProps> = ({ setResourceIds, resourceIds }) => {
  const copy = useCopyStore(s => s.copy);
  const { data: resources } = useResources();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const toggleResource = (resourceId: ResourceType['id']) => {
    setResourceIds(prev => {
      if (prev.includes(resourceId)) {
        return prev.filter(m => m !== resourceId);
      } else {
        return [...prev, resourceId];
      }
    });
  };

  return (
    <Styled.DropdownWrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={copy.app.projectsListFiltersModal.resource}
      >
        <ComboboxTrigger
          label={
            resourceIds.length > 0
              ? resourceIds
                  .map(
                    resourceId =>
                      resources?.find(({ id }) => id === resourceId)?.name
                  )
                  .join(', ')
              : copy.app.selectPlaceholder
          }
          style={{
            ...(resourceIds.length > 0
              ? {}
              : { color: rgba(colors.white, 0.5) }),
          }}
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList>
              {resources?.map(({ name, id }) => (
                <ComboboxCheckboxItem
                  key={id}
                  checked={resourceIds.includes(id)}
                  onSelect={() => {
                    toggleResource(id);
                  }}
                >
                  {name}
                </ComboboxCheckboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.DropdownWrapper>
  );
};

export default memo(Resource);
