import * as Collapsible from '@radix-ui/react-collapsible';
import Link from 'next/link';
import { rgba } from 'polished';
import styled, { css, keyframes } from 'styled-components';

import { focusVisible, setTypography, setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

const showItemAfterExpandedNavbar = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme: locale }) => setTypography('body4', locale)};
  color: ${colors.doveGray};
  width: 100%;
  cursor: pointer;
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(${setVh(100)} - 90rem);
  padding: 20rem 8rem;
`;

export const LinkWrapper = styled.div`
  padding: 0 10rem 0 50rem;
  margin-left: 50rem;
`;

export const TriggerIndicator = styled.div`
  width: 15rem;
  height: 15rem;
`;

export const TriggerIcon = styled.div`
  width: 35rem;
  height: 35rem;
`;

export const LinkTrigger = styled(Link)`
  display: flex;
  width: 100%;

  &:focus-visible {
    ${focusVisible};
  }
`;

export const TriggerText = styled.div`
  user-select: none;
  margin-left: 20rem;

  animation: ${showItemAfterExpandedNavbar} 250ms ease-in;
`;

export const NavLink = styled(Link)`
  animation: ${showItemAfterExpandedNavbar} 250ms ease-in;
  user-select: none;

  &:focus-visible > div {
    ${focusVisible};
  }
`;

export const NavGroupHeader = styled.div`
  animation: ${showItemAfterExpandedNavbar} 250ms ease-in;
  user-select: none;
  color: ${rgba(colors.black, 0.5)};
  padding-top: 10rem;
`;

export const Label = styled.div`
  text-align: left;
  width: 90%;
`;

export const CounterWrapper = styled.div``;

export const Top = styled.div`
  display: flex;
  align-items: center;
  height: 140rem;
`;

export const CollapsibleRoot = styled(Collapsible.Root)<{ $isActive: boolean }>`
  border-radius: 16rem;
  margin-bottom: 8rem;

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: ${colors.concrete};
    `}
`;

export const NavItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 64rem;
  padding: 16rem 8rem;
  border-radius: 16rem;
  ${({ theme: locale }) => setTypography('body4', locale)};

  &:hover {
    background: ${colors.gallery};
  }
`;

export const MenuItem = styled.div<{ isActive?: boolean; clickable?: boolean }>`
  display: flex;
  align-items: center;
  height: 64rem;
  padding: 16rem 8rem;
  border-radius: 16rem;
  ${({ theme: locale }) => setTypography('body4', locale)};

  ${({ clickable = true }) =>
    clickable &&
    css`
      &:hover {
        background: ${colors.gallery};
      }
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${colors.alto};
    `}
`;

export const IndicatorIcon = styled.div`
  width: 20rem;
  height: 20rem;
  transition: transform 250ms;
`;

export const TriggerIconWrapper = styled.button`
  width: 52rem;
  height: 52rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  border-radius: 10rem;

  animation: ${showItemAfterExpandedNavbar} 250ms ease-in;

  &:focus-visible {
    ${focusVisible};
  }

  &:hover {
    background: ${colors.alto};
  }

  &[data-state='open'] {
    ${IndicatorIcon} {
      transform: rotate(180deg);
    }
  }

  &[data-state='closed'] {
    ${IndicatorIcon} {
      transform: rotate(90deg);
    }
  }
`;

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
`;

export const CollapsibleContent = styled(Collapsible.Content)`
  overflow: hidden;

  &[data-state='open'] {
    animation: ${slideDown} 150ms;
  }

  &[data-state='closed'] {
    animation: ${slideUp} 150ms;
  }
`;
