import { FC } from 'react';

import { Tooltip } from 'components/Tooltip/Tooltip';
import { useUnwatchProject, useWatchProject } from 'queries/project';
import { useCopyStore } from 'store';
import { useSvgIconList } from 'u9/hooks';
import { Project } from 'utils/api.types';

import { useProject } from '../hooks/useProject';

import * as Styled from './ProjectModalWatchButton.styles';

export interface ProjectModalWatchButtonProps {
  projectId?: Project['id'];
}

const ProjectModalWatchButton: FC<ProjectModalWatchButtonProps> = ({
  projectId,
}) => {
  const copy = useCopyStore(s => s.copy);
  const [EyeClosed, Eye] = useSvgIconList(['eye_closed', 'eye']);
  const { project } = useProject(projectId);
  const { mutateAsync: watchProject } = useWatchProject();
  const { mutateAsync: unwatchProject } = useUnwatchProject();

  if (!projectId) return null;

  const handleClick = () => {
    if (project?.is_watching) {
      unwatchProject({ projectId });
    } else {
      watchProject({ projectId });
    }
  };

  return (
    <Tooltip
      content={
        project?.is_watching
          ? copy.app.projectModal.unwatch
          : copy.app.projectModal.watch
      }
    >
      <Styled.Wrapper onClick={handleClick}>
        <Styled.Icon>
          {project?.is_watching ? <EyeClosed /> : <Eye />}
        </Styled.Icon>
      </Styled.Wrapper>
    </Tooltip>
  );
};

export default ProjectModalWatchButton;
