import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { darken } from 'polished';
import { memo, useCallback, useMemo, useRef } from 'react';
import { useQueryClient } from 'react-query';

import { Currencies } from '@u9/bob3-shared/lib/types/api.types';
import Checkbox from 'components/Checkbox/Checkbox';
import Datepicker from 'components/datepickers/Datepicker/Datepicker';
import CardDropdown from 'components/dropdowns/CardDropdown/CardDropdown';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import { useDnd } from 'components/ShadTable/hooks/useDnd';
import {
  DefaultTableHeader,
  Table,
  TableCell,
  TableRow,
  TableToolbar,
  TableToolbarStart,
  TBody,
  THead,
  THeadCellContent,
} from 'components/ShadTable/Table';
import { useProjectAccess } from 'hooks/useProjectAccess';
import { useGetOptimisticConfigUpdate } from 'queries';
import {
  getProjectBudgetEntiresKey,
  useUpdateProjectBudgetEntry,
} from 'queries/budgetEntries';
import { useOrganization } from 'queries/organizations';
import { useCurrentProject } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { ReactComponent as AlertIcon } from 'svgs/alert.svg';
import { BudgetEntry } from 'utils/api.types';
import { formatDate, formatDateInline, formatMoney } from 'utils/formatters';
import { colors } from 'utils/styles/theme';

import ConversionDropdown from '../ConversionDropdown/ConversionDropdown';
import { useBudgetTable } from '../hooks/useBudgetTable';
import PaymentStatusDropdown from '../PaymentStatusDropdown/PaymentStatusDropdown';
import BudgetTableRow from './BudgetTableRow/BudgetTableRow';
import BudgetTableToolbar from './BudgetTableToolbar/BudgetTableToolbar';
import InvoiceAmountCell from './InvoiceAmountCell/InvoiceAmountCell';
import InvoiceNumberCell from './InvoiceNumberCell/InvoiceNumberCell';
import LabelCell from './LabelCell/LabelCell';
import PoNumberCell from './PoNumberCell/PoNumberCell';
import TermsCell from './TermsCell/TermsCell';

import * as Styled from '../BudgetModal.styles';

export interface BudgetTableProps {}

export const BudgetTable = () => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const copy = useCopyStore(s => s.copy);
  const locale = useCopyStore(s => s.locale);
  const { data: organization } = useOrganization();
  const currentProject = useCurrentProject();
  const currentVersionId = useCurrentVersionId();
  const { getCanEditProject } = useProjectAccess();
  const optimisticEntriesUpdate = useRef<BudgetEntry[] | null>(null);
  const queryClient = useQueryClient();
  const { getOptimisticUpdateConfig } = useGetOptimisticConfigUpdate();
  const { mutateAsync: updateProjectBudgetEntry } = useUpdateProjectBudgetEntry(
    {
      ...getOptimisticUpdateConfig({
        queryClient,
        queryKey: getProjectBudgetEntiresKey({
          projectId: currentProject?.id,
          projectVersionId: currentVersionId,
        }),
        updateRef: optimisticEntriesUpdate,
      }),
    }
  );

  const {
    budgetEntries,
    onDateChange,
    handleChange,
    saveBudgetEntry,
    handlePaymentStatusChange,
    handleCurrencyChange,
    error,
    errorType,
  } = useBudgetTable();

  const isProjectEditDisabled = !getCanEditProject(currentProject);

  const modalCopy = copy.project.budgetModal;

  const columns: ColumnDef<BudgetEntry>[] = useMemo(
    () => [
      {
        id: 'select',
        size: 40,
        enableHiding: false,
        meta: {
          isSelectColumn: true,
        },
        header: () => <></>,
        cell: props => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Checkbox
              value={props.row.getIsSelected()}
              setValue={value =>
                props.table.setRowSelection({ [props.row.index]: value })
              }
              disabled={!props.row.getCanSelect()}
              variant="square"
              dataCy="budget-row-select"
            />
          </div>
        ),
      },
      {
        accessorKey: 'date',
        size: 100,
        enableSorting: false,
        header: props => (
          <THeadCellContent column={props.column} text={modalCopy.date} />
        ),
        cell: props => (
          <Styled.DatepickerWrapper>
            <Datepicker
              color={currentColor}
              noPlaceholder
              noBorder
              fieldLabel={
                props.row.original.date
                  ? formatDate(props.row.original.date)
                  : copy.project.budgetModal.datePlaceholder
              }
              disabled={isProjectEditDisabled}
              value={
                props.row.original.date
                  ? new Date(props.row.original.date)
                  : undefined
              }
              displayValue={
                props.row.original.date
                  ? formatDateInline(new Date(props.row.original.date))
                  : copy.project.budgetModal.datePlaceholder
              }
              onChange={onDateChange(props.row.index)}
            />
          </Styled.DatepickerWrapper>
        ),
      },
      {
        accessorKey: 'label',
        size: 140,
        enableSorting: false,
        header: props => (
          <THeadCellContent column={props.column} text={modalCopy.label} />
        ),
        cell: props => {
          return (
            <LabelCell
              props={props}
              isDisabled={isProjectEditDisabled}
              handleChange={handleChange(props.row.index, 'label')}
              saveBudgetEntry={saveBudgetEntry(props.row.index)}
            />
          );
        },
      },
      {
        accessorKey: 'payment_status',
        size: 120,
        enableSorting: false,
        header: props => (
          <THeadCellContent
            column={props.column}
            text={modalCopy.paymentStatus}
          />
        ),
        cell: props => {
          const index = props.row.index;
          const { payment_status } = props.row.original;
          const isDateFilled = !!props.row.original.date;
          const isInvoiceNumberFilled = !!props.row.original.invoice_number;
          return (
            <PaymentStatusDropdown
              status={payment_status}
              onChange={handlePaymentStatusChange(index)}
              isDateFilled={isDateFilled}
              isInvoiceNumberFilled={isInvoiceNumberFilled}
            />
          );
        },
      },
      {
        accessorKey: 'terms',
        size: 60,
        enableSorting: false,
        header: props => (
          <THeadCellContent column={props.column} text={modalCopy.terms} />
        ),
        cell: props => {
          return (
            <TermsCell
              props={props}
              isDisabled={isProjectEditDisabled}
              handleChange={handleChange(props.row.index, 'terms')}
              saveBudgetEntry={saveBudgetEntry(props.row.index)}
            />
          );
        },
      },
      {
        accessorKey: 'due_date',
        size: 80,
        enableSorting: false,
        header: props => (
          <THeadCellContent column={props.column} text={modalCopy.dueDate} />
        ),
        cell: props => {
          const item = props.row.original;
          const disabled = isProjectEditDisabled;
          return (
            <Styled.DueDateWrapper disabled={disabled}>
              {item.due_date ? formatDateInline(item.due_date) : '-'}
            </Styled.DueDateWrapper>
          );
        },
      },
      {
        accessorKey: 'po_number',
        size: 80,
        enableSorting: false,
        header: props => (
          <THeadCellContent column={props.column} text={modalCopy.poNumber} />
        ),
        cell: props => {
          return (
            <PoNumberCell
              props={props}
              isDisabled={isProjectEditDisabled}
              handleChange={handleChange(props.row.index, 'po_number')}
              saveBudgetEntry={saveBudgetEntry(props.row.index)}
            />
          );
        },
      },
      {
        accessorKey: 'invoice_number',
        size: 80,
        enableSorting: false,
        header: props => (
          <THeadCellContent
            column={props.column}
            text={modalCopy.invoiceNumber}
          />
        ),
        cell: props => {
          return (
            <InvoiceNumberCell
              props={props}
              isDisabled={isProjectEditDisabled}
              handleChange={handleChange(props.row.index, 'invoice_number')}
              saveBudgetEntry={saveBudgetEntry(props.row.index)}
            />
          );
        },
      },
      {
        accessorKey: 'invoice_amount',
        size: 90,
        enableSorting: false,
        header: props => (
          <THeadCellContent column={props.column} text={modalCopy.amount} />
        ),
        cell: props => {
          return (
            <InvoiceAmountCell
              props={props}
              isDisabled={isProjectEditDisabled}
              handleChange={handleChange(props.row.index, 'invoice_amount')}
              saveBudgetEntry={saveBudgetEntry(props.row.index)}
            />
          );
        },
      },
      {
        accessorKey: 'invoice_currency',
        size: 120,
        enableSorting: false,
        header: props => (
          <THeadCellContent column={props.column} text={modalCopy.conversion} />
        ),
        cell: props => {
          const index = props.row.index;
          const item = props.row.original;
          const isSupportedCurrency = organization?.currency === Currencies.GBP;
          const disabled = isProjectEditDisabled || !isSupportedCurrency;
          return (
            <Styled.CurrencySelectWrapper>
              {!isSupportedCurrency ? (
                <Styled.AlertWrapper>
                  <CardDropdown
                    cardProps={{
                      type: 'info',
                      title: copy.project.budgetModal.unsupported.title,
                      text: copy.project.budgetModal.unsupported.text,
                      cardStyles: {
                        margin: '20rem 0 0 -40rem',
                        border: `1px solid ${darken(0.5, colors.white)}`,
                      },
                    }}
                    button={<InfoIcon theme="dark" />}
                  />
                </Styled.AlertWrapper>
              ) : error || errorType ? (
                <Styled.AlertWrapper>
                  <CardDropdown
                    cardProps={{
                      type: 'error',
                      title: copy.project.budgetModal.currencyError.title,
                      text:
                        errorType === 'tryAgain'
                          ? copy.project.budgetModal.currencyError.tryAgain
                          : copy.project.budgetModal.currencyError.notSupported,
                      cardStyles: {
                        margin: '20rem 0 0 -40rem',
                        border: `1px solid ${darken(0.5, colors.white)}`,
                      },
                    }}
                    button={<AlertIcon />}
                  />
                </Styled.AlertWrapper>
              ) : null}
              <ConversionDropdown
                currency={item.invoice_currency}
                onChange={handleCurrencyChange(index)}
                disabled={disabled}
              />
            </Styled.CurrencySelectWrapper>
          );
        },
      },
      {
        accessorKey: 'total_amount',
        size: 100,
        enableSorting: false,
        header: props => (
          <THeadCellContent
            column={props.column}
            text={modalCopy.totalInvoice}
          />
        ),
        cell: props => {
          const item = props.row.original;
          return (
            <Styled.TotalInvoice data-intro="budget-modal__total-invoice">
              <OverflowEllipsis
                text={formatMoney(
                  item.total_amount,
                  organization?.currency,
                  locale
                )}
              />
            </Styled.TotalInvoice>
          );
        },
      },
    ],
    [
      copy,
      modalCopy,
      currentColor,
      error,
      errorType,
      handleChange,
      handleCurrencyChange,
      handlePaymentStatusChange,
      isProjectEditDisabled,
      locale,
      onDateChange,
      organization?.currency,
      saveBudgetEntry,
    ]
  );

  const table = useReactTable({
    columns,
    data: budgetEntries ?? [],
    getCoreRowModel: getCoreRowModel(),
    meta: {
      dndEnabled: true,
    },
  });

  const { reorderRow: reorderTableRows } = useDnd({ table });

  const reorderRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const { dragCancelled, reordered } = reorderTableRows(
        dragIndex,
        hoverIndex
      );

      optimisticEntriesUpdate.current = reordered.map(row => row.original);

      if (currentProject && !dragCancelled && currentVersionId) {
        const items = table.getCoreRowModel().rows;
        const pos = items[dragIndex].original.row_no;
        const rowNo = items[hoverIndex].original.row_no;

        updateProjectBudgetEntry({
          projectId: currentProject.id,
          projectVersionId: currentVersionId,
          budgetEntry: { pos, row_no: rowNo },
        });
      }
    },
    [
      currentProject,
      currentVersionId,
      reorderTableRows,
      table,
      updateProjectBudgetEntry,
    ]
  );

  return (
    <>
      <TableToolbar style={{ position: 'sticky', top: 0, zIndex: 100 }}>
        <TableToolbarStart>
          <BudgetTableToolbar table={table} />
        </TableToolbarStart>
      </TableToolbar>
      <Table>
        <THead style={{ top: '100rem', zIndex: 100 }}>
          <DefaultTableHeader table={table} />
        </THead>
        <TBody>
          {table.getRowModel().rows?.length ? (
            table
              .getRowModel()
              .rows.map(row => (
                <BudgetTableRow
                  key={row.original.row_no}
                  row={row}
                  reorderRow={reorderRow}
                />
              ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={
                  table.getVisibleFlatColumns().length +
                  (table.options.meta?.dndEnabled ? 1 : 0)
                }
                style={{ textAlign: 'center', height: '400rem' }}
              >
                {copy.app.tableEmpty}
              </TableCell>
            </TableRow>
          )}
        </TBody>
      </Table>
    </>
  );
};

export default memo(BudgetTable);
