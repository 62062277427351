import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { Awaited } from 'types/helpers';
import { Vendor } from 'utils/api.types';

import { ARCHIVE_KEY } from './archive';

export const VENDORS_KEY = 'VENDORS';

export const useVendors = (
  variables?: Parameters<typeof getVendors>[0],
  options?: UseQueryOptions<Vendor[]>
) => {
  const getVendors = useApiStore(s => s.apiClient.getVendors);
  return useQuery({
    queryKey: [VENDORS_KEY, variables],
    queryFn: async () => (await getVendors(variables)).data,
    ...options,
  });
};

export const useAddVendor = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof addVendor>>,
    unknown,
    Parameters<typeof addVendor>[0]
  >
) => {
  const addVendor = useApiStore(s => s.apiClient.addVendor);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addVendor>[0]) => addVendor(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VENDORS_KEY);
      },
      ...options,
    }
  );
};

export const useUpdateVendor = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof updateVendor>>,
    unknown,
    Parameters<typeof updateVendor>[0]
  >
) => {
  const updateVendor = useApiStore(s => s.apiClient.updateVendor);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateVendor>[0]) => updateVendor(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VENDORS_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
      },
      ...options,
    }
  );
};

export const useBulkUpdateVendor = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkUpdateVendor>>,
    unknown,
    Parameters<typeof bulkUpdateVendor>[0]
  >
) => {
  const bulkUpdateVendor = useApiStore(s => s.apiClient.bulkUpdateVendor);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof bulkUpdateVendor>[0]) =>
      bulkUpdateVendor(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VENDORS_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
      },
      ...options,
    }
  );
};

export const useDeleteVendor = () => {
  const deleteVendor = useApiStore(s => s.apiClient.deleteVendor);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteVendor>[0]) => deleteVendor(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VENDORS_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
      },
    }
  );
};
