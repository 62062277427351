import { FC, memo, useMemo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useAddBrand } from 'queries/brands';
import { useOrganization } from 'queries/organizations';
import { useCopyStore, useGlobalStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import { Brand } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './BrandRequestModal.styles';

export interface BrandRequestModalProps {
  brandName: Brand['name'];
  onBrandCreated: (id: Brand['id']) => void;
}

const BrandRequestModal: FC<BrandRequestModalProps> = ({
  brandName,
  onBrandCreated,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { data: organization } = useOrganization();
  const { checkAnyAccessRight } = useCheckAccessRights();
  const {
    mutateAsync: addBrand,
    isLoading,
    isSuccess: isBrandRequested,
  } = useAddBrand();
  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
      name: {
        enabled: true,
        message: copy.app.validatorMessages.nameNoAtSign,
      },
    }),
    [
      copy.app.validatorMessages.nameNoAtSign,
      copy.app.validatorMessages.nameRequired,
    ]
  );
  const [name, setName, isNameValid, nameInvalidMessage] = useField(
    brandName,
    validators
  );

  const canUseFreshClientBySettings =
    organization?.entity_config?.brands?.allow_unverified;
  const canUseFreshClient =
    checkAnyAccessRight(['administrator', 'super admin', 'owner']) ||
    canUseFreshClientBySettings;

  const close = () => {
    closeModal('brandRequest');
  };

  const handleAddBrand = async () => {
    const { data: client } = await addBrand({
      name: name,
    });
    onBrandCreated(client.id);
    if (canUseFreshClient) {
      close();
    }
  };

  const isAnyInvalid = !isNameValid;

  return (
    <ConfigModal
      title={copy.app.brandRequestModal.title}
      buttonProps={
        isBrandRequested
          ? {
              label: copy.app.brandRequestModal.successButton,
              color: currentColor,
              onClick: close,
              icon: 'check',
            }
          : {
              label: canUseFreshClient
                ? copy.app.brandRequestModal.ctaCanCreate
                : copy.app.brandRequestModal.cta,
              color: currentColor,
              onClick: handleAddBrand,
              icon: 'check',
              disabled: isAnyInvalid || isLoading,
            }
      }
      color={currentColor}
      onCloseClick={close}
    >
      <Styled.Wrapper>
        {isBrandRequested ? (
          <Styled.Description>
            {copy.app.brandRequestModal.success}
          </Styled.Description>
        ) : (
          <>
            <Styled.Description>
              {copy.app.brandRequestModal.description}
            </Styled.Description>
            <FormInput
              onChange={setName}
              value={name}
              placeholder={copy.app.brandRequestModal.name}
              error={nameInvalidMessage}
              required
            />
          </>
        )}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(BrandRequestModal);
