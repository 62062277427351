import { rgba } from 'polished';
import {
  createContext,
  Dispatch,
  forwardRef,
  SetStateAction,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

import { colors } from './styles/theme';

export type Theme = 'light' | 'dark';

export const tableColors: Record<
  Theme,
  {
    ambientBackground: string;
    background: string;
    headerText: string;
    hover: string;
    lines: string;
    linesStrong: string;
    accessoryBackground: string;
    accessoryHover: string;
    cellText: string;
  }
> = {
  light: {
    ambientBackground: colors.grayNurse,
    background: colors.white,
    headerText: rgba(colors.outerSpace, 0.4),
    hover: rgba(colors.black, 0.05),
    lines: rgba(colors.outerSpace, 0.15),
    linesStrong: rgba(colors.outerSpace, 0.4),
    accessoryBackground: rgba(colors.dustyGray, 0.15),
    accessoryHover: rgba(colors.dustyGray, 0.2),
    cellText: rgba(colors.outerSpace, 0.75),
  },
  dark: {
    ambientBackground: colors.outerSpace,
    background: colors.outerSpace,
    headerText: rgba(colors.white, 0.5),
    hover: rgba(colors.white, 0.05),
    lines: rgba(colors.white, 0.15),
    linesStrong: rgba(colors.white, 0.4),
    accessoryBackground: rgba(colors.dustyGray, 0.15),
    accessoryHover: rgba(colors.dustyGray, 0.2),
    cellText: rgba(colors.white, 0.75),
  },
};

const checkboxColors: Record<
  Theme,
  {
    main: string;
    secondary: string;
  }
> = {
  light: {
    main: colors.black,
    secondary: rgba(colors.black, 0.5),
  },
  dark: {
    main: colors.white,
    secondary: rgba(colors.white, 0.4),
  },
};

const editableLabelColors: Record<
  Theme,
  { default: string; disabled: string }
> = {
  light: {
    default: colors.black,
    disabled: rgba(colors.outerSpace, 0.75),
  },
  dark: {
    default: colors.white,
    disabled: rgba(colors.white, 0.75),
  },
};

const datepickerColors: Record<
  Theme,
  { text: string; accent: string; textStrong: string }
> = {
  light: {
    text: rgba(colors.black, 0.5),
    textStrong: colors.black,
    accent: rgba(colors.black, 0.5),
  },
  dark: {
    text: rgba(colors.white, 0.5),
    textStrong: colors.white,
    accent: rgba(colors.white, 0.5),
  },
};

const StyleContext = styled.div<{ $theme: Theme }>`
  ${({ $theme }) => css`
    --table-ambient-background: ${tableColors[$theme].ambientBackground};
    --table-background: ${tableColors[$theme].background};
    --table-header-text: ${tableColors[$theme].headerText};
    --table-hover: ${tableColors[$theme].hover};
    --table-lines: ${tableColors[$theme].lines};
    --table-lines-strong: ${tableColors[$theme].linesStrong};
    --table-accessory-background: ${tableColors[$theme].accessoryBackground};
    --table-accessory-hover: ${tableColors[$theme].accessoryHover};
    --table-cell-text: ${tableColors[$theme].cellText};

    --checkbox-main-color: ${checkboxColors[$theme].main};
    --checkbox-secondary-color: ${checkboxColors[$theme].secondary};

    --editable-label-default-color: ${editableLabelColors[$theme].default};
    --editable-label-disabled-color: ${editableLabelColors[$theme].disabled};

    --datepicker-text-color: ${datepickerColors[$theme].text};
    --datepicker-accent-color: ${datepickerColors[$theme].accent};
    --datepicker-text-strong: ${datepickerColors[$theme].textStrong};
  `}
`;

export const ThemeContext = createContext<{
  theme: Theme;
  setTheme: Dispatch<SetStateAction<Theme>>;
}>({ theme: 'light', setTheme: () => null });

export const AppThemeProvider = forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode | React.ReactNode[];
    theme?: Theme;
  }
>(({ children, theme: initialTheme = 'light' }, ref) => {
  const [theme, setTheme] = useState<Theme>(initialTheme);
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <StyleContext $theme={theme} ref={ref}>
        {children}
      </StyleContext>
    </ThemeContext.Provider>
  );
});
AppThemeProvider.displayName = 'AppThemeProvider';
