export enum LocalStorageKeys {
  BOB_TABLE_COLUMNS = 'BOB_TABLE_COLUMNS',
  BOB_RECONCILE_SELECTION = 'BOB_RECONCILE_SELECTION',
  WHATS_NEW_LAST_VERSION_SEEN = 'WHATS_NEW_LAST_VERSION_SEEN',
}

export class LocalStorage {
  get(key: LocalStorageKeys): any {
    return localStorage.getItem(key);
  }

  set(key: LocalStorageKeys, value: any): void {
    localStorage.setItem(key, value);
  }
}
