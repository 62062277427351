import _cloneDeep from 'lodash/cloneDeep';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { UnhideProjectModalProps } from 'components/modals/UnhideProjectModal/UnhideProjectModal';
import { useCurrentVersionMode } from 'components/modules/Project/hooks/useCurrentVersionMode';
import { useHideProjectVisibility } from 'hooks/useHideProjectVisibility';
import { useIsDemo } from 'hooks/useIsDemo';
import { useProjectAccess } from 'hooks/useProjectAccess';
import { useProject } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import { Project, ProjectMode, ProjectsListItem } from 'utils/api.types';
import { ROUTES } from 'utils/routes';

export enum MenuItems {
  info = 'info',
  getLink = 'getLink',
  duplicate = 'duplicate',
  download = 'download',
  downloadClientReport = 'downloadClientReport',
  hide = 'hide',
  delete = 'delete',
  share = 'share',
  request = 'request',
  testProject = 'testProject',
  mfrDiscount = 'mfrDiscount',
  getPitchLink = 'getPitchLink',
  editPitchName = 'editPitchName',
  duplicatePitch = 'duplicatePitch',
  deletePitch = 'deletePitch',
  pitchVersions = 'pitchVersions',
  projectGroups = 'projectGroups',
  downloadMfr = 'downloadMfr',
}

export const useDropdown = ({
  currentProject: project,
  openProjectInfo,
  getLink,
  duplicate,
  download,
  downloadClientReport,
  handleDelete,
  hideProject,
  handleShare,
  handleRequestPermission,
  handleMfrDiscount,
  toggleTestProject,
  getPitchLink,
  editPitchName,
  duplicatePitch,
  deletePitch,
  downloadMfr,
  isVariantDropdown,
}: {
  currentProject?: Project | ProjectsListItem;
  getLink: () => void;
  openProjectInfo: () => void;
  duplicate: () => void;
  download: () => void;
  downloadClientReport: () => void;
  handleDelete: () => void;
  hideProject: ({ isSensitive }: { isSensitive: boolean }) => void;
  handleShare: () => void;
  handleRequestPermission: () => void;
  handleMfrDiscount: () => void;
  toggleTestProject: () => void;
  getPitchLink: () => void;
  editPitchName: () => void;
  duplicatePitch: () => void;
  deletePitch: () => void;
  downloadMfr: () => void;
  isVariantDropdown: boolean;
}) => {
  const openModal = useGlobalStore(s => s.openModal);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const mounted = useRef(false);
  const router = useRouter();
  const isPerformanceDashboard = router.route === ROUTES.PERFORMANCE;
  const { currentVersionMode } = useCurrentVersionMode();
  const { checkAnyAccessRight } = useCheckAccessRights();
  const isDemo = useIsDemo();

  const { data: currentProject } = useProject(
    { projectId: project?.id },
    { enabled: !!project?.id }
  );

  const {
    getCanRequestProjectPermission,
    getCanEditProject,
    getCanShareProjectAccess,
  } = useProjectAccess();

  const [isSensitive, setIsSensitive] = useState(!!currentProject?.access);
  const [, setIsTest] = useState(currentProject?.test_flag || false);

  const { isHideButtonVisible, isTestButtonVisible } = useHideProjectVisibility(
    {
      project: currentProject,
    }
  );

  const isDiscountButtonVisible =
    getCanEditProject(currentProject) &&
    checkAnyAccessRight(['administrator', 'owner', 'super admin']) &&
    (currentVersionMode === ProjectMode.production ||
      currentVersionMode === ProjectMode.hosting);

  useEffect(() => {
    if (currentProject) {
      setIsSensitive(!!currentProject.access);
    }
  }, [currentProject]);

  useEffect(() => {
    if (currentProject) {
      setIsTest(currentProject?.test_flag || false);
    }
  }, [currentProject]);

  const dropdownItems = useMemo(() => {
    const requestPermission = {
      id: MenuItems.request,
      displayName: copy.projectsList.requestPermission,
      icon: 'key',
      preventClosing: true,
    };
    const info = {
      id: MenuItems.info,
      displayName: copy.projectsList.info,
      icon: 'info',
      preventClosing: false,
    };
    const getLink = {
      id: MenuItems.getLink,
      displayName: copy.projectsList.link,
      icon: 'link',
      preventClosing: true,
    };
    const duplicate = {
      id: MenuItems.duplicate,
      displayName: copy.projectsList.copy,
      icon: 'copy',
      preventClosing: false,
    };
    const download = {
      id: MenuItems.download,
      displayName: copy.projectsList.download,
      icon: 'download',
      preventClosing: false,
    };
    const share = {
      id: MenuItems.share,
      displayName: copy.projectsList.share,
      icon: 'key',
      preventClosing: false,
    };
    const hide = {
      id: MenuItems.hide,
      displayName: copy.projectsList.hidden,
      icon: 'eye_closed',
      preventClosing: true,
    };
    const mfrDiscount = {
      id: MenuItems.mfrDiscount,
      displayName: copy.projectsList.mfrDiscount,
      icon: 'settings',
      preventClosing: false,
    };
    const clientReport = {
      id: MenuItems.downloadClientReport,
      displayName: copy.projectsList.clientReport,
      icon: 'download',
      divider: true,
      preventClosing: false,
    };
    const deleteProject = {
      id: MenuItems.delete,
      displayName: copy.projectsList.delete,
      icon: 'trash',
      preventClosing: false,
    };
    const testProject = {
      id: MenuItems.testProject,
      displayName: copy.projectsList.testProject,
      icon: 'collapse',
      preventClosing: true,
    };
    const editPitchName = {
      id: MenuItems.editPitchName,
      displayName: copy.projectsList.editPitchName,
      icon: 'edit',
      divider: true,
      preventClosing: false,
    };
    const getPitchLink = {
      id: MenuItems.getPitchLink,
      displayName: copy.projectsList.pitchLink,
      icon: 'link',
      preventClosing: true,
    };
    const duplicatePitch = {
      id: MenuItems.duplicatePitch,
      displayName: copy.projectsList.duplicatePitch,
      icon: 'copy',
      preventClosing: false,
    };
    const deletePitch = {
      id: MenuItems.deletePitch,
      displayName: copy.projectsList.deletePitch,
      icon: 'trash',
      preventClosing: false,
    };
    const pitchVersions = {
      id: MenuItems.pitchVersions,
      displayName: copy.projectsList.pitchVersions,
      icon: '',
      preventClosing: false,
    };
    const projectGroups = {
      id: MenuItems.projectGroups,
      displayName: copy.projectsList.projectGroups,
      icon: '',
      preventClosing: false,
    };
    const mfrDownload = {
      id: MenuItems.downloadMfr,
      displayName: copy.projectsList.downloadMfr,
      icon: 'download',
      preventClosing: false,
    };

    const canEditVariantList = [
      editPitchName,
      getPitchLink,
      duplicatePitch,
      clientReport,
      ...(!isDemo ? [mfrDownload] : []),
      ...(isDiscountButtonVisible ? [mfrDiscount] : []),
      pitchVersions,
      deletePitch,
    ];

    const cannotEditVariantList = [getPitchLink, pitchVersions];

    const isProjectGroupVisible = checkAnyAccessRight([
      'administrator',
      'owner',
      'super admin',
    ]);

    const canEditProjectList = [
      info,
      getLink,
      ...(!isPerformanceDashboard ? [duplicate] : []),
      download,
      ...(getCanShareProjectAccess(currentProject) ? [share] : []),
      ...(isHideButtonVisible && !isPerformanceDashboard ? [hide] : []),
      ...(isTestButtonVisible && !isPerformanceDashboard ? [testProject] : []),
      ...(isProjectGroupVisible ? [projectGroups] : []),
      ...(!isPerformanceDashboard ? [deleteProject] : []),
    ].map((item, index, arr) =>
      index === arr.length - 2 ? { ...item, divider: true } : item
    );
    const cannotEditProjectList = [
      info,
      ...(isProjectGroupVisible ? [projectGroups] : []),
      requestPermission,
    ];

    return getCanRequestProjectPermission(currentProject)
      ? isVariantDropdown
        ? cannotEditVariantList
        : cannotEditProjectList
      : isVariantDropdown
      ? canEditVariantList
      : canEditProjectList;
  }, [
    copy,
    isDemo,
    isDiscountButtonVisible,
    checkAnyAccessRight,
    isPerformanceDashboard,
    getCanShareProjectAccess,
    currentProject,
    isHideButtonVisible,
    isTestButtonVisible,
    getCanRequestProjectPermission,
    isVariantDropdown,
  ]);

  const [items, setItems] = useState(dropdownItems);

  useEffect(() => {
    setItems([...dropdownItems]);
  }, [dropdownItems]);

  const handleHideProject = useCallback(async () => {
    const isSensitiveUpdateValue = !isSensitive;
    const toggle = () => {
      hideProject({
        isSensitive: isSensitiveUpdateValue,
      });
      closeModal('unhideProjectModal');
    };
    if (!isSensitiveUpdateValue) {
      openModal<UnhideProjectModalProps>('unhideProjectModal', {
        handleConfirm: toggle,
        handleDecline: () => closeModal('unhideProjectModal'),
      });
    } else {
      toggle();
    }
  }, [closeModal, hideProject, isSensitive, openModal]);

  const actionsMap = useMemo(
    () => ({
      [MenuItems.info]: openProjectInfo,
      [MenuItems.getLink]: getLink,
      [MenuItems.duplicate]: duplicate,
      [MenuItems.download]: download,
      [MenuItems.downloadClientReport]: downloadClientReport,
      [MenuItems.delete]: handleDelete,
      [MenuItems.hide]: handleHideProject,
      [MenuItems.share]: handleShare,
      [MenuItems.request]: handleRequestPermission,
      [MenuItems.testProject]: toggleTestProject,
      [MenuItems.mfrDiscount]: handleMfrDiscount,
      [MenuItems.editPitchName]: editPitchName,
      [MenuItems.getPitchLink]: getPitchLink,
      [MenuItems.duplicatePitch]: duplicatePitch,
      [MenuItems.deletePitch]: deletePitch,
      [MenuItems.downloadMfr]: downloadMfr,
    }),
    [
      openProjectInfo,
      getLink,
      duplicate,
      download,
      downloadClientReport,
      handleDelete,
      handleHideProject,
      handleShare,
      handleRequestPermission,
      toggleTestProject,
      handleMfrDiscount,
      editPitchName,
      getPitchLink,
      duplicatePitch,
      deletePitch,
      downloadMfr,
    ]
  );

  const displayItemCopied = useCallback(
    (id: MenuItems) => {
      const itemsCopy = _cloneDeep(items);
      const getLinkItem = itemsCopy.find(item => item.id === id);
      if (!getLinkItem) return;
      const initialDisplayName = getLinkItem.displayName;
      const initialIcon = getLinkItem.icon;
      getLinkItem.displayName = copy.projectsList.copySuccess;
      getLinkItem.icon = 'check';
      setItems([...itemsCopy]);

      setTimeout(() => {
        if (mounted.current) {
          getLinkItem.displayName = initialDisplayName;
          getLinkItem.icon = initialIcon;
          setItems([...itemsCopy]);
        }
      }, 2000);
    },
    [copy, items]
  );

  const displayPermissionRequested = useCallback(() => {
    const itemsCopy = _cloneDeep(items);
    const requestItem = itemsCopy.find(item => item.id === MenuItems.request);
    if (!requestItem) return;
    const initialDisplayName = requestItem.displayName;
    const initialIcon = requestItem.icon;
    requestItem.displayName = copy.projectsList.requestPermissionSuccess;
    requestItem.icon = 'check';
    setItems([...itemsCopy]);

    setTimeout(() => {
      if (mounted.current) {
        requestItem.displayName = initialDisplayName;
        requestItem.icon = initialIcon;
        setItems([...itemsCopy]);
      }
    }, 2000);
  }, [copy.projectsList.requestPermissionSuccess, items]);

  const handleClick = useCallback(
    (id: keyof typeof actionsMap) => {
      actionsMap[id]?.();

      if (id === MenuItems.getLink || id === MenuItems.getPitchLink) {
        displayItemCopied(id);
      }
      if (id === MenuItems.request) {
        displayPermissionRequested();
      }
    },
    [actionsMap, displayItemCopied, displayPermissionRequested]
  );

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return {
    items,
    handleClick,
  };
};
