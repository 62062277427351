import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIsFetching } from 'react-query';

import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import {
  AdminListItem,
  CategoryIdentifier,
} from 'components/modules/Admin/hooks/useAdminListColumns';
import { useDeleteAdminItem } from 'components/modules/Admin/hooks/useDeleteAdminItem';
import { BRANDS_KEY } from 'queries/brands';
import { CLIENTS_KEY } from 'queries/clients';
import { RESOURCES_KEY } from 'queries/resources';
import { USERS_KEY } from 'queries/users';
import { VENDORS_KEY } from 'queries/vendors';
import { useCopyStore, useGlobalStore } from 'store';

import ConfigModal from '../ConfigModal/ConfigModal';
import BrandPartial from './BrandPartial/BrandPartial';
import { useBrandPartial } from './BrandPartial/hooks/useBrandPartial';
import { useCategoryDropdown } from './CategoryDropdown/hooks/useCategoryDropdown';
import ClientPartial from './ClientPartial/ClientPartial';
import { useClientPartial } from './ClientPartial/hooks/useClientPartial';
import { useChangeItemCategory } from './hooks/useChangeItemCategory';
import { useResourcePartial } from './ResourcePartial/hooks/useResourcePartial';
import ResourcePartial from './ResourcePartial/ResourcePartial';
import { useUserPartial } from './UserPartial/hooks/useUserPartial';
import UserPartial from './UserPartial/UserPartial';
import { useVendorPartial } from './VendorPartial/hooks/useVendorPartial';
import VendorPartial from './VendorPartial/VendorPartial';
import { useVendorServicePartial } from './VendorServicePartial/hooks/useVendorServicePartial';
import VendorServicePartial from './VendorServicePartial/VendorServicePartial';

import * as Styled from './AdminItemModal.styles';

export interface AdminItemModalProps {
  item?: AdminListItem;
  options?: {
    isEditModeForce?: boolean;
    canChangeCategories?: boolean;
  };
  successCallback?: () => void;
}

const AdminItemModal = ({
  item,
  options: { canChangeCategories = true, isEditModeForce } = {},
  successCallback,
}: AdminItemModalProps) => {
  const isEditMode = useMemo(() => {
    if (isEditModeForce !== undefined) {
      return isEditModeForce;
    }
    return !!item;
  }, [isEditModeForce, item]);

  const initialItem = useRef(item);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const closeModals = useGlobalStore(s => s.closeModals);
  const copy = useCopyStore(s => s.copy);
  const [isUpdating, setIsUpdating] = useState(false);
  const [closing, setClosing] = useState(false);
  const isFetchingUsers = useIsFetching(USERS_KEY);
  const isFetchingResources = useIsFetching(RESOURCES_KEY);
  const isFetchingClients = useIsFetching(CLIENTS_KEY);
  const isFetchingBrands = useIsFetching(BRANDS_KEY);
  const isFetchingVendors = useIsFetching(VENDORS_KEY);

  const isRefetching = useMemo(
    () =>
      isFetchingUsers ||
      isFetchingResources ||
      isFetchingClients ||
      isFetchingBrands ||
      isFetchingVendors,
    [
      isFetchingBrands,
      isFetchingClients,
      isFetchingResources,
      isFetchingUsers,
      isFetchingVendors,
    ]
  );

  const { handleDelete } = useDeleteAdminItem();

  const {
    activeCategoryId,
    categoryItems,
    setActiveCategoryId,
    activeCategoryDisplayName,
    previousItemCategory,
  } = useCategoryDropdown(item);

  const changedInitialCategory = useMemo(() => {
    const categoryChanged =
      initialItem.current?.categoryIdentifier !== activeCategoryId;
    return isEditMode && categoryChanged;
  }, [activeCategoryId, isEditMode]);

  const userPartial = useUserPartial(initialItem.current);
  const resourcePartial = useResourcePartial(initialItem.current);
  const clientPartial = useClientPartial(initialItem.current);
  const brandPartial = useBrandPartial(initialItem.current);
  const vendorPartial = useVendorPartial(initialItem.current);
  const vendorServicePartial = useVendorServicePartial(initialItem.current);

  const handleSave = useCallback(
    (callback?: (success?: boolean) => void) => {
      setIsUpdating(true);
      const saveMap = {
        [CategoryIdentifier.User]: userPartial.save,
        [CategoryIdentifier.Resource]: resourcePartial.save,
        [CategoryIdentifier.Client]: clientPartial.save,
        [CategoryIdentifier.Brand]: brandPartial.save,
        [CategoryIdentifier.Vendor]: vendorPartial.save,
        [CategoryIdentifier.VendorService]: vendorServicePartial.save,
        [CategoryIdentifier.Role]: () => null,
        [CategoryIdentifier.ClientRequest]: () => null,
        [CategoryIdentifier.BrandRequest]: () => null,
      };
      saveMap[activeCategoryId]?.((success = true) => {
        setIsUpdating(false);
        callback?.(success);
      });
    },
    [
      userPartial.save,
      resourcePartial.save,
      clientPartial.save,
      brandPartial.save,
      vendorPartial.save,
      vendorServicePartial.save,
      activeCategoryId,
    ]
  );

  const simpleUpdate = useCallback(() => {
    setIsUpdating(true);
    const updateMap = {
      [CategoryIdentifier.User]: userPartial.update,
      [CategoryIdentifier.Resource]: resourcePartial.update,
      [CategoryIdentifier.Client]: clientPartial.update,
      [CategoryIdentifier.Brand]: brandPartial.update,
      [CategoryIdentifier.Vendor]: vendorPartial.update,
      [CategoryIdentifier.VendorService]: vendorServicePartial.update,
      [CategoryIdentifier.Role]: () => null,
      [CategoryIdentifier.ClientRequest]: () => null,
      [CategoryIdentifier.BrandRequest]: () => null,
    };
    updateMap[activeCategoryId]?.((success = true) => {
      setIsUpdating(false);
      if (success) {
        setClosing(true);
        successCallback?.();
      } else {
        closeChangeCategoryModal();
      }
    });
    // eslint-disable-next-line
  }, [
    userPartial.update,
    clientPartial.update,
    brandPartial.update,
    vendorPartial.update,
    resourcePartial.update,
    vendorServicePartial.update,
    activeCategoryId,
  ]);

  const onConfirmChangeCategory = useCallback(() => {
    handleSave(success => {
      if (success && initialItem.current) {
        handleDelete(initialItem.current, () => {
          setClosing(true);
          successCallback?.();
          setIsUpdating(false);
        });
      } else {
        closeChangeCategoryModal();
      }
    });
    // eslint-disable-next-line
  }, [handleDelete, handleSave]);

  const { openChangeCategoryModal, closeChangeCategoryModal } =
    useChangeItemCategory({
      currentCategory: activeCategoryDisplayName,
      onConfirmChangeCategory,
      isLoading: isUpdating,
      previousItemCategory,
    });

  const handleClose = useCallback(() => {
    closeModals(['adminItemModal', 'adminItemModal2']);
  }, [closeModals]);

  useEffect(() => {
    if (closing && !isRefetching) {
      setIsUpdating(false);
      handleClose();
      closeModal('changeItemCategoryModal');
    }
  }, [closeModal, closeModals, closing, handleClose, isRefetching]);

  const deleteAndAdd = useCallback(() => {
    openChangeCategoryModal();
  }, [openChangeCategoryModal]);

  const handleUpdate = useCallback(() => {
    if (changedInitialCategory) {
      deleteAndAdd();
    } else {
      simpleUpdate();
    }
  }, [changedInitialCategory, deleteAndAdd, simpleUpdate]);

  const handleAddClick = useCallback(() => {
    handleSave(success => {
      if (success) {
        handleClose();
        successCallback?.();
      }
    });
  }, [handleClose, handleSave, successCallback]);

  const canPressSaveButton = useMemo(() => {
    const isValidMap = {
      [CategoryIdentifier.User]: userPartial.isPartialValid,
      [CategoryIdentifier.Resource]: resourcePartial.isPartialValid,
      [CategoryIdentifier.Client]: clientPartial.isPartialValid,
      [CategoryIdentifier.Brand]: brandPartial.isPartialValid,
      [CategoryIdentifier.Vendor]: vendorPartial.isPartialValid,
      [CategoryIdentifier.VendorService]: vendorServicePartial.isPartialValid,
      [CategoryIdentifier.Role]: true,
      [CategoryIdentifier.ClientRequest]: true,
      [CategoryIdentifier.BrandRequest]: true,
    };
    return isValidMap[activeCategoryId];
  }, [
    activeCategoryId,
    brandPartial.isPartialValid,
    clientPartial.isPartialValid,
    resourcePartial.isPartialValid,
    userPartial.isPartialValid,
    vendorPartial.isPartialValid,
    vendorServicePartial.isPartialValid,
  ]);

  const { editModal } = copy.admin;

  const canChangeCategory = useMemo(() => {
    return (
      canChangeCategories &&
      activeCategoryId !== CategoryIdentifier.ClientRequest &&
      activeCategoryId !== CategoryIdentifier.BrandRequest &&
      activeCategoryId !== CategoryIdentifier.VendorService
    );
  }, [activeCategoryId, canChangeCategories]);

  return (
    <ConfigModal
      onCloseClick={handleClose}
      buttonProps={{
        label: isEditMode ? editModal.editButton : editModal.addButton,
        color: currentColor,
        icon: isEditMode ? 'check' : 'add',
        disabled: !canPressSaveButton || isRefetching > 0,
        loading: isUpdating || isRefetching > 0,
        onClick: isEditMode ? handleUpdate : handleAddClick,
        dataCy: 'admin-modal-save',
      }}
      title={isEditMode ? editModal.editTitle : editModal.addTitle}
    >
      <Styled.Wrapper data-cy="admin-modal">
        {canChangeCategory && (
          <Styled.Row>
            <Select
              dataCy="admin-modal__category"
              label={editModal.categoryDropdown.label}
              value={String(activeCategoryId)}
              onValueChange={value => setActiveCategoryId(Number(value))}
            >
              {categoryItems.map(({ displayName, id }) => (
                <SelectItem key={id} value={String(id)}>
                  {displayName}
                </SelectItem>
              ))}
            </Select>
          </Styled.Row>
        )}
        {activeCategoryId === CategoryIdentifier.User && (
          <UserPartial {...userPartial} />
        )}
        {activeCategoryId === CategoryIdentifier.Resource && (
          <ResourcePartial {...resourcePartial} />
        )}
        {activeCategoryId === CategoryIdentifier.Client && (
          <ClientPartial {...clientPartial} />
        )}
        {activeCategoryId === CategoryIdentifier.Brand && (
          <BrandPartial {...brandPartial} />
        )}
        {activeCategoryId === CategoryIdentifier.Vendor && (
          <VendorPartial {...vendorPartial} />
        )}
        {activeCategoryId === CategoryIdentifier.VendorService && (
          <VendorServicePartial {...vendorServicePartial} />
        )}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default React.memo(AdminItemModal);
