import { useRouter } from 'next/router';

import { ParsedUrlQuery } from 'querystring';

export const getParsedProjectQueryString = (query: ParsedUrlQuery) => {
  const roleIds =
    query.roleIds &&
    String(query.roleIds)
      .split(',')
      .every(item => !isNaN(Number(item)))
      ? String(query.roleIds)
          .split(',')
          .map(item => Number(item))
      : [];
  const vendorServiceIds =
    query.vendorServiceIds && String(query.vendorServiceIds).split(',')
      ? String(query.vendorServiceIds).split(',')
      : [];
  const resourceTypes =
    query.resourceTypes &&
    String(query.resourceTypes)
      .split(',')
      .every(item => !!item)
      ? String(query.resourceTypes).split(',')
      : [];
  const resourceIds =
    query.resourceIds &&
    String(query.resourceIds)
      .split(',')
      .every(item => !isNaN(Number(item)))
      ? String(query.resourceIds)
          .split(',')
          .map(item => Number(item))
      : [];
  const vendorIds =
    query.vendorIds &&
    String(query.vendorIds)
      .split(',')
      .every(item => !isNaN(Number(item)))
      ? String(query.vendorIds)
          .split(',')
          .map(item => Number(item))
      : [];
  const minAllocation = isNaN(Number(query.minAllocation))
    ? undefined
    : Number(query.minAllocation);
  const maxAllocation = isNaN(Number(query.maxAllocation))
    ? undefined
    : Number(query.maxAllocation);
  const minQuantity = isNaN(Number(query.minQuantity))
    ? undefined
    : Number(query.minQuantity);
  const maxQuantity = isNaN(Number(query.maxQuantity))
    ? undefined
    : Number(query.maxQuantity);
  const minRate = isNaN(Number(query.minRate))
    ? undefined
    : Number(query.minRate);
  const maxRate = isNaN(Number(query.maxRate))
    ? undefined
    : Number(query.maxRate);
  const minTotal = isNaN(Number(query.minTotal))
    ? undefined
    : Number(query.minTotal);
  const maxTotal = isNaN(Number(query.maxTotal))
    ? undefined
    : Number(query.maxTotal);
  const minBurn = isNaN(Number(query.minBurn))
    ? undefined
    : Number(query.minBurn);
  const maxBurn = isNaN(Number(query.maxBurn))
    ? undefined
    : Number(query.maxBurn);
  const search =
    query.search && String(query.search).length > 0
      ? String(query.search)
      : undefined;

  return {
    ...(roleIds.length > 0 && { roleIds }),
    ...(vendorServiceIds.length > 0 && { vendorServiceIds }),
    ...(resourceTypes.length > 0 && { resourceTypes }),
    ...(resourceIds.length > 0 && { resourceIds }),
    ...(vendorIds.length > 0 && { vendorIds }),
    ...(minAllocation && { minAllocation }),
    ...(maxAllocation && { maxAllocation }),
    ...(minQuantity && { minQuantity }),
    ...(maxQuantity && { maxQuantity }),
    ...(minRate && { minRate }),
    ...(maxRate && { maxRate }),
    ...(minTotal && { minTotal }),
    ...(maxTotal && { maxTotal }),
    ...(minBurn && { minBurn }),
    ...(maxBurn && { maxBurn }),
    ...(search && { search }),
  };
};

export const useParseProjectQueryString = () => {
  const router = useRouter();

  return getParsedProjectQueryString(router.query);
};
