import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { SortDirection } from '@tanstack/react-table';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { focusVisible, hover, setTypography } from 'utils/styles/mixins';
import { ColorNames, colors } from 'utils/styles/theme';

type DraggingDirection = 'top' | 'bottom';
export type TableRowTypes = {
  isDragOver?: boolean;
  draggingDirection?: DraggingDirection;
  isSpacer?: boolean;
  size?: 'small' | 'medium';
};

export type TableCellTypes = { isSelectColumn?: boolean };
const cellPadding = '0 25rem';
const selectColumnCellPadding = '0 10rem';
const headerCellPadding = '45rem 25rem';
const selectColumnHeaderCellPadding = '45rem 10rem';

const VISIBLE_ON_ROW_HOVER_ATTRIBUTE = 'data-visible-on-row-hover';

export const TableDefaultWrapper = styled.div<{ wrapperOffset: number }>`
  max-height: ${({ wrapperOffset }) =>
    wrapperOffset ? `calc(100vh - ${wrapperOffset}px)` : '100%'};
  height: ${({ wrapperOffset }) =>
    wrapperOffset ? `calc(100vh - ${wrapperOffset}px)` : '100%'};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;

  table-layout: fixed;

  background: var(--table-ambient-background);
`;

export const THead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--table-ambient-background);
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  color: var(--table-header-text);

  & th {
    border-right: 1px solid var(--table-lines);
  }

  & th:last-child {
    border-right: 0;
  }
`;

export const TableCell = styled.td<TableCellTypes>`
  padding: ${({ isSelectColumn }) =>
    isSelectColumn ? selectColumnCellPadding : cellPadding};
  vertical-align: middle;
  height: inherit;
  color: var(--table-cell-text);
`;

export const TableRow = styled.tr<TableRowTypes>`
  ${({ isSpacer, isDragOver, draggingDirection, size = 'small' }) => css`
    height: ${size === 'small' ? '80rem' : '110rem'};
    box-shadow: ${
      isSpacer
        ? 'inset 0 -10rem 0 0 var(--table-lines), 20rem 20rem 100rem'
        : '0 1px 0 0'
    } var(--table-lines)${
      isDragOver
        ? `, 0 ${
            draggingDirection === 'top' ? '-3px' : '3px'
          } 0 0 var(--table-lines-strong);`
        : ';'
    }

    transition: box-shadow 100ms linear;

    ${hover(css`
      background: var(--table-hover);

      & [${VISIBLE_ON_ROW_HOVER_ATTRIBUTE}='true'] {
        visibility: visible;
      }
    `)}
  `}

  &:focus-visible {
    box-shadow: ${colors.malibu} 0 0 0 2px;
  }
`;

export const TBody = styled.tbody<{ noRadius?: boolean }>`
  background: var(--table-background);

  & ${TableRow}:first-child ${TableCell}:first-child {
    border-top-left-radius: ${({ noRadius = false }) =>
      noRadius ? '0rem' : '40rem'};
  }
  & ${TableRow}:first-child ${TableCell}:last-child {
    border-top-right-radius: ${({ noRadius = false }) =>
      noRadius ? '0rem' : '40rem'};
  }
`;

export const TableHeaderRow = styled.tr`
  display: table-row;
`;

export const SortIcon = styled.div<{
  rotated: boolean;
  isSorted: boolean | SortDirection;
}>`
  width: 24rem;
  height: 24rem;
  transition: transform 0.2s ease-out;
  transform-origin: center;
  margin: auto 10rem;
  transform: ${({ rotated }) => `rotate(${rotated ? '180deg' : '0deg'})`};
  display: none;

  ${({ isSorted }) =>
    isSorted
      ? css`
          display: flex;
        `
      : css`
          display: 'none';
        `}
`;

export type THeadCellTypes = TableCellTypes & {
  isSortEnabled: boolean;
};

export const THeadCell = styled.th<THeadCellTypes>`
  ${({ isSortEnabled, isSelectColumn }) => css`
    vertical-align: middle;
    padding: ${isSelectColumn
      ? selectColumnHeaderCellPadding
      : headerCellPadding};

    ${hover(css`
      background: var(--table-hover);

      & ${SortIcon} {
        ${isSortEnabled ? 'display: flex;' : 'display: none;'}
      }
    `)}
  `}
`;

export const THeadCellContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  & > div {
    user-select: none;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: var(--table-ambient-background);
  gap: 60rem;
  padding: 40rem 200rem;
`;

export const PaginationLabel = styled.div`
  color: var(--header-text);
`;

export const PaginationButtons = styled.div`
  display: flex;
  gap: 60rem;
`;

export const PaginationButton = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48rem;
  height: 48rem;
  border-radius: 10rem;
  padding: 12rem;
  cursor: pointer;
  transition: background 0.2s ease-out;

  ${hover(css`
    background: var(--table-hover);
  `)}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const DynamicColumnsButton = styled.div``;

export const DynamicColumnsButtonTrigger = styled(
  DropdownMenuPrimitive.Trigger
)<{
  isAnyColumnHidden: boolean;
  color: ColorNames;
}>`
  border: 1px solid var(--table-lines);
  border-radius: 20rem;
  padding: 15rem 25rem;
  display: flex;
  align-items: center;
  gap: 10rem;

  ${hover(css`
    background: var(--table-hover);
  `)};

  &:focus-visible {
    box-shadow: ${colors.malibu} 0 0 0 2px;
  }

  ${({ disabled }) =>
    disabled
      ? css`
          color: var(--table-header-text);
          pointer-events: none;
        `
      : ''}

  ${({ isAnyColumnHidden, color }) =>
    isAnyColumnHidden
      ? css`
          background: ${colors[color]};
          color: var(--table-background);

          ${hover(css`
            background: ${rgba(colors[color], 0.8)};
          `)}
        `
      : ''}

  &[data-state='open'] {
    ${focusVisible};
  }
`;

export const DynamicColumnsButtonTriggerIcon = styled.div`
  width: 32rem;
  height: 32rem;
`;

export const TableToolbar = styled.div`
  min-height: 100rem;
  height: 100rem;
  background: var(--table-ambient-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20rem;
`;

export const TableToolbarStart = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
`;

export const TableToolbarEnd = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
`;

export const ToolbarButton = styled.button<{
  disabled?: boolean;
  isActiveColor?: boolean;
  currentColor?: ColorNames;
}>`
  border: 1px solid var(--table-lines);
  border-radius: 20rem;
  padding: 15rem 25rem;
  display: flex;
  align-items: center;
  gap: 10rem;

  ${hover(css`
    background: var(--table-hover);
  `)};

  ${({ isActiveColor, currentColor }) =>
    isActiveColor && currentColor
      ? css`
          background: ${colors[currentColor]};
          color: var(--table-background);

          ${hover(css`
            background: ${rgba(colors[currentColor], 0.8)};
          `)}
        `
      : ''}

  &:focus-visible {
    box-shadow: ${colors.malibu} 0 0 0 2px;
  }

  ${({ disabled }) =>
    disabled
      ? css`
          color: var(--table-header-text);
          pointer-events: none;
        `
      : ''}
`;

export const ToolbarButtonIcon = styled.div`
  width: 32rem;
  height: 32rem;
`;

export const ToolbarButtonDivider = styled.div`
  height: 40rem;
  width: 2px;
  background: var(--table-lines);
`;

export const DragHandle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  width: 40rem;
  height: 60rem;
  visibility: hidden;
`;

export const DragHandleIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  color: var(--table-header-text);
`;

export const RowIconButton = styled.button`
  border-radius: 50%;
  width: 36rem;
  height: 36rem;
  background: ${rgba(colors.outerSpace, 0.1)};
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;

  &:hover {
    background: ${rgba(colors.outerSpace, 0.2)};
  }

  &:focus-visible {
    ${focusVisible};
  }
`;

export const RowIconButtonIconWrapper = styled.div`
  width: 22rem;
  height: 22rem;
`;

export const TableSearchbarWrapper = styled.div``;

export const TableSerachbarIcon = styled.div`
  width: 24rem;
  height: 24rem;
  margin-right: 20rem;
`;

export const THeadCellContentText = styled.div`
  width: 100%;
`;
