import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction, useState } from 'react';

import {
  Combobox,
  ComboboxCheckboxItem,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { useVendors } from 'queries/vendors';
import { useCopyStore } from 'store';
import { Vendor as VendorType } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectsListFiltersModal.styles';

export interface VendorProps {
  vendorIds: VendorType['id'][];
  setVendorIds: Dispatch<SetStateAction<VendorType['id'][]>>;
}

const Vendor: FC<VendorProps> = ({ setVendorIds, vendorIds }) => {
  const copy = useCopyStore(s => s.copy);
  const { data: vendors } = useVendors();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const toggleVendor = (vendorId: VendorType['id']) => {
    setVendorIds(prev => {
      if (prev.includes(vendorId)) {
        return prev.filter(m => m !== vendorId);
      } else {
        return [...prev, vendorId];
      }
    });
  };

  return (
    <Styled.DropdownWrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={copy.app.projectsListFiltersModal.vendor}
      >
        <ComboboxTrigger
          label={
            vendorIds.length > 0
              ? vendorIds
                  .map(
                    vendorIds =>
                      vendors?.find(({ id }) => id === vendorIds)?.name
                  )
                  .join(', ')
              : copy.app.selectPlaceholder
          }
          style={{
            ...(vendorIds.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList>
              {vendors?.map(({ name, id }) => (
                <ComboboxCheckboxItem
                  key={id}
                  checked={vendorIds.includes(id)}
                  onSelect={() => {
                    toggleVendor(id);
                  }}
                >
                  {name}
                </ComboboxCheckboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.DropdownWrapper>
  );
};

export default memo(Vendor);
