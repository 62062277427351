import * as PopoverPrimitive from '@radix-ui/react-popover';
import { ComponentProps, ForwardedRef, forwardRef } from 'react';

import {
  Command,
  CommandCheckBoxItem,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandLabel,
  CommandList,
  CommandLoading,
  CommandSeparator,
} from 'components/Command/Command';
import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/Popover/Popover';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import { useSvgIcon } from 'u9/hooks';

import * as Styled from './Combobox.styles';

export const Combobox = ({
  label,
  children,
  colorVariant,
  modal = true,
  ...props
}: PopoverPrimitive.PopoverProps & {
  label?: React.ReactNode | React.ReactNode[];
  colorVariant?: Styled.ColorVariants;
}) => {
  return (
    <Styled.Wrapper $colorVariant={colorVariant}>
      <Popover modal={modal} {...props}>
        {label && <Styled.Label>{label}</Styled.Label>}
        {children}
      </Popover>
    </Styled.Wrapper>
  );
};
Combobox.displayName = 'Combobox';

export const ComboboxTrigger = forwardRef(
  (
    {
      dataCy,
      label,
      emptyLabel = 'Select...',
      styleVariant,
      ...props
    }: PopoverPrimitive.PopoverTriggerProps & {
      dataCy?: string;
      label?: string;
      emptyLabel?: string;
      styleVariant?: Styled.StyleVariants;
    },
    forwardedRef: ForwardedRef<HTMLButtonElement>
  ) => {
    const { SvgIcon: CaretIcon } = useSvgIcon('caret_down');
    return (
      <PopoverTrigger asChild {...props} ref={forwardedRef}>
        <Styled.TriggerButton
          role="combobox"
          $styleVariant={styleVariant ?? 'form'}
          hasLabel={!!label}
          data-cy={dataCy}
        >
          <>
            <OverflowEllipsis text={label || emptyLabel} />
            <Styled.TriggerArrow>
              <CaretIcon />
            </Styled.TriggerArrow>
          </>
        </Styled.TriggerButton>
      </PopoverTrigger>
    );
  }
);
ComboboxTrigger.displayName = 'ComboboxTrigger';

export const ComboboxContentRoot = PopoverContent;
export const ComboboxCommandRoot = Command;
export const ComboboxEmpty = CommandEmpty;
export const ComboboxLoading = CommandLoading;
export const ComboboxItem = CommandItem;
export const ComboboxLabel = CommandLabel;
export const ComboboxSeparator = CommandSeparator;
export const ComboboxGroup = CommandGroup;

export const ComboboxList = forwardRef(
  (
    props: ComponentProps<typeof CommandList> & { dataCy?: string },
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => (
    <ScrollArea>
      <Styled.List ref={forwardedRef} data-cy={props.dataCy} {...props} />
    </ScrollArea>
  )
);
ComboboxList.displayName = 'ComboboxList';

export const ComboboxInput = forwardRef(
  (
    { ...props }: ComponentProps<typeof CommandInput>,
    forwardedRef: ForwardedRef<HTMLInputElement>
  ) => {
    return <CommandInput ref={forwardedRef} {...props} />;
  }
);
ComboboxInput.displayName = 'ComboboxInput';

export const ComboboxCheckboxItem = forwardRef(
  (
    { ...props }: ComponentProps<typeof CommandCheckBoxItem>,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    return <CommandCheckBoxItem ref={forwardedRef} {...props} />;
  }
);
ComboboxCheckboxItem.displayName = 'ComboboxCheckboxItem';
