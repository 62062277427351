import React, { useCallback, useEffect, useRef } from 'react';

import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';

import * as Styled from './Tabs.styles';

export interface TabItem {
  id?: number;
  label: string;
}

export interface TabsProps {
  items: TabItem[];
  activeItemIndex: number;
  setActiveItemIndex: (index: number) => void;
}

const Tabs = ({ items, activeItemIndex, setActiveItemIndex }: TabsProps) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLButtonElement[]>([]);

  const handleItemClick = useCallback(
    (index: number) => () => {
      setActiveItemIndex(index);
    },
    [setActiveItemIndex]
  );

  useEffect(() => {
    itemsRef.current[activeItemIndex]?.scrollIntoView({ behavior: 'smooth' });
  }, [activeItemIndex, itemsRef]);

  const pos = useRef({ left: 0, x: 0 });

  const getClientX = (event: MouseEvent | TouchEvent) =>
    (event as TouchEvent).touches
      ? (event as TouchEvent).touches[0].clientX
      : (event as MouseEvent).clientX;

  const mouseMoveHandler = useCallback((event: MouseEvent | TouchEvent) => {
    const dx = getClientX(event) - pos.current.x;
    if (wrapper.current) {
      wrapper.current.scrollLeft = pos.current.left - dx;
    }
  }, []);

  const mouseUpHandler = useCallback(() => {
    if (wrapper.current) {
      wrapper.current.style.cursor = 'grab';
    }

    window.removeEventListener('mousemove', mouseMoveHandler);
    window.removeEventListener('touchmove', mouseMoveHandler);
    window.removeEventListener('mouseup', mouseUpHandler);
    window.removeEventListener('touchend', mouseUpHandler);
  }, [mouseMoveHandler]);

  const mouseDownHandler = useCallback(
    (event: MouseEvent | TouchEvent) => {
      pos.current = {
        left: wrapper.current?.scrollLeft || 0,
        x: getClientX(event),
      };

      if (wrapper.current) {
        wrapper.current.style.cursor = 'grabbing';
      }

      window.addEventListener('mousemove', mouseMoveHandler);
      window.addEventListener('touchmove', mouseMoveHandler);
      window.addEventListener('mouseup', mouseUpHandler);
      window.addEventListener('touchend', mouseUpHandler);
    },
    [mouseMoveHandler, mouseUpHandler]
  );

  useEffect(() => {
    return () => {
      window.removeEventListener('mousemove', mouseMoveHandler);
      window.removeEventListener('touchmove', mouseMoveHandler);
      window.removeEventListener('mouseup', mouseUpHandler);
      window.removeEventListener('touchend', mouseUpHandler);
    };
  }, [mouseMoveHandler, mouseUpHandler]);

  return (
    <Styled.OuterWrapper>
      <Styled.Wrapper
        ref={wrapper}
        onMouseDown={event => mouseDownHandler(event as unknown as MouseEvent)}
      >
        {items.map((item, index) => (
          <Styled.Item
            key={item.label}
            tabIndex={0}
            onClick={handleItemClick(index)}
            active={activeItemIndex === index}
            data-cy={`tab-${item.label}`}
            ref={el => {
              if (itemsRef.current && el) {
                itemsRef.current[index] = el;
              }
            }}
          >
            <OverflowEllipsis
              text={item.label}
              styles={{ userSelect: 'none' }}
            />
          </Styled.Item>
        ))}
      </Styled.Wrapper>
    </Styled.OuterWrapper>
  );
};

export default React.memo(Tabs);
