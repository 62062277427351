import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import {
  AppNotification,
  isHelperNotification,
  isListNotification,
  isPopupNotification,
} from 'utils/api.types';

export const NOTIFICATIONS_KEY = 'NOTIFICATIONS';

export const useNotifications = (
  options?: UseQueryOptions<AppNotification[]>
) => {
  const getNotifications = useApiStore(s => s.apiClient.getNotifications);
  const isApiReady = useApiStore(s => s.apiClient.isReady);
  return useQuery({
    queryKey: NOTIFICATIONS_KEY,
    queryFn: async () => (await getNotifications()).data,
    enabled: isApiReady,
    ...options,
  });
};

export const useNotificationsForList = () => {
  return useNotifications({
    select: notifications =>
      notifications.filter(notification =>
        isListNotification(notification.msg_code)
      ),
  });
};

export const useNotificationsForPopup = () => {
  return useNotifications({
    select: notifications =>
      notifications.filter(notification =>
        isPopupNotification(notification.msg_code)
      ),
  });
};

export const useNotificationsForHelpers = () => {
  return useNotifications({
    select: notifications =>
      notifications.filter(notification =>
        isHelperNotification(notification.msg_code)
      ),
  });
};

export const useUpdateNotifications = () => {
  const updateNotifications = useApiStore(s => s.apiClient.updateNotifications);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateNotifications>[0]) =>
      updateNotifications(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(NOTIFICATIONS_KEY);
      },
    }
  );
};

export const useCreateNotification = () => {
  const createNotification = useApiStore(s => s.apiClient.createNotification);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof createNotification>[0]) =>
      createNotification(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(NOTIFICATIONS_KEY);
      },
    }
  );
};
