import { useState } from 'react';

import { AdminListItem } from 'components/modules/Admin/hooks/useAdminListColumns';
import useHumanRolesItems from 'hooks/useHumanRolesItems';

export const useRolesDropdown = (item?: AdminListItem) => {
  const rolesItems = useHumanRolesItems();
  const [activeRoleId, setActiveRoleId] = useState(item?.roleId || null);
  const [rolesOpen, setRolesOpen] = useState(false);

  return {
    rolesItems,
    activeRoleId,
    setActiveRoleId,
    rolesOpen,
    setRolesOpen,
  };
};
