import { Dispatch, SetStateAction, useCallback } from 'react';

import { BudgetEntry } from 'utils/api.types';
import { getDateString } from 'utils/formatters';

interface UseHandleDateChange {
  setBudgetEntries: Dispatch<SetStateAction<BudgetEntry[]>>;
}

export const useHandleDateChange = ({
  setBudgetEntries,
}: UseHandleDateChange) => {
  const handleDateChange = useCallback(
    (index: number) => (date: Date) => {
      setBudgetEntries(prev => {
        const budgetItem = prev[index];
        budgetItem.date = getDateString(date);
        return [...prev];
      });
    },
    [setBudgetEntries]
  );

  return { handleDateChange };
};
