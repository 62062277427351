import { useRouter } from 'next/router';
import { FC } from 'react';

import Badge from 'components/Badge/Badge';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useIntro } from 'hooks/useIntro';
import { useCopyStore, useGlobalStore, useProjectStore } from 'store';
import { getProjectRoute, INTRO_ON_DEMAND_QUERY, ROUTES } from 'utils/routes';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './TutorialsDropdown.styles';

export interface TutorialsDropdownProps {}

const TutorialsDropdown: FC<TutorialsDropdownProps> = () => {
  const { runIntro } = useIntro();
  const router = useRouter();
  const openModal = useGlobalStore(s => s.openModal);
  const copy = useCopyStore(s => s.copy);
  const setIsNavbarCollapsed = useGlobalStore(s => s.setIsNavbarCollapsed);
  const setIsAdminNavigationOpen = useGlobalStore(
    s => s.setIsAdminNavigationOpen
  );
  const setProjectView = useProjectStore(s => s.setProjectView);

  const runProjectsListIntro = async () => {
    const isOnProjectsList = router.route === ROUTES.PROJECTS_LIST;
    if (!isOnProjectsList) {
      await router.push(ROUTES.PROJECTS_LIST, undefined);
    }
    runIntro('projects-list', 'on-demand');
  };

  const runCreateNewProjectIntro = async () => {
    const isOnProjectsList = router.route === ROUTES.PROJECTS_LIST;
    if (!isOnProjectsList) {
      await router.push(ROUTES.PROJECTS_LIST, undefined);
    }
    openModal('editProject');
    setTimeout(() => {
      runIntro('project-modal', 'on-demand');
    }, 500);
  };

  const runAdminNavigationIntro = async () => {
    setIsNavbarCollapsed(false);
    setIsAdminNavigationOpen(true);
    await router.push(ROUTES.ADMIN, undefined);
    runIntro('admin-navigation', 'on-demand');
  };

  const runProjectPageIntro = async () => {
    await router.push(
      `${getProjectRoute(1, 1)}?${INTRO_ON_DEMAND_QUERY}=true`,
      undefined
    );
    setTimeout(() => {
      runIntro('project-page', 'on-demand', {
        onExit: () => {
          router.push(ROUTES.PROJECTS_LIST, undefined);
        },
      });
    }, 500);
  };
  const runProjectBudgetIntro = async () => {
    await router.push(
      `${getProjectRoute(1, 1)}?${INTRO_ON_DEMAND_QUERY}=true`,
      undefined
    );
    openModal('budgetModal');
    setTimeout(() => {
      runIntro('invoice-modal', 'on-demand', {
        onExit: () => {
          router.push(ROUTES.PROJECTS_LIST, undefined);
        },
      });
    }, 500);
  };

  const runCashflowIntro = async () => {
    await router.push(
      `${getProjectRoute(1, 1)}?${INTRO_ON_DEMAND_QUERY}=true`,
      undefined
    );
    setProjectView('cashflow');
    setTimeout(() => {
      runIntro('cashflow', 'on-demand', {
        onExit: () => {
          router.push(ROUTES.PROJECTS_LIST, undefined);
        },
      });
    }, 100);
  };

  return (
    <Styled.Wrapper>
      <DropdownMenu>
        <DropdownMenuTrigger>
          {copy.app.tutorialsDropdown.label}
          <Badge
            color={ColorNames.mountainMeadow}
            styles={{ marginLeft: '20rem' }}
            size="small"
          >
            {copy.app.new}
          </Badge>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              {copy.app.tutorialsDropdown.projects}
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem onSelect={runProjectsListIntro}>
                  {copy.app.tutorialsDropdown.projectsListIntro}
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={runCreateNewProjectIntro}>
                  {copy.app.tutorialsDropdown.projectModalIntro}
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={runProjectPageIntro}>
                  {copy.app.tutorialsDropdown.projectPageIntro}
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={runProjectBudgetIntro}>
                  {copy.app.tutorialsDropdown.projectBudgetIntro}
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={runCashflowIntro}>
                  {copy.app.tutorialsDropdown.projectCashflowIntro}
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              {copy.app.tutorialsDropdown.admin}
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem onSelect={runAdminNavigationIntro}>
                  {copy.app.tutorialsDropdown.adminNavigationIntro}
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        </DropdownMenuContent>
      </DropdownMenu>
    </Styled.Wrapper>
  );
};

export default TutorialsDropdown;
