import { FC, memo, useCallback, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import { useBulkUpdateVendor, useVendors } from 'queries/vendors';
import {
  useDeleteVendorService,
  useVendorServicesWithOther,
  VENDOR_SERVICE_OTHER_ID,
} from 'queries/vendorServices';
import { useCopyStore, useGlobalStore } from 'store';
import { useSvgIcon } from 'u9/hooks';
import { Vendor, VendorService } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './DeleteVendorServiceModal.styles';

type SelectedVendorServiceType = Record<
  Vendor['id'],
  VendorService['id'] | null
>;

export interface DeleteVendorServiceModalProps {
  vendorService: VendorService;
}

const DeleteVendorServiceModal: FC<DeleteVendorServiceModalProps> = ({
  vendorService,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const { SvgIcon: CheckIcon } = useSvgIcon('check');
  const { SvgIcon: AlertIcon } = useSvgIcon('alert');
  const { SvgIcon: LongArrowRight } = useSvgIcon('transition_arrow_right');

  const getInitialSelectedVendorServices = (vendors: Vendor[]) => {
    const vendorsInVendorService = vendors.filter(
      vendor => vendor.service_id === vendorService.id
    );
    const entries = vendorsInVendorService.map(vendor => [vendor.id, null]);
    const selectedVendorServices = Object.fromEntries(entries);
    return selectedVendorServices;
  };

  const { data: vendors } = useVendors(
    {},
    {
      onSuccess: data => {
        setSelectedVendorServices(getInitialSelectedVendorServices(data));
      },
    }
  );
  const { data: vendorServices } = useVendorServicesWithOther();

  const [selectedVendorServices, setSelectedVendorServices] =
    useState<SelectedVendorServiceType>(
      vendors ? getInitialSelectedVendorServices(vendors) : {}
    );

  const { mutateAsync: deleteHandler, isLoading: deleteLoading } =
    useDeleteVendorService();
  const { mutateAsync: bulkUpdateVendor, isLoading: bulkUpdateLoading } =
    useBulkUpdateVendor();

  const handleClose = useCallback(() => {
    closeModal('deleteVendorServiceModal');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    const updateMap = Object.entries(selectedVendorServices)
      .map(([vendorId, vendorServiceId]) => ({
        id: Number(vendorId),
        service_id:
          Number(vendorServiceId) === VENDOR_SERVICE_OTHER_ID
            ? 0
            : Number(vendorServiceId),
      }))
      .filter(({ service_id }) => !!service_id);
    if (updateMap.length > 0) {
      await bulkUpdateVendor(updateMap);
    }

    await deleteHandler({ id: vendorService.id });
    handleClose();
  }, [
    selectedVendorServices,
    bulkUpdateVendor,
    deleteHandler,
    vendorService.id,
    handleClose,
  ]);

  const { title, description, cancelButton, confirmButton } =
    copy.admin.deleteVendorServiceModal;

  const vendorsInVendorService = vendors?.filter(
    vendor => vendor.service_id === vendorService.id
  );

  const vendorsWithoutCurrent = vendorServices.filter(
    service => service.id !== vendorService.id
  );

  const isAllVendorsMapped =
    Object.entries(selectedVendorServices).length === 0 ||
    Object.entries(selectedVendorServices).every(
      ([, vendorServiceId]) => !!vendorServiceId
    );

  return (
    <ConfigModal title={title} zeroPadding>
      <Styled.Wrapper>
        <Styled.Description>
          {replaceString(description, '{vendorService}', vendorService?.name)}
        </Styled.Description>
        {vendorsInVendorService && vendorsInVendorService?.length > 0 ? (
          <Styled.ActiveResources>
            {vendorsInVendorService?.map(vendor => (
              <Styled.ActiveResource key={vendor.id}>
                <Styled.ResourceName>
                  <Styled.ValidationIcon>
                    {selectedVendorServices[vendor.id] ? (
                      <Styled.ValidIcon>
                        <CheckIcon />
                      </Styled.ValidIcon>
                    ) : (
                      <Styled.InvalidIcon>
                        <AlertIcon />
                      </Styled.InvalidIcon>
                    )}
                  </Styled.ValidationIcon>

                  <OverflowEllipsis text={vendor.name} />
                </Styled.ResourceName>
                <Styled.Arrow>
                  <LongArrowRight />
                </Styled.Arrow>
                <Styled.RoleDropdown>
                  <Select
                    value={
                      selectedVendorServices[vendor.id]
                        ? String(selectedVendorServices[vendor.id])
                        : undefined
                    }
                    onValueChange={roleId =>
                      setSelectedVendorServices(prev => ({
                        ...prev,
                        [vendor.id]: Number(roleId),
                      }))
                    }
                  >
                    {vendorsWithoutCurrent?.map(vendorServiceItem => (
                      <SelectItem
                        key={vendorServiceItem.id}
                        value={String(vendorServiceItem.id)}
                      >
                        {vendorServiceItem.name}
                      </SelectItem>
                    ))}
                  </Select>
                </Styled.RoleDropdown>
              </Styled.ActiveResource>
            ))}
          </Styled.ActiveResources>
        ) : null}
        <Styled.Buttons>
          <Button
            label={cancelButton}
            icon="close"
            color={ColorNames.nandor}
            onClick={handleClose}
          />
          <Button
            label={confirmButton}
            icon="check"
            color={currentColor}
            onClick={handleConfirmClick}
            loading={deleteLoading || bulkUpdateLoading}
            disabled={deleteLoading || bulkUpdateLoading || !isAllVendorsMapped}
          />
        </Styled.Buttons>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(DeleteVendorServiceModal);
