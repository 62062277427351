import { useState } from 'react';

import { AdminListItem } from 'components/modules/Admin/hooks/useAdminListColumns';
import { useClients } from 'queries/clients';

export const useCategoryDropdown = (item?: AdminListItem) => {
  const { data: clients } = useClients();

  const items = Array.from(
    new Set(
      clients?.map(client => client.category).filter(category => !!category)
    )
  ).map(category => ({
    displayName: category,
    id: category,
  }));

  const [activeCategoryId, setActiveCategoryId] = useState<string | null>(
    item?.clientCategory || ''
  );
  const [searchValue, setSearchValue] = useState('');
  const [categoryToCreate, setCategoryToCreate] = useState('');

  return {
    items,
    activeCategoryId,
    setActiveCategoryId,
    searchValue,
    setSearchValue,
    categoryToCreate,
    setCategoryToCreate,
  };
};
