import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { brightnessFilter } from '@u9/bob3-shared/lib/utils/styles/mixins';
import {
  focusVisible,
  hideScrollbar,
  hover,
  setTypography,
} from 'utils/styles/mixins';
import { ColorNames, colors, layout } from 'utils/styles/theme';

export const OuterWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
  cursor: grab;
  flex-direction: column;

  ${hideScrollbar};
`;

export const Item = styled.button<{
  active: boolean;
  activeColor?: ColorNames;
}>`
  min-width: 300rem;
  height: 100%;
  padding: 5rem;
  text-align: center;
  ${({ theme: { locale } }) => setTypography('caption', locale)}
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${layout.borderRadius.small}rem;
  transition:
    background 0.1s ease-in-out,
    border 0.1s ease-in-out;
  background: transparent;
  border: 1px solid ${rgba(colors.white, 0.25)};
  color: ${rgba(colors.white, 0.7)};
  user-select: none;
  margin-bottom: 50rem;

  :focus-visible {
    ${focusVisible};
  }

  ${hover(brightnessFilter)}

  ${({ active, activeColor = ColorNames.white }) =>
    active
      ? css`
          background: ${colors[activeColor]};
          color: ${colors.outerSpace};
          border: 1px solid transparent;
        `
      : ''};
`;

export const OverflowDropdown = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -15rem;
  background: ${colors.outerSpace};
`;
