import * as PopoverPrimitive from '@radix-ui/react-popover';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { hover, setTypography } from 'utils/styles/mixins';
import { ColorNames, colors } from 'utils/styles/theme';

export const calendarDisplayMode = ['default', 'positionAbsolute'] as const;

export const Wrapper = styled(PopoverPrimitive.Root)`
  flex-direction: column;
  flex: 1;
`;

export const FieldWrapper = styled(PopoverPrimitive.Trigger)<{
  $noBorder: boolean;
}>`
  ${({ $noBorder }) =>
    $noBorder
      ? ''
      : css`
          border-bottom: 1px solid var(--datepicker-accent-color);
        `}
  cursor: pointer;
  flex: 1;
`;

export const Anchor = styled(PopoverPrimitive.Anchor)`
  flex: 1;
  display: flex;
`;

export const Portal = styled(PopoverPrimitive.Portal)``;

export const FieldLabel = styled.div`
  color: var(--datepicker-text-color);
  ${({ theme: { locale } }) => setTypography('caption', locale)}
  margin-bottom: 24rem;
  user-select: none;
  text-align: left;
`;

export const Field = styled.div<{ noBorder: boolean; disabled: boolean }>`
  ${({ theme: { locale } }) => setTypography('subtitle1', locale)}
  color: var(--datepicker-text-strong);
  ${({ noBorder }) =>
    noBorder
      ? ''
      : css`
          margin-bottom: 30rem;
        `}
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ disabled }) =>
    disabled
      ? css`
          color: var(--datepicker-text-color);
        `
      : ''}
`;

export const FieldStart = styled.div`
  user-select: none;
`;

export const FieldEnd = styled.div``;

export const CaretIconWrapper = styled.div`
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24rem;
  height: 24rem;
`;

export const Calendar = styled(PopoverPrimitive.Content)`
  background: ${colors.black};
  width: 520rem;
  border-radius: 20rem;
  padding: 20rem;
  z-index: 10;
`;

export const Controls = styled.div`
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled.div`
  ${({ theme: { locale } }) => setTypography('body4', locale)}
  position: relative;
  margin: 5rem;
  padding: 5rem;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LongIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25.5rem;
  height: 25.5rem;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 15rem;
`;

export const Label = styled.div`
  ${({ theme: { locale } }) => setTypography('body4', locale)}
  width: 260rem;
  text-align: center;
  user-select: none;
`;

export const DaySelection = styled.div`
  ${({ theme: { locale } }) => setTypography('caption', locale)}
  color: ${colors.white};
  display: inline-flex;
  flex-direction: column;
`;

export const DayLabels = styled.div`
  display: inline-flex;
  margin-bottom: 20rem;
`;

const cell = css`
  width: 60rem;
  height: 60rem;
  margin: 4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
`;

export const DayLabel = styled.div`
  ${cell};
  opacity: 0.5;
  user-select: none;
`;

export const DayValues = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
`;

export const DayValue = styled.div<{
  color: ColorNames;
  active: boolean;
  isWeekend: boolean;
}>`
  ${cell};
  cursor: pointer;
  border-radius: 50%;
  user-select: none;
  color: ${colors.white};
  transition: background 0.2s ease-out;

  ${({ isWeekend }) =>
    isWeekend
      ? css`
          color: ${rgba(colors.white, 0.5)};
        `
      : ''}

  ${({ color }) => css`
    ${hover(css`
      background: ${rgba(colors[color], 0.4)};
    `)}
  `}

  ${({ active, color }) =>
    active
      ? css`
          background: ${colors[color]} !important;
          color: ${colors.white};
        `
      : ''}
`;

export const EmptyCell = styled.div`
  ${cell};
`;
