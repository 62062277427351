import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';

export const CLOSURE_STATES_KEY = 'CLOSURE_STATES';

export const useClosureStates = (options?: UseQueryOptions<string[]>) => {
  const getClosureStates = useApiStore(s => s.apiClient.getClosureStates);
  return useQuery({
    queryKey: CLOSURE_STATES_KEY,
    queryFn: async () => (await getClosureStates()).data,
    ...options,
  });
};

export const usePostClosureState = () => {
  const postClosureStates = useApiStore(s => s.apiClient.postClosureStates);
  const queryClosureState = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof postClosureStates>[0]) =>
      postClosureStates(variables),
    {
      onSuccess: () => {
        queryClosureState.invalidateQueries(CLOSURE_STATES_KEY);
      },
    }
  );
};
