import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction, useState } from 'react';

import {
  Combobox,
  ComboboxCheckboxItem,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { useProjectRoles } from 'queries/roles';
import { useCopyStore } from 'store';
import { Role as RoleType } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectsListFiltersModal.styles';

export interface RoleProps {
  roleIds: RoleType['role_id'][];
  setRoleIds: Dispatch<SetStateAction<RoleType['role_id'][]>>;
}

const Role: FC<RoleProps> = ({ setRoleIds, roleIds }) => {
  const copy = useCopyStore(s => s.copy);
  const { data: roles } = useProjectRoles();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const rolesWithoutGroups = roles?.filter(({ category }) => !category);

  const toggleRole = (roleId: RoleType['role_id']) => {
    setRoleIds(prev => {
      if (prev.includes(roleId)) {
        return prev.filter(m => m !== roleId);
      } else {
        return [...prev, roleId];
      }
    });
  };

  return (
    <Styled.DropdownWrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={copy.app.projectsListFiltersModal.role}
      >
        <ComboboxTrigger
          label={
            roleIds.length > 0
              ? roleIds
                  .map(
                    roleId =>
                      rolesWithoutGroups?.find(
                        ({ role_id: id }) => id === roleId
                      )?.role
                  )
                  .join(', ')
              : copy.app.selectPlaceholder
          }
          style={{
            ...(roleIds.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList>
              {rolesWithoutGroups?.map(({ role, role_id }) => (
                <ComboboxCheckboxItem
                  key={role_id}
                  checked={roleIds.includes(role_id)}
                  onSelect={() => {
                    toggleRole(role_id);
                  }}
                >
                  {role}
                </ComboboxCheckboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.DropdownWrapper>
  );
};

export default memo(Role);
