import { useField } from 'components/inputs/hooks/useField';
import { useProject } from 'queries/project';
import { Project } from 'utils/api.types';

export const useCarbonDescriptionField = (projectId?: Project['id']) => {
  const { data: project } = useProject(
    { projectId },
    {
      enabled: !!projectId,
    }
  );

  const [carbonDescription, setCarbonDescription] = useField(
    project?.carbon_notes || ''
  );

  return {
    carbonDescription,
    setCarbonDescription,
  };
};
