import { useSession } from 'next-auth/react';
import React from 'react';

import ModalManager from 'components/modals/ModalManager/ModalManager';
import { usePageTransition } from 'hooks/usePageTransition';
import { usePopupNotifications } from 'hooks/usePopupNotifications';
import { useSaveWorkspaceInfo } from 'hooks/useSaveWorkspaceInfo';
import { useWhatsNewModal } from 'hooks/useWhatsNewModal';

import DefaultLayout from '../DefaultLayout/DefaultLayout';
import useModuleTheme from './../hooks/useModuleTheme';
import PageLoader from './PageLoader/PageLoader';

export interface LayoutManagerProps {
  children: React.ReactNode | React.ReactNode[];
}

const defaultProps: Partial<LayoutManagerProps> = {};

const LayoutManager: React.FC<LayoutManagerProps> = ({ children }) => {
  const { data: session } = useSession();
  useModuleTheme();

  const LayoutComponent = session ? DefaultLayout : React.Fragment;

  useSaveWorkspaceInfo();
  usePopupNotifications();
  useWhatsNewModal();
  const { loading } = usePageTransition();

  return (
    <>
      {loading && <PageLoader />}
      <LayoutComponent>{children}</LayoutComponent>

      <ModalManager />
    </>
  );
};

LayoutManager.defaultProps = defaultProps;

export default LayoutManager;
