import { FC, memo, useRef } from 'react';

import { useGetRoleCategory } from 'components/modules/Admin/AdminRolesList/hooks/useGetRoleCategory';
import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import { useFormatDays } from 'hooks/useFormatDays';
import { useFormatMoney } from 'hooks/useFormatMoney';
import { ProjectRow } from 'utils/api.types';

import * as Styled from './DropdownRowItem.styles';

export interface DropdownRowItemProps {
  item: ProjectRow;
}

const DropdownRowItem: FC<DropdownRowItemProps> = ({ item }) => {
  const { getRoleCategory } = useGetRoleCategory();
  const roleCategory = getRoleCategory(item);
  const { formatMoney } = useFormatMoney();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { formatDays } = useFormatDays();

  return (
    <Styled.Wrapper ref={wrapperRef}>
      <Styled.SectionWrapper>
        <OverflowEllipsis text={item.role_name || item.vendor_service?.name} />
      </Styled.SectionWrapper>
      <Styled.SectionWrapper>
        <OverflowEllipsis text={roleCategory || '-'} />
      </Styled.SectionWrapper>
      <Styled.SectionWrapper>
        <OverflowEllipsis
          text={formatDays(Math.round(item.quantity * 100) / 100)}
        />
      </Styled.SectionWrapper>
      <Styled.SectionWrapper>
        <OverflowEllipsis text={formatMoney(item.total)} />
      </Styled.SectionWrapper>
    </Styled.Wrapper>
  );
};

export default memo(DropdownRowItem);
