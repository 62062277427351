import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsFetching } from 'react-query';

import { Currencies } from '@u9/bob3-shared/lib/types/api.types';
import Checkbox from 'components/Checkbox/Checkbox';
import CardDropdown from 'components/dropdowns/CardDropdown/CardDropdown';
import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useGetRoleCategory } from 'components/modules/Admin/AdminRolesList/hooks/useGetRoleCategory';
import { useRolesCategories } from 'components/modules/Admin/AdminRolesList/hooks/useRolesCategories';
import { useIsDemo } from 'hooks/useIsDemo';
import { useOrganization } from 'queries/organizations';
import {
  ROLES_KEY,
  useAddRole,
  useProjectRoles,
  useUpdateRole,
} from 'queries/roles';
import { useSupportedClientRates } from 'queries/supportedClientRates';
import { useSupportedMfrRate } from 'queries/supportedMfrRates';
import { useCopyStore, useGlobalStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import { Role, RoleType } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './RoleModal.styles';

export type RoleModalProps = {
  editRole?: Role;
};

const RoleModal = ({ editRole }: RoleModalProps) => {
  const copy = useCopyStore(s => s.copy);
  const { data: items } = useProjectRoles();
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const rolesCategories = useRolesCategories();
  const isEditing = useMemo(() => !!editRole, [editRole]);
  const [closing, setClosing] = useState(false);
  const { getRoleCategory } = useGetRoleCategory();
  const roleCategory = getRoleCategory(editRole);
  const isFetchingRoles = useIsFetching(ROLES_KEY);
  const [tempClientRates, setTempClientRates] = useState<
    Partial<Record<Currencies, number>>
  >(editRole?.client_rates ?? {});
  const [isExpense, setIsExpense] = useState(
    editRole?.role_type === RoleType.Other
  );
  const [tempMfrRates, setTempMfrRates] = useState(editRole?.mfr_rates || {});

  const { checkAnyAccessRight } = useCheckAccessRights();
  const { data: supportedClientRates } = useSupportedClientRates();
  const { data: supportedMfrRates } = useSupportedMfrRate();
  const { data: organization } = useOrganization();
  const isDemo = useIsDemo();

  const { mutateAsync: handleAddRole, isLoading: addRoleLoading } =
    useAddRole();
  const { mutateAsync: handleUpdateRole, isLoading: updateLoading } =
    useUpdateRole();

  const categories = rolesCategories?.map(role => ({
    displayName: role.category,
    id: role.row_no,
  }));

  const [activeCategoryId, setActiveCategoryId] = useState(
    categories?.find(({ displayName }) => displayName === roleCategory)?.id ||
      categories?.[0]?.id
  );
  const activeCategory = categories?.find(({ id }) => id === activeCategoryId)
    ?.displayName;

  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.roleRequired,
      },
    }),
    [copy.app.validatorMessages.roleRequired]
  );
  const roleName = editRole?.role ?? '';
  const [role, setRole, isRoleValid, roleInvalidMessage] = useField(
    roleName,
    validators
  );
  const rateValidators = useMemo(
    () => ({
      required: {
        enabled: !isExpense,
        message: copy.app.validatorMessages.rateRequired,
      },
    }),
    [copy.app.validatorMessages.rateRequired, isExpense]
  );
  const [rate, setRate, isRateValid, rateInvalidMessage] = useField(
    editRole ? String(editRole.rate || 0) : '',
    rateValidators
  );

  const [aliases, setAliases] = useField(editRole?.aliases?.join(', ') || '');
  const transformedAliases = useMemo(
    () =>
      aliases
        ?.split(',')
        .map(alias => alias.trim())
        .filter(Boolean),
    [aliases]
  );
  const [rndPercent, setRndPercent] = useField(
    editRole?.rnd_percent ? String(editRole?.rnd_percent) : ''
  );
  const [trueMarginRate, setTrueMarginRate] = useField(
    editRole?.true_margin_rate ? String(editRole?.true_margin_rate) : ''
  );

  const rowNo = useMemo(() => {
    const activeCategoryIndex = categories?.findIndex(
      ({ id }) => id === activeCategoryId
    );
    if (activeCategoryIndex === undefined) return 0;
    const nextCategoryRowNo = categories?.[activeCategoryIndex + 1]
      ?.id as Role['row_no'];

    return nextCategoryRowNo;
  }, [activeCategoryId, categories]);

  const handleCloseModal = useCallback(() => {
    closeModal('roleModal');
  }, [closeModal]);

  const handleAddClick = async () => {
    await handleAddRole({
      pos: rowNo,
      role,
      rate: Number(rate),
      aliases: transformedAliases,
      mfr_rates: tempMfrRates,
      client_rates: tempClientRates,
      rnd_percent: rndPercent ? Number(rndPercent) : 0,
      role_type: isExpense ? RoleType.Other : RoleType.Person,
      true_margin_rate: trueMarginRate ? Number(trueMarginRate) : 0,
    });
    setClosing(true);
  };

  const handleEditClick = async (index: number) => {
    if (editRole?.row_no !== undefined) {
      await handleUpdateRole({
        ...(roleCategory !== activeCategory && { row_no: index }),
        pos: editRole?.row_no,
        rate: Number(rate),
        role,
        aliases: transformedAliases,
        mfr_rates: tempMfrRates,
        client_rates: tempClientRates,
        rnd_percent: rndPercent ? Number(rndPercent) : 0,
        true_margin_rate: trueMarginRate ? Number(trueMarginRate) : 0,
        role_type: isExpense ? RoleType.Other : RoleType.Person,
      });

      setClosing(true);
    }
  };

  useEffect(() => {
    if (closing && !isFetchingRoles) {
      handleCloseModal();
    }
  }, [closing, handleCloseModal, isFetchingRoles]);

  const isAnyInvalid = useMemo(
    () => !isRoleValid || !isRateValid,
    [isRateValid, isRoleValid]
  );

  const {
    title,
    editTitle,
    addButton,
    editButton,
    categoryPlaceholder,
    rolePlaceholder,
    ratePlaceholder,
    aliasesPlaceholder,
    rndPercentPlaceholder,
    expenseLabel,
    expenseTooltipText,
    expenseTooltipTitle,
    trueMarginPlaceholder,
    requestButton,
    requestTitle,
  } = copy.admin.roleModal;

  const index = items?.findIndex(item => item?.category === activeCategory);

  const handleTempClientRatesChange = (clientRate: string, value: string) => {
    setTempClientRates(prev => ({
      ...prev,
      [clientRate]: Number(value),
    }));
  };

  const handleTempMfrRatesChange = (mfrRateId: number, value: string) => {
    setTempMfrRates(prev => ({
      ...prev,
      [mfrRateId]: Number(value),
    }));
  };

  const onRndPercentChange = (value: string) => {
    setRndPercent(String(Math.min(100, Number(value))));
  };

  const clientRateRowsCount = Math.ceil(
    (supportedClientRates?.length ?? 0) / 3
  );
  const mfrRateRowsCount = Math.ceil((supportedMfrRates?.length ?? 0) / 3);

  const clientFieldsRender = () =>
    new Array(clientRateRowsCount).fill(null).map((_, index) => (
      <Styled.Row key={index}>
        {new Array(3).fill(null).map((__, fieldIndex) => {
          const clientRateIndex = index * 3 + fieldIndex;
          const clientRate = supportedClientRates?.[clientRateIndex];
          return clientRate ? (
            <>
              <FormInput
                onChange={value =>
                  handleTempClientRatesChange(clientRate, value)
                }
                value={tempClientRates[clientRate] || ''}
                placeholder={copy.app.clientRateColumns[clientRate]}
                mask="float"
                key={clientRate}
                disabled={isExpense}
              />
              {supportedClientRates[clientRateIndex + 1] && <Styled.Divider />}
            </>
          ) : null;
        })}
      </Styled.Row>
    ));

  const mfrRatesRender = () =>
    new Array(mfrRateRowsCount).fill(null).map((_, index) => (
      <Styled.Row key={index}>
        {new Array(3).fill(null).map((__, fieldIndex) => {
          const mfrRateIndex = index * 3 + fieldIndex;
          const mfrRate = supportedMfrRates?.[mfrRateIndex];
          return mfrRate ? (
            <>
              <FormInput
                onChange={value => handleTempMfrRatesChange(mfrRate.id, value)}
                value={tempMfrRates[mfrRate.id]}
                placeholder={`${mfrRate.code} rate`}
                mask="float"
                key={mfrRate.id}
                disabled={isExpense}
              />
              {supportedMfrRates[mfrRateIndex + 1] && <Styled.Divider />}
            </>
          ) : null;
        })}
      </Styled.Row>
    ));

  const isRequestingMode =
    !organization?.entity_config?.roles?.allow_unverified &&
    !checkAnyAccessRight(['administrator', 'owner', 'super admin']);

  const isSimplified = !checkAnyAccessRight([
    'administrator',
    'owner',
    'super admin',
  ]);

  return (
    <ConfigModal
      title={isEditing ? editTitle : isRequestingMode ? requestTitle : title}
      color={currentColor}
      buttonProps={{
        color: currentColor,
        label: isEditing
          ? editButton
          : isRequestingMode
          ? requestButton
          : addButton,
        icon: isEditing ? 'check' : 'add',
        onClick: isEditing
          ? () =>
              index !== undefined &&
              handleEditClick(index === 0 ? index + 1 : index)
          : handleAddClick,
        disabled:
          isAnyInvalid ||
          addRoleLoading ||
          updateLoading ||
          isFetchingRoles > 0,
        loading: addRoleLoading || updateLoading || isFetchingRoles > 0,
        dataCy: 'role-modal__save',
      }}
      onCloseClick={handleCloseModal}
    >
      <Styled.Wrapper>
        <Styled.Row>
          <Select
            label={categoryPlaceholder}
            value={String(activeCategoryId)}
            onValueChange={value => setActiveCategoryId(Number(value))}
            required
          >
            {categories?.map(({ id, displayName }) => (
              <SelectItem key={id} value={String(id)}>
                {displayName}
              </SelectItem>
            ))}
          </Select>
        </Styled.Row>
        <Styled.Row>
          <Checkbox
            label={expenseLabel}
            variant="square"
            value={isExpense}
            setValue={setIsExpense}
          />
          <Styled.ExpenseInfoWrapper>
            <CardDropdown
              button={<InfoIcon theme="dark" />}
              cardProps={{
                text: expenseTooltipText,
                title: expenseTooltipTitle,
                cardStyles: {
                  background: colors.black,
                },
              }}
            />
          </Styled.ExpenseInfoWrapper>
        </Styled.Row>
        <Styled.Row>
          <FormInput
            value={role}
            onChange={setRole}
            placeholder={rolePlaceholder}
            required
            error={roleInvalidMessage}
            dataCy="role-modal__role"
          />
        </Styled.Row>
        <Styled.Row>
          <FormInput
            value={rate}
            onChange={setRate}
            placeholder={`${ratePlaceholder}`}
            {...(!isExpense ? { required: true } : {})}
            mask="float"
            error={rateInvalidMessage}
            dataCy="role-modal__rate"
            disabled={isExpense}
          />
        </Styled.Row>
        {isSimplified ? null : (
          <>
            <Styled.Row>
              <FormInput
                value={aliases}
                onChange={setAliases}
                placeholder={aliasesPlaceholder}
                disabled={isExpense}
              />
            </Styled.Row>
            <Styled.Row>
              <FormInput
                value={trueMarginRate}
                onChange={setTrueMarginRate}
                placeholder={trueMarginPlaceholder}
                mask="float"
                disabled={isExpense}
              />
            </Styled.Row>
            {clientFieldsRender()}
            {mfrRatesRender()}
            {!isDemo && (
              <Styled.Row>
                <FormInput
                  placeholder={rndPercentPlaceholder}
                  onChange={onRndPercentChange}
                  value={rndPercent}
                  mask={'integer'}
                  maxCharacters={3}
                  dataCy="role-modal__rnd-percent"
                  disabled={isExpense}
                />
              </Styled.Row>
            )}
          </>
        )}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default React.memo(RoleModal);
