import { createContext, useContext, useRef } from 'react';
import { createStore, useStore } from 'zustand';

import ApiClient from 'utils/api';

interface ApiStoreProps {
  apiClient: ApiClient;
}

interface ApiStoreState extends ApiStoreProps {
  apiClient: ApiClient;
}

type ApiStore = ReturnType<typeof createApiStore>;

const createApiStore = (initProps: ApiStoreProps) => {
  return createStore<ApiStoreState>()(() => ({
    apiClient: initProps.apiClient,
  }));
};

export const ApiContext = createContext<ApiStore | null>(null);

export default function useApiStore<T>(
  selector: (state: ApiStoreState) => T,
  equalityFn?: (left: T, right: T) => boolean
): T {
  const store = useContext(ApiContext);
  if (!store) throw new Error('Missing ApiContext.Provider in the tree');
  return useStore(store, selector, equalityFn);
}

export const useInitApiStore = (initProps: ApiStoreProps) => {
  const storeRef = useRef<ApiStore>();
  storeRef.current = createApiStore(initProps);

  return storeRef;
};
