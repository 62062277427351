import { useMemo } from 'react';

import { useOrganization } from 'queries/organizations';
import { getIsDemo } from 'utils/getIsDemo';

export const useIsDemo = () => {
  const { data: organization } = useOrganization();

  return useMemo(() => {
    return getIsDemo(organization);
  }, [organization]);
};
