import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction, useState } from 'react';

import {
  Combobox,
  ComboboxCheckboxItem,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { useCurrentProjectVersion } from 'queries/projectRows';
import { useCopyStore } from 'store';
import { Role } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectFiltersModal.styles';

export interface RoleDropdownProps {
  roleIds: Role['role_id'][];
  setRoleIds: Dispatch<SetStateAction<Role['role_id'][]>>;
}

const RoleDropdown: FC<RoleDropdownProps> = ({ roleIds, setRoleIds }) => {
  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const copy = useCopyStore(s => s.copy);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const allRowItems = currentProjectVersion?.rows.filter(
    ({ spacer_name, vendor_service_id }) => !spacer_name && !vendor_service_id
  );

  const uniqueRowItems = Array.from(
    new Set(allRowItems?.map(row => row.role_id))
  );
  const items = uniqueRowItems
    ?.map(id => allRowItems?.find(item => item.role_id === id))
    .flatMap(v => (v ? [v] : []));

  const toggleActive = (activeId: Role['role_id']) => {
    if (roleIds.includes(activeId)) {
      setRoleIds(roleIds.filter(prevItem => prevItem !== activeId));
    } else {
      setRoleIds([...roleIds, activeId]);
    }
  };

  const label = roleIds?.map(
    id => items?.find(item => item.role_id === id)?.role_name
  );

  return (
    <Styled.DropdownWrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={copy.app.projectFiltersModal.roleDropdown}
      >
        <ComboboxTrigger
          label={
            label.length > 0 ? label.join(', ') : copy.app.selectPlaceholder
          }
          style={{
            ...(label.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList>
              {items?.map(({ role_name, role_id }) => (
                <ComboboxCheckboxItem
                  key={role_id}
                  checked={roleIds.includes(role_id)}
                  onSelect={() => {
                    toggleActive(role_id);
                  }}
                >
                  {role_name}
                </ComboboxCheckboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.DropdownWrapper>
  );
};

export default memo(RoleDropdown);
