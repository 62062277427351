import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsFetching } from 'react-query';

import Spinner from '@u9/bob3-shared/lib/components/Spinner/Spinner';
import {
  AccordionContent,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from 'components/Accordion/Accordion';
import { useField } from 'components/inputs/hooks/useField';
import { useProjectNameValidators } from 'components/modules/Project/hooks/useProjectNameValidators';
import { useIntro } from 'hooks/useIntro';
import { useIsDemo } from 'hooks/useIsDemo';
import { useProjectAccess } from 'hooks/useProjectAccess';
import { PROJECTS_KEY } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { Project, ProjectMode, ProjectStatus } from 'utils/api.types';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';
import AdditionalInfoTab from './AdditionalInfoTab/AdditionalInfoTab';
import {
  RndCategoryType,
  rndRanges,
} from './AdditionalInfoTab/RndCategory/RndCategory';
import { useCarbonDescriptionField } from './hooks/useCarbonDescriptionField';
import { useHandleAdd } from './hooks/useHandleAdd';
import { useJobNumber } from './hooks/useJobNumber';
import { useProject } from './hooks/useProject';
import { useRndDescriptionField } from './hooks/useRndDescriptionField';
import { useRndExcludeField } from './hooks/useRndExcludeField';
import { useRndPercentLevelField } from './hooks/useRndPercentLevelField';
import MainTab from './MainTab/MainTab';
import ProjectModalWatchButton from './ProjectModalWatchButton/ProjectModalWatchButton';

import * as Styled from './ProjectModal.styles';

enum AccordionTabs {
  MAIN = 'MAIN',
  ADDITIONAL_INFO = 'ADDITIONAL_INFO',
}

export interface ProjectModalProps {
  projectId?: Project['id'];
}

const ProjectModal = ({ projectId }: ProjectModalProps) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModals = useGlobalStore(s => s.closeModals);
  const copy = useCopyStore(s => s.copy);
  const [closing, setClosing] = useState(false);
  const isProjectFetching = useIsFetching(
    projectId ? [PROJECTS_KEY, projectId] : PROJECTS_KEY
  );
  const isDemo = useIsDemo();

  const { project, isProjectLoading } = useProject(projectId);

  const { getCanEditProject } = useProjectAccess();

  const canEditProject = getCanEditProject(project) || !projectId;

  const activeStatus = project?.status || ProjectStatus.active;
  const activeMode = project?.mode || ProjectMode.pitch;

  const jobNumberField = useJobNumber({
    projectId,
    activeMode,
  });

  const [activeBrandId, setActiveBrandId] = useState(project?.brand?.id);
  const [activeVerticalId, setActiveVerticalId] = useState(
    project?.vertical?.id
  );
  const [activeClientId, setActiveClientId] = useState(project?.client?.id);
  const [activeTerritoryId, setActiveTerritoryId] = useState(
    project?.territory?.id
  );
  const [rndCategoryId, setRndCategoryId] = useState<
    RndCategoryType | undefined
  >(project?.research_and_development?.category as RndCategoryType);
  const rndRange = rndRanges[rndCategoryId as RndCategoryType];

  const rndPercentLevelField = useRndPercentLevelField(projectId);
  const rndExcludeField = useRndExcludeField(projectId);
  const rndDescriptionField = useRndDescriptionField(projectId);
  const carbonDescriptionField = useCarbonDescriptionField(projectId);

  const isSensitiveField = useState(!!project?.access);
  const [isSensitive] = isSensitiveField;
  const isTestField = useState(project?.test_flag || false);
  const [isTest] = isTestField;
  const projectNameValidators = useProjectNameValidators();
  const { runIntro } = useIntro();

  const initialProjectName = project ? project.name : '';
  const projectNameField = useField(initialProjectName, projectNameValidators);
  const [projectName, , isProjectNameValid] = projectNameField;

  const isAnyInvalid = useMemo(
    () =>
      [jobNumberField.isJobNumberValid, isProjectNameValid].some(
        valid => !valid
      ) ||
      (!activeVerticalId && !project?.vertical?.id),
    [
      isProjectNameValid,
      jobNumberField.isJobNumberValid,
      project?.vertical?.id,
      activeVerticalId,
    ]
  );

  const closeProjectModal = useCallback(() => {
    closeModals(['newProject', 'editProject']);
  }, [closeModals]);

  const { handleAdd, updateLoading, addLoading, addProjectToGroupLoading } =
    useHandleAdd({
      activeVerticalId,
      activeBrandId,
      activeClientId,
      activeTerritoryId,
      projectId,
      activeStatus,
      activeMode,
      rndCategory: rndCategoryId,
      isJobNumberValid: jobNumberField.isJobNumberValid,
      isProjectNameValid,
      projectName,
      jobNumber: jobNumberField.jobNumber,
      isSensitive,
      isTest,
      setDuplicateError: jobNumberField.setDuplicateError,
      setClosing,
      rndDescription: rndDescriptionField?.rndDescription,
      rndExclude: rndExcludeField?.isExcludeRnd,
      rndPercent: Number(rndPercentLevelField?.rndPercentLevel),
      carbonDescription: carbonDescriptionField?.carbonDescription,
    });

  const sendingRequest =
    addLoading || updateLoading || addProjectToGroupLoading;

  useEffect(() => {
    if (closing && !isProjectFetching) {
      closeProjectModal();
    }
  }, [closing, closeProjectModal, isProjectFetching]);

  useEffect(() => {
    runIntro('project-modal', 'automatic');
  }, [runIntro]);

  return (
    <ConfigModal
      color={currentColor}
      title={
        project ? copy.app.projectModal.editTitle : copy.app.projectModal.title
      }
      buttonProps={{
        disabled:
          isAnyInvalid ||
          sendingRequest ||
          isProjectLoading ||
          isProjectFetching > 0,
        label: project
          ? copy.app.projectModal.editButtonText
          : copy.app.projectModal.buttonText,
        icon: project ? 'check' : 'add',
        onClick: handleAdd,
        loading: updateLoading || addLoading || isProjectFetching > 0,
        dataCy: 'project-modal__save-button',
      }}
      onCloseClick={closeProjectModal}
      zeroPadding
      headerContent={<ProjectModalWatchButton projectId={projectId} />}
    >
      <Styled.Wrapper data-cy={canEditProject ? '' : 'project-modal--disabled'}>
        {isProjectLoading ? (
          <Styled.Loader>
            <Spinner color={ColorNames.white} />
          </Styled.Loader>
        ) : (
          <AccordionRoot
            type="single"
            defaultValue={AccordionTabs.MAIN}
            colorScheme="dark"
          >
            <AccordionItem value={AccordionTabs.MAIN}>
              {!isDemo && (
                <AccordionTrigger dataCy="project-modal-accordion__MAIN__item">
                  {copy.app.projectModal.generalTab}
                </AccordionTrigger>
              )}

              <AccordionContent>
                <Styled.AccordionContent>
                  <MainTab
                    project={project}
                    activeBrandId={activeBrandId}
                    setActiveBrandId={setActiveBrandId}
                    activeVerticalId={activeVerticalId}
                    setActiveVerticalId={setActiveVerticalId}
                    activeClientId={activeClientId}
                    activeStatus={activeStatus}
                    activeMode={activeMode}
                    activeTerritoryId={activeTerritoryId}
                    setActiveClientId={setActiveClientId}
                    setActiveTerritoryId={setActiveTerritoryId}
                    jobNumberField={jobNumberField}
                    isSensitiveField={isSensitiveField}
                    isTestField={isTestField}
                    projectNameField={projectNameField}
                  />
                </Styled.AccordionContent>
              </AccordionContent>
            </AccordionItem>
            {!isDemo && (
              <AccordionItem
                value={AccordionTabs.ADDITIONAL_INFO}
                data-intro="additional-info"
              >
                <AccordionTrigger dataCy="project-modal-accordion__ADDITIONAL_INFO__item">
                  {copy.app.projectModal.additionalInfoTab}
                </AccordionTrigger>
                <AccordionContent>
                  <Styled.AccordionContent>
                    <AdditionalInfoTab
                      project={project}
                      rndCategoryId={rndCategoryId}
                      setRndCategoryId={setRndCategoryId}
                      rndRange={rndRange}
                      rndDescriptionField={rndDescriptionField}
                      rndExcludeField={rndExcludeField}
                      rndPercentLevelField={rndPercentLevelField}
                      carbonDescriptionField={carbonDescriptionField}
                    />
                  </Styled.AccordionContent>
                </AccordionContent>
              </AccordionItem>
            )}
          </AccordionRoot>
        )}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default React.memo(ProjectModal);
