import { FC, memo, useState } from 'react';

import Datepicker from 'components/datepickers/Datepicker/Datepicker';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useCopyStore, useGlobalStore } from 'store';
import { BudgetEntry, PaymentStatuses } from 'utils/api.types';
import { formatDateInline } from 'utils/formatters';

import { useRowsFields } from '../BudgetModal/hooks/useRowsFields';
import { useSaveBudgetEntry } from '../BudgetModal/hooks/useSaveBudgetEntry';
import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './BudgetInvoiceDataFillModal.styles';

export interface BudgetInvoiceDataFillModalProps {
  budgetEntries: BudgetEntry[];
  index: number;
  value: PaymentStatuses;
  handleChange: ReturnType<typeof useRowsFields>['handleChange'];
  saveBudgetEntry: ReturnType<typeof useSaveBudgetEntry>['saveBudgetEntry'];
  onDateChange: (index: number) => (date: Date) => void;
}

const BudgetInvoiceDataFillModal: FC<BudgetInvoiceDataFillModalProps> = ({
  budgetEntries,
  handleChange,
  index,
  saveBudgetEntry,
  value,
  onDateChange,
}) => {
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const copy = useCopyStore(s => s.copy);
  const entry = budgetEntries[index];

  const [localDate, setLocalDate] = useState<Date | undefined>(
    entry.date ? new Date(entry.date) : new Date()
  );
  const [localInvoiceNumber, setLocalInvoiceNumber] = useState<string>(
    entry.invoice_number ?? ''
  );

  const close = () => {
    closeModal('budgetInvoiceDataFill');
  };

  const save = () => {
    if (localDate) {
      handleChange(index, 'payment_status')(value);
      onDateChange(index)(localDate);
      handleChange(index, 'invoice_number')(localInvoiceNumber);

      setTimeout(() => {
        saveBudgetEntry(index)();
        close();
      }, 0);
    }
  };

  return (
    <ConfigModal
      title={copy.project.invoiceDataFillModal.title}
      buttonProps={{
        label: copy.project.invoiceDataFillModal.saveButton,
        color: currentColor,
        onClick: save,
        icon: 'check',
        dataCy: 'budget-invoice-data-fill-save',
        disabled: !localDate,
      }}
      color={currentColor}
      onCloseClick={close}
      paddingBottom={100}
    >
      <Styled.Wrapper>
        <Styled.Text>{copy.project.invoiceDataFillModal.text}</Styled.Text>
        <Styled.DatepickerWrapper>
          <Datepicker
            color={currentColor}
            noBorder
            fieldLabel={copy.project.invoiceDataFillModal.dateFieldLabel}
            value={localDate}
            displayValue={
              localDate
                ? formatDateInline(localDate)
                : copy.project.invoiceDataFillModal.placeholder
            }
            onChange={setLocalDate}
          />
        </Styled.DatepickerWrapper>
        <Styled.Row>
          <FormInput
            onChange={setLocalInvoiceNumber}
            value={localInvoiceNumber}
            placeholder={
              copy.project.invoiceDataFillModal.invoiceNumberPlaceholder
            }
            dataCy="budget-invoice-data-fill-invoice-number"
          />
        </Styled.Row>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(BudgetInvoiceDataFillModal);
