import { FC, memo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import { useResolveDuplicates } from 'queries/duplicates';
import { useCopyStore, useGlobalStore } from 'store';
import {
  DuplicateItem,
  DuplicateType,
  ResolveDuplicatesInput,
  Resource,
  ResourceType,
} from 'utils/api.types';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './ResolveResourceDuplicates.styles';

export interface ResolveResourceDuplicatesProps {
  duplicateItems: DuplicateItem[];
  type: DuplicateType;
}

const OPTION_INCORRECT = 'INCORRECT';
const OPTION_SEPARATE = 'SEPARATE';
const OPTION_CORRECT = 'CORRECT';

const ResolveResourceDuplicates: FC<ResolveResourceDuplicatesProps> = ({
  duplicateItems,
  type,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { mutateAsync: resolve, isLoading: isResolveLoading } =
    useResolveDuplicates();
  const [isCorrectItemDropdownOpen, setIsCorrectItemDropdownOpen] =
    useState(false);

  const [resolveOptions, setResolveOptions] = useState(
    duplicateItems?.map(({ id }, index) =>
      index === 0
        ? { id, option: OPTION_CORRECT }
        : { id, option: OPTION_INCORRECT }
    )
  );

  const correctItemId = resolveOptions.find(
    ({ option }) => option === OPTION_CORRECT
  )?.id;
  const correctItem = duplicateItems?.find(({ id }) => id === correctItemId);

  const close = () => {
    closeModal('resolveResourceDuplicates');
  };

  const getFormattedItem = (item?: DuplicateItem) => {
    if (!item) return '';
    let typeText = '';
    let role = '';
    let email = '';
    if ('type' in item) {
      typeText =
        item?.type === ResourceType.external
          ? copy.app.territoryShort.external
          : item?.type === ResourceType.mfr
          ? copy.app.territoryShort.mfr
          : copy.app.territoryShort.internal;
    }
    if ('role' in item) {
      role = item.role ?? '';
    }
    if ('email' in item) {
      email = item.email;
    }

    return `${item?.name} ${email ? ` (${email})` : ''}${
      typeText ? ` - ${typeText}` : ''
    }${role ? ` - ${role}` : ''}`;
  };

  const resolveItems = [
    {
      id: OPTION_INCORRECT,
      label: copy.admin.duplicates.modal.optionIncorrect,
    },
    { id: OPTION_SEPARATE, label: copy.admin.duplicates.modal.optionSeparate },
  ];

  const itemsToResolve = duplicateItems?.filter(
    ({ id }) => id !== correctItemId
  );

  const assignNewCorrectResource = (resourceId: Resource['id']) => {
    setResolveOptions(
      resolveOptions.map(({ id, option }) => ({
        id,
        option:
          option === OPTION_CORRECT
            ? OPTION_INCORRECT
            : id === resourceId
            ? OPTION_CORRECT
            : option,
      }))
    );
  };

  const handleResolveItems = async () => {
    await resolve({
      type,
      input: resolveOptions
        .filter(({ option }) => option !== OPTION_CORRECT)
        .map(({ id, option }) => {
          if (option === OPTION_INCORRECT) {
            return {
              id,
              parent_id: correctItemId,
              action: 'merge',
            } as ResolveDuplicatesInput;
          } else {
            return {
              id,
              parent_id: correctItemId,
              action: 'split',
            } as ResolveDuplicatesInput;
          }
        }),
    });
    close();
  };

  return (
    <ConfigModal
      title={copy.admin.duplicates.modal.title}
      onCloseClick={close}
      color={currentColor}
      zeroPadding
    >
      <Styled.Wrapper>
        <Styled.CorrectResourceWrapper>
          <Combobox
            open={isCorrectItemDropdownOpen}
            onOpenChange={setIsCorrectItemDropdownOpen}
            label={copy.admin.duplicates.modal.correctLabel}
          >
            <ComboboxTrigger label={getFormattedItem(correctItem)} />
            <ComboboxContentRoot>
              <ComboboxCommandRoot>
                <ComboboxInput />
                <ComboboxEmpty />
                <ComboboxList>
                  {duplicateItems?.map(item => (
                    <ComboboxItem
                      key={item.id}
                      selected={item.id === correctItemId}
                      onSelect={() => {
                        assignNewCorrectResource(item.id);
                        setIsCorrectItemDropdownOpen(false);
                      }}
                    >
                      {getFormattedItem(item)}
                    </ComboboxItem>
                  ))}
                </ComboboxList>
              </ComboboxCommandRoot>
            </ComboboxContentRoot>
          </Combobox>
        </Styled.CorrectResourceWrapper>
        <Styled.ResourcesToResolveLabel>
          {copy.admin.duplicates.modal.description}
        </Styled.ResourcesToResolveLabel>
        <Styled.ResourcesToResolve>
          {itemsToResolve?.map(resource => (
            <Styled.ResourceToResolveItem key={resource.id}>
              <Styled.Resource>{getFormattedItem(resource)}</Styled.Resource>
              <Styled.ChoiceDropdown>
                <Select
                  value={
                    resolveOptions.find(({ id }) => id === resource.id)?.option
                  }
                  onValueChange={newOption => {
                    setResolveOptions(prev =>
                      prev.map(item =>
                        item.id === resource.id
                          ? {
                              id: item.id,
                              option: newOption,
                            }
                          : item
                      )
                    );
                  }}
                  label={copy.admin.duplicates.modal.selectLabel}
                >
                  {resolveItems.map(({ id, label }) => (
                    <SelectItem key={id} value={id}>
                      {label}
                    </SelectItem>
                  ))}
                </Select>
              </Styled.ChoiceDropdown>
            </Styled.ResourceToResolveItem>
          ))}
        </Styled.ResourcesToResolve>
        <Styled.Buttons>
          <Button
            label={copy.admin.duplicates.modal.cancelButton}
            color={ColorNames.nandor}
            icon="close"
            onClick={close}
            disabled={isResolveLoading}
          />
          <Button
            label={copy.admin.duplicates.modal.confirmButton}
            color={currentColor}
            icon="check"
            onClick={handleResolveItems}
            disabled={isResolveLoading}
            loading={isResolveLoading}
          />
        </Styled.Buttons>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(ResolveResourceDuplicates);
