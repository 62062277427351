import { FC, memo, useCallback, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import {
  AdminListItem,
  CategoryIdentifier,
} from 'components/modules/Admin/hooks/useAdminListColumns';
import { useDeleteBrand } from 'queries/brands';
import { useClients, useDeleteClient } from 'queries/clients';
import { useDeleteResource, useResources } from 'queries/resources';
import { useDeleteRole } from 'queries/roles';
import { useDeleteVendor } from 'queries/vendors';
import { useDeleteVendorService } from 'queries/vendorServices';
import { useCopyStore, useGlobalStore } from 'store';
import { Client, Resource } from 'utils/api.types';
import { replaceStrings } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

import * as Styled from './DeleteUnusedAdminItemModal.styles';

export interface DeleteUnusedAdminItemModalProps {
  item: AdminListItem;
}

const DeleteUnusedAdminItemModal: FC<DeleteUnusedAdminItemModalProps> = ({
  item,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const { data: resources } = useResources(
    {},
    {
      enabled: item.categoryIdentifier === CategoryIdentifier.Resource,
    }
  );
  const { data: clients } = useClients(
    {},
    {
      enabled: item.categoryIdentifier === CategoryIdentifier.Client,
    }
  );
  const replacements =
    item.categoryIdentifier === CategoryIdentifier.Resource
      ? resources?.filter(({ id }) => id !== item.resourceId) ?? []
      : item.categoryIdentifier === CategoryIdentifier.Client
      ? clients?.filter(({ id }) => id !== item.clientId) ?? []
      : [];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [replacementId, setReplacementId] = useState<
    Resource['id'] | Client['id'] | null
  >(null);

  const { mutateAsync: deleteResource, isLoading: isResourceDeleteLoading } =
    useDeleteResource();
  const { mutateAsync: deleteClient, isLoading: isDeleteClientLoading } =
    useDeleteClient();
  const { mutateAsync: deleteBrand, isLoading: isDeleteBrandLoading } =
    useDeleteBrand();
  const { mutateAsync: deleteVendor, isLoading: isDeleteVendorLoading } =
    useDeleteVendor();
  const {
    mutateAsync: deleteVendorService,
    isLoading: isDeleteVendorServiceLoading,
  } = useDeleteVendorService();
  const { mutateAsync: deleteRole, isLoading: isDeleteRoleLoading } =
    useDeleteRole();

  const isLoading =
    isResourceDeleteLoading ||
    isDeleteClientLoading ||
    isDeleteBrandLoading ||
    isDeleteVendorLoading ||
    isDeleteVendorServiceLoading ||
    isDeleteRoleLoading;

  const handleClose = useCallback(() => {
    closeModal('deleteUnusedAdminItemModal');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    if (
      item.categoryIdentifier === CategoryIdentifier.Resource &&
      item.resourceId
    ) {
      await deleteResource({
        id: item.resourceId,
        ...(replacementId && { parent_id: replacementId }),
      });
    }
    if (
      item.categoryIdentifier === CategoryIdentifier.Client &&
      item.clientId
    ) {
      await deleteClient({
        id: item.clientId,
        ...(replacementId && {
          parent_id: replacementId,
        }),
      });
    }
    if (item.categoryIdentifier === CategoryIdentifier.Brand && item.brandId) {
      await deleteBrand({
        id: item.brandId,
      });
    }
    if (
      item.categoryIdentifier === CategoryIdentifier.Vendor &&
      item.vendorId
    ) {
      await deleteVendor({
        id: item.vendorId,
      });
    }
    if (
      item.categoryIdentifier === CategoryIdentifier.VendorService &&
      item.vendorServiceId
    ) {
      await deleteVendorService({
        id: item.vendorServiceId,
      });
    }
    if (item.categoryIdentifier === CategoryIdentifier.Role && item.rowNo) {
      await deleteRole({
        rowNo: item.rowNo,
      });
    }

    handleClose();
  }, [
    item.categoryIdentifier,
    item.resourceId,
    item.clientId,
    item.brandId,
    item.vendorId,
    item.vendorServiceId,
    item.rowNo,
    handleClose,
    deleteResource,
    replacementId,
    deleteClient,
    deleteBrand,
    deleteVendor,
    deleteVendorService,
    deleteRole,
  ]);

  const {
    title,
    description,
    cancelButton,
    confirmButton,
    dropdownLabel,
    resourceDuplicatePrompt,
  } = copy.admin.deleteUnusedResourceModal;

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
        dataCy: 'delete-unused-resource-confirm',
      }}
      confirmButtonProps={{
        label: confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: isLoading,
        disabled: isLoading,
      }}
      description={replaceStrings(description, [['{name}', item?.name]])}
      title={title}
      content={
        replacements &&
        replacements?.length > 0 && (
          <Styled.SubPromptWrapper>
            <Styled.SubPrompt>{resourceDuplicatePrompt}</Styled.SubPrompt>
            <Combobox
              open={dropdownOpen}
              onOpenChange={setDropdownOpen}
              label={dropdownLabel}
            >
              <ComboboxTrigger
                label={
                  // TODO: This is a temporary fix. Proper fix is to update Typescript version to 5.2
                  // Ref: https://github.com/microsoft/TypeScript/issues/44373
                  (replacements as { id: number; name: string }[])?.find(
                    ({ id }) => id === replacementId
                  )?.name
                }
              />
              <ComboboxContentRoot>
                <ComboboxCommandRoot>
                  <ComboboxInput />
                  <ComboboxEmpty />
                  <ComboboxList>
                    {(
                      replacements as {
                        id: number;
                        name: string;
                        email: string;
                      }[]
                    )?.map(({ name, email, id }) => (
                      <ComboboxItem
                        key={id}
                        selected={replacementId === id}
                        onSelect={() => {
                          setReplacementId(replacementId === id ? null : id);
                          setDropdownOpen(false);
                        }}
                      >
                        {`${name}${email ? ` (${email})` : ''})`}
                      </ComboboxItem>
                    ))}
                  </ComboboxList>
                </ComboboxCommandRoot>
              </ComboboxContentRoot>
            </Combobox>
          </Styled.SubPromptWrapper>
        )
      }
    />
  );
};

export default memo(DeleteUnusedAdminItemModal);
