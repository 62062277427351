import { AnimatePresence, MotionProps } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';

import AddProjectRows from 'components/modals/AddProjectRows/AddProjectRows';
import AdminItemModal from 'components/modals/AdminItemModal/AdminItemModal';
import BudgetModal from 'components/modals/BudgetModal/BudgetModal';
import CardModal from 'components/modals/CardModal/CardModal';
import ConfirmShareProjectModal from 'components/modals/ConfirmShareProjectModal/ConfirmShareProjectModal';
import EditResourceModal from 'components/modals/EditResourceModal/EditResourceModal';
import ProjectModal from 'components/modals/ProjectModal/ProjectModal';
import RoleCategoryModal from 'components/modals/RoleCategoryModal/RoleCategoryModal';
import RoleModal from 'components/modals/RoleModal/RoleModal';
import TerritoryModal from 'components/modals/TerritoryModal/TerritoryModal';
import VendorServiceModal from 'components/modals/VendorServiceModal/VendorServiceModal';
import VerticalModal from 'components/modals/VerticalModal/VerticalModal';
import { useGlobalStore } from 'store';
import { ModalId } from 'store/global.types';

import AddClientRateColumnModal from '../AddClientRateColumnModal/AddClientRateColumnModal';
import AddMfrRateColumnModal from '../AddMfrRateColumnModal/AddMfrRateColumnModal';
import AddProjectGroup from '../AddProjectGroupModal/AddProjectGroup';
import AddProjectVersion from '../AddProjectVersion/AddProjectVersion';
import AdminItemUnusedPostponeModal from '../AdminItemUnusedPostponeModal/AdminItemUnusedPostponeModal';
import BrandRequestModal from '../BrandRequestModal/BrandRequestModal';
import BudgetInvoiceDataFillModal from '../BudgetInvoiceDataFillModal/BudgetInvoiceDataFillModal';
import CashflowEventEditModal from '../CashflowEventEditModal/CashflowEventEditModal';
import CashflowExpenseTransaction from '../CashflowExpenseTransaction/CashflowExpenseTransaction';
import CashflowInvoiceEditModal from '../CashflowInvoiceEditModal/CashflowInvoiceEditModal';
import CashflowPrefillItemsModal from '../CashflowPrefillItemsModal/CashflowPrefillItemsModal';
import ChangeItemCategoryModal from '../ChangeItemCategoryModal/ChangeItemCategoryModal';
import ClientReportDownloadModal from '../ClientReportDownloadModal/ClientReportDownloadModal';
import ClientRequestModal from '../ClientRequestModal/ClientRequestModal';
import ConfirmDeletePermissionModal from '../ConfirmDeletePermissionModal/ConfirmDeletePermissionModal';
import ConfirmDuplicateProjectRowsModal from '../ConfirmDuplicateProjectRowsModal/ConfirmDuplicateProjectRowsModal';
import ConfirmMainVersionChangeModal from '../ConfirmMainVersionChangeModal/ConfirmMainVersionChangeModal';
import CostAnalysisModal from '../CostAnalysisModal/CostAnalysisModal';
import CustomTargetModal from '../CustomTargetModal/CustomTargetModal';
import DeclineProjectAccessModal from '../DeclineProjectAccessmodal/DeclineProjectAccessModal';
import DeleteAdminItemModal from '../DeleteAdminItemModal/DeleteAdminItemModal';
import DeleteClientRateColumnModal from '../DeleteClientRateColumnModal/DeleteClientRateColumnModal';
import DeleteCommentModal from '../DeleteCommentModal/DeleteCommentModal';
import DeleteContractsLinkModal from '../DeleteContractsLinkModal/DeleteContractsLinkModal';
import DeleteIntegration from '../DeleteIntegration/DeleteIntegration';
import DeleteMfrRateColumnModal from '../DeleteMfrRateColumnModal/DeleteMfrRateColumnModal';
import DeleteProjectGroupModal from '../DeleteProjectGroupModal/DeleteProjectGroupModal';
import DeleteProjectModal from '../DeleteProjectModal/DeleteProjectModal';
import DeleteProjectRowModal from '../DeleteProjectRowModal/DeleteProjectRowModal';
import DeleteProjectVersion from '../DeleteProjectVersion/DeleteProjectVersion';
import DeleteRoleModal from '../DeleteRoleModal/DeleteRoleModal';
import DeleteTerritoryModal from '../DeleteTerritoryModal/DeleteTerritoryModal';
import DeleteUnusedAdminItemModal from '../DeleteUnusedAdminItemModal/DeleteUnusedAdminItemModal';
import DeleteVendorServiceModal from '../DeleteVendorServiceModal/DeleteVendorServiceModal';
import DeleteVerticalModal from '../DeleteVerticalModal/DeleteVerticalModal';
import DenyBobAccessRequestModal from '../DenyBobAccessRequestModal/DenyBobAccessRequestModal';
import DropdownConfigurationModal from '../DropdownConfigurationModal/DropdownConfigurationModal';
import DuplicateProjectModal from '../DuplicateProjectModal/DuplicateProjectModal';
import DuplicateProjectVersion from '../DuplicateProjectVersion/DuplicateProjectVersion';
import EditIntegration from '../EditIntegration/EditIntegration';
import EditMfrRateColumnModal from '../EditMfrRateColumnModal/EditMfrRateColumnModal';
import EditProjectGroup from '../EditProjectGroup/EditProjectGroup';
import EditProjectVersionNameModal from '../EditProjectVersionNameModal/EditProjectVersionNameModal';
import FinancePaymentModal from '../FinancePaymentModal/FinancePaymentModal';
import GenerateReportModal from '../GenerateReportModal/GenerateReportModal';
import HandleJobClosureRequestModal from '../HandleJobClosureRequestModal/HandleJobClosureRequestModal';
import JobNumberFillModal from '../JobNumberFillModal/JobNumberFillModal';
import MergeChangeOrderModal from '../MergeChangeOrderModal/MergeChangeOrderModal';
import MfrDiscountModal from '../MfrDiscountModal/MfrDiscountModal';
import PerformanceFiltersModal from '../PerformanceFiltersModal/PerformanceFiltersModal';
import PermissionModal from '../PermissionModal/PermissionModal';
import PopupNotification from '../PopupNotification/PopupNotification';
import ProjectFiltersModal from '../ProjectFiltersModal/ProjectFiltersModal';
import ProjectLocked from '../ProjectLocked/ProjectLocked';
import ProjectsListFiltersModal from '../ProjectsListFiltersModal/ProjectsListFiltersModal';
import ReconcileModal from '../ReconcileModal/ReconcileModal';
import ReopenProjectModal from '../ReopenProjectModal/ReopenProjectModal';
import RequestJobClosureModal from '../RequestJobClosureModal/RequestJobClosureModal';
import RequestJobNumberModal from '../RequestJobNumberModal/RequestJobNumberModal';
import ResolveResourceDuplicates from '../ResolveResourceDuplicates/ResolveResourceDuplicates';
import RestoreDeletedItemModal from '../RestoreDeletedItemModal/RestoreDeletedItemModal';
import SelectReconcileIntegrationModal from '../SelectReconcileIntegrationModal/SelectReconcileIntegrationModal';
import ShareProjectModal from '../ShareProjectModal/ShareProjectModal';
import UnhideProjectModal from '../UnhideProjectModal/UnhideProjectModal';
import UnverifyResourceModal from '../UnverifyResourceModal/UnverifyResourceModal';
import WhatsNewModal from '../WhatsNewModal/WhatsNewModal';

export interface ModalManagerProps {}

export const modalAnimConfig: MotionProps = {
  initial: { opacity: 0, x: 10 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 10 },
  transition: { duration: 0.2, delay: 0.1 },
};

export const modalMap: Record<ModalId, any> = {
  editProject: ProjectModal,
  newProject: ProjectModal,
  addProjectRow: AddProjectRows,
  adminItemModal: AdminItemModal,
  adminItemModal2: AdminItemModal,
  budgetModal: BudgetModal,
  changeItemCategoryModal: ChangeItemCategoryModal,
  confirmDeleteProjectRow: DeleteProjectRowModal,
  deleteAdminItemModal: DeleteAdminItemModal,
  deleteComment: DeleteCommentModal,
  deleteContractsLinkModal: DeleteContractsLinkModal,
  deleteProject: DeleteProjectModal,
  deleteRoleModal: DeleteRoleModal,
  deleteTerritoryModal: DeleteTerritoryModal,
  deleteVendorServiceModal: DeleteVendorServiceModal,
  deleteVerticalModal: DeleteVerticalModal,
  duplicateProject: DuplicateProjectModal,
  editResource: EditResourceModal,
  generateReportModal: GenerateReportModal,
  networkError: CardModal,
  commonError: CardModal,
  roleCategoryModal: RoleCategoryModal,
  roleModal: RoleModal,
  reopenProjectModal: ReopenProjectModal,
  territoryModal: TerritoryModal,
  vendorServiceModal: VendorServiceModal,
  verticalModal: VerticalModal,
  confirmShareProject: ConfirmShareProjectModal,
  declineProjectAccess: DeclineProjectAccessModal,
  shareProjectModal: ShareProjectModal,
  unhideProjectModal: UnhideProjectModal,
  denyBobAccessRequest: DenyBobAccessRequestModal,
  unverifyResourceModal: UnverifyResourceModal,
  addClientRateColumnModal: AddClientRateColumnModal,
  deleteClientRateColumnModal: DeleteClientRateColumnModal,
  clientReportDownloadModal: ClientReportDownloadModal,
  adminItemUnusedPostpone: AdminItemUnusedPostponeModal,
  deleteUnusedAdminItemModal: DeleteUnusedAdminItemModal,
  addMfrRateColumnModal: AddMfrRateColumnModal,
  deleteMfrRateColumnModal: DeleteMfrRateColumnModal,
  editMfrRateColumnModal: EditMfrRateColumnModal,
  performanceFiltersModal: PerformanceFiltersModal,
  selectReconcileIntegrationModal: SelectReconcileIntegrationModal,
  confirmDuplicateProjectRowsModal: ConfirmDuplicateProjectRowsModal,
  restoreDeletedItemModal: RestoreDeletedItemModal,
  customTargetModal: CustomTargetModal,
  mfrDiscountModal: MfrDiscountModal,
  requestJobNumberModal: RequestJobNumberModal,
  jobNumberFillModal: JobNumberFillModal,
  reconcileModal: ReconcileModal,
  editProjectVersionName: EditProjectVersionNameModal,
  duplicateProjectVersion: DuplicateProjectVersion,
  deleteProjectVersion: DeleteProjectVersion,
  addProjectVersion: AddProjectVersion,
  addProjectGroup: AddProjectGroup,
  editProjectGroup: EditProjectGroup,
  deleteProjectGroup: DeleteProjectGroupModal,
  costAnalysis: CostAnalysisModal,
  projectsListFilters: ProjectsListFiltersModal,
  clientRequest: ClientRequestModal,
  brandRequest: BrandRequestModal,
  resolveResourceDuplicates: ResolveResourceDuplicates,
  cashflowEventEdit: CashflowEventEditModal,
  confirmMainVersionChange: ConfirmMainVersionChangeModal,
  financePayment: FinancePaymentModal,
  projectFilters: ProjectFiltersModal,
  budgetInvoiceDataFill: BudgetInvoiceDataFillModal,
  deleteIntegration: DeleteIntegration,
  editIntegration: EditIntegration,
  requestJobClosureModal: RequestJobClosureModal,
  handleJobClosureRequestModal: HandleJobClosureRequestModal,
  popupNotification: PopupNotification,
  confirmDeletePermissionModal: ConfirmDeletePermissionModal,
  permissionModal: PermissionModal,
  dropdownConfiguration: DropdownConfigurationModal,
  projectLocked: ProjectLocked,
  cashflowExpenseTransaction: CashflowExpenseTransaction,
  cashflowInvoiceEdit: CashflowInvoiceEditModal,
  cashflowPrefillItems: CashflowPrefillItemsModal,
  mergeChangeOrder: MergeChangeOrderModal,
  whatsNew: WhatsNewModal,
};

const ModalManager: React.FC<ModalManagerProps> = () => {
  const router = useRouter();
  const openModalIds = useGlobalStore(s => s.openModalIds);
  const modalsProps = useGlobalStore(s => s.modalsProps);
  const closeAllModals = useGlobalStore(s => s.closeAllModals);

  const handleCloseModals = useCallback(
    (_: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        closeAllModals();
      }
    },
    [closeAllModals]
  );

  useEffect(() => {
    router.events.on('routeChangeComplete', handleCloseModals);
    router.events.on('routeChangeStart', handleCloseModals);
    return () => {
      router.events.off('routeChangeComplete', handleCloseModals);
      router.events.on('routeChangeStart', handleCloseModals);
    };
  }, [closeAllModals, handleCloseModals, router.events]);

  return (
    <AnimatePresence>
      {openModalIds.map(modalId => {
        const ModalComponent = modalMap[modalId];
        const modalProps = modalsProps[modalId];
        return openModalIds.includes(modalId) ? (
          <ModalComponent {...modalProps} key={modalId} />
        ) : null;
      })}
    </AnimatePresence>
  );
};

export default ModalManager;
