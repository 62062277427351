import { useState } from 'react';

import { AdminListItem } from 'components/modules/Admin/hooks/useAdminListColumns';
import { useResources } from 'queries/resources';

export const useDepartmentDropdown = (item?: AdminListItem) => {
  const { data: resources } = useResources();

  const departmentItems = Array.from(
    new Set(
      resources
        ?.map(resource => resource.department)
        .filter(department => !!department)
    )
  ).map(department => ({
    displayName: department,
    id: department,
  }));

  const [departmentOpen, setDepartmentOpen] = useState(false);
  const [activeDepartmentId, setActiveDepartmentId] = useState<string | null>(
    item?.department || ''
  );
  const [departmentSearchValue, setDepartmentSearchValue] = useState('');
  const [departmentToCreate, setDepartmentToCreate] = useState('');

  return {
    departmentItems,
    activeDepartmentId,
    setActiveDepartmentId,
    departmentOpen,
    setDepartmentOpen,
    departmentSearchValue,
    setDepartmentSearchValue,
    departmentToCreate,
    setDepartmentToCreate,
  };
};
