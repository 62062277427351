import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60rem;
  padding: 80rem;
  width: 1200rem;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 40rem;
  align-items: center;
`;

export const Description = styled.div``;

export const Datepicker = styled.div``;
