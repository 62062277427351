import { memo, useState } from 'react';

import {
  availableCurrencies,
  Currencies,
} from '@u9/bob3-shared/lib/types/api.types';
import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import { useOrganization } from 'queries/organizations';

export interface ConversionDropdownProps {
  currency?: Currencies | null;
  onChange?: (value: Currencies) => void;
  disabled: boolean;
}

const defaultProps: Partial<ConversionDropdownProps> = {
  currency: null,
  onChange: () => null,
};

const ConversionDropdown: React.FC<ConversionDropdownProps> = ({
  currency,
  onChange,
  disabled,
}) => {
  const { data: organization } = useOrganization();

  const items = availableCurrencies.map(invoiceCurrency => ({
    displayName: invoiceCurrency,
    id: invoiceCurrency,
  }));
  const [activeCurrencyId, setActiveCurrencyId] = useState<Currencies | null>(
    currency ?? null
  );

  const handleDropdownClick = (id: Currencies) => {
    setActiveCurrencyId(id);
    onChange?.(id);
  };

  return (
    <Select
      value={String(activeCurrencyId)}
      onValueChange={handleDropdownClick}
      disabled={disabled}
      styleVariant="table"
      dataIntro="budget-modal__conversion-dropdown"
    >
      {items.map(({ id, displayName }) => (
        <SelectItem key={id} value={id}>
          {displayName} {' > '} {organization?.currency}
        </SelectItem>
      ))}
    </Select>
  );
};

ConversionDropdown.defaultProps = defaultProps;

export default memo(ConversionDropdown);
