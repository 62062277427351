import React, { useCallback, useMemo, useRef, useState } from 'react';

import BaseInput, { BaseInputProps } from '../BaseInput/BaseInput';

import * as Styled from './FormInput.styles';

export const formInputTheme = ['dark', 'light'] as const;
export type FormInputThemeType = (typeof formInputTheme)[number];

export interface FormInputProps extends BaseInputProps {
  required?: boolean;
  error?: string | null;
  forceError?: boolean;
  theme?: FormInputThemeType;
  dataIntro?: string;
}

const FormInput = ({
  value,
  placeholder,
  onChange,
  onEnter,
  onEscape,
  onBlur,
  onFocus,
  disabled = false,
  required = false,
  loading,
  error = '',
  forceError = false,
  dataCy,
  dataIntro,
  mask,
  startHtml,
  endHtml,
  maxCharacters,
  theme = 'dark',
  type,
}: FormInputProps) => {
  const fieldEl = useRef<HTMLInputElement>(null);
  const [blurred, setBlurred] = useState(false);

  const displaysError = useMemo(
    () => !!((blurred || forceError) && !!error),
    [blurred, error, forceError]
  );

  const handleBlur = useCallback(() => {
    setBlurred(true);
    onBlur && onBlur();
  }, [onBlur]);

  const handleFocus = useCallback(() => {
    onFocus && onFocus();
  }, [onFocus]);

  const handleWrapperClick = useCallback(() => {
    fieldEl.current?.focus();
  }, []);

  return (
    <Styled.Wrapper
      onClick={handleWrapperClick}
      disabled={disabled}
      hasError={displaysError}
      color={theme}
    >
      <Styled.FormInputWrapper
        hasError={displaysError}
        color={theme}
        data-intro={dataIntro}
      >
        <Styled.Placeholder color={theme}>{`${placeholder}${
          required ? ' *' : ''
        }`}</Styled.Placeholder>
        <Styled.FieldWrapper>
          <Styled.InputWrapper>
            <BaseInput
              loading={loading}
              disabled={disabled}
              ref={fieldEl}
              value={value}
              onChange={onChange}
              onEnter={onEnter}
              onEscape={onEscape}
              onBlur={handleBlur}
              onFocus={handleFocus}
              dataCy={dataCy}
              mask={mask}
              startHtml={startHtml}
              endHtml={endHtml}
              maxCharacters={maxCharacters}
              type={type}
            />
          </Styled.InputWrapper>
        </Styled.FieldWrapper>
      </Styled.FormInputWrapper>

      {displaysError && <Styled.ErrorMessage>{error}</Styled.ErrorMessage>}
    </Styled.Wrapper>
  );
};

export default React.memo(FormInput);
