import { Dispatch, memo, SetStateAction, useMemo, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxSeparator,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { VerticalModalProps } from 'components/modals/VerticalModal/VerticalModal';
import { useProjectAccess } from 'hooks/useProjectAccess';
import { useVerticals } from 'queries/verticals';
import { useCopyStore, useGlobalStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import {
  Project,
  ProjectStatus,
  Vertical as VerticalType,
} from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { sort, sortString } from 'utils/sort';

import DropdownWarning from '../../DropdownWarning/DropdownWarning';
import { useProject } from '../../hooks/useProject';

import * as Styled from '../../ProjectModal.styles';

export interface VerticalProps {
  projectId?: Project['id'];
  activeVerticalId?: VerticalType['id'];
  setActiveVerticalId: Dispatch<SetStateAction<VerticalType['id'] | undefined>>;
}

export const Vertical = ({
  projectId,
  activeVerticalId,
  setActiveVerticalId,
}: VerticalProps) => {
  const openModal = useGlobalStore(s => s.openModal);
  const { project } = useProject(projectId);
  const copy = useCopyStore(s => s.copy);
  const locale = useCopyStore(s => s.locale);
  const { data: verticals } = useVerticals();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { checkAnyAccessRight } = useCheckAccessRights();
  const isAdmin = checkAnyAccessRight([
    'administrator',
    'owner',
    'super admin',
  ]);

  const displayClosedWarning =
    project?.status !== ProjectStatus.closed &&
    project?.vertical?.closed &&
    activeVerticalId === project?.vertical.id;

  const modalCopy = copy.app.projectModal;

  const { getCanEditVertical } = useProjectAccess();

  const verticalItems = useMemo(
    () =>
      sort(
        verticals?.map(({ name, id }) => ({
          id,
          displayName: name,
        })) || [],
        'displayName',
        sortString,
        'asc',
        locale
      ),
    [verticals, locale]
  );

  return (
    <Styled.FormDropdownWrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={
          <Styled.FormDropdownLabelWrapper>
            {modalCopy.vertical}
            {displayClosedWarning && (
              <DropdownWarning
                title={modalCopy.verticalClosedWarningTitle}
                text={modalCopy.verticalClosedWarningDescription}
              />
            )}
          </Styled.FormDropdownLabelWrapper>
        }
      >
        <ComboboxTrigger
          disabled={project && !getCanEditVertical(project)}
          label={
            verticalItems.find(({ id }) => id === activeVerticalId)
              ?.displayName ?? project?.vertical.name
          }
          dataCy="project-modal__vertical-dropdown__button"
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList dataCy={'project-modal__vertical-dropdown__dropdown'}>
              {isAdmin && (
                <ComboboxItem
                  value={searchValue}
                  onSelect={() => {
                    openModal<VerticalModalProps>('verticalModal', {
                      vertical: {
                        name: searchValue,
                      },
                      onVerticalCreated: vertical => {
                        setActiveVerticalId(vertical.id);
                      },
                    });
                    setOpen(false);
                  }}
                >
                  {searchValue.length > 0
                    ? replaceString(
                        copy.app.comboboxCreateButton,
                        '{name}',
                        searchValue
                      )
                    : copy.app.comboboxCreateButtonEmpty}
                </ComboboxItem>
              )}
              <ComboboxSeparator />
              {verticalItems.map(({ displayName, id }) => (
                <ComboboxItem
                  key={id}
                  selected={activeVerticalId === id}
                  data-cy={`${id}__menu-item`}
                  onSelect={() => {
                    setActiveVerticalId(id);
                    setOpen(false);
                  }}
                >
                  {displayName}
                </ComboboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.FormDropdownWrapper>
  );
};

export default memo(Vertical);
