import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction, useState } from 'react';

import {
  Combobox,
  ComboboxCheckboxItem,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { useCurrentProjectVersion } from 'queries/projectRows';
import { useCopyStore } from 'store';
import { Vendor } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectFiltersModal.styles';

export interface VendorDropdownProps {
  vendorIds: Vendor['id'][];
  setVendorIds: Dispatch<SetStateAction<Vendor['id'][]>>;
}

const VendorDropdown: FC<VendorDropdownProps> = ({
  vendorIds,
  setVendorIds,
}) => {
  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const copy = useCopyStore(s => s.copy);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const allVendorItems = currentProjectVersion?.rows
    ?.map(row => row?.vendor)
    .flatMap(v => (v ? [v] : []));

  const uniqueVendorIds = Array.from(
    new Set(allVendorItems?.map(vendor => vendor.id))
  );
  const items = uniqueVendorIds
    ?.map(id => allVendorItems?.find(item => item.id === id))
    .flatMap(v => (v ? [v] : []));

  const toggleActive = (activeId: Vendor['id']) => {
    if (vendorIds.includes(activeId)) {
      setVendorIds(vendorIds.filter(prevItem => prevItem !== activeId));
    } else {
      setVendorIds([...vendorIds, activeId]);
    }
  };

  const label = vendorIds?.map(id => items?.find(item => item.id === id)?.name);

  return (
    <Styled.DropdownWrapper style={{ width: '100%' }}>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={copy.app.projectFiltersModal.vendorDropdown}
      >
        <ComboboxTrigger
          label={
            label.length > 0 ? label.join(', ') : copy.app.selectPlaceholder
          }
          style={{
            ...(label.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList>
              {items?.map(({ name, id }) => (
                <ComboboxCheckboxItem
                  key={id}
                  checked={vendorIds.includes(id)}
                  onSelect={() => {
                    toggleActive(id);
                  }}
                >
                  {name}
                </ComboboxCheckboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.DropdownWrapper>
  );
};

export default memo(VendorDropdown);
