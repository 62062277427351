import { Dispatch, memo, SetStateAction, useMemo, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { TerritoryModalProps } from 'components/modals/TerritoryModal/TerritoryModal';
import { useTerritories } from 'queries/territories';
import { useCopyStore, useGlobalStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import { GeoArea, Project, ProjectStatus } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { sort, sortString } from 'utils/sort';

import DropdownWarning from '../../DropdownWarning/DropdownWarning';
import { useProject } from '../../hooks/useProject';

import * as Styled from '../../ProjectModal.styles';

export interface TerritoryProps {
  projectId?: Project['id'];
  activeTerritoryId?: GeoArea['id'];
  setActiveTerritoryId: Dispatch<SetStateAction<GeoArea['id'] | undefined>>;
  canEditProject: boolean;
}

export const Territory = ({
  projectId,
  activeTerritoryId,
  canEditProject,
  setActiveTerritoryId,
}: TerritoryProps) => {
  const openModal = useGlobalStore(s => s.openModal);
  const { project } = useProject(projectId);
  const copy = useCopyStore(s => s.copy);
  const locale = useCopyStore(s => s.locale);
  const { data: territories } = useTerritories();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { checkAnyAccessRight } = useCheckAccessRights();
  const isAdmin = checkAnyAccessRight([
    'administrator',
    'owner',
    'super admin',
  ]);

  const displayClosedWarning =
    project?.status !== ProjectStatus.closed &&
    project?.territory?.closed &&
    activeTerritoryId === project?.territory.id;

  const modalCopy = copy.app.projectModal;

  const territoryItems = useMemo(
    () =>
      sort(
        territories?.map(({ name, id }) => ({
          id,
          displayName: name,
        })) || [],
        'displayName',
        sortString,
        'asc',
        locale
      ),
    [territories, locale]
  );

  return (
    <Styled.FormDropdownWrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={
          <Styled.FormDropdownLabelWrapper>
            {modalCopy.territory}
            {displayClosedWarning && (
              <DropdownWarning
                title={modalCopy.territoryClosedWarningTitle}
                text={modalCopy.territoryClosedWarningDescription}
              />
            )}
          </Styled.FormDropdownLabelWrapper>
        }
      >
        <ComboboxTrigger
          disabled={!canEditProject}
          label={
            territoryItems.find(({ id }) => id === activeTerritoryId)
              ?.displayName ?? project?.territory?.name
          }
          dataCy="project-modal__territory-dropdown__button"
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList dataCy="project-modal__territory-dropdown__dropdown">
              {isAdmin && (
                <ComboboxItem
                  value={searchValue}
                  onSelect={() => {
                    openModal<TerritoryModalProps>('territoryModal', {
                      territory: {
                        name: searchValue,
                      },
                      onTerritoryCreated: territory => {
                        setActiveTerritoryId(territory.id);
                      },
                    });
                    setOpen(false);
                  }}
                >
                  {searchValue.length > 0
                    ? replaceString(
                        copy.app.comboboxCreateButton,
                        '{name}',
                        searchValue
                      )
                    : copy.app.comboboxCreateButtonEmpty}
                </ComboboxItem>
              )}
              {territoryItems.map(({ displayName, id }) => (
                <ComboboxItem
                  key={id}
                  selected={activeTerritoryId === id}
                  data-cy={`${id}__menu-item`}
                  onSelect={() => {
                    setActiveTerritoryId(id);
                    setOpen(false);
                  }}
                >
                  {displayName}
                </ComboboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.FormDropdownWrapper>
  );
};

export default memo(Territory);
