import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction, useState } from 'react';

import {
  Combobox,
  ComboboxCheckboxItem,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { useCurrentProjectVersion } from 'queries/projectRows';
import { useCopyStore } from 'store';
import { Resource } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectFiltersModal.styles';

export interface ResourceDropdownProps {
  resourceIds: Resource['id'][];
  setResourceIds: Dispatch<SetStateAction<Resource['id'][]>>;
}

const ResourceDropdown: FC<ResourceDropdownProps> = ({
  resourceIds,
  setResourceIds,
}) => {
  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const copy = useCopyStore(s => s.copy);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const allResourceItems = currentProjectVersion?.rows
    ?.map(row => row?.resource)
    .flatMap(v => (v ? [v] : []));

  const uniqueResourceIds = Array.from(
    new Set(allResourceItems?.map(resource => resource.id))
  );
  const items = uniqueResourceIds
    ?.map(id => allResourceItems?.find(item => item.id === id))
    .flatMap(v => (v ? [v] : []));

  const toggleActive = (activeId: Resource['id']) => {
    if (resourceIds.includes(activeId)) {
      setResourceIds(resourceIds.filter(prevItem => prevItem !== activeId));
    } else {
      setResourceIds([...resourceIds, activeId]);
    }
  };

  const label = resourceIds?.map(
    id => items?.find(item => item.id === id)?.name
  );

  return (
    <Styled.DropdownWrapper style={{ width: '100%' }}>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={copy.app.projectFiltersModal.resourceDropdown}
      >
        <ComboboxTrigger
          label={
            label.length > 0 ? label.join(', ') : copy.app.selectPlaceholder
          }
          style={{
            ...(label.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList>
              {items?.map(({ name, id }) => (
                <ComboboxCheckboxItem
                  key={id}
                  checked={resourceIds.includes(id)}
                  onSelect={() => {
                    toggleActive(id);
                  }}
                >
                  {name}
                </ComboboxCheckboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.DropdownWrapper>
  );
};

export default memo(ResourceDropdown);
