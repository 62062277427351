import { rgba } from 'polished';
import styled from 'styled-components';

import { focusVisible, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

import { FormInputThemeType } from './FormInput';

export const Wrapper = styled.div<{
  disabled: boolean;
  hasError: boolean;
  color: FormInputThemeType;
}>`
  color: ${({ hasError, color, disabled }) =>
    hasError
      ? colors.sunsetOrange
      : {
          dark: colors.white,
          light: disabled ? colors.dustyGray : colors.outerSpace,
        }[color]};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FormInputWrapper = styled.div<{
  hasError: boolean;
  color: FormInputThemeType;
}>`
  flex: 1;

  border-bottom: 1px solid
    ${({ hasError, color }) =>
      hasError
        ? colors.sunsetOrange
        : {
            dark: rgba(colors.white, 0.5),
            light: rgba(colors.outerSpace, 0.15),
          }[color]};
`;

export const Placeholder = styled.div<{ color: FormInputThemeType }>`
  ${({ theme: { locale } }) => setTypography('caption', locale)}
  user-select: none;

  color: ${({ color }) =>
    ({
      dark: rgba(colors.white, 0.5),
      light: rgba(colors.outerSpace, 0.5),
    })[color]};
`;

export const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputWrapper = styled.div`
  padding: 24rem 0 24rem 16rem;
  ${({ theme: { locale } }) => setTypography('input', locale)}
  flex: 1;

  &:focus-within {
    ${focusVisible};
  }
`;

export const DisabledIcon = styled.div`
  margin-right: 15rem;
`;

export const IconWrapper = styled.div`
  width: 28rem;
  height: 28rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
`;

export const ErrorMessage = styled.div`
  ${({ theme: { locale } }) => setTypography('body5', locale)}
  color: ${colors.sunsetOrange};
  height: 24rem;
  position: absolute;
  top: 100%;
`;
