import { FC, memo, useCallback, useState } from 'react';

import { useCopyStore, useGlobalStore } from 'store';
import { replaceStrings } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface ChangeItemCategoryModalProps {
  onConfirmChangeCategory: () => void;
  currentCategory: string;
  previousItemCategory: string;
}

const ChangeItemCategoryModal: FC<ChangeItemCategoryModalProps> = ({
  currentCategory,
  previousItemCategory,
  onConfirmChangeCategory,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const [loading, setLoading] = useState(false);

  const handleConfirmButtonClick = useCallback(() => {
    setLoading(true);
    onConfirmChangeCategory();
  }, [onConfirmChangeCategory]);

  const changeCategoryModalBack = useCallback(() => {
    closeModal('changeItemCategoryModal');
  }, [closeModal]);

  const { title, description, cancelButton, confirmButton } =
    copy.admin.changeCategoryModal;

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: cancelButton,
        color: ColorNames.nandor,
        icon: 'arrow_left',
        onClick: changeCategoryModalBack,
      }}
      confirmButtonProps={{
        label: confirmButton,
        color: currentColor,
        icon: 'check',
        onClick: handleConfirmButtonClick,
        loading: loading,
        disabled: loading,
        dataCy: 'change-category-confirm',
      }}
      title={title}
      description={replaceStrings(description, [
        ['{beforeCategory}', previousItemCategory],
        ['{afterCategory}', currentCategory],
      ])}
    />
  );
};

export default memo(ChangeItemCategoryModal);
