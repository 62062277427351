import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction, useState } from 'react';

import {
  Combobox,
  ComboboxCheckboxItem,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { useBrands } from 'queries/brands';
import { useCopyStore } from 'store';
import { Brand as BrandType } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectsListFiltersModal.styles';

export interface BrandProps {
  brandIds: BrandType['id'][];
  setBrandIds: Dispatch<SetStateAction<BrandType['id'][]>>;
}

const Brand: FC<BrandProps> = ({ setBrandIds, brandIds }) => {
  const copy = useCopyStore(s => s.copy);
  const { data: brands } = useBrands();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const toggleBrand = (brandId: BrandType['id']) => {
    setBrandIds(prev => {
      if (prev.includes(brandId)) {
        return prev.filter(m => m !== brandId);
      } else {
        return [...prev, brandId];
      }
    });
  };

  return (
    <Styled.DropdownWrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={copy.app.projectsListFiltersModal.brand}
      >
        <ComboboxTrigger
          label={
            brandIds.length > 0
              ? brandIds
                  .map(
                    brandId => brands?.find(({ id }) => id === brandId)?.name
                  )
                  .join(', ')
              : copy.app.selectPlaceholder
          }
          style={{
            ...(brandIds.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList>
              {brands?.map(({ name, id }) => (
                <ComboboxCheckboxItem
                  key={id}
                  checked={brandIds.includes(id)}
                  onSelect={() => {
                    toggleBrand(id);
                  }}
                >
                  {name}
                </ComboboxCheckboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.DropdownWrapper>
  );
};

export default memo(Brand);
