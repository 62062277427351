import { FC, memo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import {
  AdminListItem,
  CategoryIdentifier,
} from 'components/modules/Admin/hooks/useAdminListColumns';
import { useDeleteBrand } from 'queries/brands';
import { useDeleteClient } from 'queries/clients';
import { useDeleteResource } from 'queries/resources';
import { useDeleteRole } from 'queries/roles';
import { useDeleteVendor } from 'queries/vendors';
import { useDeleteVendorService } from 'queries/vendorServices';
import { useCopyStore, useGlobalStore } from 'store';
import { Role } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

import * as Styled from './UnverifyResourceModal.styles';

export interface UnverifyResourceModalProps {
  item?: AdminListItem;
  role?: Role;
}

const UnverifyResourceModal: FC<UnverifyResourceModalProps> = ({
  item,
  role,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const [message, setMessage] = useField('');
  const { mutateAsync: deleteResource, isLoading: isResourceDeleteLoading } =
    useDeleteResource();
  const { mutateAsync: deleteVendor, isLoading: isVendorDeleteLoading } =
    useDeleteVendor();
  const { mutateAsync: deleteClient, isLoading: isClientDeleteLoading } =
    useDeleteClient();
  const { mutateAsync: deleteBrand, isLoading: isBrandDeleteLoading } =
    useDeleteBrand();
  const {
    mutateAsync: deleteVendorService,
    isLoading: isDeleteVendorServiceLoading,
  } = useDeleteVendorService();
  const { mutateAsync: deleteRole, isLoading: isDeleteRoleLoading } =
    useDeleteRole();

  const isLoading =
    isResourceDeleteLoading ||
    isVendorDeleteLoading ||
    isClientDeleteLoading ||
    isBrandDeleteLoading ||
    isDeleteVendorServiceLoading ||
    isDeleteRoleLoading;

  const handleClose = () => {
    closeModal('unverifyResourceModal');
  };

  const handleSave = async () => {
    if (role) {
      await deleteRole({
        rowNo: role.row_no,
        reason: message,
      });
    }
    if (item) {
      if (
        item.categoryIdentifier === CategoryIdentifier.Resource &&
        item.resourceId
      ) {
        await deleteResource({
          id: item.resourceId,
          reason: message,
        });
      }
      if (
        item.categoryIdentifier === CategoryIdentifier.Vendor &&
        item.vendorId
      ) {
        await deleteVendor({
          id: item.vendorId,
          reason: message,
        });
      }
      if (
        item.categoryIdentifier === CategoryIdentifier.Client &&
        item.clientId
      ) {
        await deleteClient({
          id: item.clientId,
          reason: message,
        });
      }
      if (
        item.categoryIdentifier === CategoryIdentifier.Brand &&
        item.brandId
      ) {
        await deleteBrand({
          id: item.brandId,
          reason: message,
        });
      }
      if (
        item.categoryIdentifier === CategoryIdentifier.VendorService &&
        item.vendorServiceId
      ) {
        await deleteVendorService({
          id: item.vendorServiceId,
          reason: message,
        });
      }
    }

    handleClose();
  };

  const { confirmButton, declineButton, description, messageLabel, title } =
    copy.admin.unverifiedResourcesList.declineModal;

  return (
    <ConfirmModal
      cancelButtonProps={{
        label: declineButton,
        color: ColorNames.nandor,
        icon: 'check',
        onClick: handleClose,
      }}
      confirmButtonProps={{
        label: confirmButton,
        color: currentColor,
        icon: 'check',
        onClick: handleSave,
        disabled: isLoading,
        loading: isLoading,
        dataCy: 'unverify-resource-modal__save-button',
      }}
      description={replaceString(
        description,
        '{name}',
        item?.name ?? role?.role ?? ''
      )}
      title={title}
      content={
        <Styled.FieldWrapper>
          <FormInput
            value={message}
            onChange={setMessage}
            placeholder={messageLabel}
            dataCy="unverify-resource-modal__message-field"
          />
        </Styled.FieldWrapper>
      }
    />
  );
};

export default memo(UnverifyResourceModal);
