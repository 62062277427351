import { rgba } from 'polished';
import styled from 'styled-components';

import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  max-width: 1200rem;
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;

export const Releases = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20rem;
`;

export const CurrentRelease = styled.div``;

export const OtherReleases = styled.div``;

export const Disclaimer = styled.div`
  padding-top: 40rem;
  color: ${rgba(colors.white, 0.5)};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7rem;
`;

export const ContentItem = styled.div``;
