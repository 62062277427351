import React, { Dispatch, SetStateAction, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxSeparator,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { VendorServiceModalProps } from 'components/modals/VendorServiceModal/VendorServiceModal';
import { useCopyStore, useGlobalStore } from 'store';

import AddressFields from '../AddressFields/AddressFields';

import * as AdminStyled from '../AdminItemModal.styles';

interface VendorPartialProps {
  vendorName: ReturnType<typeof useField>;
  emailField: ReturnType<typeof useField>;
  carbonDescription: ReturnType<typeof useField>;
  emailUniqueError: boolean;
  activeVendorServiceId: string | number | null;
  setActiveVendorServiceId: Dispatch<SetStateAction<string | number | null>>;
  vendorServiceItems?: {
    id: string | number;
    displayName: string | undefined;
  }[];
  addressLine1: string;
  setAddressLine1: Dispatch<SetStateAction<string>>;
  addressLine2: string;
  setAddressLine2: Dispatch<SetStateAction<string>>;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  zipCode: string;
  setZipCode: Dispatch<SetStateAction<string>>;
  countryCode: string | null;
  setCountryCode: Dispatch<SetStateAction<string | null>>;
}

const VendorPartial = ({
  vendorName: vendorNameField,
  emailField,
  carbonDescription: carbonDescriptionField,
  emailUniqueError,
  activeVendorServiceId,
  setActiveVendorServiceId,
  vendorServiceItems,
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  city,
  setCity,
  zipCode,
  setZipCode,
  countryCode,
  setCountryCode,
}: VendorPartialProps) => {
  const copy = useCopyStore(s => s.copy);
  const openModal = useGlobalStore(s => s.openModal);

  const [vendorName, setVendorName, , vendorNameInvalidMessage] =
    vendorNameField;
  const [email, setEmail, , emailInvalidMessage] = emailField;
  const [carbonDescription, setCarbonDescription] = carbonDescriptionField;
  const [vendorServiceOpen, setVendorServiceOpen] = useState(false);

  const {
    vendorNameLabel,
    emailLabel,
    vendorServiceLabel,
    carbonDescriptionLabel,
  } = copy.admin.editModal.vendor;

  const emailUniqueErrorMessage =
    emailUniqueError && copy.app.validatorMessages.emailNotUnique;

  return (
    <>
      <AdminStyled.Row>
        <FormInput
          value={vendorName}
          onChange={setVendorName}
          error={vendorNameInvalidMessage}
          required
          placeholder={vendorNameLabel}
          dataCy="vendor-partial__name"
        />
        <AdminStyled.FieldSpace />
        <FormInput
          value={email}
          onChange={setEmail}
          error={emailUniqueErrorMessage || emailInvalidMessage}
          forceError={emailUniqueError}
          required
          placeholder={emailLabel}
          dataCy="vendor-partial__email"
        />
      </AdminStyled.Row>
      <AdminStyled.Row>
        <AdminStyled.FullWidth>
          <Combobox
            open={vendorServiceOpen}
            onOpenChange={setVendorServiceOpen}
            label={vendorServiceLabel}
          >
            <ComboboxTrigger
              label={
                vendorServiceItems?.find(
                  ({ id }) => id === activeVendorServiceId
                )?.displayName
              }
            />
            <ComboboxContentRoot>
              <ComboboxCommandRoot>
                <ComboboxInput />
                <ComboboxEmpty />
                <ComboboxList>
                  <ComboboxItem
                    onSelect={() => {
                      openModal<VendorServiceModalProps>('vendorServiceModal');
                    }}
                  >
                    {copy.app.comboboxCreateButtonEmpty}
                  </ComboboxItem>
                  <ComboboxSeparator />
                  {vendorServiceItems?.map(({ displayName, id }) => (
                    <ComboboxItem
                      key={id}
                      selected={activeVendorServiceId === id}
                      onSelect={() => {
                        setActiveVendorServiceId(id ?? null);
                        setVendorServiceOpen(false);
                      }}
                    >
                      {displayName}
                    </ComboboxItem>
                  ))}
                </ComboboxList>
              </ComboboxCommandRoot>
            </ComboboxContentRoot>
          </Combobox>
        </AdminStyled.FullWidth>
      </AdminStyled.Row>
      <AdminStyled.Row>
        <FormInput
          value={carbonDescription}
          onChange={setCarbonDescription}
          placeholder={carbonDescriptionLabel}
        />
      </AdminStyled.Row>
      <AddressFields
        addressLine1={addressLine1}
        setAddressLine1={setAddressLine1}
        addressLine2={addressLine2}
        setAddressLine2={setAddressLine2}
        city={city}
        setCity={setCity}
        zipCode={zipCode}
        setZipCode={setZipCode}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
    </>
  );
};

export default React.memo(VendorPartial);
