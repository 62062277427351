import * as Collapsible from '@radix-ui/react-collapsible';
import React, { FC, FunctionComponent } from 'react';

import Counter from 'components/Counter/Counter';
import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useGlobalStore } from 'store';
import { useSvgIcon } from 'u9/hooks';
import { ROUTES } from 'utils/routes';

import LogoItem from '../LogoItem/LogoItem';
import { useDefaultItems } from './hooks/useDefaultItems';

import * as Styled from './DefaultNavigation.styles';

export type SubmenuItem =
  | {
      label: string;
      route: string;
      count?: number;
      isGroupHeader?: never;
    }
  | {
      label: string;
      isGroupHeader: true;
      count?: never;
      route?: never;
    };

export type NavigationItem = {
  label: string;
  Icon?: FunctionComponent;
  id: string;
  route: string;
  submenu?: Set<SubmenuItem>;
  count?: number;
  endHtml?: React.ReactNode | React.ReactNode[];
};

export interface DefaultNavigationProps {
  isNavbarCollapsed: boolean;
  collapseButton: React.ReactNode | React.ReactNode[];
}

const DefaultNavigation: FC<DefaultNavigationProps> = ({
  isNavbarCollapsed,
  collapseButton,
}) => {
  const { SvgIcon: CaretIcon } = useSvgIcon('caret');
  const isAdminNavigationOpen = useGlobalStore(s => s.isAdminNavigationOpen);
  const setIsAdminNavigationOpen = useGlobalStore(
    s => s.setIsAdminNavigationOpen
  );

  const { navigationItems, getIsRouteActive } = useDefaultItems();

  return (
    <Styled.Wrapper>
      <Styled.Top>
        {!isNavbarCollapsed && <LogoItem />}
        {collapseButton}
      </Styled.Top>

      <Styled.Navigation>
        {Array.from(navigationItems).map(({ Icon, ...navItem }) => (
          <Styled.CollapsibleRoot
            $isActive={getIsRouteActive(navItem.route)}
            key={navItem.id}
            {...(navItem.id === ROUTES.ADMIN
              ? {
                  open: isAdminNavigationOpen,
                  onOpenChange: () =>
                    setIsAdminNavigationOpen(!isAdminNavigationOpen),
                }
              : {})}
          >
            <Styled.NavItemWrapper>
              {!isNavbarCollapsed && (
                <Collapsible.Trigger asChild>
                  <Styled.TriggerIconWrapper
                    style={{
                      visibility: navItem.submenu ? 'visible' : 'hidden',
                    }}
                  >
                    <Styled.IndicatorIcon>
                      <CaretIcon />
                    </Styled.IndicatorIcon>
                  </Styled.TriggerIconWrapper>
                </Collapsible.Trigger>
              )}
              <Styled.LinkTrigger
                href={navItem.route}
                data-cy={`nav__${navItem.route}`}
                data-intro={`nav_${navItem.route}`}
              >
                <Tooltip
                  content={navItem.label}
                  style={{
                    visibility: isNavbarCollapsed ? 'visible' : 'hidden',
                    zIndex: 20,
                  }}
                  side="right"
                  sideOffset={10}
                >
                  <Styled.TriggerIcon>{Icon && <Icon />}</Styled.TriggerIcon>
                </Tooltip>

                {!isNavbarCollapsed && (
                  <Styled.TriggerText>{navItem.label}</Styled.TriggerText>
                )}
              </Styled.LinkTrigger>
              {navItem.endHtml && !isNavbarCollapsed && navItem.endHtml}
            </Styled.NavItemWrapper>
            {!isNavbarCollapsed && (
              <Styled.CollapsibleContent>
                {navItem.submenu &&
                  Array.from(navItem.submenu).map(submenuItem => {
                    if (submenuItem?.isGroupHeader) {
                      return (
                        <Styled.NavGroupHeader key={submenuItem.label}>
                          <Styled.MenuItem clickable={false}>
                            <Styled.Label>
                              <Styled.LinkWrapper>
                                {submenuItem.label}
                              </Styled.LinkWrapper>
                            </Styled.Label>
                          </Styled.MenuItem>
                        </Styled.NavGroupHeader>
                      );
                    }
                    return (
                      <Styled.NavLink
                        href={submenuItem.route}
                        key={submenuItem.route}
                        data-cy={`subnav__${submenuItem.route}`}
                        data-intro={`subnav_${submenuItem.route}`}
                      >
                        <Styled.MenuItem
                          isActive={getIsRouteActive(submenuItem.route)}
                        >
                          <Styled.Label>
                            <Styled.LinkWrapper>
                              <OverflowEllipsis text={submenuItem.label} />
                            </Styled.LinkWrapper>
                          </Styled.Label>

                          {submenuItem.count && submenuItem.count > 0 ? (
                            <Styled.CounterWrapper
                              data-cy={`count__${submenuItem.route}`}
                            >
                              <Counter number={submenuItem.count} />
                            </Styled.CounterWrapper>
                          ) : null}
                        </Styled.MenuItem>
                      </Styled.NavLink>
                    );
                  })}
              </Styled.CollapsibleContent>
            )}
          </Styled.CollapsibleRoot>
        ))}
      </Styled.Navigation>
    </Styled.Wrapper>
  );
};

export default DefaultNavigation;
