import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction, useState } from 'react';

import {
  Combobox,
  ComboboxCheckboxItem,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { useCurrentProjectVersion } from 'queries/projectRows';
import { useCopyStore } from 'store';
import { VendorService } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectFiltersModal.styles';

export interface VendorServiceDropdownProps {
  vendorServiceIds: VendorService['id'][];
  setVendorServiceIds: Dispatch<SetStateAction<VendorService['id'][]>>;
}

const VendorServiceDropdown: FC<VendorServiceDropdownProps> = ({
  vendorServiceIds,
  setVendorServiceIds,
}) => {
  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const copy = useCopyStore(s => s.copy);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const allRowItems = currentProjectVersion?.rows.filter(
    ({ spacer_name, role_id }) => !spacer_name && !role_id
  );

  const uniqueRowItems = Array.from(
    new Set(allRowItems?.map(row => row.vendor_service_id))
  );
  const items = uniqueRowItems
    ?.map(id => allRowItems?.find(item => item.vendor_service_id === id))
    .flatMap(v => (v ? [v] : []));

  const toggleActive = (activeId: VendorService['id']) => {
    if (vendorServiceIds.includes(activeId)) {
      setVendorServiceIds(
        vendorServiceIds.filter(prevItem => prevItem !== activeId)
      );
    } else {
      setVendorServiceIds([...vendorServiceIds, activeId]);
    }
  };

  const label = vendorServiceIds?.map(
    id =>
      items?.find(item => item.vendor_service_id === id)?.vendor_service?.name
  );

  return (
    <Styled.DropdownWrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={copy.app.projectFiltersModal.vendorServiceDropdown}
      >
        <ComboboxTrigger
          label={
            label.length > 0 ? label.join(', ') : copy.app.selectPlaceholder
          }
          style={{
            ...(label.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList>
              {items?.map(({ vendor_service, vendor_service_id }) => (
                <ComboboxCheckboxItem
                  key={vendor_service_id}
                  checked={Boolean(
                    vendor_service_id &&
                      vendorServiceIds.includes(vendor_service_id)
                  )}
                  onSelect={() => {
                    if (vendor_service_id) {
                      toggleActive(vendor_service_id);
                    }
                  }}
                >
                  {vendor_service?.name}
                </ComboboxCheckboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.DropdownWrapper>
  );
};

export default memo(VendorServiceDropdown);
