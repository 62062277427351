import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useCopyStore } from 'store';
import { useSvgIconList } from 'u9/hooks/useSvgIcon';
import {
  formatDateInline,
  getDateString,
  getDayName,
  getMonthName,
} from 'utils/formatters';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './Datepicker.styles';

export interface DatepickerProps {
  value?: Date;
  onChange: (date: Date) => void;
  fieldLabel?: string;
  color?: ColorNames;
  defaultExpanded?: boolean;
  closeOnChange?: boolean;
  disabled?: boolean;
  noPlaceholder?: boolean;
  displayValue?: string;
  noBorder?: boolean;
}

const Datepicker = ({
  value,
  onChange,
  fieldLabel,
  color = ColorNames.blueRibbon,
  defaultExpanded = false,
  closeOnChange = false,
  disabled = false,
  noPlaceholder = false,
  displayValue,
  noBorder = false,
}: DatepickerProps) => {
  const locale = useCopyStore(s => s.locale);

  const [
    DoubleArrowLeftIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    DoubleArrowRightIcon,
    CaretIcon,
  ] = useSvgIconList([
    'double_arrow_left',
    'arrow_left',
    'arrow_right',
    'double_arrow_right',
    'caret_down',
  ]);

  const [calendarExpanded, setCalendarExpanded] = useState(defaultExpanded);

  const [selectedDate, setSelectedDate] = useState<number | null>(
    value ? value.getDate() : null
  );
  const [selectedMonth, setSelectedMonth] = useState<number | null>(
    value ? value.getMonth() : null
  );
  const [selectedYear, setSelectedYear] = useState<number | null>(
    value ? value.getFullYear() : null
  );

  const [currentMonth, setCurrentMonth] = useState<number>(
    value ? value.getMonth() : new Date().getMonth()
  );
  const [currentYear, setCurrentYear] = useState<number>(
    value ? value.getFullYear() : new Date().getFullYear()
  );

  const goYearBack = useCallback(() => {
    setCurrentYear(prev => prev - 1);
  }, []);

  const goYearForward = useCallback(() => {
    setCurrentYear(prev => prev + 1);
  }, []);

  const goMonthBack = useCallback(() => {
    setCurrentMonth(prev => {
      const month = prev - 1;
      if (month === -1) {
        goYearBack();
        return 11;
      } else {
        return month;
      }
    });
  }, [goYearBack]);

  const goMonthForward = useCallback(() => {
    setCurrentMonth(prev => {
      const month = prev + 1;
      if (month === 12) {
        goYearForward();
        return 0;
      } else {
        return month;
      }
    });
  }, [goYearForward]);

  const handleDayClick = useCallback(
    (index: number) => () => {
      setSelectedDate(index);
      setSelectedMonth(currentMonth);
      setSelectedYear(currentYear);

      onChange && onChange(new Date(currentYear, currentMonth, index));
      if (closeOnChange) {
        setCalendarExpanded(false);
      }
    },
    [closeOnChange, currentMonth, currentYear, onChange]
  );

  const toggleCalendar = useCallback(() => {
    if (!disabled) {
      setCalendarExpanded(prev => !prev);
    }
  }, [disabled]);

  const numberOfDaysInCurrentMonth: number = useMemo(() => {
    return new Date(currentYear, currentMonth + 1, 0).getDate();
  }, [currentYear, currentMonth]);

  const emptyCellsToRender: number = useMemo(() => {
    return new Date(currentYear, currentMonth, 0).getDay();
  }, [currentMonth, currentYear]);

  const dayLabels = useMemo(() => {
    return new Array(7)
      .fill(0)
      .map((_, index) => getDayName(new Date(1900, 0, index + 1), locale)); // 01 January 1900 is Monday
  }, [locale]);

  useEffect(() => {
    if (value) {
      setSelectedDate(value.getDate());
      setSelectedMonth(value.getMonth());
      setSelectedYear(value.getFullYear());
    }
  }, [value]);

  return (
    <Styled.Wrapper open={calendarExpanded} onOpenChange={setCalendarExpanded}>
      <Styled.Anchor>
        {fieldLabel && (
          <Styled.FieldWrapper
            onClick={toggleCalendar}
            $noBorder={noBorder}
            disabled={disabled}
          >
            {!noPlaceholder && (
              <Styled.FieldLabel>{fieldLabel}</Styled.FieldLabel>
            )}
            <Styled.Field noBorder={noBorder} disabled={disabled}>
              <Styled.FieldStart>
                {displayValue
                  ? displayValue
                  : value === null ||
                    selectedYear === null ||
                    selectedMonth === null ||
                    selectedDate === null
                  ? '-'
                  : formatDateInline(
                      getDateString(
                        new Date(selectedYear, selectedMonth, selectedDate)
                      )
                    )}
              </Styled.FieldStart>
              <Styled.FieldEnd>
                <Styled.CaretIconWrapper>
                  <CaretIcon />
                </Styled.CaretIconWrapper>
              </Styled.FieldEnd>
            </Styled.Field>
          </Styled.FieldWrapper>
        )}
      </Styled.Anchor>

      <Styled.Portal>
        <Styled.Calendar>
          <Styled.Controls>
            <Styled.Button onClick={goYearBack}>
              <Styled.LongIconWrapper>
                <DoubleArrowLeftIcon />
              </Styled.LongIconWrapper>
            </Styled.Button>
            <Styled.Button onClick={goMonthBack}>
              <Styled.IconWrapper>
                <ArrowLeftIcon />
              </Styled.IconWrapper>
            </Styled.Button>
            <Styled.Label>
              {getMonthName(new Date(0, currentMonth), locale)} {currentYear}
            </Styled.Label>
            <Styled.Button onClick={goMonthForward}>
              <Styled.IconWrapper>
                <ArrowRightIcon />
              </Styled.IconWrapper>
            </Styled.Button>
            <Styled.Button onClick={goYearForward}>
              <Styled.LongIconWrapper>
                <DoubleArrowRightIcon />
              </Styled.LongIconWrapper>
            </Styled.Button>
          </Styled.Controls>
          <Styled.DaySelection>
            <Styled.DayLabels>
              {dayLabels.map((day, index) => (
                <Styled.DayLabel key={index}>{day}</Styled.DayLabel>
              ))}
            </Styled.DayLabels>
            <Styled.DayValues>
              {new Array(emptyCellsToRender).fill(null).map((_, index) => (
                <Styled.EmptyCell key={index} />
              ))}
              {new Array(numberOfDaysInCurrentMonth)
                .fill(null)
                .map((_, dayIndex) => (
                  <Styled.DayValue
                    key={dayIndex}
                    color={color}
                    isWeekend={[0, 6].includes(
                      new Date(currentYear, currentMonth, dayIndex + 1).getDay()
                    )}
                    onClick={handleDayClick(dayIndex + 1)}
                    active={
                      selectedDate === dayIndex + 1 &&
                      selectedMonth === currentMonth &&
                      selectedYear === currentYear
                    }
                  >
                    {dayIndex + 1}
                  </Styled.DayValue>
                ))}
            </Styled.DayValues>
          </Styled.DaySelection>
        </Styled.Calendar>
      </Styled.Portal>
    </Styled.Wrapper>
  );
};

export default React.memo(Datepicker);
