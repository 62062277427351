import { memo } from 'react';

import useProjectDisplayText from 'hooks/useProjectDisplayText';
import { Project } from 'utils/api.types';

import * as Styled from '../../ProjectModal.styles';

export interface BudgetStatusProps {
  activeStatus: Project['status'];
  activeMode: Project['mode'];
  jobClosureRequested?: boolean;
  jobNumberRequested?: boolean;
}

export const BudgetStatus = ({
  activeStatus,
  activeMode,
  jobClosureRequested,
  jobNumberRequested,
}: BudgetStatusProps) => {
  const getStatusDisplayText = useProjectDisplayText();

  return (
    <Styled.Label>
      Status:{' '}
      {getStatusDisplayText(activeMode, activeStatus, {
        job_closure_requested: jobClosureRequested,
        job_number_requested: jobNumberRequested,
      })}
    </Styled.Label>
  );
};

export default memo(BudgetStatus);
