import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

import { Address } from '@u9/bob3-shared/lib/types/api.types';
import Badge from 'components/Badge/Badge';
import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import { THeadCellContent } from 'components/ShadTable/Table';
import { useCurrentAdminRoute } from 'containers/AdminPage/hooks/useCurrentAdminRoute';
import { useCopyStore } from 'store';
import {
  Brand,
  Client,
  Resource,
  ResourceType,
  Role,
  User,
  Vendor,
  VendorService,
  VerificationStatusType,
} from 'utils/api.types';
import { ADMIN_ROUTES } from 'utils/routes';
import { ColorNames } from 'utils/styles/theme';

import AdminListItemDropdown from '../AdminList/AdminListItemDropdown/AdminListItemDropdown';

import * as Styled from '../AdminList/AdminList.styles';

export enum CategoryIdentifier {
  User,
  Resource,
  Client,
  Brand,
  Vendor,
  ClientRequest,
  BrandRequest,
  VendorService,
  Role,
}

export interface AdminListItem {
  name: string;
  categoryIdentifier: CategoryIdentifier;
  email?: string;
  role?: Role['role'];
  roleId?: Role['role_id'];
  userId?: User['id'];
  clientId?: Client['id'];
  brandId?: Brand['id'];
  resourceId?: Resource['id'];
  vendorId?: Vendor['id'];
  rate?: Resource['rate'];
  type?: Resource['type'];
  mfrId?: Resource['mfr_id'];
  mfrName?: string;
  vendorService?: VendorService['name'];
  vendorServiceId?: VendorService['id'];
  verificationStatus?: VerificationStatusType;
  clientCategory?: Client['category'];
  department?: Resource['department'];
  companyName?: Resource['company_name'];
  unused?: Resource['unused'];
  carbonDescription?: string;
  formatted?: {
    email?: string;
    ext?: string;
    accessGroup?: string;
    rate?: string;
    permissionSet?: string;
  };
  address?: Address;
  message?: string;
  requestId?: number;
  permissionSet?: User['permission_set'];
  associatedVendors?: Vendor[];
  rowNo?: Role['row_no'];
}

export enum ListFields {
  Name = 'name',
  Email = 'formatted.email',
  Territory = 'formatted.ext',
  Role = 'role',
  Rate = 'rate',
  VendorService = 'vendorService',
  ClientCategory = 'clientCategory',
  Department = 'department',
  Message = 'message',
  PermissionSet = 'permissionSet',
  AssociatedVendors = 'associatedVendors',
}

export const useAdminListColumns = () => {
  const copy = useCopyStore(s => s.copy);
  const route = useCurrentAdminRoute();

  const ListFieldConfig: Record<ListFields, ColumnDef<AdminListItem>> = useMemo(
    () => ({
      [ListFields.Name]: {
        accessorKey: 'name',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.admin.list.columns.name}
          />
        ),
        cell: props => {
          return (
            <Styled.NameWrapper>
              <OverflowEllipsis
                text={props.getValue<AdminListItem['name']>()}
              />
              <AdminListItemDropdown item={props.row.original} />
            </Styled.NameWrapper>
          );
        },
      },
      [ListFields.Email]: {
        accessorKey: 'formatted.email',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.admin.list.columns.email}
          />
        ),
        cell: props => (
          <OverflowEllipsis text={props.row.original.formatted?.email} />
        ),
      },
      [ListFields.Territory]: {
        accessorKey: 'formatted.ext',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.admin.list.columns.territory}
            style={{ textAlign: 'center' }}
          />
        ),
        cell: props => {
          const type = props.row.original.type;
          return type === ResourceType.external ? (
            <Badge
              color={ColorNames.selectiveYellow}
              textColor={ColorNames.outerSpace}
            >
              {props.row.original.formatted?.ext}
            </Badge>
          ) : type === ResourceType.mfr ? (
            <Badge textColor={ColorNames.selectiveYellow} variant="simple">
              <Styled.MfrNameWrapper>
                <OverflowEllipsis text={props.row.original.mfrName} />
              </Styled.MfrNameWrapper>
            </Badge>
          ) : (
            props.row.original.formatted?.ext
          );
        },
      },
      [ListFields.Role]: {
        accessorKey: 'role',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.admin.list.columns.role}
          />
        ),
        cell: props => (
          <OverflowEllipsis text={props.getValue<AdminListItem['role']>()} />
        ),
      },
      [ListFields.Rate]: {
        accessorKey: 'rate',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.admin.list.columns.rate}
          />
        ),
        cell: props => (
          <OverflowEllipsis text={props.row.original.formatted?.rate} />
        ),
      },
      [ListFields.VendorService]: {
        accessorKey: 'vendorService',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.admin.list.columns.vendorService}
          />
        ),
        cell: props => (
          <OverflowEllipsis
            text={props.getValue<AdminListItem['vendorService']>()}
          />
        ),
      },
      [ListFields.ClientCategory]: {
        accessorKey: 'clientCategory',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.admin.list.columns.clientCategory}
          />
        ),
        cell: props => (
          <OverflowEllipsis
            text={props.getValue<AdminListItem['clientCategory']>()}
          />
        ),
      },
      [ListFields.Department]: {
        accessorKey: 'department',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.admin.list.columns.department}
          />
        ),
        cell: props => (
          <OverflowEllipsis
            text={props.getValue<AdminListItem['department']>()}
          />
        ),
      },
      [ListFields.Message]: {
        accessorKey: 'message',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.admin.list.columns.message}
          />
        ),
        cell: props => (
          <OverflowEllipsis text={props.getValue<AdminListItem['message']>()} />
        ),
      },
      [ListFields.PermissionSet]: {
        accessorKey: 'formatted.permissionSet',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.admin.list.columns.userPermission}
          />
        ),
        cell: props => (
          <OverflowEllipsis
            text={props.row.original.formatted?.permissionSet}
          />
        ),
      },
      [ListFields.AssociatedVendors]: {
        accessorKey: 'associatedVendors',
        header: props => (
          <THeadCellContent
            column={props.column}
            text={copy.admin.list.columns.associatedVendors}
          />
        ),
        cell: props => (
          <OverflowEllipsis
            text={props
              .getValue<AdminListItem['associatedVendors']>()
              ?.map(vendor => vendor.name)
              .join(', ')}
          />
        ),
      },
    }),
    [copy.admin.list]
  );

  const columns = useMemo<ColumnDef<AdminListItem>[]>(() => {
    const {
      USERS,
      CLIENTS,
      CLIENTS_UNUSED,
      CLIENTS_REQUESTS,
      BRANDS,
      BRANDS_UNUSED,
      BRANDS_REQUESTS,
      VENDOR,
      VENDOR_REQUESTS,
      VENDOR_UNUSED,
      INTERNAL,
      EXTERNAL,
      RESOURCE_UNUSED,
      RESOURCE_REQUESTS,
      VENDOR_SERVICE_UNUSED,
      VENDOR_SERVICE,
      VENDOR_SERVICE_REQUESTS,
    } = ADMIN_ROUTES;

    return [
      ...(route === CLIENTS || route === CLIENTS_UNUSED
        ? [
            ListFieldConfig[ListFields.Name],
            ListFieldConfig[ListFields.Email],
            ListFieldConfig[ListFields.ClientCategory],
          ]
        : []),
      ...(route === BRANDS || route === BRANDS_UNUSED
        ? [ListFieldConfig[ListFields.Name]]
        : []),
      ...(route === INTERNAL ||
      route === RESOURCE_UNUSED ||
      route === RESOURCE_REQUESTS
        ? [
            ListFieldConfig[ListFields.Name],
            ListFieldConfig[ListFields.Email],
            ListFieldConfig[ListFields.Territory],
            ListFieldConfig[ListFields.Role],
            ListFieldConfig[ListFields.Department],
          ]
        : []),
      ...(route === EXTERNAL
        ? [
            ListFieldConfig[ListFields.Name],
            ListFieldConfig[ListFields.Email],
            ListFieldConfig[ListFields.Territory],
            ListFieldConfig[ListFields.Role],
            ListFieldConfig[ListFields.Rate],
            ListFieldConfig[ListFields.Department],
          ]
        : []),
      ...(route === VENDOR ||
      route === VENDOR_REQUESTS ||
      route === VENDOR_UNUSED
        ? [
            ListFieldConfig[ListFields.Name],
            ListFieldConfig[ListFields.Email],
            ListFieldConfig[ListFields.Territory],
            ListFieldConfig[ListFields.VendorService],
          ]
        : []),
      ...(route === USERS
        ? [
            ListFieldConfig[ListFields.Name],
            ListFieldConfig[ListFields.Email],
            ListFieldConfig[ListFields.PermissionSet],
          ]
        : []),
      ...(route === CLIENTS_REQUESTS
        ? [
            ListFieldConfig[ListFields.Name],
            ListFieldConfig[ListFields.Email],
            ListFieldConfig[ListFields.Message],
          ]
        : []),
      ...(route === BRANDS_REQUESTS
        ? [
            ListFieldConfig[ListFields.Name],
            ListFieldConfig[ListFields.Message],
          ]
        : []),
      ...(route === VENDOR_SERVICE_REQUESTS ||
      route === VENDOR_SERVICE_UNUSED ||
      route === VENDOR_SERVICE
        ? [
            ListFieldConfig[ListFields.Name],
            ListFieldConfig[ListFields.AssociatedVendors],
          ]
        : []),
    ];
  }, [ListFieldConfig, route]);

  return {
    columns,
  };
};
