import { Command as CommandPrimitive } from 'cmdk';
import { rgba } from 'polished';
import * as React from 'react';

import { useSvgIcon } from 'u9/hooks';
import { colors } from 'utils/styles/theme';

import * as Styled from './Command.styles';

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ ...props }, ref) => <CommandPrimitive ref={ref} {...props} />);
Command.displayName = CommandPrimitive.displayName;

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ placeholder = 'Search...', ...props }, ref) => {
  const { SvgIcon: SearchIcon } = useSvgIcon('search');
  return (
    <Styled.CommandInputWrapper>
      <Styled.SearchIconWrapper>
        <SearchIcon />
      </Styled.SearchIconWrapper>
      <Styled.CommandInput placeholder={placeholder} ref={ref} {...props} />
    </Styled.CommandInputWrapper>
  );
});

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ ...props }, ref) => <Styled.List ref={ref} {...props} />);

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>(({ children, ...props }, ref) => (
  <CommandPrimitive.Empty ref={ref} {...props}>
    <Styled.EmptyLabel>
      {children ? children : 'No results...'}
    </Styled.EmptyLabel>
  </CommandPrimitive.Empty>
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandLoading = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>(({ children, ...props }, ref) => (
  <CommandPrimitive.Loading ref={ref} {...props}>
    <Styled.LoadingLabel>
      {children ? children : 'Fetching...'}
    </Styled.LoadingLabel>
  </CommandPrimitive.Loading>
));

CommandLoading.displayName = CommandPrimitive.Loading.displayName;

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ ...props }, ref) => <Styled.CommandGroup ref={ref} {...props} />);

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ ...props }, ref) => (
  <CommandPrimitive.Separator
    style={{
      borderBottom: `1px solid ${rgba(colors.white, 0.25)}`,
    }}
    ref={ref}
    {...props}
  />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & {
    selected?: boolean;
    dataCy?: string;
  }
>(({ children, selected, dataCy, ...props }, ref) => {
  const { SvgIcon: CheckIcon } = useSvgIcon('check');
  return (
    <Styled.Item ref={ref} selected={selected} data-cy={dataCy} {...props}>
      {children}
      {selected && (
        <Styled.IndicatorWrapper>
          <CheckIcon />
        </Styled.IndicatorWrapper>
      )}
    </Styled.Item>
  );
});
CommandItem.displayName = CommandPrimitive.Item.displayName;

const CommandCheckBoxItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & {
    checked?: boolean;
  }
>(({ children, checked, ...props }, ref) => {
  const { SvgIcon: CheckIcon } = useSvgIcon('check');
  return (
    <Styled.Item ref={ref} {...props}>
      {children}
      <Styled.CheckIconWrapper>
        {checked && <CheckIcon />}
      </Styled.CheckIconWrapper>
    </Styled.Item>
  );
});
CommandCheckBoxItem.displayName = CommandPrimitive.Item.displayName;

const CommandShortcut = ({
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return <span {...props} />;
};
CommandShortcut.displayName = 'CommandShortcut';

const CommandLabel = Styled.Label;

export {
  Command,
  CommandCheckBoxItem,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandLabel,
  CommandList,
  CommandLoading,
  CommandSeparator,
  CommandShortcut,
};
