import { useCallback, useEffect, useMemo, useState } from 'react';

import { useField } from 'components/inputs/hooks/useField';
import { AdminListItem } from 'components/modules/Admin/hooks/useAdminListColumns';
import { useCommonErrorHandling } from 'hooks/useCommonErrorHandling';
import { useAddVendor, useUpdateVendor } from 'queries/vendors';
import { useVendorServices } from 'queries/vendorServices';
import { useCopyStore } from 'store';
import { getErrorCode } from 'utils/api.helpers';
import { VendorService } from 'utils/api.types';

export const useVendorPartial = (item?: AdminListItem) => {
  const copy = useCopyStore(s => s.copy);
  const [emailUniqueError, setEmailUniqueError] = useState(false);
  const { commonErrorHandler } = useCommonErrorHandling();

  const { data: vendorServices } = useVendorServices();

  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.vendorNameRequired,
      },
    }),
    [copy.app.validatorMessages.vendorNameRequired]
  );
  const vendorName = useField(item?.name ?? '', validators);
  const carbonDescription = useField(item?.carbonDescription ?? '');

  const emailValidators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.emailRequired,
      },
      email: { enabled: true, message: copy.app.validatorMessages.validEmail },
    }),
    [
      copy.app.validatorMessages.emailRequired,
      copy.app.validatorMessages.validEmail,
    ]
  );
  const emailField = useField(item?.email ?? '', emailValidators);

  const items = useMemo(
    () =>
      vendorServices?.map(vendorService => ({
        displayName: vendorService.name,
        id: vendorService.id,
      })),
    [vendorServices]
  );

  const vendorServiceItems = items;

  const [activeVendorServiceId, setActiveVendorServiceId] = useState(
    item?.vendorServiceId ?? null
  );

  const [addressLine1, setAddressLine1] = useState(
    item?.address?.address_lines[0] ?? ''
  );
  const [addressLine2, setAddressLine2] = useState(
    item?.address?.address_lines[1] ?? ''
  );
  const [city, setCity] = useState(item?.address?.city ?? '');
  const [zipCode, setZipCode] = useState(item?.address?.postal_code ?? '');
  const [countryCode, setCountryCode] = useState<string | null>(
    item?.address?.country_code ?? null
  );

  const isPartialValid = useMemo(() => {
    const [, , isVendorNameValid] = vendorName;
    const [, , isEmailValid] = emailField;
    return isVendorNameValid && isEmailValid;
  }, [emailField, vendorName]);

  const [email] = emailField;

  useEffect(() => {
    setEmailUniqueError(false);
  }, [email]);

  const handleError = useCallback(
    (error: unknown) => {
      commonErrorHandler({
        error,
        handledLocally: ['vendor.email_not_unique'],
        localHandler(error) {
          if (getErrorCode(error as Error) === 'vendor.email_not_unique') {
            setEmailUniqueError(true);
          }
        },
      });
    },
    [commonErrorHandler]
  );

  const { mutateAsync: handleAdd } = useAddVendor({
    onError: handleError,
  });
  const { mutateAsync: handleUpdate } = useUpdateVendor({
    onError: handleError,
  });

  const save = useCallback(
    async (callback: (success?: boolean) => void) => {
      const [vendor_name] = vendorName;
      const [email] = emailField;
      const [carbonNotes] = carbonDescription;
      const vendorServiceId = activeVendorServiceId as VendorService['id'];

      try {
        await handleAdd({
          email,
          name: vendor_name,
          carbon_notes: carbonNotes,
          ...(vendorServiceId && {
            service_id: vendorServiceId || 0,
          }),
          ...(countryCode &&
          (addressLine1.length > 2 || addressLine2.length > 2)
            ? {
                address: {
                  address_lines: [addressLine1, addressLine2],
                  city,
                  postal_code: zipCode,
                  country_code: countryCode ?? '',
                },
              }
            : {}),
        });

        callback?.();
      } catch (error) {
        callback?.(false);
      }
    },
    [
      vendorName,
      emailField,
      carbonDescription,
      activeVendorServiceId,
      handleAdd,
      countryCode,
      addressLine1,
      addressLine2,
      city,
      zipCode,
    ]
  );

  const update = useCallback(
    async (callback: (success?: boolean) => void) => {
      const [vendorNameValue] = vendorName;
      const [email] = emailField;
      const [carbonNotes] = carbonDescription;
      const vendorServiceId = activeVendorServiceId as VendorService['id'];

      try {
        if (item?.vendorId) {
          await handleUpdate({
            id: item.vendorId,
            email,
            name: vendorNameValue,
            carbon_notes: carbonNotes,
            ...(vendorServiceId && {
              service_id: vendorServiceId || 0,
            }),
            ...(countryCode &&
            (addressLine1.length > 2 || addressLine2.length > 2)
              ? {
                  address: {
                    address_lines: [addressLine1, addressLine2],
                    city,
                    postal_code: zipCode,
                    country_code: countryCode ?? '',
                  },
                }
              : {}),
          });
        }

        callback?.();
      } catch (error) {
        callback?.(false);
      }
    },
    [
      vendorName,
      emailField,
      carbonDescription,
      activeVendorServiceId,
      item?.vendorId,
      handleUpdate,
      countryCode,
      addressLine1,
      addressLine2,
      city,
      zipCode,
    ]
  );

  return {
    vendorName,
    emailField,
    carbonDescription,
    isPartialValid,
    emailUniqueError,
    save,
    update,
    activeVendorServiceId,
    setActiveVendorServiceId,
    vendorServiceItems,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    city,
    setCity,
    zipCode,
    setZipCode,
    countryCode,
    setCountryCode,
  };
};
