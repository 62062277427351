import { isArray } from 'lodash';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useParseProjectsListQueryString } from 'components/modules/ProjectsList/hooks/useParseProjectsListQueryString';
import { useJobNumberPrefix } from 'hooks/useJobNumberPrefix';
import { getPaginationQueryKey, getSortingQueryKey } from 'queries';
import { useApiStore, useProjectsListStore } from 'store';
import { Awaited } from 'types/helpers';
import {
  IntegrationProject,
  IntegrationProjectInput,
  Project,
  ProjectClosureStatus,
  ProjectsListItem,
  ReconcileResult,
} from 'utils/api.types';
import { ROUTES } from 'utils/routes';

import { ARCHIVE_KEY } from './archive';
import { PROJECT_FIELD_LISTS_KEY } from './projectFieldLists';
import { PROJECTS_PERFORMANCE_KEY } from './projectsPerformance';

export const PROJECTS_KEY = 'PROJECTS_KEY';
export const MY_PROJECTS_KEY = 'MY_PROJECTS';
export const RECONCILE_MUTATION_KEY = 'RECONCILE_MUTATION';
export const INTEGRATION_PROJECTS_KEY = 'INTEGRATION_PROJECTS_KEY';
export const RECONCILIATION_DATA_KEY = 'RECONCILIATION_DATA';
export const PROJECT_CLOSURE_STATUS_KEY = 'PROJECT_CLOSURE_STATUS';

export const useProjects = (
  variables: Parameters<typeof getProjects>[0],
  options?: UseQueryOptions<{
    data: ProjectsListItem[];
    headers: Record<string, unknown>;
  }>
) => {
  const getProjects = useApiStore(s => s.apiClient.getProjects);
  const { transformGetAll } = useTransformProject();

  return useQuery({
    queryKey: [
      PROJECTS_KEY,
      getPaginationQueryKey(variables?.limit ?? 0, variables?.page ?? 0),
    ],
    queryFn: async () => {
      const response = await getProjects(variables);
      return {
        data: response.data,
        headers: response.headers,
      };
    },
    select: transformGetAll,
    ...options,
  });
};

export const useFilteredProjects = (
  options?: UseQueryOptions<{
    data: ProjectsListItem[];
    headers: Record<string, unknown>;
  }>
) => {
  const getProjects = useApiStore(s => s.apiClient.getProjects);
  const { transformGetAll } = useTransformProject();
  const tablePagination = useProjectsListStore(s => s.tablePagination);
  const sorting = useProjectsListStore(s => s.tableSorting);
  const filtersQuery = useParseProjectsListQueryString();
  const router = useRouter();

  const groupId = Number(
    isArray(router.query?.groupId)
      ? router.query?.groupId[0]
      : router.query?.groupId
  );

  const isJobClosurePending =
    router.route === ROUTES.PROJECTS_LIST_JOB_CLOSURE_PENDING;
  const isJobNumberPending =
    router.route === ROUTES.PROJECTS_LIST_JOB_NUMBER_PENDING;

  const projectsQueryKey = [
    PROJECTS_KEY,
    { groupId, isJobClosurePending, isJobNumberPending },
    getPaginationQueryKey(tablePagination.pageSize, tablePagination.pageIndex),
    getSortingQueryKey(sorting[0]),
    filtersQuery,
  ];

  const {
    brandIds,
    budgetFrom,
    budgetTo,
    clientIds,
    modes,
    resourceIds,
    search,
    statuses,
    verticalIds,
    roleIds,
    vendorIds,
    vendorServiceIds,
    select,
  } = filtersQuery;

  // Sorting by mode is not enough. Mode column in projects list contains information about
  // mode, status, job number and job closure. To get sorting working as expected, we need to sort
  // by all of those fields
  const modeSortFields: (keyof Project)[] = [
    'mode',
    'status',
    'job_number_requested',
    'job_closure_requested',
  ];
  const sortBy =
    sorting[0].id === 'mode' ? modeSortFields.join(',') : sorting[0].id;

  const queryVariables: Parameters<typeof getProjects>[0] = {
    select,
    limit: tablePagination.pageSize,
    page: tablePagination.pageIndex + 1,
    ...(sorting.length ? { sort_by: sortBy, desc: sorting[0].desc } : null),
    ...(resourceIds ? { resourceIds: resourceIds } : null),
    ...(brandIds ? { brandIds: brandIds } : null),
    ...(clientIds ? { clientIds: clientIds } : null),
    ...(vendorIds ? { vendorIds: vendorIds } : null),
    ...(vendorServiceIds ? { vendorServiceIds: vendorServiceIds } : null),
    ...(roleIds ? { roleIds: roleIds } : null),
    ...(search ? { search: search } : null),
    ...(verticalIds ? { verticalIds: verticalIds } : null),
    ...(modes?.length ? { modes: modes } : null),
    ...(statuses?.length ? { statuses: statuses } : null),
    ...(budgetFrom ? { budgetFrom: budgetFrom } : null),
    ...(budgetTo ? { budgetTo: budgetTo } : null),
    ...(groupId ? { groupId: groupId } : null),
    ...(isJobClosurePending
      ? { jobClosurePending: true, sort_nulls_first: true }
      : null),
    ...(isJobNumberPending ? { jobNumberPending: true } : null),
  };

  return useQuery({
    queryKey: projectsQueryKey,
    queryFn: async () => {
      const response = await getProjects(queryVariables);
      return {
        data: response.data,
        headers: response.headers,
      };
    },
    select: data => transformGetAll(data),
    ...options,
  });
};

export const useProject = (
  variables: { projectId: Project['id'] | undefined },
  options?: UseQueryOptions<Project>
) => {
  const getProject = useApiStore(s => s.apiClient.getProject);
  const { transformGet } = useTransformProject();

  return useQuery<Project>(
    [PROJECTS_KEY, String(variables?.projectId)],
    async () => {
      if (variables?.projectId === undefined) {
        return Promise.reject(new Error('projectId is undefined'));
      }
      return (await getProject(variables?.projectId)).data;
    },
    {
      select: transformGet,
      ...options,
    }
  );
};

export const useCurrentProject = () => {
  const router = useRouter();
  const projectId = router.query.projectSlug?.[0];
  const { data: currentProject } = useProject(
    {
      projectId: Number(projectId),
    },
    {
      enabled: !!projectId,
    }
  );
  return currentProject;
};

export const useUpdateProject = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProject>>,
    unknown,
    Parameters<typeof updateProject>[0]
  >
) => {
  const updateProject = useApiStore(s => s.apiClient.updateProject);
  const queryClient = useQueryClient();

  return useMutation(
    (variables: Parameters<typeof updateProject>[0]) => {
      return updateProject(variables);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(PROJECTS_KEY);
        queryClient.invalidateQueries(PROJECTS_PERFORMANCE_KEY);
        queryClient.invalidateQueries(PROJECT_FIELD_LISTS_KEY);
        queryClient.invalidateQueries(PROJECT_CLOSURE_STATUS_KEY);
        if (variables.project.deleted === false) {
          queryClient.invalidateQueries(ARCHIVE_KEY);
        }
      },
      ...options,
    }
  );
};

export const useAddProject = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof postProject>>,
    unknown,
    Parameters<typeof postProject>[0]
  >
) => {
  const postProject = useApiStore(s => s.apiClient.postProject);
  const queryClient = useQueryClient();

  return useMutation(
    (variables: Parameters<typeof postProject>[0]) => {
      return postProject(variables);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECTS_KEY);
        queryClient.invalidateQueries(PROJECTS_PERFORMANCE_KEY);
        queryClient.invalidateQueries(PROJECT_FIELD_LISTS_KEY);
      },
      ...options,
    }
  );
};

export const useDeleteProject = () => {
  const deleteProject = useApiStore(s => s.apiClient.deleteProject);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteProject>[0]) =>
      deleteProject(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECTS_KEY);
        queryClient.invalidateQueries(PROJECTS_PERFORMANCE_KEY);
        queryClient.invalidateQueries(PROJECT_FIELD_LISTS_KEY);
      },
    }
  );
};

export const useDownloadReport = () => {
  const downloadReport = useApiStore(s => s.apiClient.downloadReport);
  return useMutation((variables: Parameters<typeof downloadReport>[0]) =>
    downloadReport(variables)
  );
};

export const useReconcile = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof reconcile>>,
    unknown,
    Parameters<typeof reconcile>[0]
  >
) => {
  const queryClient = useQueryClient();
  const reconcile = useApiStore(s => s.apiClient.reconcile);
  return useMutation(
    (variables: Parameters<typeof reconcile>[0]) => reconcile(variables),
    {
      mutationKey: RECONCILE_MUTATION_KEY,
      onSuccess: (response, variables) => {
        const queryKey = [RECONCILIATION_DATA_KEY, variables.project_id];
        queryClient.setQueryData(queryKey, response.data);
      },
      ...options,
    }
  );
};

export const useReconciliationData = (
  variables: { projectId?: Project['id'] },
  options?: UseQueryOptions<ReconcileResult>
) => {
  const queryClient = useQueryClient();
  const queryKey = [RECONCILIATION_DATA_KEY, variables.projectId];
  return useQuery({
    queryKey,
    queryFn: () => queryClient.getQueryData(queryKey) as ReconcileResult,
    ...options,
  });
};

export const useIntegrationProjects = (
  variables: IntegrationProjectInput,
  options?: UseQueryOptions<IntegrationProject[]>
) => {
  const getIntegrationProjects = useApiStore(
    s => s.apiClient.getIntegrationProjects
  );
  return useQuery({
    queryKey: [PROJECTS_KEY, String(variables?.type), variables.name],
    queryFn: async () => (await getIntegrationProjects(variables)).data,
    ...options,
  });
};

export const useRequestJobNumber = () => {
  const queryClient = useQueryClient();
  const requestJobNumber = useApiStore(s => s.apiClient.requestJobNumber);
  return useMutation(
    (variables: Parameters<typeof requestJobNumber>[0]) =>
      requestJobNumber(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECTS_KEY);
      },
    }
  );
};

export const useCreateProjectVersion = () => {
  const queryClient = useQueryClient();
  const createProjectVersion = useApiStore(
    s => s.apiClient.createProjectVersion
  );
  return useMutation(
    (variables: Parameters<typeof createProjectVersion>[0]) =>
      createProjectVersion(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECTS_KEY);
      },
    }
  );
};

export const useUpdateProjectVersion = () => {
  const queryClient = useQueryClient();
  const updateProjectVersion = useApiStore(
    s => s.apiClient.updateProjectVersion
  );
  return useMutation(
    (variables: Parameters<typeof updateProjectVersion>[0]) =>
      updateProjectVersion(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECTS_KEY);
      },
    }
  );
};

export const useDeleteProjectVersion = () => {
  const queryClient = useQueryClient();
  const deleteProjectVersion = useApiStore(
    s => s.apiClient.deleteProjectVersion
  );
  return useMutation(
    (variables: Parameters<typeof deleteProjectVersion>[0]) =>
      deleteProjectVersion(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECTS_KEY);
      },
    }
  );
};

export const useMovePitchToProduction = () => {
  const queryClient = useQueryClient();
  const movePitchToProduction = useApiStore(
    s => s.apiClient.movePitchToProduction
  );
  return useMutation(
    (variables: Parameters<typeof movePitchToProduction>[0]) =>
      movePitchToProduction(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECTS_KEY);
      },
    }
  );
};

export const useProjectClosureStatus = (
  variables: Partial<Parameters<typeof getProjectClosureStatus>[0]>,
  options?: UseQueryOptions<ProjectClosureStatus>
) => {
  const getProjectClosureStatus = useApiStore(
    s => s.apiClient.getProjectClosureStatus
  );
  return useQuery({
    queryKey: [PROJECT_CLOSURE_STATUS_KEY, variables.projectId],
    queryFn: async () => {
      if (variables?.projectId === undefined) {
        return Promise.reject(new Error('projectId is undefined'));
      }
      return (
        await getProjectClosureStatus({
          projectId: variables.projectId,
        })
      ).data;
    },
    ...options,
  });
};

export const useRequestJobClosure = () => {
  const queryClient = useQueryClient();
  const requestJobClosure = useApiStore(s => s.apiClient.requestJobClosure);
  return useMutation(
    (variables: Parameters<typeof requestJobClosure>[0]) =>
      requestJobClosure(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECTS_KEY);
      },
    }
  );
};

export const useWatchProject = () => {
  const queryClient = useQueryClient();
  const watchProject = useApiStore(s => s.apiClient.watchProject);
  return useMutation(
    (variables: Parameters<typeof watchProject>[0]) => watchProject(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECTS_KEY);
      },
    }
  );
};

export const useUnwatchProject = () => {
  const queryClient = useQueryClient();
  const unwatchProject = useApiStore(s => s.apiClient.unwatchProject);
  return useMutation(
    (variables: Parameters<typeof unwatchProject>[0]) =>
      unwatchProject(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECTS_KEY);
      },
    }
  );
};

export const useMergeProjectVersion = () => {
  const queryClient = useQueryClient();
  const mergeProjectVersion = useApiStore(s => s.apiClient.mergeProjectVersion);
  return useMutation(
    (variables: Parameters<typeof mergeProjectVersion>[0]) =>
      mergeProjectVersion(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECTS_KEY);
      },
    }
  );
};

const useTransformProject = () => {
  const { stripJobNumberPrefix } = useJobNumberPrefix();

  const transformGet = useCallback(
    <T extends ProjectsListItem | Project>(project: T) => {
      if (project.job_number) {
        project.job_number = stripJobNumberPrefix(project.job_number);
      }
      if (project.pitch_number) {
        project.pitch_number = stripJobNumberPrefix(project.pitch_number);
      }
      return project;
    },
    [stripJobNumberPrefix]
  );

  const transformGetAll = useCallback(
    <T extends ProjectsListItem | Project>(projects: {
      data: T[];
      headers: Record<string, unknown>;
    }) => {
      const mappedProjects = projects.data.map(transformGet);
      return {
        data: mappedProjects,
        headers: projects.headers,
      };
    },
    [transformGet]
  );

  return {
    transformGet,
    transformGetAll,
  };
};
