import { FC, memo, useCallback } from 'react';

import { useUpdateBrand } from 'queries/brands';
import { useUpdateClient } from 'queries/clients';
import { useUpdateProject } from 'queries/project';
import { useUpdateResource } from 'queries/resources';
import { useUpdateUser } from 'queries/users';
import { useUpdateVendor } from 'queries/vendors';
import { useCopyStore, useGlobalStore } from 'store';
import { ArchiveItem } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface RestoreDeletedItemModalProps {
  item: ArchiveItem;
}

const RestoreDeletedItemModal: FC<RestoreDeletedItemModalProps> = ({
  item,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);

  const { mutateAsync: updateProject, isLoading: isProjectUpdateLoading } =
    useUpdateProject();
  const { mutateAsync: updateUser, isLoading: isUserUpdateLoading } =
    useUpdateUser();
  const { mutateAsync: updateBrand, isLoading: isBrandUpdateLoading } =
    useUpdateBrand();
  const { mutateAsync: updateClient, isLoading: isClientUpdateLoading } =
    useUpdateClient();
  const { mutateAsync: updateResource, isLoading: isResourceUpdateLoading } =
    useUpdateResource();
  const { mutateAsync: updateVendor, isLoading: isVendorUpdateLoading } =
    useUpdateVendor();
  const isAnyLoading =
    isProjectUpdateLoading ||
    isUserUpdateLoading ||
    isBrandUpdateLoading ||
    isClientUpdateLoading ||
    isResourceUpdateLoading ||
    isVendorUpdateLoading;

  const handleClose = useCallback(() => {
    closeModal('restoreDeletedItemModal');
  }, [closeModal]);

  const handleConfirmClick = useCallback(async () => {
    if (item.type === 'project') {
      updateProject({ projectId: item.id, project: { deleted: false } });
    }
    if (item.type === 'user') {
      updateUser({ id: item.id, closed: false });
    }
    if (item.type === 'brand') {
      updateBrand({
        id: item.id,
        closed: false,
      });
    }
    if (item.type === 'client') {
      updateClient({
        id: item.id,
        closed: false,
      });
    }
    if (item.type === 'resource') {
      updateResource({
        id: item.id,
        closed: false,
      });
    }
    if (item.type === 'vendor') {
      updateVendor({
        id: item.id,
        closed: false,
      });
    }
    handleClose();
  }, [
    handleClose,
    item.id,
    item.type,
    updateBrand,
    updateClient,
    updateProject,
    updateResource,
    updateUser,
    updateVendor,
  ]);

  return (
    <ConfirmModal
      cancelButtonProps={{
        disabled: isAnyLoading,
        label: copy.admin.restoreDeletedItemModal.declineButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: handleClose,
      }}
      confirmButtonProps={{
        disabled: isAnyLoading,
        label: copy.admin.restoreDeletedItemModal.confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: isAnyLoading,
        dataCy: 'restore-item__confirm-button',
      }}
      description={replaceString(
        copy.admin.restoreDeletedItemModal.description,
        '{name}',
        item.name
      )}
      title={copy.admin.restoreDeletedItemModal.title}
    />
  );
};

export default memo(RestoreDeletedItemModal);
