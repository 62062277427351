import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { focusVisible, hover } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  padding: 80rem;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
  padding-bottom: 40rem;
`;

export const ListItem = styled.div`
  display: flex;
  padding: 20rem;
  justify-content: space-between;
  border: 1px solid ${rgba(colors.white, 0.5)};
  border-radius: 20rem;
`;

export const ListItemLabel = styled.div`
  width: 100%;
`;

export const ListItemButton = styled.button`
  width: 48rem;
  height: 48rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;
  border-radius: 10rem;

  ${hover(css`
    background-color: ${colors.black};
  `)}

  &:focus-visible {
    ${focusVisible};
  }
`;

export const IconWrapper = styled.div`
  width: 28rem;
  height: 28rem;
`;

export const AddItemButton = styled.button`
  background: ${colors.blueRibbon};
  padding: 20rem;
  width: 100%;
  transition: background-color 0.2s;
  border-radius: 20rem;

  ${hover(css`
    background: ${rgba(colors.blueRibbon, 0.7)};
  `)}

  &:focus-visible {
    ${focusVisible};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20rem;
  justify-content: flex-end;
  padding-top: 80rem;
`;
