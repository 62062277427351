import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { ADMIN_ROUTES } from 'utils/routes';

export const useCurrentAdminRoute = () => {
  const router = useRouter();

  const route = useMemo(() => {
    const route = router.asPath.split('?')[0].replace('/admin/', '');
    return Object.values(ADMIN_ROUTES).find(adminRoute => adminRoute === route);
  }, [router.asPath]);

  return route;
};
