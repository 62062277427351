import { FC, memo, useEffect } from 'react';

import { DEFAULT_CURRENCY } from '@u9/bob3-shared/lib/types/api.types';
import Checkbox from 'components/Checkbox/Checkbox';
import DatepickerRange from 'components/datepickers/DatepickerRange/DatepickerRange';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useOrganization } from 'queries/organizations';
import {
  useCopyStore,
  useGlobalStore,
  usePerformanceDashboardStore,
} from 'store';
import { getDateString } from 'utils/formatters';
import { replaceString } from 'utils/replace';

import FlagDropdown from './FlagDropdown/FlagDropdown';
import VerticalDropdown from './VerticalDropdown/VerticalDropdown';

import * as Styled from '../PerformanceFiltersModal.styles';

export interface GeneralSettingsProps {}

const GeneralSettings: FC<GeneralSettingsProps> = () => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const copy = useCopyStore(s => s.copy);
  const { data: organization } = useOrganization();

  const resultsByDate = usePerformanceDashboardStore(s => s.resultsByDate);
  const startDate = usePerformanceDashboardStore(s => s.startDate);
  const endDate = usePerformanceDashboardStore(s => s.endDate);
  const includeStart = usePerformanceDashboardStore(s => s.includeStart);
  const includeEnd = usePerformanceDashboardStore(s => s.includeEnd);
  const excludeClosed = usePerformanceDashboardStore(s => s.excludeClosed);
  const budgetFrom = usePerformanceDashboardStore(s => s.budgetFrom);
  const budgetTo = usePerformanceDashboardStore(s => s.budgetTo);
  const setStartDate = usePerformanceDashboardStore(s => s.setStartDate);
  const setEndDate = usePerformanceDashboardStore(s => s.setEndDate);
  const setIncludeStart = usePerformanceDashboardStore(s => s.setIncludeStart);
  const setIncludeEnd = usePerformanceDashboardStore(s => s.setIncludeEnd);
  const setExcludeClosed = usePerformanceDashboardStore(
    s => s.setExcludeClosed
  );
  const setBudgetFrom = usePerformanceDashboardStore(s => s.setBudgetFrom);
  const setBudgetTo = usePerformanceDashboardStore(s => s.setBudgetTo);
  const setResultsByDate = usePerformanceDashboardStore(
    s => s.setResultsByDate
  );

  const onRangeChange = (startDate: Date | null, endDate: Date | null) => {
    if (startDate) {
      setStartDate(getDateString(startDate));
    }
    if (endDate) {
      setEndDate(getDateString(endDate));
    }
  };

  const tabCopy = copy.performanceDashboard.filtersModal.generalTab;

  useEffect(() => {
    if (!startDate) {
      setIncludeStart(false);
    }
  }, [setIncludeStart, startDate]);

  useEffect(() => {
    if (!endDate) {
      setIncludeEnd(false);
    }
  }, [endDate, setIncludeEnd]);

  return (
    <Styled.TabWrapper>
      <Styled.Row>
        <DatepickerRange
          onChange={onRangeChange}
          color={currentColor}
          startLabel={tabCopy.startDate}
          endLabel={tabCopy.endDate}
          startInitValue={new Date(startDate ?? Date.now())}
          endInitValue={new Date(endDate ?? Date.now())}
          closeOnChange
        />
      </Styled.Row>
      <Styled.Row>
        <Styled.FieldWrapper>
          <Styled.Label>{tabCopy.resultsLabel}</Styled.Label>
          <Checkbox
            value={resultsByDate}
            setValue={setResultsByDate}
            label={tabCopy.filterResultsByDate}
            dataCy="strict"
          />
        </Styled.FieldWrapper>
      </Styled.Row>
      <Styled.Row>
        <Styled.Label>{tabCopy.inclusionLabel}</Styled.Label>
        <Styled.FieldWrapper>
          <Checkbox
            value={includeStart}
            setValue={setIncludeStart}
            label={tabCopy.includeStart}
            disabled={startDate === null}
            dataCy="include-start"
          />
        </Styled.FieldWrapper>
        <Styled.FieldWrapper>
          <Checkbox
            value={includeEnd}
            setValue={setIncludeEnd}
            label={tabCopy.includeEnd}
            disabled={endDate === null}
            dataCy="include-end"
          />
        </Styled.FieldWrapper>
      </Styled.Row>
      <Styled.Row>
        <Styled.Label>{tabCopy.inclusionClosed}</Styled.Label>
        <Styled.FieldWrapper>
          <Checkbox
            value={excludeClosed}
            setValue={setExcludeClosed}
            label={tabCopy.excludeClosed}
            dataCy="exclude-closed"
          />
        </Styled.FieldWrapper>
      </Styled.Row>
      <Styled.Row>
        <VerticalDropdown />
      </Styled.Row>
      <Styled.Row>
        <Styled.FieldWrapper>
          <FormInput
            placeholder={replaceString(
              tabCopy.budgetFrom,
              '{name}',
              copy.app.currenciesSign[
                organization?.currency ?? DEFAULT_CURRENCY
              ]
            )}
            onChange={value => setBudgetFrom(value)}
            value={budgetFrom ?? ''}
            mask="float"
            dataCy="budget-from-field"
          />
        </Styled.FieldWrapper>
        <Styled.FieldWrapper>
          <FormInput
            placeholder={replaceString(
              tabCopy.budgetTo,
              '{name}',
              copy.app.currenciesSign[
                organization?.currency ?? DEFAULT_CURRENCY
              ]
            )}
            onChange={value => setBudgetTo(value)}
            value={budgetTo ?? ''}
            mask="float"
            dataCy="budget-to-field"
          />
        </Styled.FieldWrapper>
      </Styled.Row>
      <Styled.Row>
        <FlagDropdown />
      </Styled.Row>
    </Styled.TabWrapper>
  );
};

export default memo(GeneralSettings);
