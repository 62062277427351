import { useCallback } from 'react';

import { DeleteAdminItemModalProps } from 'components/modals/DeleteAdminItemModal/DeleteAdminItemModal';
import { useGlobalStore } from 'store';

import { AdminListItem } from './useAdminListColumns';

export const useDeleteItemModal = (item: AdminListItem | null) => {
  const openModal = useGlobalStore(s => s.openModal);

  const handleOpenDeleteItemModal = useCallback(() => {
    if (item) {
      openModal<DeleteAdminItemModalProps>('deleteAdminItemModal', { item });
    }
  }, [item, openModal]);

  return {
    handleOpenDeleteItemModal,
  };
};
