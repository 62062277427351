import { FC, memo } from 'react';

import Copy from 'components/Copy/Copy';
import { useNotificationHover } from 'components/Notifications/hooks/useNotificationHover';
import { useCopyStore } from 'store';
import { ResourceAcceptedNotification as ResourceAcceptedNotificationType } from 'utils/api.types';

import * as Styled from '../../NotificationItem.styles';

export interface ResourceAcceptedNotificationProps {
  notification: ResourceAcceptedNotificationType;
}

const ResourceAcceptedNotification: FC<ResourceAcceptedNotificationProps> = ({
  notification,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { onMouseEnter, onMouseLeave } = useNotificationHover();

  return (
    <Styled.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Copy
        text={copy.app.notifications.resourceAcceptedNotification}
        replacements={[
          [
            '{user}',
            `${notification.extra_info.fullName} (${notification.extra_info.username})`,
          ],
          ['{resource}', notification.extra_info.resourceName],
        ]}
      />
    </Styled.Wrapper>
  );
};

export default memo(ResourceAcceptedNotification);
