import { FC, memo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import Datepicker from 'components/datepickers/Datepicker/Datepicker';
import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { usePrefillCashflowItems } from 'queries/cashflow';
import { useCurrentProject, useUpdateProject } from 'queries/project';
import { useGlobalStore } from 'store';
import { getDateString } from 'utils/formatters';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './CashflowPrefillItemsModal.styles';

export interface CashflowPrefillItemsModalProps {}

const CashflowPrefillItemsModal: FC<CashflowPrefillItemsModalProps> = () => {
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const currentProject = useCurrentProject();
  const currentVersionId = useCurrentVersionId();
  const [kickoffDate, setKickoffDate] = useState<Date>(
    currentProject?.kickoff_date
      ? new Date(currentProject?.kickoff_date)
      : new Date()
  );
  const isKickoffDateSet = !!currentProject?.kickoff_date;
  const { mutateAsync: updateProject, isLoading: isProjectUpdateLoading } =
    useUpdateProject();
  const { mutateAsync: prefillCashflowItems, isLoading: isPrefillLoading } =
    usePrefillCashflowItems();

  const close = () => {
    closeModal('cashflowPrefillItems');
  };

  const handleConfirm = async () => {
    if (currentProject && currentVersionId) {
      const kickoffDateChanged =
        getDateString(kickoffDate) !== currentProject?.kickoff_date;
      if (kickoffDateChanged) {
        await updateProject({
          projectId: currentProject?.id,
          project: {
            kickoff_date: getDateString(kickoffDate),
          },
        });
      }

      await prefillCashflowItems({
        projectId: currentProject?.id,
        projectVersionId: currentVersionId,
      });

      close();
    }
  };

  return (
    <ConfigModal
      title={
        isKickoffDateSet
          ? 'Reset And Fill Cashflow Items'
          : 'Fill Cashflow Items'
      }
      {...(isKickoffDateSet
        ? {
            onCloseClick: close,
          }
        : {})}
      color={currentColor}
      zeroPadding
    >
      <Styled.Wrapper>
        <Styled.Description>
          {isKickoffDateSet
            ? 'Proceeding will delete all items from the cashflow timeline. New items will be generated based on the current state of the project. Are you sure you want to continue?'
            : 'Fill Kickoff Date to generate cashflow items. You can always edit Kickoff Date later to generate new items. You can do that by pressing "Reset & Reallocate" button.'}
        </Styled.Description>
        <Styled.Datepicker>
          <Datepicker
            onChange={setKickoffDate}
            value={kickoffDate}
            color={currentColor}
            fieldLabel="Kickoff Date"
          />
        </Styled.Datepicker>
        <Styled.Buttons>
          {isKickoffDateSet && (
            <Button
              label="Cancel"
              onClick={close}
              color={ColorNames.nandor}
              icon="close"
            />
          )}

          <Button
            label="Confirm"
            onClick={handleConfirm}
            color={currentColor}
            icon="check"
            loading={isProjectUpdateLoading || isPrefillLoading}
          />
        </Styled.Buttons>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(CashflowPrefillItemsModal);
