import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { getProjectsListGroupRoute, ROUTES } from 'utils/routes';

import { useCurrentGroupId } from './useCurrentGroupId';

export const useCurrentPathname = () => {
  const router = useRouter();

  const groupId = useCurrentGroupId();

  const pathname = useMemo(() => {
    const isJobClosureRoute =
      router.route === ROUTES.PROJECTS_LIST_JOB_CLOSURE_PENDING;
    const isJobNumberRoute =
      router.route === ROUTES.PROJECTS_LIST_JOB_NUMBER_PENDING;
    const isGroupRoute = router.route === ROUTES.PROJECTS_LIST_GROUP && groupId;

    if (isJobNumberRoute) return ROUTES.PROJECTS_LIST_JOB_NUMBER_PENDING;
    if (isJobClosureRoute) return ROUTES.PROJECTS_LIST_JOB_CLOSURE_PENDING;
    if (isGroupRoute) return getProjectsListGroupRoute(groupId);
    return ROUTES.PROJECTS_LIST;
  }, [groupId, router.route]);

  return pathname;
};
