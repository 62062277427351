import { Dispatch, FC, SetStateAction, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { AdminListItem } from 'components/modules/Admin/hooks/useAdminListColumns';
import { useResources } from 'queries/resources';
import { useCopyStore } from 'store';
import { Resource, ResourceType } from 'utils/api.types';

import * as Styled from '../DeleteAdminItemModal.styles';

export interface ResourceContentProps {
  item: AdminListItem;
  activeResourceId: Resource['id'] | null;
  setActiveResourceId: Dispatch<SetStateAction<Resource['id'] | null>>;
}

const ResourceContent: FC<ResourceContentProps> = ({
  item,
  activeResourceId,
  setActiveResourceId,
}) => {
  const { data: resources } = useResources();
  const copy = useCopyStore(s => s.copy);
  const resourcesWithoutCurrent = resources?.filter(
    ({ id }) => id !== item.resourceId
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Styled.SubPromptWrapper>
      <Styled.SubPrompt>
        {copy.admin.deleteModal.resourceDuplicatePrompt}
      </Styled.SubPrompt>
      <Combobox
        open={dropdownOpen}
        onOpenChange={setDropdownOpen}
        label={copy.admin.deleteModal.resourceDuplicateDropdown}
      >
        <ComboboxTrigger
          label={
            resourcesWithoutCurrent?.find(({ id }) => id === activeResourceId)
              ?.name
          }
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput />
            <ComboboxEmpty />
            <ComboboxList>
              {resourcesWithoutCurrent?.map(({ name, email, id, type }) => (
                <ComboboxItem
                  key={id}
                  selected={activeResourceId === id}
                  onSelect={() => {
                    setActiveResourceId(activeResourceId === id ? null : id);
                    setDropdownOpen(false);
                  }}
                >
                  <Styled.ComboboxItemLabel>
                    {`${name} (${email})`}
                  </Styled.ComboboxItemLabel>
                  <Styled.ComboboxItemEnd>
                    {
                      copy.app.territoryShort[
                        type === ResourceType.internal
                          ? 'internal'
                          : type === ResourceType.external
                          ? 'external'
                          : 'mfr'
                      ]
                    }
                  </Styled.ComboboxItemEnd>
                </ComboboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.SubPromptWrapper>
  );
};

export default ResourceContent;
