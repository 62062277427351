import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction, useState } from 'react';

import {
  Combobox,
  ComboboxCheckboxItem,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { useVendorServices } from 'queries/vendorServices';
import { useCopyStore } from 'store';
import { VendorService as VendorServiceType } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectsListFiltersModal.styles';

export interface VendorServiceProps {
  vendorServiceIds: VendorServiceType['id'][];
  setVendorServiceIds: Dispatch<SetStateAction<VendorServiceType['id'][]>>;
}

const VendorService: FC<VendorServiceProps> = ({
  setVendorServiceIds,
  vendorServiceIds,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { data: vendorServices } = useVendorServices();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const toggleVendorService = (vendorServiceId: VendorServiceType['id']) => {
    setVendorServiceIds(prev => {
      if (prev.includes(vendorServiceId)) {
        return prev.filter(m => m !== vendorServiceId);
      } else {
        return [...prev, vendorServiceId];
      }
    });
  };

  return (
    <Styled.DropdownWrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={copy.app.projectsListFiltersModal.vendorService}
      >
        <ComboboxTrigger
          label={
            vendorServiceIds.length > 0
              ? vendorServiceIds
                  .map(
                    vendorServiceId =>
                      vendorServices?.find(({ id }) => id === vendorServiceId)
                        ?.name
                  )
                  .join(', ')
              : copy.app.selectPlaceholder
          }
          style={{
            ...(vendorServiceIds.length > 0
              ? {}
              : { color: rgba(colors.white, 0.5) }),
          }}
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList>
              {vendorServices?.map(({ name, id }) => (
                <ComboboxCheckboxItem
                  key={id}
                  checked={vendorServiceIds.includes(id)}
                  onSelect={() => {
                    toggleVendorService(id);
                  }}
                >
                  {name}
                </ComboboxCheckboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.DropdownWrapper>
  );
};

export default memo(VendorService);
