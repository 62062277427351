import { useRouter } from 'next/router';

import {
  ClientDuplicate,
  DuplicateType,
  ResourceDuplicate,
  Role,
} from 'utils/api.types';
import { ROUTES } from 'utils/routes';

import { AdminListItem, CategoryIdentifier } from './useAdminListColumns';

export const useHandleProjectLookup = () => {
  const router = useRouter();

  return {
    handleProjectLookup: ({
      adminItem,
      roleItem,
    }: {
      adminItem?: AdminListItem;
      roleItem?: Role;
    }) => {
      const isResource =
        adminItem?.categoryIdentifier === CategoryIdentifier.Resource;
      const isClient =
        adminItem?.categoryIdentifier === CategoryIdentifier.Client;
      const isBrand =
        adminItem?.categoryIdentifier === CategoryIdentifier.Brand;
      const isVendor =
        adminItem?.categoryIdentifier === CategoryIdentifier.Vendor;
      const isVendorService =
        adminItem?.categoryIdentifier === CategoryIdentifier.VendorService;
      const isRole = !!roleItem;

      router.push({
        pathname: ROUTES.PROJECTS_LIST,
        query: {
          ...(isResource ? { resourceIds: adminItem?.resourceId } : {}),
          ...(isClient ? { clientIds: adminItem?.clientId } : {}),
          ...(isBrand ? { brandIds: adminItem?.brandId } : {}),
          ...(isVendor ? { vendorIds: adminItem?.vendorId } : {}),
          ...(isVendorService
            ? { vendorServiceIds: adminItem?.vendorServiceId }
            : {}),
          ...(isRole ? { roleIds: roleItem.role_id } : {}),
        },
      });
    },
    getDuplicateLookupHref: ({
      type,
      duplicateItem,
    }: {
      type: DuplicateType;
      duplicateItem: ResourceDuplicate | ClientDuplicate;
    }) => {
      const id = duplicateItem.id;

      return {
        pathname: ROUTES.PROJECTS_LIST,
        query: {
          ...(type === 'resource' ? { resourceIds: id } : {}),
          ...(type === 'clients' ? { clientIds: id } : {}),
        },
      };
    },
  };
};
