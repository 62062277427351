import { isArray } from 'lodash';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const useCurrentGroupId = () => {
  const router = useRouter();
  return useMemo(
    () =>
      isArray(router.query?.groupId)
        ? router.query?.groupId[0]
        : router.query?.groupId,
    [router.query?.groupId]
  );
};
