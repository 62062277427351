import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React from 'react';

import * as Styled from './Tooltip.styles';

export type TooltipProps = TooltipPrimitive.TooltipContentProps &
  TooltipPrimitive.TooltipProps & {
    content: React.ReactNode | React.ReactNode[];
    delayDuration?: number;
    enabled?: boolean;
  };

export const Tooltip = ({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  delayDuration,
  enabled = true,
  ...props
}: TooltipProps) => {
  if (enabled === false) return <>{children}</>;
  return (
    <TooltipPrimitive.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      delayDuration={delayDuration}
    >
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <Styled.Content align="center" {...props}>
        {content}
        <TooltipPrimitive.Arrow width={11} height={5} />
      </Styled.Content>
    </TooltipPrimitive.Root>
  );
};
