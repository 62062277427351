import React from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useCopyStore } from 'store';

import * as AdminStyled from '../AdminItemModal.styles';

interface VendorServicePartialProps {
  nameField: ReturnType<typeof useField>;
}

const VendorServicePartial = ({ nameField }: VendorServicePartialProps) => {
  const copy = useCopyStore(s => s.copy);

  const [name, setName, , nameInvalidMessage] = nameField;

  const { vendorServiceLabel } = copy.admin.editModal.vendorService;

  return (
    <>
      <AdminStyled.Row>
        <FormInput
          value={name}
          onChange={setName}
          error={nameInvalidMessage}
          required
          placeholder={vendorServiceLabel}
          dataCy="vendor-service-partial__name"
        />
      </AdminStyled.Row>
    </>
  );
};

export default React.memo(VendorServicePartial);
