import { useState } from 'react';

import { AdminListItem } from 'components/modules/Admin/hooks/useAdminListColumns';
import { useCopyStore } from 'store';
import { ResourceType } from 'utils/api.types';

export const useTypeDropdown = (item?: AdminListItem) => {
  const copy = useCopyStore(s => s.copy);

  const typeItems = [
    { displayName: copy.app.territory.internal, id: ResourceType.internal },
    { displayName: copy.app.territory.external, id: ResourceType.external },
    { displayName: copy.app.territory.mfr, id: ResourceType.mfr },
  ];

  const [typeOpen, setTypeOpen] = useState(false);
  const [activeTypeId, setActiveTypeId] = useState<ResourceType>(
    item?.type || ResourceType.internal
  );

  return {
    typeItems,
    activeTypeId,
    setActiveTypeId,
    typeOpen,
    setTypeOpen,
  };
};
