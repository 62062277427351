export interface Release {
  version: string;
  date: Date;
  changes: {
    major: string[];
    added: string[];
    fixed: string[];
    improved: string[];
  };
}

export const useReleases = () => {
  // Important: Append new releases at the beginning of the array
  const releases: Release[] = [
    {
      version: '0.18.0',
      date: new Date('2024-06-01'),
      changes: {
        major: [
          'Project Cashflow',
          'You will receive a notification if we find Teamdeck Project that relates to one of your projects.',
          'For Teamdeck projects, you will now see if the burn is outdated comparing to Teamdeck data.',
        ],
        added: [
          'New carbon description feature.',
          'Quick filter for Job Number Requests and Job Closure Requests in project navigation.',
          'New configurable User Access Rights.',
          'Option to watch/stop watching projects.',
        ],
        fixed: [
          'Fixed sorting for status column in projects list',
          'Fixed a bug where users from different timezones would see different dates in invoice list popup',
          'Fixed a bug where datepicker in history log would not show properly',
          'Fixed a bug where uploading JAS sheet would not work for Hosting projects',
          'Fixed a bug where 404 pages would not show properly',
        ],
        improved: [
          'Added automatic redirect from maintenance page when maintenance is over',
          'Updated MFR Net header',
          "Changed 'Production Versions' to 'Change Orders', Change Orders can be merged to main budget",
          "Made 'Client' field mandatory before user is able to request job number",
        ],
      },
    },
    {
      version: '0.17.0',
      date: new Date('2024-04-01'),
      changes: {
        major: ['Job Closure Requesting Flow'],
        added: [
          "A new 'Payments' view available for Finance Team",
          "A new tab 'Payments' in project invoice window. It shows all payments related to the project",
          'Possibility to filter project rows',
          'Slackbot integration support',
          'Customization of MFR Report and Client Facing Budget Report',
          'Teamdeck Report',
          'New field in project invoice window - Invoice Number',
        ],
        fixed: [
          'Fixed a bug where burn summary was not calculated properly',
          'Fixed a bug where invoice date could not be adjusted in budget table',
          'Fixed a bug where some email notifications would incorrectly be tied to the same email thread',
        ],
        improved: [
          "Allow to input both 'dot' and 'comma' as decimal separator",
          'Improved error handling',
        ],
      },
    },
  ];

  return releases;
};
