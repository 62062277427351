import { FC, memo } from 'react';

import {
  AdminListItem,
  CategoryIdentifier,
} from 'components/modules/Admin/hooks/useAdminListColumns';
import { useUpdateBrand } from 'queries/brands';
import { useUpdateClient } from 'queries/clients';
import { useUpdateResource } from 'queries/resources';
import { useUpdateRole } from 'queries/roles';
import { useUpdateVendor } from 'queries/vendors';
import { useUpdateVendorService } from 'queries/vendorServices';
import { useCopyStore, useGlobalStore } from 'store';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface AdminItemUnusedPostponeModalProps {
  item: AdminListItem;
}

const AdminItemUnusedPostponeModal: FC<AdminItemUnusedPostponeModalProps> = ({
  item,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const { mutateAsync: updateResource, isLoading: isUpdateResourceLoading } =
    useUpdateResource();
  const { mutateAsync: updateVendor, isLoading: isUpdateVendorLoading } =
    useUpdateVendor();
  const { mutateAsync: updateClient, isLoading: isUpdateClientLoading } =
    useUpdateClient();
  const { mutateAsync: updateBrand, isLoading: isUpdateBrandLoading } =
    useUpdateBrand();
  const {
    mutateAsync: updateVendorService,
    isLoading: isUpdateVendorServiceLoading,
  } = useUpdateVendorService();
  const { mutateAsync: updateRole, isLoading: isUpdateRoleLoading } =
    useUpdateRole();

  const isLoading =
    isUpdateResourceLoading ||
    isUpdateVendorLoading ||
    isUpdateClientLoading ||
    isUpdateBrandLoading ||
    isUpdateVendorServiceLoading ||
    isUpdateRoleLoading;

  const handleDecline = () => {
    closeModal('adminItemUnusedPostpone');
  };

  const handleConfirm = async () => {
    if (
      item.categoryIdentifier === CategoryIdentifier.Resource &&
      item.resourceId
    ) {
      await updateResource({
        id: item.resourceId,
        unused: false,
      });
    }
    if (
      item.categoryIdentifier === CategoryIdentifier.Vendor &&
      item.vendorId
    ) {
      await updateVendor({
        id: item.vendorId,
        unused: false,
      });
    }
    if (
      item.categoryIdentifier === CategoryIdentifier.Client &&
      item.clientId
    ) {
      await updateClient({
        id: item.clientId,
        unused: false,
      });
    }
    if (item.categoryIdentifier === CategoryIdentifier.Brand && item.brandId) {
      await updateBrand({
        id: item.brandId,
        unused: false,
      });
    }
    if (
      item.categoryIdentifier === CategoryIdentifier.VendorService &&
      item.vendorServiceId
    ) {
      await updateVendorService({
        id: item.vendorServiceId,
        unused: false,
      });
    }
    if (item.categoryIdentifier === CategoryIdentifier.Role && item.rowNo) {
      await updateRole({
        pos: item.rowNo,
        unused: false,
      });
    }

    closeModal('adminItemUnusedPostpone');
  };

  return (
    <ConfirmModal
      title={copy.admin.adminItemUnusedPostpone.title}
      description={replaceString(
        copy.admin.adminItemUnusedPostpone.description,
        '{name}',
        item.name
      )}
      cancelButtonProps={{
        label: copy.admin.adminItemUnusedPostpone.declineButton,
        color: ColorNames.nandor,
        onClick: handleDecline,
        icon: 'close',
      }}
      confirmButtonProps={{
        label: copy.admin.adminItemUnusedPostpone.confirmButton,
        color: currentColor,
        onClick: handleConfirm,
        icon: 'check',
        disabled: isLoading,
        loading: isLoading,
      }}
    />
  );
};

export default memo(AdminItemUnusedPostponeModal);
