import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { focusVisible, hover, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Root = styled(ToggleGroup.Root)`
  display: flex;
  gap: 30rem;
`;

export type ToggleItemTheme = 'light' | 'dark';

const itemColors: Record<
  ToggleItemTheme,
  {
    background: string;
    color: string;
    hover: string;
    active: string;
    border: string;
  }
> = {
  light: {
    background: colors.concrete,
    color: colors.nandor,
    hover: rgba(colors.outerSpace, 0.2),
    active: colors.alto,
    border: colors.transparent,
  },
  dark: {
    background: colors.outerSpace,
    color: colors.white,
    hover: rgba(colors.black, 0.2),
    active: colors.black,
    border: colors.doveGray,
  },
};

export const Item = styled(ToggleGroup.Item)<{
  $colorScheme?: ToggleItemTheme;
}>`
  ${({ $colorScheme = 'light' }) => css`
    height: 100%;
    padding: 16rem 48rem;
    text-align: center;
    ${({ theme: { locale } }) => setTypography('body4', locale)}
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30rem;
    transition:
      background 300ms,
      box-shadow 300ms;
    border: 1px solid ${itemColors[$colorScheme].border};
    white-space: nowrap;

    background: ${itemColors[$colorScheme].background};
    color: ${itemColors[$colorScheme].color};
    user-select: none;

    ${hover(css`
      background: ${itemColors[$colorScheme].hover};
    `)}

    &:focus-visible {
      ${focusVisible};
    }

    &[data-state='on'] {
      background: ${itemColors[$colorScheme].active};
    }
  `}
`;
