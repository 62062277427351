import { Dispatch, memo, SetStateAction, useMemo, useState } from 'react';

import {
  Combobox,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { BrandRequestModalProps } from 'components/modals/BrandRequestModal/BrandRequestModal';
import { useBrands } from 'queries/brands';
import { useOrganization } from 'queries/organizations';
import { useCopyStore, useGlobalStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import { Brand as BrandType, Project, ProjectStatus } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { sort, sortString } from 'utils/sort';

import DropdownWarning from '../../DropdownWarning/DropdownWarning';
import { useProject } from '../../hooks/useProject';

import * as Styled from '../../ProjectModal.styles';

export interface BrandProps {
  projectId?: Project['id'];
  canEditProject: boolean;
  activeBrandId?: BrandType['id'];
  setActiveBrandId: Dispatch<SetStateAction<BrandType['id'] | undefined>>;
}

export const Brand = ({
  projectId,
  activeBrandId,
  canEditProject,
  setActiveBrandId,
}: BrandProps) => {
  const openModal = useGlobalStore(s => s.openModal);
  const { project } = useProject(projectId);
  const copy = useCopyStore(s => s.copy);
  const locale = useCopyStore(s => s.locale);
  const [searchValue, setSearchValue] = useState('');
  const { data: organization } = useOrganization();
  const { data: brands } = useBrands({
    ...(!organization?.entity_config?.brands?.allow_unverified && {
      verified: true,
    }),
  });
  const { checkAnyAccessRight } = useCheckAccessRights();

  const items = useMemo(
    () =>
      sort(
        brands?.map(({ name, id }) => ({
          id,
          displayName: name,
        })) || [],
        'displayName',
        sortString,
        'asc',
        locale
      ),
    [brands, locale]
  );

  const displayClosedWarning =
    project?.status !== ProjectStatus.closed &&
    project?.brand?.closed &&
    activeBrandId === project?.brand?.id;

  const modalCopy = copy.app.projectModal;

  const [open, setOpen] = useState(false);

  const canUseFreshBrandBySettings =
    organization?.entity_config?.brands?.allow_unverified;
  const canUseFreshBrand =
    checkAnyAccessRight(['administrator', 'super admin', 'owner']) ||
    canUseFreshBrandBySettings;

  const handleBrandCreated = (id: BrandType['id']) => {
    if (canUseFreshBrand) {
      setActiveBrandId(id);
    }
  };

  return (
    <Styled.FormDropdownWrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={
          <Styled.FormDropdownLabelWrapper>
            {modalCopy.brand}
            {displayClosedWarning && (
              <DropdownWarning
                title={modalCopy.brandClosedWarningTitle}
                text={modalCopy.brandClosedWarningDescription}
                {...(project.brand?.verification_status?.message
                  ? {
                      subText: replaceString(
                        modalCopy.brandClosedWarningMessage,
                        '{comment}',
                        project.brand.verification_status.message
                      ),
                    }
                  : {})}
              />
            )}
          </Styled.FormDropdownLabelWrapper>
        }
      >
        <ComboboxTrigger
          disabled={!canEditProject}
          label={
            items.find(({ id }) => id === activeBrandId)?.displayName ??
            project?.brand?.name
          }
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList>
              <ComboboxItem
                value={searchValue}
                onSelect={() => {
                  openModal<BrandRequestModalProps>('brandRequest', {
                    brandName: searchValue,
                    onBrandCreated: handleBrandCreated,
                  });
                  setOpen(false);
                }}
              >
                {searchValue.length > 0
                  ? replaceString(
                      canUseFreshBrand
                        ? copy.app.comboboxCreateButton
                        : copy.app.comboboxSuggestButton,
                      '{name}',
                      searchValue
                    )
                  : canUseFreshBrand
                  ? copy.app.comboboxCreateButtonEmpty
                  : copy.app.comboboxSuggestButtonEmpty}
              </ComboboxItem>
              {items.map(({ displayName, id }) => (
                <ComboboxItem
                  key={id}
                  selected={activeBrandId === id}
                  onSelect={() => {
                    setActiveBrandId(id);
                    setOpen(false);
                  }}
                >
                  {displayName}
                </ComboboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.FormDropdownWrapper>
  );
};

export default memo(Brand);
