import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { ColorNames, colors, layout } from 'utils/styles/theme';

import { BadgeVariantsType } from './Badge.types';

interface WrapperProps {
  variant: BadgeVariantsType;
  color: ColorNames;
  textColor: ColorNames;
  opacity: number;
  size: 'small' | 'medium';
}

export const Wrapper = styled.span`
  ${({ variant, color, textColor, opacity, size }: WrapperProps) => css`
    display: inline-flex;
    ${({ theme: { locale } }) =>
      setTypography(size === 'medium' ? 'subtitle1' : 'caption', locale)}
    border-radius: ${layout.borderRadius.normal}rem;
    color: ${colors[textColor]};
    padding: ${size === 'small' ? '3rem 12rem' : '6rem 24rem'};
    border: 1px solid transparent;
    user-select: none;
    max-width: 100%;

    ${variant === 'contained' &&
    css`
      background: ${rgba(colors[color], opacity)};
    `}

    ${variant === 'outlined' &&
    css`
      border: 1px solid ${rgba(colors[color], opacity)};
    `}
  `}
`;
