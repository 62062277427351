import { useMemo } from 'react';

import { useCopyStore } from 'store';
import { ProjectMode, ProjectStatus } from 'utils/api.types';

export const useProjectDisplayText = () => {
  const copy = useCopyStore(s => s.copy);

  const projectDisplayText = useMemo(
    () => ({
      status: {
        [ProjectStatus.active]: copy.app.statuses.active,
        [ProjectStatus.cancelled]: copy.app.statuses.cancelled,
        [ProjectStatus.closed]: copy.app.statuses.closed,
      },
      mode: {
        [ProjectMode.pitch]: copy.app.modes.pitch,
        [ProjectMode.production]: copy.app.modes.production,
        [ProjectMode.hosting]: copy.app.modes.hosting,
      },
    }),
    [copy]
  );

  const getStatusDisplayText = (
    mode: ProjectMode,
    status?: ProjectStatus,
    options?: {
      job_closure_requested?: boolean;
      job_number_requested?: boolean;
    }
  ) => {
    if (options?.job_closure_requested) {
      return `${projectDisplayText.mode[mode]} (${copy.app.job_closure_requested})`;
    }
    if (options?.job_number_requested) {
      return `${projectDisplayText.mode[mode]} (${copy.app.job_number_requested})`;
    }
    if (!status || status === ProjectStatus.active) {
      return projectDisplayText.mode[mode];
    }
    return `${projectDisplayText.status[status]} (${projectDisplayText.mode[mode]})`;
  };

  return getStatusDisplayText;
};

export default useProjectDisplayText;
