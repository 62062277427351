import { useRouter } from 'next/router';
import { FC, memo } from 'react';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useCurrentProject, useMergeProjectVersion } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { getProjectRoute } from 'utils/routes';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface MergeChangeOrderModalProps {}

const MergeChangeOrderModal: FC<MergeChangeOrderModalProps> = () => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentProject = useCurrentProject();
  const currentVersionId = useCurrentVersionId();
  const { mutateAsync: mergeProjectVersion, isLoading } =
    useMergeProjectVersion();
  const router = useRouter();
  const copy = useCopyStore(s => s.copy);

  const close = () => {
    closeModal('mergeChangeOrder');
  };

  const handleMerge = async () => {
    if (currentProject && currentVersionId) {
      await mergeProjectVersion({
        projectId: currentProject.id,
        sourceId: currentVersionId,
      });
      close();
      router.push(
        getProjectRoute(currentProject.id, currentProject.main_version_id)
      );
    }
  };

  return (
    <ConfirmModal
      title={copy.app.mergeChangeOrderModal.title}
      description={copy.app.mergeChangeOrderModal.description}
      cancelButtonProps={{
        label: copy.app.mergeChangeOrderModal.cancelButton,
        color: ColorNames.nandor,
        icon: 'close',
        onClick: close,
      }}
      confirmButtonProps={{
        label: copy.app.mergeChangeOrderModal.confirmButton,
        color: currentColor,
        icon: 'check',
        onClick: handleMerge,
        loading: isLoading,
      }}
    />
  );
};

export default memo(MergeChangeOrderModal);
