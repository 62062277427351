import { useRouter } from 'next/router';

import { useCurrentProject } from 'queries/project';
import { useProjectGroups } from 'queries/projectGroups';
import { useCopyStore } from 'store';
import {
  getProjectRoute,
  getProjectsListGroupRoute,
  ROUTES,
} from 'utils/routes';

export const useBreadcrumbs = () => {
  const router = useRouter();
  const copy = useCopyStore(s => s.copy);
  const currentProject = useCurrentProject();
  const { data: projectGroups } = useProjectGroups();

  const pathWithoutQuery = router.asPath.split('?')[0];
  const pathParts = pathWithoutQuery.split('/').filter(Boolean);

  const getBreadcrumbHref = (index: number) => {
    return '/' + [...pathParts].splice(0, index + 1).join('/');
  };

  const getStaticLabel = (route: string) => {
    return copy.app.routes[route];
  };

  const getBreadcrumbs = (pathParts: string[]) => {
    return pathParts
      .map((part, index) => ({
        label: getStaticLabel(part),
        href: getBreadcrumbHref(index),
      }))
      .filter(Boolean);
  };

  const getProjectBreadcrumbs = () => {
    const projectGroup = projectGroups?.find(({ projects }) =>
      projects.some(id => id === currentProject?.id)
    );
    const projectHasGroup = !!projectGroup;
    return [
      {
        href: ROUTES.PROJECTS_LIST,
        label: getStaticLabel('projects'),
      },
      ...(projectHasGroup
        ? [
            {
              href: getProjectsListGroupRoute(projectGroup?.id),
              label: projectGroup?.name,
            },
          ]
        : []),
      ...(currentProject?.id && currentProject?.main_version_id
        ? [
            {
              href: getProjectRoute(
                currentProject?.id,
                currentProject?.main_version_id
              ),
              label: currentProject?.name,
            },
          ]
        : []),
    ];
  };

  const isProjectPage = router.route === ROUTES.PROJECT;
  const breadcrumbs = isProjectPage
    ? getProjectBreadcrumbs()
    : getBreadcrumbs(pathParts);

  return {
    breadcrumbs,
  };
};
