import * as Tooltip from '@radix-ui/react-tooltip';
import styled from 'styled-components';

import { setTypography, slideDownAnimation } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Content = styled(Tooltip.Content)`
  background: ${colors.black};
  color: ${colors.white};
  padding: 20rem 30rem;
  border-radius: 20rem;
  ${({ theme: { locale } }) => setTypography('body5', locale)};
  z-index: 1;

  ${slideDownAnimation};
`;
