import { rgba } from 'polished';
import { Dispatch, FC, memo, SetStateAction, useState } from 'react';

import {
  Combobox,
  ComboboxCheckboxItem,
  ComboboxCommandRoot,
  ComboboxContentRoot,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxList,
  ComboboxTrigger,
} from 'components/Combobox/Combobox';
import { useClients } from 'queries/clients';
import { useCopyStore } from 'store';
import { Client as ClientType } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import * as Styled from '../ProjectsListFiltersModal.styles';

export interface ClientProps {
  clientIds: ClientType['id'][];
  setClientIds: Dispatch<SetStateAction<ClientType['id'][]>>;
}

const Client: FC<ClientProps> = ({ setClientIds, clientIds }) => {
  const copy = useCopyStore(s => s.copy);
  const { data: clients } = useClients();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const toggleClient = (clientId: ClientType['id']) => {
    setClientIds(prev => {
      if (prev.includes(clientId)) {
        return prev.filter(m => m !== clientId);
      } else {
        return [...prev, clientId];
      }
    });
  };

  return (
    <Styled.DropdownWrapper>
      <Combobox
        open={open}
        onOpenChange={setOpen}
        label={copy.app.projectsListFiltersModal.client}
      >
        <ComboboxTrigger
          label={
            clientIds.length > 0
              ? clientIds
                  .map(
                    clientId => clients?.find(({ id }) => id === clientId)?.name
                  )
                  .join(', ')
              : copy.app.selectPlaceholder
          }
          style={{
            ...(clientIds.length > 0 ? {} : { color: rgba(colors.white, 0.5) }),
          }}
        />
        <ComboboxContentRoot>
          <ComboboxCommandRoot>
            <ComboboxInput value={searchValue} onValueChange={setSearchValue} />
            <ComboboxEmpty />
            <ComboboxList>
              {clients?.map(({ name, id }) => (
                <ComboboxCheckboxItem
                  key={id}
                  checked={clientIds.includes(id)}
                  onSelect={() => {
                    toggleClient(id);
                  }}
                >
                  {name}
                </ComboboxCheckboxItem>
              ))}
            </ComboboxList>
          </ComboboxCommandRoot>
        </ComboboxContentRoot>
      </Combobox>
    </Styled.DropdownWrapper>
  );
};

export default memo(Client);
