import styled from 'styled-components';

import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  height: 3px;
  width: 100vw;
  --c: no-repeat linear-gradient(${colors.blueRibbon} 0 0);
  background: var(--c), var(--c), ${colors.concrete};
  background-size: 60% 100%;
  animation: l16 3s infinite;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  @keyframes l16 {
    0% {
      background-position:
        -150% 0,
        -150% 0;
    }
    66% {
      background-position:
        250% 0,
        -150% 0;
    }
    100% {
      background-position:
        250% 0,
        250% 0;
    }
  }
`;
