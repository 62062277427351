import { Command as CommandPrimitive } from 'cmdk';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { focusVisible, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div``;

export const CommandInputWrapper = styled.div`
  color: ${colors.white};
  display: flex;
  gap: 20rem;
  width: 100%;
  align-items: center;
  padding-left: 20rem;
  border-bottom: 1px solid ${rgba(colors.white, 0.5)};
  margin-bottom: 5rem;
`;

export const SearchIconWrapper = styled.div`
  width: 20rem;
  height: 20rem;
  color: ${rgba(colors.white, 0.5)};
`;

export const CommandInput = styled(CommandPrimitive.Input)`
  padding: 20rem 0;
  width: 100%;
`;

export const CommandGroup = styled(CommandPrimitive.Group)`
  [cmdk-group-heading] {
    color: ${rgba(colors.white, 0.5)};
    padding: 18rem 40rem;
    ${({ theme: { locale } }) => setTypography('caption', locale)};
  }
`;

export const Item = styled(CommandPrimitive.Item)<
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & {
    selected?: boolean;
  }
>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18rem 40rem;
  color: ${colors.white};
  cursor: pointer;
  ${({ theme: { locale } }) => setTypography('body5', locale)};

  ${({ selected }) =>
    selected
      ? css`
          background: var(--current-color);
        `
      : ''}

  &[data-selected='true'] {
    color: ${rgba(colors.white, 0.8)};
  }

  &[data-disabled] {
    color: ${rgba(colors.white, 0.7)};
  }

  &:focus-visible {
    ${focusVisible};
  }

  & > span:first-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align: start;
  }
`;

export const IndicatorWrapper = styled.div`
  width: 18rem;
  height: 18rem;
  margin-left: 10rem;
`;

const label = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18rem 40rem;
  color: ${colors.white};
  cursor: pointer;
  ${({ theme: { locale } }) => setTypography('body5', locale)};
  color: ${rgba(colors.white, 0.7)};
`;

export const Label = styled.div`
  ${label}
`;

export const EmptyLabel = styled.div`
  ${label};
`;

export const LoadingLabel = styled.div`
  ${label};
`;

export const List = styled(CommandPrimitive.List)``;

export const CheckIconWrapper = styled.div`
  width: 34rem;
  height: 34rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.white};
  border-radius: 10rem;
  padding: 5rem;
`;
