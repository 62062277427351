import styled from 'styled-components';

import { fixedPageButton } from 'utils/styles/mixins';

export const Wrapper = styled.div``;

export const AddButton = styled.div`
  ${fixedPageButton()};
`;

export const ToolbarWrapper = styled.div`
  position: absolute;
  right: 25rem;
`;

export const MfrNameWrapper = styled.div`
  width: 300rem;
  overflow: hidden;
`;

export const NameWrapper = styled.div`
  display: flex;
`;
