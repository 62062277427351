import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useProjectAccess } from 'hooks/useProjectAccess';
import { useCurrentProject } from 'queries/project';
import { useGlobalStore } from 'store';
import { ProjectMode } from 'utils/api.types';
import { ROUTES } from 'utils/routes';
import { ColorNames, colors } from 'utils/styles/theme';

const useModuleTheme = () => {
  const router = useRouter();
  const currentProject = useCurrentProject();
  const setCurrentColor = useGlobalStore(s => s.setCurrentColor);
  const currentVersionId = useCurrentVersionId();

  const { getCanEditProject } = useProjectAccess();

  const currentColor = useMemo(() => {
    const isProjectModule = router.route === ROUTES.PROJECT;

    if (isProjectModule) {
      if (!getCanEditProject(currentProject)) {
        return ColorNames.dustyGray;
      }
      const modeColor = (() => {
        if (currentProject?.mode === ProjectMode.pitch)
          return ColorNames.electricViolet;
        if (currentProject?.mode === ProjectMode.production) {
          const isMainVersion =
            currentVersionId === currentProject.main_version_id;
          if (isMainVersion) return ColorNames.mountainMeadow;
          return ColorNames.electricViolet;
        }

        if (currentProject?.mode === ProjectMode.hosting)
          return ColorNames.mountainMeadow;

        return ColorNames.blueRibbon;
      })();

      return modeColor;
    }

    return ColorNames.blueRibbon;
  }, [currentProject, currentVersionId, getCanEditProject, router.route]);

  useEffect(() => {
    setCurrentColor(currentColor);

    document.documentElement.style.setProperty(
      '--current-color',
      colors[currentColor]
    );
  }, [currentColor, setCurrentColor]);
};

export default useModuleTheme;
