import { FC, memo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useProjectAccess } from 'hooks/useProjectAccess';
import { useCopyStore } from 'store';
import { Project } from 'utils/api.types';

import { useCarbonDescriptionField } from '../../hooks/useCarbonDescriptionField';

export interface CarbonDescriptionProps {
  carbonDescriptionField: ReturnType<typeof useCarbonDescriptionField>;
  project?: Project;
}

const CarbonDescription: FC<CarbonDescriptionProps> = ({
  carbonDescriptionField,
  project,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { carbonDescription, setCarbonDescription } = carbonDescriptionField;

  const { getCanEditProject } = useProjectAccess();
  const canEditProject = getCanEditProject(project) || !project?.id;

  return (
    <FormInput
      onChange={setCarbonDescription}
      value={carbonDescription}
      placeholder={copy.app.projectModal.carbonDescriptionLabel}
      disabled={!canEditProject}
    />
  );
};

export default memo(CarbonDescription);
