import styled, { css } from 'styled-components';

import { focusVisible, hover } from 'utils/styles/mixins';

export const variants = ['round', 'square'] as const;

const borderRadius: Record<(typeof variants)[number], string> = {
  round: '50%',
  square: '6rem',
};

export const OuterWrapper = styled.button<{
  disabled: boolean;
}>`
  display: flex;
  cursor: pointer;
  align-items: center;

  color: var(--checkbox-secondary-color);

  &:focus-visible {
    ${focusVisible};
  }

  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          opacity: 0.4;
        `
      : ''}
`;

export const Wrapper = styled.div<{
  border: boolean;
  variant: (typeof variants)[number];
}>`
  ${({ border, variant }) => css`
    min-width: 33rem;
    min-height: 33rem;
    width: 33rem;
    height: 33rem;
    border-radius: ${borderRadius[variant]};

    ${border
      ? css`
          border: 1px solid var(--checkbox-secondary-color);
        `
      : ''}

    display: flex;
    justify-content: center;
    align-items: center;
    ${hover(css`
      border-color: var(--checkbox-main-color);
      color: var(--checkbox-main-color);
    `)}

    transition: border-color 0.1s ease-in, color 0.1s ease-in;
  `}
`;

export const IconWrapper = styled.div`
  width: 22rem;
  height: 22rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    overflow: visible;
  }
`;

export const LabelWrapper = styled.div`
  margin-left: 20rem;
  color: inherit;
  user-select: none;
`;
