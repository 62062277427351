import { useSession } from 'next-auth/react';
import { useCallback, useEffect, useRef } from 'react';

import { useReleases } from 'components/modals/WhatsNewModal/hooks/useReleases';
import { useGlobalStore } from 'store';
import { LocalStorage, LocalStorageKeys } from 'utils/localStorage';

export const useWhatsNewModal = () => {
  const openModal = useGlobalStore(s => s.openModal);
  const localStorage = useRef(new LocalStorage());
  const { data: session } = useSession();

  const releases = useReleases();

  const runWhatsNew = useCallback(() => {
    if (process.env.ENV === 'development') return;
    if (!session) return;
    setTimeout(() => {
      const isIntroRunning = document.querySelector('.introjs-tooltip');
      if (isIntroRunning) {
        return;
      }
      const lastRelease = releases[0];
      openModal('whatsNew');
      localStorage.current.set(
        LocalStorageKeys.WHATS_NEW_LAST_VERSION_SEEN,
        lastRelease.version
      );
    }, 1000);
  }, [openModal, releases, session]);

  useEffect(() => {
    const lastVersionSeen = localStorage.current.get(
      LocalStorageKeys.WHATS_NEW_LAST_VERSION_SEEN
    );
    if (lastVersionSeen) {
      const [major, minor, patch] = lastVersionSeen.split('.').map(Number);
      const lastRelease = releases[0];
      const [majorRelease, minorRelease, patchRelease] = lastRelease.version
        .split('.')
        .map(Number);

      const isNewerMajor = majorRelease > major;
      const isNewerMinor = minorRelease > minor;
      const isNewerPatch = patchRelease > patch;

      if (isNewerMajor || isNewerMinor || isNewerPatch) {
        runWhatsNew();
      }
    } else {
      // If lastVersionSeen is not set, we assume it's new user or new machine. In this case we don't show the modal.
      const lastRelease = releases[0];
      localStorage.current.set(
        LocalStorageKeys.WHATS_NEW_LAST_VERSION_SEEN,
        lastRelease.version
      );
    }
  }, [releases, runWhatsNew]);
};
