import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useApiStore } from 'store';
import { CashflowItem, Project, ProjectVersion } from 'utils/api.types';

import { useCurrentProject } from './project';

export const getCashflowKey = ({
  projectId,
  projectVersionId,
}: {
  projectId?: Project['id'];
  projectVersionId?: ProjectVersion['id'];
}) => {
  return ['CASHFLOW', projectId, projectVersionId];
};

export const useCurrentCashflow = () => {
  const currentProject = useCurrentProject();
  const currentVersionId = useCurrentVersionId();
  return useCashflow({
    projectId: currentProject?.id,
    projectVersionId: currentVersionId,
  });
};

export const useCashflow = (
  variables: Partial<Parameters<typeof getCashflow>[0]>,
  options?: UseQueryOptions<CashflowItem[]>
) => {
  const getCashflow = useApiStore(s => s.apiClient.getCashflow);

  return useQuery({
    queryKey: getCashflowKey({
      projectId: variables.projectId,
      projectVersionId: variables.projectVersionId,
    }),
    queryFn: async () => {
      if (variables?.projectId === undefined) {
        return Promise.reject(new Error('projectId is undefined'));
      }
      if (variables?.projectVersionId === undefined) {
        return Promise.reject(new Error('projectVersionId is undefined'));
      }
      return (
        await getCashflow({
          projectId: variables.projectId,
          projectVersionId: variables.projectVersionId,
        })
      ).data;
    },
    ...options,
  });
};

export const useAddCashflowItem = () => {
  const addCashflowItem = useApiStore(s => s.apiClient.addCashflowItem);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addCashflowItem>[0]) =>
      addCashflowItem(variables),
    {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries(
          getCashflowKey({
            projectId: variables.projectId,
            projectVersionId: variables.projectVersionId,
          })
        );
      },
    }
  );
};

export const useUpdateCashflowItem = () => {
  const updateCashflowItem = useApiStore(s => s.apiClient.updateCashflowItem);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateCashflowItem>[0]) =>
      updateCashflowItem(variables),
    {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries(
          getCashflowKey({
            projectId: variables.projectId,
            projectVersionId: variables.projectVersionId,
          })
        );
      },
    }
  );
};

export const useDeleteCashflowItem = () => {
  const deleteCashflowItem = useApiStore(s => s.apiClient.deleteCashflowItem);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteCashflowItem>[0]) =>
      deleteCashflowItem(variables),
    {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries(
          getCashflowKey({
            projectId: variables.projectId,
            projectVersionId: variables.projectVersionId,
          })
        );
      },
    }
  );
};

export const usePrefillCashflowItems = () => {
  const prefillCashflowItems = useApiStore(
    s => s.apiClient.prefillCashflowItems
  );
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof prefillCashflowItems>[0]) =>
      prefillCashflowItems(variables),
    {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries(
          getCashflowKey({
            projectId: variables.projectId,
            projectVersionId: variables.projectVersionId,
          })
        );
      },
    }
  );
};
