import { FC, memo, useEffect, useMemo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import Datepicker from 'components/datepickers/Datepicker/Datepicker';
import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import FormInput from 'components/inputs/FormInput/FormInput';
import {
  ToggleGroup,
  ToggleGroupItem,
} from 'components/ToggleGroup/ToggleGroup';
import { useOrganization } from 'queries/organizations';
import { useCurrentProject } from 'queries/project';
import {
  useCurrentProjectVersion,
  useUpdateProjectRows,
} from 'queries/projectRows';
import { useCopyStore, useGlobalStore } from 'store';
import { getDateString } from 'utils/formatters';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './CashflowExpenseTransaction.styles';

export interface CashflowExpenseTransactionProps {
  rowId: number;
  noToggle?: boolean;
}

const EXPENSE_TRANSACTION_MODE_RECURRING = 'recurring';
const EXPENSE_TRANSACTION_MODE_CUSTOM = 'custom';

const recurringOptions = {
  EVERY_MONTH: 'every_month',
  EVERY_WEEK: 'every_week',
  DAYS_AFTER_EVENT: 'days_after_event',
  DAYS_BEFORE_EVENT: 'days_before_event',
} as const;

type RecurringOptionType =
  (typeof recurringOptions)[keyof typeof recurringOptions];

const isRecurringOptionType = (value: string): value is RecurringOptionType =>
  Object.values(recurringOptions).includes(value as any);

const CashflowExpenseTransaction: FC<CashflowExpenseTransactionProps> = ({
  rowId,
  noToggle = false,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  // const locale = useCopyStore(s => s.locale);
  const copy = useCopyStore(s => s.copy);
  const { data: currentProjectVersion } = useCurrentProjectVersion();
  const projectRow = useMemo(
    () => currentProjectVersion?.rows.find(({ id }) => id === rowId),
    [currentProjectVersion?.rows, rowId]
  );
  const { data: organization } = useOrganization();
  const [expenseTransactionMode, setExpenseTransactionMode] = useState<
    | typeof EXPENSE_TRANSACTION_MODE_RECURRING
    | typeof EXPENSE_TRANSACTION_MODE_CUSTOM
  >(
    projectRow?.expense_rule?.rule === 'on_custom_dates'
      ? EXPENSE_TRANSACTION_MODE_CUSTOM
      : EXPENSE_TRANSACTION_MODE_RECURRING
  );
  const [recurringOption, setRecurringOption] = useState<RecurringOptionType>(
    projectRow?.expense_rule?.rule &&
      isRecurringOptionType(projectRow.expense_rule.rule)
      ? projectRow.expense_rule.rule
      : recurringOptions.EVERY_MONTH
  );
  const currentProject = useCurrentProject();
  const { data: projectVersion } = useCurrentProjectVersion();
  const { mutateAsync: updateProjectRows, isLoading: isUpdating } =
    useUpdateProjectRows();
  const [monthDay, setMonthDay] = useState(
    projectRow?.expense_rule?.rule === recurringOptions.EVERY_MONTH
      ? projectRow.expense_rule.value
      : 1
  );
  // const [weekDay, setWeekDay] = useState(
  //   projectRow?.expense_rule?.rule === recurringOptions.EVERY_WEEK
  //     ? projectRow.expense_rule.value
  //     : 1
  // );
  const [daysAfterEvent, setDaysAfterEvent] = useState(
    projectRow?.expense_rule?.rule === recurringOptions.DAYS_AFTER_EVENT
      ? projectRow.expense_rule.value
      : 1
  );
  const [daysBeforeEvent, setDaysBeforeEvent] = useState(
    projectRow?.expense_rule?.rule === recurringOptions.DAYS_BEFORE_EVENT
      ? projectRow.expense_rule.value
      : 1
  );
  const [customOptions, setCustomOptions] = useState<
    {
      amount: string | number;
      date: string;
    }[]
  >(
    projectRow?.expense_rule?.rule === 'on_custom_dates'
      ? projectRow.expense_rule.value
      : []
  );

  useEffect(() => {
    if (projectRow?.expense_rule?.rule === 'on_custom_dates') {
      setCustomOptions(projectRow.expense_rule.value);
    }
  }, [projectRow]);

  const close = () => {
    closeModal('cashflowExpenseTransaction');
  };

  const handleSave = async () => {
    if (projectVersion && currentProject) {
      if (expenseTransactionMode === EXPENSE_TRANSACTION_MODE_CUSTOM) {
        await updateProjectRows({
          projectId: currentProject.id,
          versionId: projectVersion.id,
          projectRows: [
            {
              id: rowId,
              expense_rule: {
                sticky: true,
                rule: 'on_custom_dates',
                value: customOptions.map(({ amount, date }) => ({
                  amount: Number(amount),
                  date,
                })),
              },
            },
          ],
        });
        close();
      } else {
        const value = (() => {
          if (recurringOption === recurringOptions.EVERY_MONTH) {
            return monthDay;
          }
          // if (recurringOption === recurringOptions.EVERY_WEEK) {
          //   return weekDay;
          // }
          if (recurringOption === recurringOptions.DAYS_AFTER_EVENT) {
            return daysAfterEvent;
          }
          if (recurringOption === recurringOptions.DAYS_BEFORE_EVENT) {
            return daysBeforeEvent;
          }
          return 1;
        })();
        await updateProjectRows({
          projectId: currentProject.id,
          versionId: projectVersion.id,
          projectRows: [
            {
              id: rowId,
              expense_rule: {
                sticky: true,
                rule: recurringOption,
                value,
              },
            },
          ],
        });
        close();
      }
    }
  };

  const handleAddExpenseTransaction = () => {
    setCustomOptions([
      ...customOptions,
      {
        amount: '',
        date: getDateString(new Date()),
      },
    ]);
  };

  if (!organization) return null;

  return (
    <ConfigModal
      title="Expense Transactions Configuration"
      onCloseClick={close}
      color={currentColor}
      buttonProps={{
        label: 'Save',
        color: currentColor,
        icon: 'check',
        onClick: handleSave,
        disabled: isUpdating,
        loading: isUpdating,
      }}
    >
      <Styled.Wrapper>
        {!noToggle && (
          <>
            <Styled.Description>
              Here you can configure the expense transactions for this cashflow.
              Rules based on Resource Allocation will automatically generate
              transactions for resources based on allocated work. Use Custom
              Payment Schedule for more advanced configurations.
            </Styled.Description>
            <ToggleGroup
              type="single"
              value={expenseTransactionMode}
              onValueChange={value => {
                if (
                  value === EXPENSE_TRANSACTION_MODE_RECURRING ||
                  value === EXPENSE_TRANSACTION_MODE_CUSTOM
                ) {
                  setExpenseTransactionMode(value);
                }
              }}
            >
              <ToggleGroupItem
                value={EXPENSE_TRANSACTION_MODE_RECURRING}
                colorScheme="dark"
              >
                Based On Resource Allocation
              </ToggleGroupItem>
              <ToggleGroupItem
                value={EXPENSE_TRANSACTION_MODE_CUSTOM}
                colorScheme="dark"
              >
                Custom Payment Schedule
              </ToggleGroupItem>
            </ToggleGroup>
          </>
        )}

        {expenseTransactionMode === EXPENSE_TRANSACTION_MODE_RECURRING && (
          <Styled.Recurring>
            <Select
              label="Recurring Rule"
              value={recurringOption}
              onValueChange={value => {
                if (isRecurringOptionType(value)) {
                  setRecurringOption(value);
                }
              }}
            >
              <SelectItem value={recurringOptions.EVERY_MONTH}>
                Every month on a day
              </SelectItem>
              {/* <SelectItem value={recurringOptions.EVERY_WEEK}>
                Every week on a day
              </SelectItem> */}
              <SelectItem value={recurringOptions.DAYS_AFTER_EVENT}>
                Days after event ends
              </SelectItem>
              <SelectItem value={recurringOptions.DAYS_BEFORE_EVENT}>
                Days before event starts
              </SelectItem>
            </Select>
            {recurringOption === recurringOptions.EVERY_MONTH && (
              <Select
                label="Select Day"
                value={String(monthDay)}
                onValueChange={value => setMonthDay(Number(value))}
              >
                {Array.from({ length: 31 }, (_, i) => i + 1).map(day => (
                  <SelectItem key={day} value={String(day)}>
                    {day}
                  </SelectItem>
                ))}
              </Select>
            )}
            {/* {recurringOption === recurringOptions.EVERY_WEEK && (
              <Select
                label="Select Day"
                value={String(weekDay)}
                onValueChange={value => setWeekDay(Number(value))}
              >
                {Array.from({ length: 7 }, (_, i) => i + 1).map(day => (
                  <SelectItem key={day} value={String(day)}>
                    {new Intl.DateTimeFormat(locale, {
                      weekday: 'long',
                    }).format(new Date(0, 0, day))}
                  </SelectItem>
                ))}
              </Select>
            )} */}
            {recurringOption === recurringOptions.DAYS_AFTER_EVENT && (
              <Select
                label="Select Day"
                value={String(daysAfterEvent)}
                onValueChange={value => setDaysAfterEvent(Number(value))}
              >
                {Array.from({ length: 90 }, (_, i) => i + 1).map(day => (
                  <SelectItem key={day} value={String(day)}>
                    {day}
                  </SelectItem>
                ))}
              </Select>
            )}
            {recurringOption === recurringOptions.DAYS_BEFORE_EVENT && (
              <Select
                label="Select Day"
                value={String(daysBeforeEvent)}
                onValueChange={value => setDaysBeforeEvent(Number(value))}
              >
                {Array.from({ length: 90 }, (_, i) => i + 1).map(day => (
                  <SelectItem key={day} value={String(day)}>
                    {day}
                  </SelectItem>
                ))}
              </Select>
            )}
          </Styled.Recurring>
        )}
        {expenseTransactionMode === EXPENSE_TRANSACTION_MODE_CUSTOM && (
          <Styled.Custom>
            <Styled.CustomList>
              {customOptions.map(({ amount, date }, index) => (
                <Styled.CustomListItem key={date + index}>
                  <FormInput
                    placeholder="Transaction Amount"
                    value={amount}
                    startHtml={copy.app.currenciesSign[organization?.currency]}
                    onChange={value => {
                      setCustomOptions(
                        customOptions.map((option, customOptionIndex) =>
                          index === customOptionIndex
                            ? { ...option, amount: value }
                            : option
                        )
                      );
                    }}
                    mask="float"
                  />
                  <Datepicker
                    value={new Date(date)}
                    closeOnChange={true}
                    color={currentColor}
                    fieldLabel="Transaction Date"
                    onChange={date => {
                      setCustomOptions(
                        customOptions.map((option, customOptionIndex) =>
                          index === customOptionIndex
                            ? { ...option, date: getDateString(date) }
                            : option
                        )
                      );
                    }}
                  />
                </Styled.CustomListItem>
              ))}
            </Styled.CustomList>
            <Button
              label="Add Expense Transaction"
              color={currentColor}
              icon="add"
              onClick={handleAddExpenseTransaction}
            />
          </Styled.Custom>
        )}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(CashflowExpenseTransaction);
