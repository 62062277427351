import { isNumber } from 'lodash';
import { FC, memo } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import { useUpdateProject } from 'queries/project';
import { useCopyStore, useGlobalStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';

import CardModal from '../CardModal/CardModal';

export interface ProjectLockedProps {
  context?: any;
}

const ProjectLocked: FC<ProjectLockedProps> = ({ context }) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { mutateAsync: updateProject } = useUpdateProject();
  const { checkAccessRight } = useCheckAccessRights();
  const copy = useCopyStore(s => s.copy);

  const projectId =
    context?.projectId && isNumber(context?.projectId)
      ? (context?.projectId as number)
      : null;

  const canUnlockProject = checkAccessRight('project_lock');

  const unlockProject = async () => {
    if (projectId) {
      await updateProject({
        projectId,
        project: { locked: false },
      });
      close();
    }
  };

  const close = () => {
    closeModal('projectLocked');
  };

  return (
    <CardModal
      type="error"
      title={copy.app.projectLockedModal.title}
      subText={
        canUnlockProject
          ? copy.app.projectLockedModal.adminMessage
          : copy.app.projectLockedModal.message
      }
      handleCloseClick={close}
      text={copy.app.projectLockedModal.text}
      buttons={
        <>
          {projectId && canUnlockProject && (
            <Button
              label={copy.app.projectLockedModal.unlockButton}
              onClick={unlockProject}
              color={currentColor}
              icon="lock"
            />
          )}
          <Button
            label={copy.app.projectLockedModal.okButton}
            onClick={close}
            color={currentColor}
            icon="check"
          />
        </>
      }
    />
  );
};

export default memo(ProjectLocked);
