import { useCallback } from 'react';

import { ChangeItemCategoryModalProps } from 'components/modals/ChangeItemCategoryModal/ChangeItemCategoryModal';
import { useGlobalStore } from 'store';

interface UseChangeItemCategory {
  onConfirmChangeCategory: () => void;
  currentCategory: string;
  isLoading?: boolean;
  previousItemCategory?: string;
}

export const useChangeItemCategory = ({
  onConfirmChangeCategory,
  currentCategory,
  previousItemCategory = '',
}: UseChangeItemCategory) => {
  const openModal = useGlobalStore(s => s.openModal);
  const closeModal = useGlobalStore(s => s.closeModal);

  const openChangeCategoryModal = useCallback(() => {
    openModal<ChangeItemCategoryModalProps>('changeItemCategoryModal', {
      currentCategory,
      onConfirmChangeCategory,
      previousItemCategory,
    });
  }, [
    currentCategory,
    onConfirmChangeCategory,
    openModal,
    previousItemCategory,
  ]);

  const closeChangeCategoryModal = useCallback(() => {
    closeModal('changeItemCategoryModal');
  }, [closeModal]);

  return {
    openChangeCategoryModal,
    closeChangeCategoryModal,
  };
};
