import { useMemo } from 'react';

import { useProjectRoles } from 'queries/roles';
import { Role, RoleType } from 'utils/api.types';

export const useHumanRolesItems = () => {
  const { data: roles } = useProjectRoles();

  const rolesItems = useMemo(() => {
    const filterPersonRoleTypes = ({ role_type, role_id }: Role) =>
      role_id ? role_type === RoleType.Person : true;

    const filterCategories = ({ category }: Role) => !category;

    return roles
      ?.filter(filterPersonRoleTypes)
      .filter(filterCategories)
      .map(role => ({ id: role.role_id, displayName: role.role }));
  }, [roles]);

  return rolesItems;
};

export default useHumanRolesItems;
