import * as LabelPrimitive from '@radix-ui/react-label';
import * as SelectPrimitive from '@radix-ui/react-select';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import {
  focusVisible,
  setTypography,
  slideDownAnimation,
} from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

const colorVariantsList = ['dark', 'light', 'lightWithDarkContent'] as const;
export type ColorVariants = (typeof colorVariantsList)[number];

const styleVariantsList = ['table', 'form'] as const;
export type StyleVariants = (typeof styleVariantsList)[number];

const colorVariants: Record<
  ColorVariants,
  { primary: string; secondary: string; background: string; boxShadow: string }
> = {
  dark: {
    primary: colors.white,
    secondary: rgba(colors.white, 0.5),
    background: colors.black,
    boxShadow: '0',
  },
  light: {
    primary: colors.outerSpace,
    secondary: rgba(colors.outerSpace, 0.5),
    background: colors.white,
    boxShadow: `20rem 20rem 100rem ${rgba(colors.outerSpace, 0.15)}`,
  },
  lightWithDarkContent: {
    primary: colors.outerSpace,
    secondary: rgba(colors.outerSpace, 0.5),
    background: colors.black,
    boxShadow: '0',
  },
};

export const Root = styled(SelectPrimitive.Root)``;

export const Trigger = styled(SelectPrimitive.Trigger)<{
  $styleVariant: StyleVariants;
}>`
  ${({ $styleVariant }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    color: var(--primary-color);
    min-height: 60rem;

    ${$styleVariant === 'form' &&
    css`
      padding-top: 20rem;
      padding-bottom: 27rem;
      border-bottom: 1px solid var(--secondary-color);
      ${({ theme: { locale } }) => setTypography('input', locale)}
    `}

    ${$styleVariant === 'table' &&
    css`
      ${({ theme: { locale } }) => setTypography('body3', locale)}
      text-transform: none;
    `}
    

    &[data-placeholder] {
      color: var(--secondary-color);
    }

    &:hover {
      filter: brightness(0.8);
    }

    &:focus-visible {
      ${focusVisible};
      border-bottom: 1px solid transparent;
    }

    &[data-state='open'] {
      ${focusVisible};
      border-bottom: 1px solid transparent;
    }

    &[data-disabled] {
      color: var(--secondary-color);
    }

    &[data-disabled]:hover {
      filter: none;
    }

    & > span:first-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-align: start;
    }
  `}
`;

export const Value = styled(SelectPrimitive.Value)``;

export const Icon = styled(SelectPrimitive.Icon)`
  width: 24rem;
  height: 24rem;
  display: flex;
`;

export const Portal = styled(SelectPrimitive.Portal)``;

export const Content = styled(SelectPrimitive.Content)<{
  $colorVariant: ColorVariants;
}>`
  ${({ $colorVariant }) => css`
    --primary-color: ${colorVariants[$colorVariant].primary};
    --secondary-color: ${colorVariants[$colorVariant].secondary};
    --background-color: ${colorVariants[$colorVariant].background};
    --box-shadow: ${colorVariants[$colorVariant].boxShadow};
  `}

  background: var(--background-color);
  box-shadow: var(--box-shadow);
  color: ${colors.outerSpace};
  overflow: hidden;
  border-radius: 30rem;

  ${slideDownAnimation};

  width: var(--radix-select-trigger-width);
  max-height: var(--radix-select-content-available-height);
`;

export const ScrollUpButton = styled(SelectPrimitive.ScrollUpButton)`
  width: 24rem;
  height: 24rem;
  margin: 10rem auto;
`;

export const Viewport = styled(SelectPrimitive.Viewport)``;

export const ScrollDownButton = styled(SelectPrimitive.ScrollDownButton)`
  width: 24rem;
  height: 24rem;
  transform: rotate(180deg);
  margin: 10rem auto;
`;

export const Item = styled(SelectPrimitive.Item)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 18rem 40rem;
  color: ${colors.white};
  cursor: pointer;
  ${({ theme: { locale } }) => setTypography('body5', locale)};

  &[data-state='checked'] {
    background: var(--current-color);
  }

  &[data-disabled] {
    color: ${rgba(colors.white, 0.7)};
  }

  &[data-highlighted] {
    color: ${rgba(colors.white, 0.8)};
  }

  &:focus-visible {
    ${focusVisible};
  }

  & > span:first-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align: start;
  }
`;

export const ItemText = styled(SelectPrimitive.ItemText)``;

export const ItemIndicator = styled(SelectPrimitive.ItemIndicator)`
  width: 18rem;
  height: 18rem;
  margin-left: 10rem;
`;

export const Wrapper = styled.div<{
  $colorVariant: ColorVariants;
}>`
  ${({ $colorVariant }) => css`
    --primary-color: ${colorVariants[$colorVariant].primary};
    --secondary-color: ${colorVariants[$colorVariant].secondary};
    --background-color: ${colorVariants[$colorVariant].background};
    --box-shadow: ${colorVariants[$colorVariant].boxShadow};
  `}

  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme: locale }) => setTypography('caption', locale)};
  color: var(--secondary-color);
`;

export const Label = styled(LabelPrimitive.Root)``;

export const Separator = styled(SelectPrimitive.Separator)`
  height: 1px;
  background: ${rgba(colors.white, 0.3)};
`;

export const SelectLabel = styled(SelectPrimitive.Label)`
  color: ${rgba(colors.white, 0.5)};
  ${({ theme: { locale } }) => setTypography('caption', locale)};
`;

export const ItemIconWrapper = styled.div`
  width: 32rem;
  height: 32rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20rem;
`;
