import { FC } from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { AdminItemUnusedPostponeModalProps } from 'components/modals/AdminItemUnusedPostponeModal/AdminItemUnusedPostponeModal';
import { CostAnalysisModalProps } from 'components/modals/CostAnalysisModal/CostAnalysisModal';
import { DeleteUnusedAdminItemModalProps } from 'components/modals/DeleteUnusedAdminItemModal/DeleteUnusedAdminItemModal';
import { UnverifyResourceModalProps } from 'components/modals/UnverifyResourceModal/UnverifyResourceModal';
import { useCurrentAdminRoute } from 'containers/AdminPage/hooks/useCurrentAdminRoute';
import { useUpdateBrand } from 'queries/brands';
import { useUpdateClient } from 'queries/clients';
import { useUpdateResource } from 'queries/resources';
import { useUpdateVendor } from 'queries/vendors';
import { useUpdateVendorService } from 'queries/vendorServices';
import { useCopyStore, useGlobalStore } from 'store';
import { CostAnalysisInput, VerificationStatus } from 'utils/api.types';
import { ADMIN_ROUTES } from 'utils/routes';

import { useAdminItemModal } from '../../hooks/useAdminItemModal';
import {
  AdminListItem,
  CategoryIdentifier,
} from '../../hooks/useAdminListColumns';
import { useDeleteItemModal } from '../../hooks/useDeleteItemModal';
import { useHandleProjectLookup } from '../../hooks/useHandleProjectLookup';

export interface AdminListItemDropdownProps {
  item: AdminListItem;
}

const AdminListItemDropdown: FC<AdminListItemDropdownProps> = ({ item }) => {
  const copy = useCopyStore(s => s.copy);
  const { mutateAsync: updateResource } = useUpdateResource();
  const { mutateAsync: updateClient } = useUpdateClient();
  const { mutateAsync: updateBrand } = useUpdateBrand();
  const { mutateAsync: updateVendor } = useUpdateVendor();
  const { mutateAsync: updateVendorService } = useUpdateVendorService();
  const { handleOpenAdminItemModal: handleOpenEditItem } =
    useAdminItemModal('adminItemModal2');
  const { handleOpenDeleteItemModal } = useDeleteItemModal(item);
  const { handleProjectLookup } = useHandleProjectLookup();
  const adminRoute = useCurrentAdminRoute();
  const openModal = useGlobalStore(s => s.openModal);

  const hasProjectLookup = [
    ADMIN_ROUTES.BRANDS,
    ADMIN_ROUTES.BRANDS_REQUESTS,
    ADMIN_ROUTES.CLIENTS,
    ADMIN_ROUTES.CLIENTS_REQUESTS,
    ADMIN_ROUTES.INTERNAL,
    ADMIN_ROUTES.EXTERNAL,
    ADMIN_ROUTES.RESOURCE_REQUESTS,
    ADMIN_ROUTES.VENDOR,
    ADMIN_ROUTES.VENDOR_REQUESTS,
    ADMIN_ROUTES.VENDOR_SERVICE,
    ADMIN_ROUTES.VENDOR_SERVICE_REQUESTS,
  ].some(route => route === adminRoute);

  const hasCostAnalysis = [
    ADMIN_ROUTES.INTERNAL,
    ADMIN_ROUTES.EXTERNAL,
    ADMIN_ROUTES.RESOURCE_REQUESTS,
    ADMIN_ROUTES.VENDOR,
    ADMIN_ROUTES.VENDOR_REQUESTS,
  ].some(route => route === adminRoute);

  const isUnverifiedFilter = [
    ADMIN_ROUTES.RESOURCE_REQUESTS,
    ADMIN_ROUTES.ROLES_REQUESTS,
    ADMIN_ROUTES.CLIENTS_REQUESTS,
    ADMIN_ROUTES.BRANDS_REQUESTS,
    ADMIN_ROUTES.VENDOR_REQUESTS,
    ADMIN_ROUTES.VENDOR_SERVICE_REQUESTS,
  ].some(route => route === adminRoute);

  const isUnusedFilter = [
    ADMIN_ROUTES.RESOURCE_UNUSED,
    ADMIN_ROUTES.ROLES_UNUSED,
    ADMIN_ROUTES.CLIENTS_UNUSED,
    ADMIN_ROUTES.BRANDS_UNUSED,
    ADMIN_ROUTES.VENDOR_UNUSED,
    ADMIN_ROUTES.VENDOR_SERVICE_UNUSED,
  ].some(route => route === adminRoute);

  const openCostAnalysis = (item: AdminListItem) => () => {
    const typeMap: Partial<
      Record<CategoryIdentifier, CostAnalysisInput['type']>
    > = {
      [CategoryIdentifier.Resource]: 'resource',
      [CategoryIdentifier.Vendor]: 'vendor',
    };
    const type = typeMap[item.categoryIdentifier];
    if (type) {
      openModal<CostAnalysisModalProps>('costAnalysis', {
        type,
        id:
          type === 'resource' ? Number(item.resourceId) : Number(item.vendorId),
        entityName: item.name,
      });
    }
  };

  const handleVerify = async () => {
    if (
      item.categoryIdentifier === CategoryIdentifier.Resource &&
      item.resourceId
    ) {
      updateResource({
        id: item.resourceId,
        verification_status: {
          message: '',
          status: VerificationStatus.verified,
        },
      });
    }
    if (
      item.categoryIdentifier === CategoryIdentifier.Client &&
      item.clientId
    ) {
      updateClient({
        id: item.clientId,
        verification_status: {
          message: '',
          status: VerificationStatus.verified,
        },
      });
    }
    if (item.categoryIdentifier === CategoryIdentifier.Brand && item.brandId) {
      updateBrand({
        id: item.brandId,
        verification_status: {
          message: '',
          status: VerificationStatus.verified,
        },
      });
    }
    if (
      item.categoryIdentifier === CategoryIdentifier.Vendor &&
      item.vendorId
    ) {
      updateVendor({
        id: item.vendorId,
        verification_status: {
          message: '',
          status: VerificationStatus.verified,
        },
      });
    }
    if (
      item.categoryIdentifier === CategoryIdentifier.VendorService &&
      item.vendorServiceId
    ) {
      updateVendorService({
        id: item.vendorServiceId,
        verification_status: {
          message: '',
          status: VerificationStatus.verified,
        },
      });
    }
  };

  const handleReject = async () => {
    openModal<UnverifyResourceModalProps>('unverifyResourceModal', {
      item,
    });
  };

  const handleDeleteUnusedResource = () => {
    openModal<DeleteUnusedAdminItemModalProps>('deleteUnusedAdminItemModal', {
      item,
    });
  };

  const handlePostponeUnusedItem = async () => {
    openModal<AdminItemUnusedPostponeModalProps>('adminItemUnusedPostpone', {
      item,
    });
  };

  const EditItem = (
    <DropdownMenuItem icon="edit" onSelect={() => handleOpenEditItem(item)}>
      {copy.app.toolbarTooltips.edit}
    </DropdownMenuItem>
  );

  const DeleteItem = (
    <DropdownMenuItem icon="trash" onSelect={handleOpenDeleteItemModal}>
      {copy.app.toolbarTooltips.delete}
    </DropdownMenuItem>
  );

  const LookupItem = (
    <DropdownMenuItem
      icon="external_link"
      onSelect={() => handleProjectLookup({ adminItem: item })}
    >
      {copy.app.toolbarTooltips.findProjects}
    </DropdownMenuItem>
  );

  const CostAnalysisItem = (
    <DropdownMenuItem icon="trend" onSelect={openCostAnalysis(item)}>
      {copy.app.toolbarTooltips.costAnalysis}
    </DropdownMenuItem>
  );

  const VerifyItem = (
    <DropdownMenuItem icon="check" onSelect={handleVerify}>
      {copy.app.toolbarTooltips.verify}
    </DropdownMenuItem>
  );

  const RejectItem = (
    <DropdownMenuItem icon="close" onSelect={handleReject}>
      {copy.app.toolbarTooltips.delete}
      <DropdownMenuSeparator />
    </DropdownMenuItem>
  );

  const DeleteUnusedResourceItem = (
    <DropdownMenuItem icon="check" onSelect={handleDeleteUnusedResource}>
      {copy.app.toolbarTooltips.delete}
    </DropdownMenuItem>
  );

  const PostponeUnusedItem = (
    <DropdownMenuItem icon="close" onSelect={handlePostponeUnusedItem}>
      {copy.app.toolbarTooltips.postpone}
    </DropdownMenuItem>
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger />
      <DropdownMenuContent>
        {isUnverifiedFilter ? (
          <>
            {VerifyItem}
            {RejectItem}
            {EditItem}
            {hasProjectLookup ? LookupItem : null}
            {hasCostAnalysis ? CostAnalysisItem : null}
          </>
        ) : isUnusedFilter ? (
          <>
            {DeleteUnusedResourceItem}
            {PostponeUnusedItem}
          </>
        ) : (
          <>
            {EditItem}
            {DeleteItem}
            {hasProjectLookup ? LookupItem : null}
            {hasCostAnalysis ? CostAnalysisItem : null}
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AdminListItemDropdown;
