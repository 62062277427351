import { useCallback, useMemo } from 'react';

import { useField } from 'components/inputs/hooks/useField';
import { AdminListItem } from 'components/modules/Admin/hooks/useAdminListColumns';
import { useAddBrand, useUpdateBrand } from 'queries/brands';
import { useCopyStore } from 'store';

export const useBrandPartial = (item?: AdminListItem) => {
  const copy = useCopyStore(s => s.copy);

  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.brandRequired,
      },
      name: {
        enabled: true,
        message: copy.app.validatorMessages.nameNoAtSign,
      },
    }),
    [
      copy.app.validatorMessages.brandRequired,
      copy.app.validatorMessages.nameNoAtSign,
    ]
  );
  const nameField = useField(item?.name ?? '', validators);

  const isPartialValid = useMemo(() => {
    const [, , isNameValid] = nameField;
    return isNameValid;
  }, [nameField]);

  const { mutateAsync: handleAdd } = useAddBrand();
  const { mutateAsync: handleUpdate } = useUpdateBrand();

  const save = useCallback(
    async (callback: () => void) => {
      const [name] = nameField;

      await handleAdd({
        name,
      });

      callback?.();
    },
    [handleAdd, nameField]
  );

  const update = useCallback(
    async (callback: () => void) => {
      const [name] = nameField;

      if (item?.brandId) {
        await handleUpdate({
          id: item.brandId,
          name,
        });
      }

      callback?.();
    },
    [handleUpdate, item?.brandId, nameField]
  );

  return {
    nameField,
    isPartialValid,
    save,
    update,
  };
};
