import { useCallback, useEffect, useState } from 'react';

import { emailRegex } from 'utils/regex';

interface RequiredValidator {
  enabled: boolean;
  message: string;
}

interface ExactLengthValidator {
  length: number;
  message: string;
}

interface MinLengthValidator {
  atLeast: number;
  message: string;
}

interface OnlyDigitsValidator {
  enabled: boolean;
  message: string;
}

interface EmailValidator {
  enabled: boolean;
  message: string;
}

interface NumberGreaterThanZeroValidator {
  enabled: boolean;
  message: string;
}

interface NumberGreaterThanOrEqualValidator {
  value: number;
  message: string;
}

interface CustomRegexValidator {
  enabled: boolean;
  message: string;
  regex: RegExp;
}

interface NameValidator {
  enabled: boolean;
  message: string;
}

interface Validators {
  required?: RequiredValidator;
  exactLength?: ExactLengthValidator;
  minLength?: MinLengthValidator;
  onlyDigits?: OnlyDigitsValidator;
  email?: EmailValidator;
  numberGreaterThanZero?: NumberGreaterThanZeroValidator;
  numberGreaterThanOrEqual?: NumberGreaterThanOrEqualValidator;
  customRegex?: CustomRegexValidator;
  name?: NameValidator;
}

// Second argument has to be memoized, otherwise it can potentially cause infinite loop
export const useField = (
  initValue: string,
  memoizedValidators?: Validators
) => {
  const [value, setValue] = useState(initValue);
  const [isValid, setIsValid] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState<string | null>(null);

  const handleRequired = useCallback(
    (required: RequiredValidator) => {
      const isValid = !!value;
      !isValid && setIsValid(isValid);
      !isValid && dirty && setInvalidMessage(required.message);
    },
    [value, dirty]
  );

  const handleExactLength = useCallback(
    (exactLength: ExactLengthValidator) => {
      const isValid = value?.length === exactLength.length;
      !isValid && setIsValid(isValid);
      !isValid && dirty && setInvalidMessage(exactLength.message);
    },
    [value, dirty]
  );

  const handleMinLength = useCallback(
    (minLength: MinLengthValidator) => {
      const isValid = value?.length >= minLength.atLeast;
      !isValid && setIsValid(isValid);
      !isValid && dirty && setInvalidMessage(minLength.message);
    },
    [dirty, value?.length]
  );

  const handleOnlyDigits = useCallback(
    (onlyDigits: OnlyDigitsValidator) => {
      if (!value) return true;
      const isValid = !isNaN(Number(value));
      !isValid && setIsValid(isValid);
      !isValid && dirty && setInvalidMessage(onlyDigits.message);
    },
    [value, dirty]
  );

  const handleEmail = useCallback(
    (email: EmailValidator) => {
      if (!value) return true;
      const isValid = emailRegex.test(value);
      !isValid && setIsValid(isValid);
      !isValid && dirty && setInvalidMessage(email.message);
    },
    [dirty, value]
  );

  const handleNumberGreaterThanZero = useCallback(
    (numberGreaterThanZero: NumberGreaterThanZeroValidator) => {
      const isNumber = !isNaN(Number(value));
      const isGreaterThanZero = Number(value) > 0;
      const isValid = isNumber && isGreaterThanZero;
      !isValid && setIsValid(isValid);
      !isValid && dirty && setInvalidMessage(numberGreaterThanZero.message);
    },
    [dirty, value]
  );

  const handleNumberGreaterThanOrEqual = useCallback(
    (numberGreaterThan: NumberGreaterThanOrEqualValidator) => {
      const isNumber = !isNaN(Number(value));
      const isGreaterThan = Number(value) >= numberGreaterThan.value;
      const isValid = isNumber && isGreaterThan;
      !isValid && setIsValid(isValid);
      !isValid && dirty && setInvalidMessage(numberGreaterThan.message);
    },
    [dirty, value]
  );

  const handleCustomRegex = useCallback(
    (customRegex: CustomRegexValidator) => {
      if (!value) return true;
      const isValid = customRegex.regex.test(value);
      !isValid && setIsValid(isValid);
      !isValid && dirty && setInvalidMessage(customRegex.message);
    },
    [dirty, value]
  );

  const handleName = useCallback(
    (name: NameValidator) => {
      if (!value) return true;
      const isValid = !value.includes('@');
      !isValid && setIsValid(isValid);
      !isValid && dirty && setInvalidMessage(name.message);
    },
    [dirty, value]
  );

  useEffect(() => {
    setInvalidMessage(null);
    setIsValid(true);
    const {
      required,
      exactLength,
      onlyDigits,
      email,
      minLength,
      numberGreaterThanZero,
      customRegex,
      name,
      numberGreaterThanOrEqual,
    } = memoizedValidators || {};

    required?.enabled && handleRequired(required);
    !!exactLength?.length && handleExactLength(exactLength);
    !!minLength?.atLeast && handleMinLength(minLength);
    onlyDigits?.enabled && handleOnlyDigits(onlyDigits);
    email?.enabled && handleEmail(email);
    numberGreaterThanZero?.enabled &&
      handleNumberGreaterThanZero(numberGreaterThanZero);
    numberGreaterThanOrEqual?.value &&
      handleNumberGreaterThanOrEqual(numberGreaterThanOrEqual);
    customRegex?.enabled && handleCustomRegex(customRegex);
    name?.enabled && handleName(name);
  }, [
    memoizedValidators,
    handleRequired,
    handleExactLength,
    handleOnlyDigits,
    handleEmail,
    dirty,
    handleMinLength,
    handleNumberGreaterThanZero,
    handleCustomRegex,
    handleName,
    handleNumberGreaterThanOrEqual,
  ]);

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  useEffect(() => {
    if (value !== initValue) {
      setDirty(true);
    }
  }, [value, initValue]);

  return [value, setValue, isValid, invalidMessage] as const;
};
