import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import _merge from 'lodash/merge';

import {
  Address,
  availableCurrencies,
  Organization,
} from '@u9/bob3-shared/lib/types/api.types';
import { ListFilterType } from 'store/projectsList.types';
import { AtLeast } from 'types/helpers';
import {
  AccessRight,
  AddUser,
  AdminAlerts,
  AppNotification,
  ArchiveItem,
  BobAccessRequest,
  Brand,
  BudgetEntry,
  CashflowItem,
  Client,
  ClientDuplicate,
  CostAnalysisInput,
  DuplicatePitchVersionInput,
  DuplicateType,
  GeoArea,
  HistoryEntry,
  HistoryLogInput,
  IntegrationProject,
  IntegrationProjectInput,
  LinkableOnDeletion,
  MfrRate,
  PaginatedQueryParams,
  Payment,
  PerformanceDashboardInput,
  Permission,
  Profile,
  Project,
  ProjectAccessRequest,
  ProjectClosureStatus,
  ProjectComment,
  ProjectDetails,
  ProjectFieldLists,
  ProjectGroup,
  ProjectMode,
  ProjectPermission,
  ProjectRow,
  ProjectRowUpdate,
  ProjectsListItem,
  ProjectsPerformance,
  ProjectStatus,
  ProjectVersion,
  ReconcileInput,
  ReconcileResult,
  Report,
  ReportTemplate,
  ResolveDuplicatesInput,
  Resource,
  ResourceDuplicate,
  ResourceType,
  Role,
  RolesSuggestions,
  SortableQueryParams,
  TeamdeckProjectMapping,
  TeamdeckResourceMapping,
  UpdateUser,
  User,
  Vendor,
  VendorService,
  VendorUpdate,
  Vertical,
} from 'utils/api.types';

import { LoginInput, ProjectRowsInput } from './api.types';

export const DEFAULT_PAGE_SIZE = 50;

const defaultConfig: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

export default class Api {
  client: AxiosInstance;
  isReady: boolean;

  constructor(config: AxiosRequestConfig = {}, token?: string) {
    this.client = axios.create(_merge(config, defaultConfig));
    this.isReady = !!token;
  }

  getWorkspace = (
    subdomain: string
  ): Promise<AxiosResponse<{ exists: boolean }>> =>
    this.client.get(`/workspaces/${subdomain}`);

  getWorkspaceStatus = (subdomain: string): Promise<AxiosResponse> =>
    this.client.get(`/status/${subdomain}`);

  login = (
    loginInput: LoginInput
  ): Promise<AxiosResponse<{ token: string; access_rights: AccessRight[] }>> =>
    this.client.post('/auth', loginInput);

  getProfile = (): Promise<AxiosResponse<Profile>> => this.client.get('/me');

  updateProfile = (
    profile: Partial<Profile>
  ): Promise<AxiosResponse<Profile>> => this.client.put('/me', profile);

  getProjects: {
    (
      options: {
        select?: ListFilterType;
        resourceIds?: Resource['id'][];
        vendorIds?: Vendor['id'][];
        clientIds?: Client['id'][];
        brandIds?: Brand['id'][];
        vendorServiceIds?: VendorService['id'][];
        roleIds?: Role['role_id'][];
        verticalIds?: Vertical['id'][];
        groupId?: number | null;
        search?: string;
        modes?: ProjectMode[];
        statuses?: ProjectStatus[];
        budgetFrom?: number | null;
        budgetTo?: number | null;
        jobNumberPending?: boolean;
        jobClosurePending?: boolean;
      } & PaginatedQueryParams &
        SortableQueryParams
    ): Promise<AxiosResponse<ProjectsListItem[]>>;
  } = variables =>
    this.client.get('/projects', {
      params: {
        ...(variables?.select === 'all' && { select: 'all' }),
        ...(variables?.resourceIds?.length &&
          variables?.resourceIds?.length > 0 && {
            resource: variables.resourceIds.join(','),
          }),
        ...(variables?.vendorIds?.length &&
          variables?.vendorIds?.length > 0 && {
            vendor: variables.vendorIds.join(','),
          }),
        ...(variables?.clientIds?.length &&
          variables?.clientIds?.length > 0 && {
            client: variables.clientIds.join(','),
          }),
        ...(variables?.brandIds?.length &&
          variables?.brandIds?.length > 0 && {
            brand: variables.brandIds.join(','),
          }),
        ...(variables?.vendorServiceIds?.length &&
          variables?.vendorServiceIds?.length > 0 && {
            vendor_service: variables.vendorServiceIds.join(','),
          }),
        ...(variables?.roleIds?.length &&
          variables?.roleIds?.length > 0 && {
            role: variables.roleIds.join(','),
          }),
        ...(variables?.groupId && { group_id: variables.groupId }),
        ...(variables?.limit && { limit: variables.limit }),
        ...(variables?.page && { page: variables.page }),
        ...(variables?.search && { search: variables.search }),
        ...(variables?.sort_by && { sort_by: variables.sort_by }),
        asc: !variables.desc,
        ...(variables.sort_nulls_first && { nulls_first: true }),
        ...(variables?.verticalIds?.length &&
          variables?.verticalIds?.length > 0 && {
            vertical: variables.verticalIds?.join(','),
          }),
        ...(variables?.modes?.length &&
          variables?.modes?.length > 0 && { mode: variables.modes.join(',') }),
        ...(variables?.statuses?.length &&
          variables?.statuses?.length > 0 && {
            status: variables.statuses.join(','),
          }),
        ...(variables?.budgetFrom && { budget_from: variables.budgetFrom }),
        ...(variables?.budgetTo && { budget_to: variables.budgetTo }),
        ...(variables?.jobNumberPending && { job_number_requested: true }),
        ...(variables?.jobClosurePending && { job_closure_requested: true }),
      },
    });

  getProject = (
    projectSlug: string | number
  ): Promise<AxiosResponse<Project>> =>
    this.client.get(`/projects/${projectSlug}`);

  postProject = (project: ProjectDetails): Promise<AxiosResponse<Project>> =>
    this.client.post('/projects', project);

  deleteProject = (
    projectId: Project['id']
  ): Promise<AxiosResponse<{ deleted: boolean }>> =>
    this.client.delete(`/projects/${projectId}`);

  updateProject = async ({
    projectId,
    project,
  }: {
    projectId: Project['id'];
    project: ProjectDetails;
  }): Promise<AxiosResponse<Project>> =>
    this.client.put(`/projects/${projectId}`, project);

  watchProject = ({
    projectId,
  }: {
    projectId: Project['id'];
  }): Promise<AxiosResponse<Project>> =>
    this.client.put(`/projects/${projectId}/watch`);

  unwatchProject = ({
    projectId,
  }: {
    projectId: Project['id'];
  }): Promise<AxiosResponse<Project>> =>
    this.client.put(`/projects/${projectId}/unwatch`);

  getProjectVersion = ({
    projectId,
    projectVersionId,
  }: {
    projectId: Project['id'];
    projectVersionId: ProjectVersion['id'];
  }): Promise<AxiosResponse<ProjectVersion>> =>
    this.client.get(`/projects/${projectId}/versions/${projectVersionId}`);

  addProjectRows = ({
    projectId,
    versionId,
    input,
  }: {
    projectId: Project['id'];
    versionId: ProjectVersion['id'];
    input: ProjectRowsInput;
  }): Promise<AxiosResponse<Project>> =>
    this.client.post(
      `/projects/${projectId}/versions/${versionId}/rows`,
      input
    );

  updateProjectRows = ({
    projectId,
    projectRows,
    versionId,
  }: {
    projectId: Project['id'];
    versionId: ProjectVersion['id'];
    projectRows: ProjectRowUpdate[];
  }): Promise<AxiosResponse<Project>> =>
    this.client.put(
      `/projects/${projectId}/versions/${versionId}/rows`,
      projectRows
    );

  deleteProjectRow = ({
    projectId,
    rowsIds,
    versionId,
  }: {
    projectId: Project['id'];
    rowsIds: ProjectRow['id'][];
    versionId: ProjectVersion['id'];
  }): Promise<AxiosResponse<{ deleted: boolean }>> =>
    this.client.delete(`/projects/${projectId}/versions/${versionId}/rows`, {
      data: {
        bulk: rowsIds,
      },
    });

  createProjectVersion = async ({
    projectId,
    input,
  }: {
    projectId: Project['id'];
    input: Pick<ProjectVersion, 'name' | 'mode'> | DuplicatePitchVersionInput;
  }): Promise<AxiosResponse<ProjectVersion>> =>
    this.client.post(`/projects/${projectId}/versions`, input);

  updateProjectVersion = async ({
    projectId,
    projectVersion,
  }: {
    projectId: Project['id'];
    projectVersion: Partial<
      Pick<
        ProjectVersion,
        'id' | 'name' | 'comment' | 'mfr_discount' | 'mfr_discount_reason'
      >
    >;
  }): Promise<AxiosResponse<Project>> =>
    this.client.put(`/projects/${projectId}/versions`, projectVersion);

  deleteProjectVersion = async ({
    projectId,
    projectVersionId,
  }: {
    projectId: Project['id'];
    projectVersionId: ProjectVersion['id'];
  }): Promise<AxiosResponse<{ deleted: boolean }>> =>
    this.client.delete(`/projects/${projectId}/versions`, {
      data: {
        id: projectVersionId,
      },
    });

  movePitchToProduction = async ({
    projectId,
    versionId,
    mode,
  }: {
    projectId: Project['id'];
    versionId: ProjectVersion['id'];
    mode: ProjectMode.production | ProjectMode.hosting;
  }): Promise<AxiosResponse<Project>> =>
    this.client.post(`/projects/${projectId}/versions/move`, {
      id: versionId,
      mode,
    });

  downloadReport = async ({
    projectId,
    reportType = 'default',
    currency,
    versionId,
  }:
    | {
        projectId: Project['id'];
        reportType?: 'default';
        currency?: never;
        versionId?: never;
      }
    | {
        projectId: Project['id'];
        reportType?: 'clientFacing';
        currency?: (typeof availableCurrencies)[number];
        versionId?: ProjectVersion['id'];
      }
    | {
        projectId: Project['id'];
        reportType: 'mfrReport';
        currency?: never;
        versionId: ProjectVersion['id'];
      }): Promise<AxiosResponse<string>> =>
    this.client.get(`/projects/${projectId}/download`, {
      responseType: 'arraybuffer',
      params: {
        ...(reportType === 'clientFacing'
          ? {
              client_report: currency,
              ...(versionId ? { version: versionId } : {}),
            }
          : {}),
        ...(reportType === 'mfrReport'
          ? { mfr_report: 'true', version: versionId }
          : {}),
      },
    });

  getFinancialReports = (
    startDate = null,
    endDate = null
  ): Promise<{ data: Report[] }> =>
    this.client.get('/reports', {
      params: {
        startDate,
        endDate,
      },
    });

  generateFinancialReport = async (): Promise<AxiosResponse<string>> =>
    this.client.post('/reports', {}, { responseType: 'arraybuffer' });

  getFinancialReport = async (
    id: Report['id']
  ): Promise<AxiosResponse<string>> =>
    this.client.get(`/reports/${id}`, { responseType: 'arraybuffer' });

  getResources = (params?: {
    type?: ResourceType[];
    unused?: boolean;
    verified?: boolean;
  }): Promise<AxiosResponse<Resource[]>> =>
    this.client.get('/resources', {
      params: {
        unused: params?.unused,
        verified: params?.verified,
        ...(params?.type?.length &&
          params?.type?.length > 0 && {
            type: params.type.join(','),
          }),
      },
    });

  addResource = (
    resource: Omit<Resource, 'id'>
  ): Promise<AxiosResponse<Resource>> =>
    this.client.post('/resources', resource);

  updateResource = (
    resource: AtLeast<Resource, 'id'>
  ): Promise<AxiosResponse<Resource>> =>
    this.client.put('/resources', resource);

  bulkUpdateResources = (
    resources: AtLeast<Resource, 'id'>[]
  ): Promise<AxiosResponse<Resource[]>> =>
    this.client.put('/resources/bulk', resources);

  deleteResource = ({
    id,
    parent_id,
    reason,
  }: {
    id: Resource['id'];
    reason?: string;
  } & LinkableOnDeletion<Resource>): Promise<
    AxiosResponse<{ deleted: boolean }>
  > => this.client.delete('/resources', { data: { id, parent_id, reason } });

  getClients = (params?: {
    unused?: boolean;
    verified?: boolean;
  }): Promise<AxiosResponse<Client[]>> =>
    this.client.get('/clients', {
      params,
    });

  addClient = (newClient: Partial<Client>): Promise<AxiosResponse<Client>> =>
    this.client.post('/clients', newClient);

  deleteClient = ({
    id,
    parent_id,
    reason,
  }: {
    id: Client['id'];
    reason?: string;
  } & LinkableOnDeletion<Client>): Promise<
    AxiosResponse<{ deleted: boolean }>
  > => this.client.delete('/clients', { data: { id, parent_id, reason } });

  updateClient = (
    client: AtLeast<Client, 'id'>
  ): Promise<AxiosResponse<Client>> => this.client.put('/clients', client);

  getBrands = (params?: {
    unused?: boolean;
    verified?: boolean;
  }): Promise<AxiosResponse<Brand[]>> => this.client.get('/brands', { params });

  addBrand = (newBrand: Partial<Brand>): Promise<AxiosResponse<Brand>> =>
    this.client.post('/brands', newBrand);

  deleteBrand = ({
    id,
    reason,
  }: {
    id: Brand['id'];
    reason?: string;
  }): Promise<AxiosResponse<{ deleted: boolean }>> =>
    this.client.delete('/brands', { data: { id, reason } });

  updateBrand = (brand: AtLeast<Brand, 'id'>): Promise<AxiosResponse<Brand>> =>
    this.client.put('/brands', brand);

  getProjectComments = (
    projectSlug: string | number
  ): Promise<AxiosResponse<ProjectComment[]>> =>
    this.client.get(`/projects/${projectSlug}/comments`);

  addProjectComment = ({
    projectSlug,
    comment,
  }: {
    projectSlug: string | number;
    comment: ProjectComment;
  }): Promise<AxiosResponse<ProjectComment>> =>
    this.client.post(`/projects/${projectSlug}/comments`, comment);

  updateComment = ({
    projectSlug,
    comment,
  }: {
    projectSlug: string | number;
    comment: ProjectComment;
  }): Promise<AxiosResponse<ProjectComment>> =>
    this.client.put(`/projects/${projectSlug}/comments/${comment.id}`, comment);

  deleteComment = ({
    projectSlug,
    commentId,
  }: {
    projectSlug: string | number;
    commentId: ProjectComment['id'];
  }): Promise<AxiosResponse<{ deleted: boolean }>> =>
    this.client.delete(`/projects/${projectSlug}/comments/${commentId}`);

  getBudgetEntries = ({
    projectId,
    projectVersionId,
  }: {
    projectId: Project['id'];
    projectVersionId: ProjectVersion['id'];
  }): Promise<{ data: BudgetEntry[] }> =>
    this.client.get(
      `/projects/${projectId}/versions/${projectVersionId}/budgetentries`
    );

  updateBudgetEntry = ({
    projectId,
    projectVersionId,
    budgetEntry,
  }: {
    projectId: Project['id'];
    projectVersionId: ProjectVersion['id'];
    budgetEntry: Partial<BudgetEntry>;
  }): Promise<AxiosResponse<BudgetEntry[]>> =>
    this.client.put(
      `/projects/${projectId}/versions/${projectVersionId}/budgetentries`,
      budgetEntry
    );

  addBudgetEntry = ({
    projectId,
    projectVersionId,
    budgetEntry,
  }: {
    projectId: Project['id'];
    projectVersionId: ProjectVersion['id'];
    budgetEntry: Partial<BudgetEntry>;
  }): Promise<AxiosResponse<BudgetEntry[]>> =>
    this.client.post(
      `/projects/${projectId}/versions/${projectVersionId}/budgetentries`,
      budgetEntry
    );

  deleteBudgetEntry = ({
    projectId,
    projectVersionId,
    budgetEntryId,
  }: {
    projectId: Project['id'];
    projectVersionId: ProjectVersion['id'];
    budgetEntryId: BudgetEntry['pos'];
  }): Promise<AxiosResponse<{ result: string }>> =>
    this.client.delete(
      `/projects/${projectId}/versions/${projectVersionId}/budgetentries`,
      {
        data: { pos: budgetEntryId },
      }
    );

  addRole = (
    role:
      | Partial<
          Pick<
            Role,
            | 'rate'
            | 'role'
            | 'category'
            | 'aliases'
            | 'mfr_rates'
            | 'rnd_percent'
            | 'role_type'
            | 'true_margin_rate'
          >
        >
      | { pos: Role['row_no']; client_rates?: Record<number, number> }
  ): Promise<AxiosResponse<{ rows: Role[] }>> =>
    this.client.post('/roles', role);

  getRoles = (params?: {
    unused?: boolean;
    verified?: boolean;
  }): Promise<AxiosResponse<{ rows: Role[] }>> =>
    this.client.get('/roles', { params });

  deleteRole = (
    params:
      | {
          rowNo: Role['row_no'];
          role_id?: never;
          reason?: string;
        }
      | {
          rowNo?: never;
          role_id: Role['role_id'];
          reason?: string;
        }
  ): Promise<AxiosResponse<{ rows: Role[] }>> =>
    this.client.delete('/roles', {
      data: {
        pos: params.rowNo,
        role_id: params.role_id,
        reason: params.reason,
      },
    });

  updateRole = (
    role: Partial<
      Pick<
        Role,
        | 'rate'
        | 'role'
        | 'category'
        | 'row_no'
        | 'aliases'
        | 'mfr_rates'
        | 'rnd_percent'
        | 'role_type'
        | 'true_margin_rate'
        | 'verification_status'
        | 'unused'
      >
    > & {
      pos: Role['row_no'];
      client_rates?: Record<number, number>;
    }
  ): Promise<AxiosResponse<{ rows: Role[] }>> =>
    this.client.put('/roles', role);

  getUser = ({
    id,
    account_type = 'user',
  }: {
    id: User['id'];
    account_type: User['account_type'];
  }): Promise<AxiosResponse<User>> =>
    this.client.get(`/users/${id}`, {
      params: {
        account_type,
      },
    });

  getUsers = ({
    account_type = 'user',
  }: {
    account_type?: User['account_type'];
  } = {}): Promise<AxiosResponse<User[]>> =>
    this.client.get('/users', { params: { account_type } });

  deleteUser = (
    username: User['username']
  ): Promise<AxiosResponse<{ deleted: boolean }>> =>
    this.client.delete('/users', { data: { username } });

  addUser = (user: AddUser): Promise<AxiosResponse<User>> =>
    this.client.post('/users', user);

  updateUser = (user: UpdateUser): Promise<AxiosResponse<User>> =>
    this.client.put('/users', user);

  getTerritories = (): Promise<AxiosResponse<GeoArea[]>> =>
    this.client.get('/territory');

  deleteTerritory = (id: GeoArea['id']): Promise<AxiosResponse<GeoArea[]>> =>
    this.client.delete('/territory', { data: { id } });

  addTerritory = ({
    name,
  }: Omit<GeoArea, 'id'>): Promise<AxiosResponse<GeoArea>> =>
    this.client.post('/territory', { name });

  updateTerritory = (territory: GeoArea): Promise<AxiosResponse<GeoArea>> =>
    this.client.put('/territory', territory);

  getVerticals = (): Promise<AxiosResponse<Vertical[]>> =>
    this.client.get('/vertical');

  deleteVertical = (
    id: Vertical['id']
  ): Promise<AxiosResponse<{ deleted: boolean }>> =>
    this.client.delete('/vertical', { data: { id } });

  addVertical = (
    vertical: Omit<Vertical, 'id'>
  ): Promise<AxiosResponse<Vertical>> =>
    this.client.post('/vertical', vertical);

  updateVertical = (
    vertical: AtLeast<Vertical, 'id'>
  ): Promise<AxiosResponse<Vertical>> => this.client.put('/vertical', vertical);

  getVendorServices = (params?: {
    unused?: boolean;
    verified?: boolean;
  }): Promise<AxiosResponse<VendorService[]>> =>
    this.client.get('/vendor_services', {
      params,
    });

  deleteVendorService = ({
    id,
    reason,
  }: {
    id: VendorService['id'];
    reason?: string;
  }): Promise<AxiosResponse<{ deleted: boolean }>> =>
    this.client.delete('/vendor_services', { data: { id, reason } });

  addVendorService = (
    vendorService: Omit<VendorService, 'id'>
  ): Promise<AxiosResponse<VendorService>> =>
    this.client.post('/vendor_services', vendorService);

  updateVendorService = (
    vendorService: AtLeast<VendorService, 'id'>
  ): Promise<AxiosResponse<VendorService>> =>
    this.client.put('/vendor_services', vendorService);

  getVendors = (params?: {
    unused?: boolean;
    verified?: boolean;
  }): Promise<AxiosResponse<Vendor[]>> =>
    this.client.get('/vendors', { params });

  deleteVendor = ({
    id,
    reason,
  }: {
    id: Vendor['id'];
    reason?: string;
  }): Promise<AxiosResponse<{ deleted: boolean }>> =>
    this.client.delete('/vendors', { data: { id, reason } });

  addVendor = (
    vendor: Omit<VendorUpdate, 'id'>
  ): Promise<AxiosResponse<Vendor>> => this.client.post('/vendors', vendor);

  updateVendor = (vendor: VendorUpdate): Promise<AxiosResponse<Vendor>> =>
    this.client.put('/vendors', vendor);

  bulkUpdateVendor = (
    vendors: VendorUpdate[]
  ): Promise<AxiosResponse<Vendor[]>> =>
    this.client.put('/vendors/bulk', vendors);

  getOrganization = (
    workspace?: Organization['subdomain']
  ): Promise<AxiosResponse<Organization>> =>
    this.client.get('/organizations', {
      params: {
        workspace,
      },
    });

  updateOrganization = (
    organization: AtLeast<Organization, 'id'>
  ): Promise<AxiosResponse<Organization>> =>
    this.client.put('/organizations', organization);

  getProjectAccessRequests = ({
    projectId,
    history = null,
  }: {
    projectId?: Project['id'];
    history?: number | null;
  } = {}): Promise<AxiosResponse<ProjectAccessRequest[]>> =>
    this.client.get('/project_access_requests', {
      params: {
        ...(projectId && { project_id: projectId }),
        ...(history !== null && { history }),
      },
    });

  addProjectAccessRequest = ({
    projectId,
    username,
  }: {
    projectId: Project['id'];
    username: string;
  }): Promise<AxiosResponse<ProjectAccessRequest>> =>
    this.client.post('/project_access_requests', {
      project_id: projectId,
      username,
    });

  getProjectPermissions = ({
    projectId,
  }: {
    projectId: Project['id'];
  }): Promise<AxiosResponse<ProjectPermission[]>> =>
    this.client.get(`/project_permissions/${projectId}`);

  grantProjectPermission = ({
    projectId,
    username,
  }: {
    projectId: Project['id'];
    username: string;
  }): Promise<AxiosResponse<ProjectPermission>> =>
    this.client.post(`/project_permissions/${projectId}`, {
      username,
    });

  declineProjectPermission = ({
    projectId,
    username,
  }: {
    projectId: Project['id'];
    username: string;
  }): Promise<AxiosResponse<ProjectPermission>> =>
    this.client.delete(`/project_permissions/${projectId}`, {
      data: {
        username,
      },
    });

  getAdminAlerts = (): Promise<AxiosResponse<AdminAlerts>> =>
    this.client.get('/admin_alerts');

  getRolesSuggestions = ({
    searchValue,
  }: {
    searchValue: string;
  }): Promise<AxiosResponse<RolesSuggestions>> =>
    this.client.get('/roles_search', { params: { name: searchValue } });

  getBobAccessRequests = ({
    workspace,
  }: {
    workspace: string;
  }): Promise<AxiosResponse<BobAccessRequest[]>> =>
    this.client.get('/bob_access_requests', {
      params: {
        workspace,
      },
    });

  addBobAccessRequest = (
    bobAccessRequest: Pick<
      BobAccessRequest,
      'username' | 'message' | 'workspace' | 'full_name'
    >
  ): Promise<AxiosResponse<BobAccessRequest>> =>
    this.client.post('/bob_access_requests', bobAccessRequest);

  getSupportedClientRates = (): Promise<
    AxiosResponse<(typeof availableCurrencies)[number][]>
  > => this.client.get('/supported_client_rates');

  addSupportedClientRate = ({
    currency,
  }: {
    currency: (typeof availableCurrencies)[number];
  }): Promise<AxiosResponse<(typeof availableCurrencies)[number][]>> =>
    this.client.post('/supported_client_rates', {
      code: currency,
    });

  deleteSupportedClientRate = ({
    currency,
  }: {
    currency: (typeof availableCurrencies)[number];
  }): Promise<AxiosResponse<(typeof availableCurrencies)[number][]>> =>
    this.client.delete('/supported_client_rates', {
      data: {
        code: currency,
      },
    });

  getSupportedMfrRates = (): Promise<AxiosResponse<MfrRate[]>> =>
    this.client.get('/supported_mfr_rates');

  addSupportedMfrRates = ({
    name,
  }: {
    name: MfrRate['code'];
  }): Promise<AxiosResponse<MfrRate[]>> =>
    this.client.post('/supported_mfr_rates', {
      code: name,
    });

  updateSupportedMfrRates = ({
    name,
    id,
  }: {
    name: MfrRate['code'];
    id: MfrRate['id'];
  }): Promise<AxiosResponse<MfrRate[]>> =>
    this.client.put('/supported_mfr_rates', {
      code: name,
      id,
    });

  deleteSupportedMfrRates = ({
    id,
  }: {
    id: MfrRate['id'];
  }): Promise<AxiosResponse<MfrRate[]>> =>
    this.client.delete('/supported_mfr_rates', {
      data: {
        id,
      },
    });

  getProjectsPerformance = ({
    startDate,
    endDate,
    includeStart,
    includeEnd,
    includeStatuses = [],
    includeModes = [],
    verticals = [],
    budgetFrom,
    budgetTo,
    marginVariation,
    timeframe,
    similarProjectId,
    internalPercent,
    icons = [],
    includeNonIcon,
  }: PerformanceDashboardInput): Promise<
    AxiosResponse<ProjectsPerformance>
  > => {
    const rolesInternalPercentByAmt =
      internalPercent && internalPercent?.reduce((a, b) => a + b) / 2;
    const threshold =
      rolesInternalPercentByAmt &&
      internalPercent?.[0] &&
      Math.abs(rolesInternalPercentByAmt - internalPercent?.[0] || 0);

    return this.client.get('/dashboard', {
      params: {
        ...(startDate ? { start_date: startDate } : {}),
        ...(endDate ? { end_date: endDate } : {}),
        include_before_start: Number(!includeStart),
        include_after_end: Number(!includeEnd),
        ...(includeStatuses.length > 0
          ? { include_statuses: includeStatuses?.join(',') }
          : {}),
        ...(includeModes.length > 0
          ? { include_modes: includeModes?.join(',') }
          : {}),
        ...(verticals.length > 0 ? { vertical_ids: verticals?.join(',') } : {}),
        ...(budgetFrom !== null ? { budget_from: budgetFrom } : {}),
        ...(budgetTo !== null ? { budget_to: budgetTo } : {}),
        ...(marginVariation !== null
          ? { margin_variation_greater_than: Number(marginVariation) }
          : {}),
        ...(timeframe ? { margin_variation_time_frame: timeframe } : {}),
        ...(similarProjectId ? { similar_project_id: similarProjectId } : {}),
        ...(internalPercent
          ? {
              roles_internal_percent_by_amt: rolesInternalPercentByAmt,
              threshold,
            }
          : {}),
        ...(icons.length > 0 ? { icon: icons.join(',') } : {}),
        ...(includeNonIcon ? { include_non_icon: Number(includeNonIcon) } : {}),
        trend: true,
      },
    });
  };

  reconcile = (
    reconcileInput: ReconcileInput
  ): Promise<AxiosResponse<ReconcileResult>> => {
    const { project_id, ...rest } = reconcileInput;
    return this.client.post(`/projects/${project_id}/reconcile`, rest);
  };

  reportDownload = (params?: {
    type: 'utilisation_by_department';
  }): Promise<AxiosResponse<string>> =>
    this.client.get('download_report', {
      responseType: 'arraybuffer',
      params,
    });

  getNotifications = (): Promise<AxiosResponse<AppNotification[]>> =>
    this.client.get('/notifications');

  updateNotifications = (
    notifications: Pick<AppNotification, 'id' | 'seen'>[]
  ): Promise<AxiosResponse<AppNotification>> =>
    this.client.put('/notifications', notifications);

  createNotification = (variables: {
    receiver_user_id: User['id'];
    projectId: Project['id'];
    msg_code: 'job_number.approved' | 'job_closure.approved';
  }): Promise<AxiosResponse<AppNotification>> =>
    this.client.post('/notifications', variables);

  getArchive = (): Promise<AxiosResponse<ArchiveItem[]>> =>
    this.client.get('/archive');

  getProjectFieldLists = (): Promise<AxiosResponse<ProjectFieldLists>> =>
    this.client.get('/project_field_lists');

  requestJobNumber = (requestInput: {
    project_id: Project['id'];
    version_id: ProjectVersion['id'];
    mode: ProjectMode.production | ProjectMode.hosting;
  }): Promise<AxiosResponse<{ success: boolean }>> =>
    this.client.post('/request_job_number', requestInput);

  getIntegrationProjects = ({
    type,
    name,
  }: IntegrationProjectInput): Promise<AxiosResponse<IntegrationProject[]>> =>
    this.client.get('/integrations/projects', { params: { type, name } });

  getProjectGroups = (): Promise<AxiosResponse<ProjectGroup[]>> =>
    this.client.get('/project_groups');

  updateProjectGroup = (
    input: Pick<ProjectGroup, 'id' | 'name'>
  ): Promise<AxiosResponse<ProjectGroup>> =>
    this.client.put('/project_groups', input);

  deleteProjectGroup = ({
    id,
  }: {
    id: ProjectGroup['id'];
  }): Promise<AxiosResponse<{ deleted: boolean }>> =>
    this.client.delete('/project_groups', {
      data: {
        id,
      },
    });

  addProjectGroup = (
    input: Pick<ProjectGroup, 'name'>
  ): Promise<AxiosResponse<ProjectGroup>> =>
    this.client.post('/project_groups', input);

  addProjectToGroup = (input: {
    projectId: Project['id'];
    groupId: ProjectGroup['id'];
  }): Promise<AxiosResponse<ProjectGroup>> =>
    this.client.post(`/project_groups/${input.groupId}/add_project`, {
      project_id: input.projectId,
    });

  removeProjectFromGroup = (input: {
    projectId: Project['id'];
    groupId: ProjectGroup['id'];
  }): Promise<AxiosResponse<ProjectGroup>> =>
    this.client.post(`/project_groups/${input.groupId}/remove_project`, {
      project_id: input.projectId,
    });

  validateAddress = (
    address: Address
  ): Promise<
    AxiosResponse<{
      country_code?: string;
      address_lines?: string[];
      postal_code?: string;
      city?: string;
      formatted_address?: string;
      is_complete?: boolean;
    }>
  > => this.client.post('/validate_address', address);

  getHistory = (
    input: HistoryLogInput & PaginatedQueryParams
  ): Promise<AxiosResponse<HistoryEntry[]>> =>
    this.client.get('/history', {
      params: input,
    });

  getCostAnalysis = (input: CostAnalysisInput) =>
    this.client.get('/cost_analysis', { params: input });

  getDuplicates = <T extends DuplicateType>(
    type: T
  ): Promise<
    AxiosResponse<
      T extends 'resource'
        ? ResourceDuplicate[][]
        : T extends 'clients'
        ? ClientDuplicate[][]
        : never
    >
  > => this.client.get('/duplicates', { params: { type } });

  resolveDuplicates = <T extends DuplicateType>({
    input,
    type,
  }: {
    type: T;
    input: ResolveDuplicatesInput[];
  }): Promise<
    AxiosResponse<
      T extends 'resource'
        ? ResourceDuplicate[][]
        : T extends 'client'
        ? ClientDuplicate[][]
        : never
    >
  > => this.client.post('/duplicates', input, { params: { type } });

  getCashflow = ({
    projectId,
    projectVersionId,
  }: {
    projectId: Project['id'];
    projectVersionId: ProjectVersion['id'];
  }): Promise<AxiosResponse<CashflowItem[]>> =>
    this.client.get(
      `/projects/${projectId}/versions/${projectVersionId}/cashflow`
    );

  addCashflowItem = ({
    projectId,
    projectVersionId,
    item,
  }: {
    projectId: Project['id'];
    projectVersionId: ProjectVersion['id'];
    item: Omit<CashflowItem, 'id'>;
  }) =>
    this.client.post(
      `/projects/${projectId}/versions/${projectVersionId}/cashflow`,
      item
    );

  updateCashflowItem = ({
    projectId,
    projectVersionId,
    item,
  }: {
    projectId: Project['id'];
    projectVersionId: ProjectVersion['id'];
    item: AtLeast<CashflowItem, 'id'>;
  }) =>
    this.client.put(
      `/projects/${projectId}/versions/${projectVersionId}/cashflow`,
      item
    );

  deleteCashflowItem = ({
    projectId,
    projectVersionId,
    itemId,
  }: {
    projectId: Project['id'];
    projectVersionId: ProjectVersion['id'];
    itemId: CashflowItem['id'];
  }) =>
    this.client.delete(
      `/projects/${projectId}/versions/${projectVersionId}/cashflow`,
      {
        data: {
          id: itemId,
        },
      }
    );

  getPayments = ({
    projectId,
  }: {
    projectId?: Project['id'];
  } = {}): Promise<AxiosResponse<Payment[]>> =>
    this.client.get('/payments', {
      params: {
        ...(projectId && { project_id: projectId }),
      },
    });

  updatePayment = (payment: Payment): Promise<AxiosResponse<Payment>> =>
    this.client.put('/payments', payment);

  createPayment = (
    payment: Pick<Payment, 'reference'>
  ): Promise<AxiosResponse<Payment>> => this.client.post('/payments', payment);

  getReportTemplate = (
    code: ReportTemplate['code']
  ): Promise<AxiosResponse<ReportTemplate>> => {
    return this.client.get(`/templates/${code}`);
  };

  updateReportTemplate = ({ code, ...template }: ReportTemplate) => {
    return this.client.put(`/templates/${code}`, template);
  };

  downloadTemplatePreview = (code: ReportTemplate['code']) => {
    return this.client.get(`/templates/${code}/download`, {
      responseType: 'arraybuffer',
    });
  };

  getProjectClosureStatus = ({
    projectId,
  }: {
    projectId: Project['id'];
  }): Promise<AxiosResponse<ProjectClosureStatus>> =>
    this.client.get(`/projects/${projectId}/closure_status`);

  requestJobClosure = ({
    projectId,
  }: {
    projectId: Project['id'];
  }): Promise<AxiosResponse<{ success: boolean }>> =>
    this.client.post('/request_job_closure', {
      project_id: projectId,
    });

  getTeamdeckResourceMapping = (): Promise<
    AxiosResponse<TeamdeckResourceMapping[]>
  > => this.client.get('/teamdeck_resource_mapping');

  updateTeamdeckResourceMapping = (
    mapping: Pick<TeamdeckResourceMapping, 'bob_id' | 'teamdeck_id'>
  ): Promise<AxiosResponse<TeamdeckResourceMapping>> =>
    this.client.put('/teamdeck_resource_mapping', mapping);

  getTeamdeckProjectMapping = (): Promise<
    AxiosResponse<TeamdeckProjectMapping[]>
  > => this.client.get('/teamdeck_project_mapping');

  updateTeamdeckProjectMapping = (
    mapping: Pick<TeamdeckProjectMapping, 'id' | 'bob_category'>
  ): Promise<AxiosResponse<TeamdeckProjectMapping>> =>
    this.client.put('/teamdeck_project_mapping', mapping);

  getPermissions = (): Promise<AxiosResponse<Permission[]>> =>
    this.client.get('/permissions');

  addPermission = (
    permission: Omit<Permission, 'id'>
  ): Promise<AxiosResponse<Permission>> =>
    this.client.post('/permissions', permission);

  deletePermission = (
    id: Permission['id']
  ): Promise<AxiosResponse<{ deleted: boolean }>> =>
    this.client.delete('/permissions', { data: { id } });

  updatePermission = (permission: {
    id: Permission['id'];
    name: Permission['name'];
    add_access: AccessRight[];
    remove_access: AccessRight[];
  }): Promise<AxiosResponse<Permission>> =>
    this.client.put('/permissions', permission);

  getClosureStates = (): Promise<AxiosResponse<string[]>> =>
    this.client.get('/closure_states');

  postClosureStates = (
    closureStates: string[]
  ): Promise<AxiosResponse<string[]>> =>
    this.client.post('/closure_states', closureStates);

  prefillCashflowItems = ({
    projectId,
    projectVersionId,
  }: {
    projectId: Project['id'];
    projectVersionId: ProjectVersion['id'];
  }): Promise<AxiosResponse<CashflowItem[]>> =>
    this.client.post(
      `/projects/${projectId}/versions/${projectVersionId}/cashflow/prefill`
    );

  mergeProjectVersion = ({
    projectId,
    sourceId,
  }: {
    projectId: Project['id'];
    sourceId: ProjectVersion['id'];
  }): Promise<AxiosResponse<Project>> =>
    this.client.post(`/projects/${projectId}/versions/merge`, {
      source_id: sourceId,
    });

  resetPassword = ({
    password,
  }: {
    password: string;
  }): Promise<AxiosResponse<{ success: boolean }>> =>
    this.client.post('/reset_password', { password });

  forgotPassword = ({
    username,
    workspace,
  }: {
    username: string;
    workspace: string;
  }): Promise<AxiosResponse<{ success: boolean }>> =>
    this.client.post('/forgot_password', { username, workspace });
}

export const getApiClient = (token?: string) => {
  return new Api(
    {
      baseURL: process.env.API_URL,
      headers: {
        ...(token && { Authorization: token }),
      },
    },
    token
  );
};
