import { memo } from 'react';

import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import { useOrganization } from 'queries/organizations';
import { useCopyStore, useGlobalStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import { ProjectRow } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';
import { CategoryItems } from './EditResourceModal.types';
import { useEditResource } from './hooks/useEditResource';
import ResourcePartial from './ResourcePartial/ResourcePartial';
import VendorPartial from './VendorPartial/VendorPartial';

import * as Styled from './EditResourceModal.styles';

export interface EditResourceModalProps {
  row: ProjectRow;
  triggerMarkupWarning: (rowId: ProjectRow['id']) => void;
}

const EditResourceModal = ({
  row,
  triggerMarkupWarning,
}: EditResourceModalProps) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const copy = useCopyStore(s => s.copy);
  const { data: organization } = useOrganization();
  const { checkAnyAccessRight } = useCheckAccessRights();

  const modalCopy = copy.project.fillResourceInformation.editResourceModal;

  const {
    isAnyLoading,
    save,
    isAnyInvalid,
    territoryItems,
    handleClose,
    rolesDropdown,
    activeTerritoryField,
    companyField,
    emailErrorMessage,
    emailField,
    nameField,
    vendorServiceDropdown,
    vendorServiceItems,
    addressLine1,
    addressLine2,
    city,
    countryCode,
    setAddressLine1,
    setAddressLine2,
    setCity,
    setCountryCode,
    setZipCode,
    zipCode,
  } = useEditResource({ row, tempValue: '', triggerMarkupWarning });

  const [activeTerritoryId, setActiveTerritoryId] = activeTerritoryField;

  const isResource =
    activeTerritoryId === CategoryItems.Internal ||
    activeTerritoryId === CategoryItems.External;
  const isVendor = activeTerritoryId === CategoryItems.Vendor;

  const canCreateEntityBySettings = isResource
    ? organization?.entity_config?.resources?.allow_unverified
    : organization?.entity_config?.vendors?.allow_unverified;
  const canCreateEntity =
    checkAnyAccessRight(['administrator', 'super admin', 'owner']) ||
    canCreateEntityBySettings;

  const title = canCreateEntity
    ? isResource
      ? modalCopy.title
      : modalCopy.vendorTitle
    : isResource
    ? modalCopy.requestTitle
    : modalCopy.requestVendorTitle;

  return (
    <ConfigModal
      buttonProps={{
        label: canCreateEntity ? modalCopy.saveButton : modalCopy.requestButton,
        icon: 'check',
        onClick: save,
        disabled: isAnyInvalid,
        loading: isAnyLoading,
        dataCy: 'edit-resource-modal__save-button',
      }}
      title={title}
      onCloseClick={handleClose}
      color={currentColor}
    >
      <Styled.Wrapper>
        <Styled.Row>
          <Select
            label={modalCopy.listLabel}
            dataCy="edit-resource-modal__territory"
            value={activeTerritoryId}
            onValueChange={setActiveTerritoryId}
          >
            {territoryItems.map(({ id, displayName }) => (
              <SelectItem
                key={id}
                value={String(id)}
                dataCy={`${id}__menu-item`}
              >
                {displayName}
              </SelectItem>
            ))}
          </Select>
        </Styled.Row>
        {isResource ? (
          <ResourcePartial
            rolesDropdown={rolesDropdown}
            companyField={companyField}
            emailErrorMessage={emailErrorMessage}
            emailField={emailField}
            nameField={nameField}
            activeTerritoryField={activeTerritoryField}
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            city={city}
            countryCode={countryCode}
            setAddressLine1={setAddressLine1}
            setAddressLine2={setAddressLine2}
            setCity={setCity}
            setCountryCode={setCountryCode}
            setZipCode={setZipCode}
            zipCode={zipCode}
          />
        ) : null}
        {isVendor ? (
          <VendorPartial
            emailErrorMessage={emailErrorMessage}
            emailField={emailField}
            nameField={nameField}
            vendorServiceDropdown={vendorServiceDropdown}
            vendorServiceItems={vendorServiceItems}
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            city={city}
            countryCode={countryCode}
            setAddressLine1={setAddressLine1}
            setAddressLine2={setAddressLine2}
            setCity={setCity}
            setCountryCode={setCountryCode}
            setZipCode={setZipCode}
            zipCode={zipCode}
          />
        ) : null}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(EditResourceModal);
