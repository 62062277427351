import { FC, memo, useMemo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useAddClient } from 'queries/clients';
import { useOrganization } from 'queries/organizations';
import { useCopyStore, useGlobalStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import { Client } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './ClientRequestModal.styles';

export interface ClientRequestModalProps {
  clientName: Client['name'];
  onClientCreated: (id: Client['id']) => void;
}

const ClientRequestModal: FC<ClientRequestModalProps> = ({
  clientName,
  onClientCreated,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { data: organization } = useOrganization();
  const { checkAnyAccessRight } = useCheckAccessRights();
  const {
    mutateAsync: addClient,
    isLoading,
    isSuccess: isClientRequested,
  } = useAddClient();
  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
      name: {
        enabled: true,
        message: copy.app.validatorMessages.nameNoAtSign,
      },
    }),
    [
      copy.app.validatorMessages.nameNoAtSign,
      copy.app.validatorMessages.nameRequired,
    ]
  );
  const [name, setName, isNameValid, nameInvalidMessage] = useField(
    clientName,
    validators
  );
  const emailValidators = useMemo(
    () => ({
      email: {
        enabled: true,
        message: copy.app.validatorMessages.validEmail,
      },
    }),
    [copy.app.validatorMessages.validEmail]
  );
  const [email, setEmail, isEmailValid, emailInvalidMessage] = useField(
    '',
    emailValidators
  );

  const canUseFreshClientBySettings =
    organization?.entity_config?.clients?.allow_unverified;
  const canUseFreshClient =
    checkAnyAccessRight(['administrator', 'super admin', 'owner']) ||
    canUseFreshClientBySettings;

  const close = () => {
    closeModal('clientRequest');
  };

  const handleAddClient = async () => {
    const { data: client } = await addClient({
      name: name,
      ...(email ? { email: email } : {}),
    });
    onClientCreated(client.id);
    if (canUseFreshClient) {
      close();
    }
  };

  const isAnyInvalid = !isNameValid || !isEmailValid;

  return (
    <ConfigModal
      title={copy.app.clientRequestModal.title}
      buttonProps={
        isClientRequested && !canUseFreshClient
          ? {
              label: copy.app.clientRequestModal.successButton,
              color: currentColor,
              onClick: close,
              icon: 'check',
            }
          : {
              label: canUseFreshClient
                ? copy.app.clientRequestModal.ctaCanCreate
                : copy.app.clientRequestModal.cta,
              color: currentColor,
              onClick: handleAddClient,
              icon: 'check',
              disabled: isAnyInvalid || isLoading,
            }
      }
      color={currentColor}
      onCloseClick={close}
    >
      <Styled.Wrapper>
        {isClientRequested && !canUseFreshClient ? (
          <Styled.Description>
            {copy.app.clientRequestModal.success}
          </Styled.Description>
        ) : (
          <>
            {!canUseFreshClient && (
              <Styled.Description>
                {copy.app.clientRequestModal.description}
              </Styled.Description>
            )}

            <FormInput
              onChange={setName}
              value={name}
              placeholder={copy.app.clientRequestModal.name}
              error={nameInvalidMessage}
              required
            />
            <FormInput
              onChange={setEmail}
              value={email}
              placeholder={copy.app.clientRequestModal.email}
              error={emailInvalidMessage}
            />
          </>
        )}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(ClientRequestModal);
