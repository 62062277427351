import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60rem;
`;

export const Recurring = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40rem;
`;

export const Description = styled.div`
  color: ${rgba(colors.white, 0.5)};
  ${({ theme: { locale } }) => setTypography('body3', locale)};
  width: 100%;
  width: 1040rem;
`;

export const Custom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 40rem;
`;

export const CustomList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
  width: 100%;
`;

export const CustomListItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20rem;
  width: 100%;
`;
