import axios from 'axios';
import { useCallback, useMemo, useRef } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import { CardModalProps } from 'components/modals/CardModal/CardModal';
import { useCopyStore, useGlobalStore } from 'store';
import { ModalId } from 'store/global.types';
import { getErrorCode, knownErrorCodes } from 'utils/api.helpers';
import { ColorNames } from 'utils/styles/theme';

const useGetErrorProps = () => {
  const copy = useCopyStore(s => s.copy);
  const errorsCopy = copy.app.errorPopups;

  const apiCodes: Partial<
    Record<
      (typeof knownErrorCodes)[number],
      {
        title?: string;
        text?: string;
        subText?: string;
        modalId?: ModalId;
        type: CardModalProps['type'];
      }
    >
  > = useMemo(
    () => ({
      'user.last_owner': {
        title: errorsCopy.beCodes?.['user.last_owner']?.title || '',
        text: errorsCopy.beCodes?.['user.last_owner']?.text || '',
        subText: errorsCopy.beCodes?.['user.last_owner']?.subText || '',
        type: 'error',
      },
      'role.mfr_rate_exists': {
        title: errorsCopy.beCodes?.['role.mfr_rate_exists']?.title || '',
        text: errorsCopy.beCodes?.['role.mfr_rate_exists']?.text || '',
        subText: errorsCopy.beCodes?.['role.mfr_rate_exists']?.subText || '',
        type: 'error',
      },
      'mfr.resource_exists': {
        title: errorsCopy.beCodes?.['role.mfr_rate_exists']?.title || '',
        text: errorsCopy.beCodes?.['role.mfr_rate_exists']?.text || '',
        subText: errorsCopy.beCodes?.['role.mfr_rate_exists']?.subText || '',
        type: 'error',
      },
      'server.maintenance': {
        title: errorsCopy.beCodes?.['server.maintenance']?.title || '',
        text: errorsCopy.beCodes?.['server.maintenance']?.text || '',
        type: 'error',
      },
      'workspace.default_client_rate': {
        dataCy: 'default-client-rate-delete-erro?r',
        title:
          errorsCopy.beCodes?.['workspace.default_client_rate']?.title || '',
        text: errorsCopy.beCodes?.['workspace.default_client_rate']?.text || '',
        type: 'error',
      },
      'resource.exceeds_role_rate': {
        title: errorsCopy.beCodes?.['resource.exceeds_role_rate']?.title || '',
        text: errorsCopy.beCodes?.['resource.exceeds_role_rate']?.text || '',
        subText:
          errorsCopy.beCodes?.['resource.exceeds_role_rate']?.subText || '',
        type: 'error',
      },
      'role.resource_exists_with_higher_rate': {
        title:
          errorsCopy.beCodes?.['role.resource_exists_with_higher_rate']
            ?.title || '',
        text:
          errorsCopy.beCodes?.['role.resource_exists_with_higher_rate']?.text ||
          '',
        subText:
          errorsCopy.beCodes?.['role.resource_exists_with_higher_rate']
            ?.subText || '',
        type: 'error',
      },
      'reconcile.incorrect_job_number': {
        title:
          errorsCopy.beCodes?.['reconcile.incorrect_job_number']?.title || '',
        text:
          errorsCopy.beCodes?.['reconcile.incorrect_job_number']?.text || '',
        subText:
          errorsCopy.beCodes?.['reconcile.incorrect_job_number']?.subText || '',
        type: 'error',
      },
      'project.last_pitch': {
        title: errorsCopy.beCodes?.['project.last_pitch']?.title || '',
        text: errorsCopy.beCodes?.['project.last_pitch']?.text || '',
        subText: errorsCopy.beCodes?.['project.last_pitch']?.subText || '',
        type: 'error',
      },
      'email.does_not_exist': {
        title: errorsCopy.beCodes?.['email.does_not_exist']?.title || '',
        text: errorsCopy.beCodes?.['email.does_not_exist']?.text || '',
        subText: errorsCopy.beCodes?.['email.does_not_exist']?.subText || '',
        type: 'error',
      },
      'integration.auth_error': {
        title: errorsCopy.beCodes?.['integration.auth_error']?.title || '',
        text: errorsCopy.beCodes?.['integration.auth_error']?.text || '',
        subText: errorsCopy.beCodes?.['integration.auth_error']?.subText || '',
        type: 'error',
      },
      'project.job_number_not_found': {
        title:
          errorsCopy.beCodes?.['project.job_number_not_found']?.title || '',
        text: errorsCopy.beCodes?.['project.job_number_not_found']?.text || '',
        subText:
          errorsCopy.beCodes?.['project.job_number_not_found']?.subText || '',
        type: 'error',
      },
      'service_account.invalid_key': {
        title: errorsCopy.beCodes?.['service_account.invalid_key']?.title || '',
        text: errorsCopy.beCodes?.['service_account.invalid_key']?.text || '',
        subText:
          errorsCopy.beCodes?.['service_account.invalid_key']?.subText || '',
        type: 'error',
      },
      'project.locked': {
        modalId: 'projectLocked',
        type: 'error',
      },
      'permission.in_use': {
        title: errorsCopy.beCodes?.['permission.in_use']?.title || '',
        text: errorsCopy.beCodes?.['permission.in_use']?.text || '',
        subText: errorsCopy.beCodes?.['permission.in_use']?.subText || '',
        type: 'error',
      },
      'client.already_exists': {
        title: errorsCopy.beCodes?.['client.already_exists']?.title || '',
        text: errorsCopy.beCodes?.['client.already_exists']?.text || '',
        subText: errorsCopy.beCodes?.['client.already_exists']?.subText || '',
        type: 'error',
      },
      'brand.already_exists': {
        title: errorsCopy.beCodes?.['brand.already_exists']?.title || '',
        text: errorsCopy.beCodes?.['brand.already_exists']?.text || '',
        subText: errorsCopy.beCodes?.['brand.already_exists']?.subText || '',
        type: 'error',
      },
      'vendor_service.already_exists': {
        title:
          errorsCopy.beCodes?.['vendor_service.already_exists']?.title || '',
        text: errorsCopy.beCodes?.['vendor_service.already_exists']?.text || '',
        subText:
          errorsCopy.beCodes?.['vendor_service.already_exists']?.subText || '',
        type: 'error',
      },
    }),
    [errorsCopy]
  );

  const localCodes: Record<
    string,
    {
      title?: string;
      text?: string;
      subText?: string;
      modalId?: ModalId;
      type: CardModalProps['type'];
    }
  > = useMemo(
    () => ({
      feError: {
        title: errorsCopy.feError.title,
        text: errorsCopy.feError.text,
        subText: errorsCopy.feError.subText,
        type: 'error',
      },
      offlineError: {
        modalId: 'networkError',
        type: 'networkError',
        title: errorsCopy.offline.title,
        text: errorsCopy.offline.text,
        subText: errorsCopy.offline.subText,
      },
      uncaughtBeError: {
        title: errorsCopy.uncaughtBeError.title,
        text: errorsCopy.uncaughtBeError.text,
        subText: errorsCopy.uncaughtBeError.subText,
        type: 'networkError',
      },
    }),
    [errorsCopy]
  );

  const getErrorProps = useCallback(
    (error: unknown) => {
      if (axios.isAxiosError(error)) {
        if (!error.response) {
          return localCodes.offlineError;
        } else {
          const errCode = getErrorCode(error);
          const errSetup = errCode && apiCodes[errCode];
          return errSetup ? errSetup : localCodes.uncaughtBeError;
        }
      } else {
        return localCodes.feError;
      }
    },
    [apiCodes, localCodes]
  );

  return {
    getErrorProps,
  };
};

export const useErrorPopup = () => {
  const openModal = useGlobalStore(s => s.openModal);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const { getErrorProps } = useGetErrorProps();
  const pastErrorsRef = useRef<{ timestamp: number }[]>([]);

  const showError = useCallback(
    (error: unknown, variables?: any) => {
      const {
        modalId = 'commonError',
        type = 'error',
        title = '',
        text = '',
        subText = '',
      } = getErrorProps(error);

      const handleCloseClick = () => {
        closeModal(modalId);
      };

      pastErrorsRef.current = [
        ...pastErrorsRef.current,
        { timestamp: Date.now() },
      ];
      const errorsWithinLastMinute = pastErrorsRef.current.filter(
        err => err.timestamp > Date.now() - 60000
      );
      const showReloadButton = errorsWithinLastMinute.length >= 2;

      openModal<CardModalProps>(modalId, {
        type,
        handleCloseClick,
        title,
        text,
        subText,
        buttons: (
          <>
            {showReloadButton ? (
              <Button
                label={copy.app.reload}
                color={ColorNames.nandor}
                onClick={() => window.location.reload()}
                icon={'revert'}
              />
            ) : null}
            <Button
              label={copy.app.ok}
              color={ColorNames.blueRibbon}
              onClick={handleCloseClick}
              icon={'check'}
            />
          </>
        ),
        context: variables,
      });
    },
    [closeModal, copy.app.ok, copy.app.reload, getErrorProps, openModal]
  );
  return {
    showError,
  };
};
