import { useCallback, useEffect, useMemo, useState } from 'react';

import { useField } from 'components/inputs/hooks/useField';
import { AdminListItem } from 'components/modules/Admin/hooks/useAdminListColumns';
import { useCommonErrorHandling } from 'hooks/useCommonErrorHandling';
import { useAddResource, useUpdateResource } from 'queries/resources';
import { useSupportedMfrRate } from 'queries/supportedMfrRates';
import { useCopyStore } from 'store';
import { getErrorCode } from 'utils/api.helpers';
import { ResourceType } from 'utils/api.types';

import { useDepartmentDropdown } from '../../hooks/useDepartmentDropdown';
import { useRolesDropdown } from '../../hooks/useRolesDropdown';
import { useTypeDropdown } from '../../hooks/useTypeDropdown';

export const useResourcePartial = (item?: AdminListItem) => {
  const copy = useCopyStore(s => s.copy);
  const { data: supportedMfrRates } = useSupportedMfrRate();
  const { commonErrorHandler } = useCommonErrorHandling();

  const [emailUniqueError, setEmailUniqueError] = useState(false);

  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.resourceNameRequired,
      },
      name: {
        enabled: true,
        message: copy.app.validatorMessages.nameNoAtSign,
      },
    }),
    [
      copy.app.validatorMessages.nameNoAtSign,
      copy.app.validatorMessages.resourceNameRequired,
    ]
  );
  const resourceNameField = useField(item?.name ?? '', validators);

  const emailValidators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.emailRequired,
      },
      email: { enabled: true, message: copy.app.validatorMessages.validEmail },
    }),
    [
      copy.app.validatorMessages.emailRequired,
      copy.app.validatorMessages.validEmail,
    ]
  );
  const emailField = useField(item?.email ?? '', emailValidators);

  const companyField = useField(item?.companyName ?? '');
  const rateValidators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.rateRequired,
      },
      numberGreaterThanZero: {
        enabled: true,
        message: copy.app.validatorMessages.numberGreaterThanZero,
      },
    }),
    [
      copy.app.validatorMessages.numberGreaterThanZero,
      copy.app.validatorMessages.rateRequired,
    ]
  );
  const rateField = useField(
    item?.rate ? String(item?.rate) : '',
    rateValidators
  );

  const rolesDropdown = useRolesDropdown(item);
  const { activeRoleId } = rolesDropdown;

  const departmentDropdown = useDepartmentDropdown(item);
  const { activeDepartmentId, departmentToCreate } = departmentDropdown;

  const typeDropdown = useTypeDropdown(item);

  const supportedMfrRatesItems = supportedMfrRates?.map(({ code, id }) => ({
    id,
    displayName: code,
  }));

  const [activeMfrRateId, setActiveMfrRateId] = useState(
    item?.mfrId ?? supportedMfrRates?.[0]?.id
  );

  const [addressLine1, setAddressLine1] = useState(
    item?.address?.address_lines[0] ?? ''
  );
  const [addressLine2, setAddressLine2] = useState(
    item?.address?.address_lines[1] ?? ''
  );
  const [city, setCity] = useState(item?.address?.city ?? '');
  const [zipCode, setZipCode] = useState(item?.address?.postal_code ?? '');
  const [countryCode, setCountryCode] = useState<string | null>(
    item?.address?.country_code ?? null
  );

  const isPartialValid = useMemo(() => {
    const { activeTypeId } = typeDropdown;
    const [, , isResourceNameValid] = resourceNameField;
    const [, , isEmailValid] = emailField;
    const [, , isCompanyValid] = companyField;
    const [, , isRateFieldValid] = rateField;
    return (
      activeRoleId &&
      isResourceNameValid &&
      isEmailValid &&
      (activeTypeId === ResourceType.internal || isCompanyValid) &&
      (activeTypeId !== ResourceType.mfr || isRateFieldValid)
    );
  }, [
    typeDropdown,
    resourceNameField,
    emailField,
    companyField,
    rateField,
    activeRoleId,
  ]);

  const [email] = emailField;

  useEffect(() => {
    setEmailUniqueError(false);
  }, [email]);

  useEffect(() => {
    setActiveMfrRateId(item?.mfrId ?? supportedMfrRates?.[0]?.id);
  }, [item?.mfrId, setActiveMfrRateId, supportedMfrRates]);

  const handleError = useCallback(
    (error: unknown) => {
      commonErrorHandler({
        error,
        handledLocally: ['resource.email_not_unique'],
        localHandler(error) {
          if (getErrorCode(error as Error) === 'resource.email_not_unique') {
            setEmailUniqueError(true);
          }
        },
      });
    },
    [commonErrorHandler]
  );

  const { mutateAsync: handleAdd } = useAddResource({
    onError: handleError,
  });
  const { mutateAsync: handleUpdate } = useUpdateResource({
    onError: handleError,
  });

  const save = useCallback(
    async (callback: (success?: boolean) => void) => {
      const [resourceName] = resourceNameField;
      const [email] = emailField;
      const [companyName] = companyField;
      const [rate] = rateField;

      try {
        if (activeRoleId) {
          await handleAdd({
            email,
            type: typeDropdown.activeTypeId,
            ...(typeDropdown.activeTypeId !== ResourceType.internal
              ? {
                  rate: Number(rate),
                  company_name: companyName,
                }
              : {}),
            ...(typeDropdown.activeTypeId === ResourceType.mfr
              ? {
                  mfr_id: Number(activeMfrRateId),
                }
              : {}),
            name: resourceName,
            role_id: activeRoleId,
            department: departmentToCreate || activeDepartmentId || '',
            ...(countryCode &&
            (addressLine1.length > 2 || addressLine2.length > 2)
              ? {
                  address: {
                    address_lines: [addressLine1, addressLine2],
                    city,
                    postal_code: zipCode,
                    country_code: countryCode ?? '',
                  },
                }
              : {}),
          });
        }

        callback?.();
      } catch (error) {
        callback?.(false);
      }
    },
    [
      resourceNameField,
      emailField,
      companyField,
      rateField,
      activeRoleId,
      handleAdd,
      typeDropdown.activeTypeId,
      activeMfrRateId,
      departmentToCreate,
      activeDepartmentId,
      countryCode,
      addressLine1,
      addressLine2,
      city,
      zipCode,
    ]
  );

  const update = useCallback(
    async (callback: (success?: boolean) => void) => {
      const [resourceName] = resourceNameField;
      const [email] = emailField;
      const [companyName] = companyField;
      const [rate] = rateField;

      try {
        if (item?.resourceId && activeRoleId) {
          await handleUpdate({
            id: item?.resourceId,
            email,
            type: typeDropdown.activeTypeId,
            ...(typeDropdown.activeTypeId !== ResourceType.internal
              ? {
                  rate: Number(rate),
                  company_name: companyName,
                }
              : {}),
            ...(typeDropdown.activeTypeId === ResourceType.mfr
              ? {
                  mfr_id: Number(activeMfrRateId),
                }
              : {}),
            name: resourceName,
            role_id: activeRoleId,
            department: departmentToCreate || activeDepartmentId || '',
            ...(countryCode &&
            (addressLine1.length > 2 || addressLine2.length > 2)
              ? {
                  address: {
                    address_lines: [addressLine1, addressLine2],
                    city,
                    postal_code: zipCode,
                    country_code: countryCode ?? '',
                  },
                }
              : {}),
          });
        }

        callback?.();
      } catch (error) {
        callback?.(false);
      }
    },
    [
      resourceNameField,
      emailField,
      companyField,
      rateField,
      item?.resourceId,
      activeRoleId,
      handleUpdate,
      typeDropdown.activeTypeId,
      activeMfrRateId,
      departmentToCreate,
      activeDepartmentId,
      countryCode,
      addressLine1,
      addressLine2,
      city,
      zipCode,
    ]
  );

  return {
    resourceNameField,
    emailField,
    companyField,
    departmentDropdown,
    rateField,
    isPartialValid,
    emailUniqueError,
    rolesDropdown,
    save,
    update,
    activeMfrRateId,
    setActiveMfrRateId,
    supportedMfrRatesItems,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    city,
    setCity,
    zipCode,
    setZipCode,
    countryCode,
    setCountryCode,
    typeDropdown,
  };
};
