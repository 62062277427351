import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';

import {
  getHasQuery,
  parseDashboardParams,
} from 'containers/PerformanceDashboardPage/utils/parseDashboardParams';
import {
  useApiStore,
  useGlobalStore,
  usePerformanceDashboardStore,
} from 'store';
import { PerformanceDashboardFilters } from 'store/performanceDashboard';
import {
  PerformanceDashboardBudget,
  PerformanceDashboardInput,
  ProjectsPerformance,
} from 'utils/api.types';

import { useOrganization } from './organizations';

export const PROJECTS_PERFORMANCE_KEY = 'PROJECTS_PERFORMANCE';

// A lot of producers fill in costs without providing example invoice.
// This causes a very high negative margin. Numbers mess up with charts
// Filtering those out from budget history
//! This is temporary solution, we need a permanent solution and data cleared from database.
const filterNegativeMarginBudgetHistoryItems = (
  data: ProjectsPerformance
): ProjectsPerformance => {
  const projects = data.projects?.map(project => {
    if (!project.budget_history) {
      return project;
    }

    const filteredBudgetHistory = Object.fromEntries(
      Object.entries(project.budget_history).filter(([, budget]) => {
        if (budget.total_budget === 0 && budget.margin <= 0) return false;
        return true;
      })
    );

    return {
      ...project,
      budget_history: filteredBudgetHistory,
    };
  });

  return {
    ...data,
    projects,
  };
};

// There's no way to edit historic data right now, but it can mess with performance dashboard
// Limiting burn so that it can't go over 125%
const limitMaxBurnValue = (
  data: ProjectsPerformance,
  allowedOverburn?: number
): ProjectsPerformance => {
  if (!allowedOverburn) {
    return data;
  }
  const projects = data.projects?.map(project => {
    if (!project.budget_history) {
      return project;
    }

    const maxBurnValueBudgetHistory = Object.fromEntries(
      Object.entries(project.budget_history).map(([key, budget]) => {
        return [
          key,
          {
            ...budget,
            percent_complete: Math.min(
              budget.percent_complete,
              allowedOverburn
            ),
          },
        ];
      })
    );

    return {
      ...project,
      budget_history: maxBurnValueBudgetHistory,
    };
  });

  return {
    ...data,
    projects,
  };
};

const enhanceProjectsPerformance = (
  data: ProjectsPerformance
): ProjectsPerformance => {
  const getIntPercent = (internalTotalCost: number, totalBudget: number) => {
    if (!totalBudget) return 0;
    return (internalTotalCost / totalBudget) * 100;
  };

  const getExtPercent = (externalTotalCost: number, totalBudget: number) => {
    if (!totalBudget) return 0;
    return (externalTotalCost / totalBudget) * 100;
  };

  const getIntPercentRatio = (
    internalTotalCost: number,
    externalTotalCost: number
  ) => {
    const totalCost = internalTotalCost + externalTotalCost;
    if (!totalCost) return 0;
    return (internalTotalCost / totalCost) * 100;
  };

  const getExtPercentRatio = (
    internalTotalCost: number,
    externalTotalCost: number
  ) => {
    const totalCost = internalTotalCost + externalTotalCost;
    if (!totalCost) return 0;
    return (externalTotalCost / totalCost) * 100;
  };

  const getMfrMarginPercent = (
    mfrMargin: Record<string, number>,
    totalBudget: number
  ) => {
    return Object.fromEntries(
      Object.entries(mfrMargin || {}).map(([key, value]) => [
        key,
        totalBudget === 0 ? 0 : (value / totalBudget) * 100,
      ])
    );
  };

  const projects = data.projects.map(project => {
    const budgetHistory = project?.budget_history
      ? Object.fromEntries(
          Object.entries(project?.budget_history).map(([key, budget]) => {
            return [
              key,
              {
                ...budget,
                internal_percent: getIntPercent(
                  budget.internal_total_cost,
                  budget.total_budget
                ),
                external_percent: getExtPercent(
                  budget.external_total_cost,
                  budget.total_budget
                ),
                internal_percent_ratio: getIntPercentRatio(
                  budget.internal_total_cost,
                  budget.external_total_cost
                ),
                external_percent_ratio: getExtPercentRatio(
                  budget.internal_total_cost,
                  budget.external_total_cost
                ),
                mfr_margin_percent: getMfrMarginPercent(
                  budget.mfr_margin || {},
                  budget.total_budget
                ),
              },
            ];
          })
        )
      : project?.budget_history;

    project.budget.internal_percent = getIntPercent(
      project.budget.internal_total_cost,
      project.budget.total_budget
    );
    project.budget.external_percent = getExtPercent(
      project.budget.external_total_cost,
      project.budget.total_budget
    );
    project.budget.internal_percent_ratio = getIntPercentRatio(
      project.budget.internal_total_cost,
      project.budget.external_total_cost
    );
    project.budget.external_percent_ratio = getExtPercentRatio(
      project.budget.internal_total_cost,
      project.budget.external_total_cost
    );
    project.budget.mfr_margin_percent = getMfrMarginPercent(
      project.budget.mfr_margin || {},
      project.budget.total_budget
    );

    return {
      ...project,
      budget_history: budgetHistory,
    };
  });

  return {
    ...data,
    projects,
  };
};

export type SubtractableBudget = Pick<
  PerformanceDashboardBudget,
  | 'external_total_cost'
  | 'internal_total_cost'
  | 'margin'
  | 'pass_through_cost'
  | 'total_budget'
  | 'true_margin'
  | 'mfr_margin'
>;

export const subtractBudgets = (
  budget1: Partial<PerformanceDashboardBudget>,
  budget2?: SubtractableBudget
) => {
  if (!budget2) return budget1;
  return {
    ...budget1,
    external_total_cost:
      (budget1.external_total_cost || 0) - budget2.external_total_cost || 0,
    total_budget: (budget1.total_budget || 0) - budget2.total_budget || 0,
    internal_total_cost:
      (budget1.internal_total_cost || 0) - budget2.internal_total_cost || 0,
    margin: (budget1.margin || 0) - budget2.margin || 0,
    true_margin: (budget1.true_margin || 0) - budget2.true_margin || 0,
    pass_through_cost:
      (budget1.pass_through_cost || 0) - (budget2.pass_through_cost || 0) || 0,
    mfr_margin: Object.fromEntries(
      Object.entries(budget1.mfr_margin || {}).map(([key, value]) => [
        key,
        value - (budget2.mfr_margin?.[key] || 0),
      ])
    ),
  };
};

const projectsPerformanceSelect =
  (allowedOverburn?: number) =>
  (data: ProjectsPerformance): ProjectsPerformance => {
    return enhanceProjectsPerformance(
      limitMaxBurnValue(
        filterNegativeMarginBudgetHistoryItems(data),
        allowedOverburn
      )
    );
  };

export const useProjectsPerformance = (
  variables?: PerformanceDashboardInput,
  options?: UseQueryOptions<ProjectsPerformance>
) => {
  const getProjectsPerformance = useApiStore(
    s => s.apiClient.getProjectsPerformance
  );
  const { data: organization } = useOrganization();

  return useQuery({
    queryKey: [PROJECTS_PERFORMANCE_KEY, variables],
    queryFn: async () =>
      variables
        ? (await getProjectsPerformance(variables)).data
        : Promise.reject('No variables'),
    select: projectsPerformanceSelect(organization?.allowed_overburn),
    ...options,
  });
};

export const useProjectsPerformanceFiltered = (
  options?: UseQueryOptions<ProjectsPerformance>
) => {
  const filters = usePerformanceDashboardStore();
  const { data: organization } = useOrganization();
  const [filtersRef, setFiltersRef] =
    useState<PerformanceDashboardFilters>(filters);
  const router = useRouter();
  const openModalIds = useGlobalStore(s => s.openModalIds);

  useEffect(() => {
    if (!openModalIds.includes('performanceFiltersModal')) {
      setFiltersRef(filters);
    }
  }, [filters, openModalIds]);

  const hasQuery = getHasQuery(router.query);

  return {
    ...useProjectsPerformance(
      parseDashboardParams(filtersRef, organization, hasQuery),
      {
        enabled:
          !openModalIds.includes('performanceFiltersModal') &&
          !!filtersRef.startDate &&
          !!filtersRef.endDate,
        ...options,
      }
    ),
    queryFilters: filtersRef,
  };
};
