import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsFetching } from 'react-query';

import { useField } from 'components/inputs/hooks/useField';
import { shouldAutoUpdateMargin } from 'components/modules/Project/helpers/shouldAutoUpdateMargin';
import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useCommonErrorHandling } from 'hooks/useCommonErrorHandling';
import { useOrganization } from 'queries/organizations';
import { PROJECTS_KEY, useCurrentProject } from 'queries/project';
import { useUpdateProjectRows } from 'queries/projectRows';
import { RESOURCES_KEY, useAddResource } from 'queries/resources';
import { useAddVendor, VENDORS_KEY } from 'queries/vendors';
import { useVendorServices } from 'queries/vendorServices';
import { useCopyStore, useGlobalStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import { getErrorCode } from 'utils/api.helpers';
import {
  ProjectRow,
  Resource,
  ResourceType,
  VendorService,
  VendorUpdate,
} from 'utils/api.types';

import { CategoryItems } from '../EditResourceModal.types';
import { useRolesDropdown } from './useRolesDropdown';

export interface EditResourceProps {
  row: ProjectRow;
  tempValue: string;
  triggerMarkupWarning: (rowId: ProjectRow['id']) => void;
}

export const useEditResource = ({
  row,
  tempValue,
  triggerMarkupWarning,
}: EditResourceProps) => {
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const companyField = useField('');
  const [companyName] = companyField;
  const currentProject = useCurrentProject();
  const copy = useCopyStore(s => s.copy);
  const isFetchingResources = useIsFetching(RESOURCES_KEY);
  const isFetchingVendors = useIsFetching(VENDORS_KEY);
  const isFetchingProject = useIsFetching([PROJECTS_KEY, currentProject?.id]);
  const { data: vendorServices } = useVendorServices();
  const closeModal = useGlobalStore(s => s.closeModal);
  const { commonErrorHandler } = useCommonErrorHandling();
  const currentVersionId = useCurrentVersionId();
  const { data: organization } = useOrganization();
  const { checkAnyAccessRight } = useCheckAccessRights();

  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [countryCode, setCountryCode] = useState<string | null>(null);

  const handleUniqueEmailError = useCallback(() => {
    setEmailErrorMessage(copy.app.validatorMessages.emailNotUnique);
  }, [copy]);

  const handleError = useCallback(
    (error: unknown) => {
      commonErrorHandler({
        error,
        handledLocally: [
          'resource.email_not_unique',
          'vendor.email_not_unique',
        ],
        localHandler(error) {
          if (getErrorCode(error) === 'resource.email_not_unique') {
            handleUniqueEmailError();
          } else if (getErrorCode(error) === 'vendor.email_not_unique') {
            handleUniqueEmailError();
          }
        },
      });
    },
    [commonErrorHandler, handleUniqueEmailError]
  );

  const { mutateAsync: handleAddResource, isLoading: addResourceLoading } =
    useAddResource({
      onError: handleError,
    });

  const {
    mutateAsync: handleUpdateProjectRows,
    isLoading: projectRowsLoading,
  } = useUpdateProjectRows();

  const { mutateAsync: handleAddVendor, isLoading: addVendorLoading } =
    useAddVendor({
      onError: handleError,
    });

  const territoryItems = [
    {
      id: CategoryItems.Internal,
      displayName:
        copy.project.fillResourceInformation.editResourceModal.internal,
    },
    {
      id: CategoryItems.External,
      displayName:
        copy.project.fillResourceInformation.editResourceModal.external,
    },
    {
      id: CategoryItems.Vendor,
      displayName:
        copy.project.fillResourceInformation.editResourceModal.vendor,
    },
  ];

  const rolesDropdown = useRolesDropdown(row);
  const { activeRoleId } = rolesDropdown;

  const vendorServiceItems = vendorServices?.map(vendorService => ({
    id: vendorService.id,
    displayName: vendorService.name,
  }));

  const vendorServiceDropdown = useState<VendorService['id'] | null>(
    row.vendor_service_id ?? null
  );
  const [activeVendorServiceId] = vendorServiceDropdown;

  const isAnyLoading =
    addResourceLoading ||
    addVendorLoading ||
    projectRowsLoading ||
    isFetchingProject > 0 ||
    isFetchingResources > 0 ||
    isFetchingVendors > 0;

  const activeTerritoryField = useState<string>(
    row.type === 'resource'
      ? row.resource_type === ResourceType.external
        ? CategoryItems.External
        : CategoryItems.Internal
      : CategoryItems.Vendor
  );
  const [activeTerritoryId] = activeTerritoryField;

  const emailValidators = useMemo(
    () => ({
      email: {
        enabled: true,
        message: copy.app.validatorMessages.validEmail,
      },
      required: {
        enabled: true,
        message: copy.app.validatorMessages.emailRequired,
      },
    }),
    [
      copy.app.validatorMessages.emailRequired,
      copy.app.validatorMessages.validEmail,
    ]
  );
  const emailField = useField('', emailValidators);
  const [email, , isEmailValid] = emailField;

  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.resourceNameRequired,
      },
      name: {
        enabled: true,
        message: copy.app.validatorMessages.nameNoAtSign,
      },
    }),
    [
      copy.app.validatorMessages.nameNoAtSign,
      copy.app.validatorMessages.resourceNameRequired,
    ]
  );
  const nameField = useField(tempValue, validators);
  const [resourceName, , isResourceNameValid, ,] = nameField;

  const isRoleRequired = activeTerritoryId !== CategoryItems.Vendor;
  const isAnyInvalid =
    !isResourceNameValid || !isEmailValid || (isRoleRequired && !activeRoleId);

  const isResource =
    activeTerritoryId === CategoryItems.Internal ||
    activeTerritoryId === CategoryItems.External;
  const isVendor = activeTerritoryId === CategoryItems.Vendor;

  const canCreateEntityBySettings = isResource
    ? organization?.entity_config?.resources?.allow_unverified
    : organization?.entity_config?.vendors?.allow_unverified;
  const canCreateResource =
    checkAnyAccessRight(['administrator', 'super admin', 'owner']) ||
    canCreateEntityBySettings;

  const saveResource = async () => {
    setEmailErrorMessage('');
    const roleId = activeRoleId || row.role_id;
    const isExternal = activeTerritoryId === CategoryItems.External;
    if (!isAnyInvalid && roleId && currentProject?.id && currentVersionId) {
      const resource: Omit<Resource, 'id'> = {
        name: resourceName,
        role_id: roleId,
        type: isExternal ? ResourceType.external : ResourceType.internal,
        email,
        company_name: isExternal ? companyName : '',
        ...(countryCode && (addressLine1.length > 2 || addressLine2.length > 2)
          ? {
              address: {
                address_lines: [addressLine1, addressLine2],
                city,
                postal_code: zipCode,
                country_code: countryCode ?? '',
              },
            }
          : {}),
      };
      try {
        const addedResource = await handleAddResource(resource);
        if (!addedResource?.data) return;

        if (canCreateResource) {
          const { setMarkup, markupToSet, resourceTypeChanged } =
            shouldAutoUpdateMargin(
              row,
              isExternal ? ResourceType.external : ResourceType.internal
            );
          const updateResponse = await handleUpdateProjectRows({
            projectId: currentProject?.id,
            versionId: currentVersionId,
            projectRows: [
              {
                id: row.id,
                resource_id: addedResource.data.id,
                role_id: roleId,
                ...(setMarkup && markupToSet && { markup: markupToSet }),
              },
            ],
          });
          if (resourceTypeChanged && !setMarkup) triggerMarkupWarning(row.id);
          if (!updateResponse?.data) return;
        }

        handleClose();
      } catch (error) {}
    }
  };

  const saveVendor = async () => {
    if (currentProject?.id && currentVersionId) {
      const vendorServiceId =
        (Number(activeVendorServiceId) as VendorService['id']) ||
        row.vendor_service_id;
      if (isAnyInvalid) return;

      const vendor: Omit<VendorUpdate, 'id'> = {
        name: resourceName,
        email,
        service_id: vendorServiceId || undefined,
        ...(countryCode && (addressLine1.length > 2 || addressLine2.length > 2)
          ? {
              address: {
                address_lines: [addressLine1, addressLine2],
                city,
                postal_code: zipCode,
                country_code: countryCode ?? '',
              },
            }
          : {}),
      };
      try {
        const addedVendor = await handleAddVendor(vendor);
        if (!addedVendor?.data) return;

        if (canCreateResource) {
          await handleUpdateProjectRows({
            projectId: currentProject?.id,
            versionId: currentVersionId,
            projectRows: [
              {
                id: row.id,
                vendor_id: addedVendor.data.id,
                vendor_service_id: vendorServiceId || undefined,
              },
            ],
          });
        }

        handleClose();
      } catch (error) {}
    }
  };

  const save = () => {
    if (isResource) {
      saveResource();
    } else if (isVendor) {
      saveVendor();
    }
  };

  useEffect(() => {
    setEmailErrorMessage('');
  }, []);

  const handleClose = () => {
    closeModal('editResource');
  };

  return {
    isAnyLoading,
    isAnyInvalid,
    nameField,
    activeTerritoryField,
    territoryItems,
    save,
    emailField,
    companyField,
    handleClose,
    emailErrorMessage,
    rolesDropdown,
    vendorServiceItems,
    vendorServiceDropdown,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    city,
    setCity,
    zipCode,
    setZipCode,
    countryCode,
    setCountryCode,
  };
};
