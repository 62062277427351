import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import {
  ClientDuplicate,
  DuplicateType,
  ResourceDuplicate,
} from 'utils/api.types';

import { ARCHIVE_KEY } from './archive';
import { RESOURCES_KEY } from './resources';

export const DUPLICATES_KEY = 'DUPLICATES';

export const useDuplicates = <T extends DuplicateType>(
  type: T,
  options?: UseQueryOptions<
    T extends 'resource'
      ? ResourceDuplicate[][]
      : T extends 'clients'
      ? ClientDuplicate[][]
      : never
  >
) => {
  const getDuplicates = useApiStore(s => s.apiClient.getDuplicates);
  return useQuery({
    queryKey: [DUPLICATES_KEY, type],
    queryFn: async () => (await getDuplicates(type)).data,
    ...options,
  });
};

export const useResolveDuplicates = () => {
  const resolveDuplicates = useApiStore(s => s.apiClient.resolveDuplicates);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof resolveDuplicates>[0]) =>
      resolveDuplicates(variables),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(DUPLICATES_KEY);
        queryClient.invalidateQueries(ARCHIVE_KEY);
        if (variables.type === 'resource') {
          queryClient.invalidateQueries(RESOURCES_KEY);
        }
      },
    }
  );
};
