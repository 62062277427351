import { useCallback } from 'react';

import { AdminItemModalProps } from 'components/modals/AdminItemModal/AdminItemModal';
import { useGlobalStore } from 'store';

export const useAdminItemModal = (
  modalId: 'adminItemModal' | 'adminItemModal2'
) => {
  const openModal = useGlobalStore(s => s.openModal);

  const handleOpenAdminItemModal = useCallback(
    (
      initialItem?: AdminItemModalProps['item'],
      options?: AdminItemModalProps['options']
    ) => {
      openModal<AdminItemModalProps>(modalId, {
        item: initialItem,
        options,
      });
    },
    [modalId, openModal]
  );

  return {
    handleOpenAdminItemModal,
  };
};
