import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { brightnessFilter } from '@u9/bob3-shared/lib/utils/styles/mixins';
import { focusVisible, hover, setTypography, setVh } from 'utils/styles/mixins';
import { ColorNames, colors, layout } from 'utils/styles/theme';

export const TabsWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const TabsSelector = styled.div<{ isEmpty: boolean }>`
  display: flex;
  width: 100%;
  padding-right: 80rem;
  border-right: ${({ isEmpty }) =>
    isEmpty ? 0 : `1px solid ${rgba(colors.white, 0.2)}`};
`;

export const ItemsWrapper = styled.div<{ rowsNumber: number }>`
  display: grid;
  grid-template-rows: ${({ rowsNumber }) =>
    `repeat(${rowsNumber > 0 ? rowsNumber : 1}, 1fr)`};
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  align-items: start;
`;

export const ResourceItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60rem;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30rem;
`;

export const Wrapper = styled.div<{ maxNumberOfRows: number }>`
  width: 2060rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 50rem 80rem 0;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  margin-top: 70rem;
  position: relative;
`;

export const ResourceLabel = styled.div<{ isActive: boolean }>`
  ${({ theme: { locale } }) => setTypography('body4', locale)}
  cursor: pointer;
  color: ${rgba(colors.white, 0.5)};
  user-select: none;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isActive }) =>
    isActive
      ? css`
          color: ${colors.white};
        `
      : ''}

  ${hover(brightnessFilter)}
`;

export const Counter = styled.button<{
  active: boolean;
  activeColor: ColorNames;
}>`
  min-width: 60rem;
  min-height: 60rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme: { locale } }) => setTypography('caption', locale)}
  border: 1px solid ${rgba(colors.white, 0.25)};
  color: ${rgba(colors.white, 0.5)};
  border-radius: ${layout.borderRadius.small}rem;
  margin-right: 40rem;
  user-select: none;
  transition: background 0.2s ease-out;

  :focus-visible {
    ${focusVisible};
  }

  ${({ active, activeColor }) =>
    active
      ? css`
          background: ${colors[activeColor]};
          color: ${colors.white};
          border: 0;
        `
      : ''}
`;

export const CounterInput = styled.input`
  max-width: 55rem;
  max-height: 55rem;
  appearance: none;
  -moz-appearance: textfield;
  text-align: center;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Loader = styled.div`
  width: 100%;
  height: 800rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
`;

export const TopRightButton = styled.div``;

export const Input = styled.div`
  flex: 1;
  margin-right: 70rem;
`;

export const AdditionalButton = styled.div`
  ${({ theme: { locale } }) => setTypography('body4', locale)};
  border-bottom: 1px solid ${colors.white};
  width: fit-content;
  cursor: pointer;
  position: relative;
  left: 450rem;
  ${hover(brightnessFilter)};
  z-index: 10;
`;

export const ItemsOuterWrapper = styled.div<{
  maxNumberOfRows: number;
}>`
  display: flex;
  flex-direction: column;
  padding-left: 80rem;
  flex: 1;
  overflow-y: auto;
  position: relative;
  max-height: calc(${setVh(90)} - 650rem);
`;

export const EmptyLabel = styled.div``;

export const SuggestedItemsWrapper = styled.div``;

export const SuggestedItemsLabel = styled.div`
  margin: 20rem 0 40rem;
`;
