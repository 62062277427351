import { useRouter } from 'next/router';
import { FC, memo, useMemo, useState } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useCommonErrorHandling } from 'hooks/useCommonErrorHandling';
import { useJobNumberPrefix } from 'hooks/useJobNumberPrefix';
import useProjectDisplayText from 'hooks/useProjectDisplayText';
import { useCreateNotification } from 'queries/notifications';
import {
  useCurrentProject,
  useMovePitchToProduction,
  useUpdateProject,
} from 'queries/project';
import { useProjectVersion } from 'queries/projectRows';
import { useCopyStore, useGlobalStore } from 'store';
import { getErrorCode } from 'utils/api.helpers';
import { ProjectMode } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './JobNumberFillModal.styles';

export interface JobNumberFillModalProps {}

const JobNumberFillModal: FC<JobNumberFillModalProps> = () => {
  const router = useRouter();
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const currentProject = useCurrentProject();
  const [isDuplicateError, setIsDuplicateError] = useState(false);
  const { commonErrorHandler } = useCommonErrorHandling();
  const currentVersionId = useCurrentVersionId();
  const { data: currentVersion } = useProjectVersion(
    {
      projectId: currentProject?.id,
      projectVersionId: currentProject?.main_version_id,
    },
    { enabled: !!currentProject?.id }
  );
  const mainVersionMode = currentVersion?.mode;
  const alreadyHasJobNumber = mainVersionMode !== ProjectMode.pitch;

  const handleError = (error: unknown) => {
    commonErrorHandler({
      error,
      handledLocally: ['project.job_number_not_unique'],
      localHandler(error) {
        if (getErrorCode(error) === 'project.job_number_not_unique') {
          setIsDuplicateError(true);
        }
      },
    });
  };

  const { mutateAsync: updateProject, isLoading: updateLoading } =
    useUpdateProject({
      onError: handleError,
    });
  const { mutateAsync: movePitchToProduction, isLoading: copyLoading } =
    useMovePitchToProduction();
  const { mutateAsync: sendNotification, isLoading: sendNotificationLoading } =
    useCreateNotification();

  const isAnyLoading = updateLoading || copyLoading || sendNotificationLoading;

  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.jobNumberRequired,
      },
    }),
    [copy.app.validatorMessages.jobNumberRequired]
  );
  const [jobNumber, setJobNumber, isValid, invalidMessage] = useField(
    '',
    validators
  );

  const [forceError, setForceError] = useState(false);

  const getStatusDisplayText = useProjectDisplayText();
  const { addJobNumberPrefix } = useJobNumberPrefix();

  const handleConfirm = async () => {
    setForceError(false);
    if (
      isValid &&
      currentProject?.id &&
      currentVersionId &&
      (router.query.mode === ProjectMode.production ||
        router.query.mode === ProjectMode.hosting)
    ) {
      await updateProject({
        projectId: currentProject?.id,
        project: {
          job_number: addJobNumberPrefix(jobNumber, currentProject.mode),
          main_version_id: currentVersionId,
        },
      });
      await movePitchToProduction({
        projectId: currentProject?.id,
        versionId: currentVersionId,
        mode: router.query.mode,
      });
      if (router.query.receiverUserId) {
        const receiverUserId = Number(router.query.receiverUserId);
        await sendNotification({
          msg_code: 'job_number.approved',
          projectId: currentProject?.id,
          receiver_user_id: receiverUserId,
        });
      }
      delete router.query.fillJobNumberModal;
      delete router.query.receiverUserId;
      router.replace(
        {
          query: router.query,
        },
        undefined,
        { shallow: true }
      );
      closeModal('jobNumberFillModal');
    } else {
      setForceError(true);
    }
  };

  return (
    <ConfigModal
      title={copy.project.requestJobNumber.modalTitle}
      color={currentColor}
      onCloseClick={() => closeModal('jobNumberFillModal')}
      buttonProps={{
        label: alreadyHasJobNumber
          ? copy.project.requestJobNumber.alreadyFilledButton
          : copy.project.requestJobNumber.saveButton,
        icon: 'check',
        color: currentColor,
        onClick: alreadyHasJobNumber
          ? () => closeModal('jobNumberFillModal')
          : handleConfirm,
        disabled: isAnyLoading,
        loading: isAnyLoading,
      }}
    >
      <Styled.Wrapper>
        {alreadyHasJobNumber ? (
          <div>{copy.project.requestJobNumber.alreadyFilledMessage}</div>
        ) : (
          <>
            {router.query.mode === ProjectMode.production ||
              (router.query.mode === ProjectMode.hosting && (
                <Styled.Data>
                  <Styled.Label>
                    {copy.project.requestJobNumber.dropdownLabel}
                  </Styled.Label>
                  <Styled.Value>
                    {getStatusDisplayText(router.query.mode)}
                  </Styled.Value>
                </Styled.Data>
              ))}

            <Styled.FieldWrapper>
              <FormInput
                value={jobNumber}
                onChange={setJobNumber}
                placeholder={copy.project.requestJobNumber.jobNumberFieldLabel}
                required
                error={
                  isDuplicateError
                    ? copy.app.validatorMessages.jobNumberDuplicated
                    : isValid
                    ? invalidMessage
                    : forceError
                    ? copy.app.validatorMessages.jobNumberRequired
                    : ''
                }
                forceError={true}
              />
            </Styled.FieldWrapper>
          </>
        )}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(JobNumberFillModal);
