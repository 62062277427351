import { FC, memo } from 'react';

import * as Styled from './PageLoader.styles';

export interface PageLoaderProps {}

const PageLoader: FC<PageLoaderProps> = () => {
  return <Styled.Wrapper />;
};

export default memo(PageLoader);
