import styled from 'styled-components';

import { brightnessFilter } from '@u9/bob3-shared/lib/utils/styles/mixins';
import { focusVisible, hover } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  width: 60rem;
  height: 60rem;
  border-radius: 50%;
  background: ${colors.outerSpace};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 80rem;
  right: 160rem;
  cursor: pointer;

  ${hover(brightnessFilter)};

  &:focus-visible {
    ${focusVisible};
  }
`;

export const Icon = styled.div`
  width: 40rem;
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
